import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  TextInput
} from "react-native";
import Checkbox from "@material-ui/core/Checkbox";
import ReassignMaterialController from "./ReassignMaterialController";
import scale, { verticalScale } from "../../../../../components/src/Scale";

class ReassignMaterial extends ReassignMaterialController {
  renderHaderContainer = () => {
    return (
      <View style={styles.headerWrapper}>
        <TouchableOpacity
          onPress={() => this.props.navigation.goBack()}
          style={styles.leftArrowIconWrapper}
        >
          <Image
            source={require("../../../assets/leftArrow.png")}
            style={styles.leftArrowIcon}
          />
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Reassign Material</Text>
      </View>
    );
  };

  renderSurgeryListConatiner = () => {
    return (
      <View style={styles.renderSurgeryListConatiner}>
        <Text style={styles.renderSurgeryListTextConatiner}>
          Surgery equipment(facility)
        </Text>
        {this.state.materialList &&
          this.state.materialList.length > 0 &&
          this.state.materialList.map((data: any, index: any) => {
            return (
              <View style={styles.boxImg1Container}>
                <Text style={styles.boxImgText}>
                  {index + 1}) {data.attributes.materials.data.attributes.name}
                </Text>
                <Checkbox
                  checked={data.isReassign}
                  style={{ color: "#000" }}
                  onChange={event => this._handleCheckBox(event, index)}
                />
              </View>
            );
          })}
      </View>
    );
  };

  renderInputContainer = () => {
    return (
      <View style={styles.renderInputContainer}>
        <Text style={styles.renderInputTextContainer}>Sales Rep Mail ID</Text>
        <View style={styles.inputTextContainer}>
          <TextInput
            placeholder="Enter Mail ID"
            style={styles.inputPlaceholderText}
            value={this.state.salesEmail}
            onChangeText={text => this.setState({ salesEmail: text })}
          />
          <View style={styles.lineView} />
        </View>
      </View>
    );
  };

  renderButtonContainer = () => {
    return (
      <TouchableOpacity
        style={styles.renderButtonContainer}
        onPress={() => this._handleReAssignedMaterial()}
      >
        <Text style={styles.renderButtonTeexContainer}>
          Assign to sales rep
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    const role_id: any = JSON.parse(localStorage.getItem("role") || "{}");

    return (
      <View style={styles.container}>
        {this.renderHaderContainer()}
        {this.renderSurgeryListConatiner()}
        {role_id === 4 && this.renderInputContainer()}
        {this.renderButtonContainer()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  renderHaderContainer: {
    height: scale(72),
    backgroundColor: "#4e3e71",
    flexDirection: "row",
    alignItems: "center"
  },
  leftArrowImg: {
    marginLeft: scale(24),
    width: scale(24),
    height: scale(24)
  },
  renderHaderTextContainer: {
    color: "#e8fafe",
    marginLeft: scale(64),
    fontSize: scale(20)
  },
  boxImg: {
    width: scale(24),
    height: scale(24)
    // resizeMode: "contain"
  },
  renderSurgeryListConatiner: {
    marginTop: verticalScale(54),
    paddingLeft: scale(29),
    paddingRight: scale(43)
  },
  renderSurgeryListTextConatiner: {
    fontSize: scale(20),
    color: "#4e3e71",
    lineHeight: scale(22),
    fontWeight: "bold",
    marginBottom: 20
  },
  boxImg1Container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  boxImgText: {
    color: "#7f7f7f",
    fontSize: scale(14)
  },
  inputPlaceholderText: {
    fontSize: scale(15),
    lineHeight: scale(22),
    color: "#b2bac6"
  },
  renderInputTextContainer: {
    fontSize: scale(20),
    lineHeight: scale(22),
    fontWeight: "bold",
    color: "#4e3e71"
  },
  renderInputContainer: {
    marginTop: verticalScale(22),
    paddingLeft: scale(30)
  },
  inputTextContainer: {
    marginTop: verticalScale(29)
  },
  lineView: {
    width: scale(327),
    height: scale(1),
    backgroundColor: "#e5e5e5",
    marginTop: verticalScale(15)
  },
  renderButtonContainer: {
    width: scale(327),
    height: scale(54),
    backgroundColor: "#4e3e71",
    alignSelf: "center",
    marginBottom: scale(24),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: scale(12),
    bottom: scale(0),
    position: "absolute"
  },
  renderButtonTeexContainer: {
    color: "#e8fafe",
    lineHeight: scale(22),
    fontSize: scale(17)
  }
});

export default ReassignMaterial;
