import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    FlatList,
    TextInput
} from "react-native";

import EditProfileController, {
    Props,
} from "./EditProfileController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../assets'

//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";

class EditProfile extends EditProfileController {

    renderHaderContainer = () => {
        return (
            <View style={styles.renderHaderContainer}>
             <TouchableOpacity onPress={()=> this.props.navigation.goBack()}>
                <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                </TouchableOpacity>
                <Text style={styles.renderHaderTextContainer}>Profile</Text>
            </View>
        )
    }

    renderImgContainer = () => {
        return (
            <View style={styles.renderImgContainer}>
                <Image source={IMG_CONST.personImg} style={styles.personImg} />
            </View>
        )
    }

    renderProfileInformationContainer = () => {
        return (
            <View style={styles.renderBasicInformationContainer}>
                <View>
                    <Text style={styles.renderBasicInformationText}>Frist Name</Text>
                    <View style={styles.editIconContainer}>
                        <TextInput placeholder="Kaixa" style={styles.renderBasicInformationTextInput} />
                        <Image source={IMG_CONST.editIcon} style={styles.editIconImg} />
                    </View>
                    <View style={styles.lineView} />
                </View>
                <View style={styles.renderBasicInformationSubContainer} >
                    <Text style={styles.renderBasicInformationText}>Last Name</Text>
                    <View style={styles.editIconContainer}>
                        <TextInput placeholder="Pham" style={styles.renderBasicInformationTextInput} />
                        <Image source={IMG_CONST.editIcon} style={styles.editIconImg} />
                    </View>
                    <View style={styles.lineView} />
                </View>
                <View style={styles.renderBasicInformationSubContainer} >
                    <Text style={styles.renderBasicInformationText}>Date of Birth</Text>
                    <View style={styles.editIconContainer}>
                        <TextInput placeholder="09/21/1995" style={styles.renderBasicInformationTextInput} />
                        <Image source={IMG_CONST.editIcon} style={styles.editIconImg} />
                    </View>
                    <View style={styles.lineView} />
                </View>
                <View style={styles.renderBasicInformationSubContainer}>
                    <Text style={styles.renderBasicInformationText}>Phone Number</Text>
                    <TextInput placeholder="213-3989-4593" style={styles.renderBasicInformationTextInput} />
                    <View style={styles.lineView} />
                </View>
                <View style={styles.renderBasicInformationSubContainer} >
                    <Text style={styles.renderBasicInformationText}>Address</Text>
                    <View style={styles.editIconContainer}>
                        <TextInput placeholder="23 Estean, New York City, USA" style={styles.renderBasicInformationTextInput} />
                        <Image source={IMG_CONST.editIcon} style={styles.editIconImg} />
                    </View>
                    <View style={styles.lineView} />
                </View>
                <View style={styles.renderBasicInformationSubContainer} >
                    <Text style={styles.renderBasicInformationText}>Email</Text>
                    <View style={styles.editIconContainer}>
                        <TextInput placeholder="Kaixa@tmrw.studio" style={styles.renderBasicInformationTextInput} />
                        <Image source={IMG_CONST.editIcon} style={styles.editIconImg} />
                    </View>
                    <View style={styles.lineView} />
                </View>
                <View style={styles.renderBasicInformationSubContainer} >
                    <Text style={styles.renderBasicInformationText}>Gender</Text>
                    <View style={styles.editIconContainer}>
                        <TextInput placeholder="Male" style={styles.renderBasicInformationTextInput} />
                        <Image source={IMG_CONST.editIcon} style={styles.editIconImg} />
                    </View>
                    <View style={styles.lineView} />
                </View>
            </View>
        )
    }

    renderButtonConatiner = () => {
        return (
            <TouchableOpacity style={styles.renderButtonContainer}>
                <Text style={styles.renderButtonTextContainer}>Save Changes</Text>
            </TouchableOpacity>

        )
    }

    render() {
        return (
            <View style={styles.container}>
                {this.renderHaderContainer()}
                {this.renderImgContainer()}
                {this.renderProfileInformationContainer()}
                {this.renderButtonConatiner()}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

    },

    renderHaderContainer: {
        height: scale(72),
        backgroundColor: "#4e3e71",
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: scale(24)
    },

    leftArrowImg: {
        width: scale(24),
        height: scale(24),
        resizeMode: 'contain'
    },

    renderHaderTextContainer: {
        color: "#e8fafe",
        fontSize: scale(20),
        marginLeft: scale(125)
    },

    renderImgContainer: {
        width: scale(96),
        height: scale(96),
        backgroundColor: 'skyblue',
        marginLeft: scale(24),
        marginTop: verticalScale(30),
        alignItems: 'center',
        borderRadius: scale(100),
        justifyContent: 'center'
    },

    personImg: {
        width: scale(96),
        height: scale(96),
        resizeMode: 'contain',
        borderRadius:scale(96)
    },

    renderBasicInformationContainer: {
        marginTop: verticalScale(25),
        paddingLeft: scale(24)
    },

    renderBasicInformationText: {
        color: '#4e3e71',
        fontSize: scale(12),
        lineHeight: scale(18)
    },

    renderBasicInformationTextInput: {
        fontSize: scale(15),
        fontWeight: 'bold',
        color: '#ddd',
        lineHeight: scale(22),
        marginTop: verticalScale(5),
        width: scale(250)
    },

    lineView: {
        width: scale(370),
        height: scale(1),
        backgroundColor: '#e5e5e5',
        alignSelf: 'center',
        marginTop: verticalScale(19),
        marginRight: scale(20)
    },

    editIconImg: {
        width: scale(18),
        height: scale(18),
        resizeMode: "contain",
        marginTop:verticalScale(10)
    },

    editIconContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: verticalScale(5),
        paddingRight: scale(24)
    },

    renderBasicInformationSubContainer: {
        marginTop: verticalScale(17)
    },

    renderButtonContainer: {
        width:scale(360),
        height:scale(54),
        backgroundColor:"#4e3e71",
        borderRadius:scale(12),
        alignSelf:'center',
        marginTop:verticalScale(78),
        marginBottom:verticalScale(27),
        alignItems:'center',
        justifyContent:'center'
    },

    renderButtonTextContainer: {
        color:"#e8fafe",
        lineHeight:scale(22),
        fontSize:scale(17)
    }


})


export default EditProfile;