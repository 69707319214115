// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  FlatList,
  ActivityIndicator,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  getAxiosAPI,
  getErrorMessage,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import {
  getSessionDate,
  getBookingTime,
  getTimeStemp,
  SortData,
} from "../../../framework/src/Utilities";
import Scale from "../../../components/src/Scale";
// @ts-ignore
import GenerateReportCustomTable from "../../../framework/src/GenerateReportCustomTable";
import CustomPopUp from "../../../framework/src/CustomPopUp";

const tableComponents = [
  "Email ID",
  "User Name",
  "Facility/Hospital Name",
  "Facility/Hospital ID",
  "IP Address",
  "Session Date & Time",
  "Booking ID",
  "Impacted Booking Detail Field 1",
  "Impacted Booking Detail Field 2",
];

export default function DesktopSurgeryDetails({
  navigation,
}: {
  navigation: any;
}) {
  const [selectedDate, setselectedDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [apiReport, setApiReport] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [errormsg, setErrorMsg] = useState("");

  const [showList, setShowlist] = useState({
    surgeon: true,
    cpt: false,
    facility: false,
  });

  const [Names, setNames] = useState({
    surgeon: "",
    cpt: "",
    facility: "",
    surgeon_id: "",
    facility_id: "",
  });

  const ListEmptyView = () => {
    return (
      <View>
        <Text style={styles.heading}>No Record Found</Text>
      </View>
    );
  };
  const ListEmptyViewTable = () => {
    return (
      <View style={{ display: "flex", height: "100%" }}>
        <Text style={styles.heading2}>No Record Found</Text>
      </View>
    );
  };

  const getHospitals = async (search: any = "") => {
    setLoader(true);
    let url = `/bx_block_hospitals/hospitals?search=${search}&limit=10`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        setLoader(false);
      } else {
        setHospitals(
          res.data.data.sort((a: any, b: any) =>
            SortData(
              a.attributes.name.toLowerCase(),
              b.attributes.name.toLowerCase()
            )
          )
        );
        setLoader(false);
      }
    });
  };

  const handleSearch = (text: any, type: any) => {
    setNames((preVal) => ({ ...preVal, [type]: text }));
    if (type === "facility") {
      if(!text.length){
        setNames((pre) => ({...pre, facility_id:''}))
      }
      getHospitals(text);
    }
  };

  useEffect(() => {
    const isPrimary = JSON.parse(
      localStorage.getItem("loggedinUserInfo") || "{}"
    );
    if (!isPrimary.is_primary_contact) {
      navigation.goBack();
    } else {
      getHospitals();
    }
  }, []);

  const getBookings = async () => {
    setLoading(true);
    let startDate = getTimeStemp(selectedDate.start)
    let endDate = getTimeStemp(selectedDate.end)
    let url = `/bx_block_order/orders/sessions_report?start_date=${startDate}&end_date=${endDate + 86387000}&hospital_id=${
      Names.facility_id
    }`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setErrorMsg(msg);
        setPopup(true);
        setLoading(false);
      } else {
        let reqData = res.data.data.map((x: any) => ({
          EmailID: x.attributes.email,
          UserName: x.attributes.user_name,
          Facility: x.attributes.facility_name,
          FacilityId: x.attributes.facility_id,
          UserIp: x.attributes.ip_address,
          session_dateTime: x.attributes.session_datetime.split(',').join(' '),
          BookingId: x.attributes.booking_id,
          ImpactedField: getSessionDate(parseInt(x.attributes.impacted_field[0]))+" at "+getBookingTime(parseInt(x.attributes.impacted_field[0])),
          ImpactedField2: x.attributes.impacted_field[1],
        }));
        setApiReport(
          reqData.sort((a: any, b: any) => SortData(a.UserName, b.UserName))
        );
        setLoading(false);
      }
    });
  };

  const handleDateChange = (date: any, type: string) => {
    setselectedDate((perval: any) => ({ ...perval, [type]: date }));
  };

  const downloadFile = ({
    data,
    fileName,
    fileType,
  }: {
    data: any;
    fileName: any;
    fileType: any;
  }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e: any) => {
    e.preventDefault();
    let headers = [tableComponents.join(",")];
    let usersCsv = apiReport.reduce((acc: any, user: any) => {
      const {
        EmailID,
        UserName,
        Facility,
        FacilityId,
        UserIp,
        session_dateTime,
        BookingId,
        ImpactedField,
        ImpactedField2,
      } = user;
      acc.push(
        [
          EmailID,
          UserName,
          Facility,
          FacilityId,
          UserIp,
          session_dateTime,
          BookingId,
          ImpactedField,
          ImpactedField2,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "Sessions Report.csv",
      fileType: "text/csv",
    });
  };

  const tableRenderer = () => {
    return (
      <>
        <GenerateReportCustomTable
          apiReport={apiReport}
          header={tableComponents}
        />
      </>
    );
  };

  const renderPopUp = () => {
    if (popup) {
      return (
        <CustomPopUp
          message={errormsg}
          btnText={"Back"}
          ClosePopUp={() => setPopup(false)}
        />
      );
    }
  };

  const renderFacilityList = () => {
    if (showList.facility) {
      if (loader) {
        return (
          <ActivityIndicator
            style={{
              marginHorizontal: 30,
              height: 60,
              width: 60,
            }}
            size="large"
            color="#4e3e71"
          />
        );
      } else {
        return (
          <FlatList
            data={hospitals}
            ListEmptyComponent={ListEmptyView()}
            keyExtractor={(item: any) => item.id}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.onselect}
                onPress={() =>
                  setNames((preVal: any) => ({
                    ...preVal,
                    facility: item.attributes.name,
                    facility_id: item.id,
                  }))
                }
              >
                <Text style={styles.listText}>{item.attributes.name}</Text>
              </TouchableOpacity>
            )}
          />
        );
      }
    }
  };

  const renderMobtable = () => {
    if (loader) {
      return (
        <ActivityIndicator
          style={{ margin: "auto", height: 100, width: 100 }}
          size="large"
          color="#4e3e71"
        />
      );
    } else {
      return (
        <View style={styles.tableView}>
          {apiReport.length ? (
            <>
              <Text style={styles.heading3}>
                Data from date range{" "}
                {new Date(selectedDate.start).toLocaleDateString()} -{" "}
                {new Date(selectedDate.end).toLocaleDateString()}
              </Text>

              <View>
                <ScrollView showsHorizontalScrollIndicator={true}>
                  {tableRenderer()}
                </ScrollView>
              </View>
              <TouchableOpacity
                style={styles.reportBtn}
                onPress={(e) => exportToCsv(e)}
              >
                <Text style={styles.reportText}>Export to CSV</Text>
              </TouchableOpacity>
            </>
          ) : <View style={styles.emptyMob}>
          <Text style={styles.heading}>No Record Found</Text>
        </View>}
        </View>
      );
    }
  };

  return (
    <>
      {renderPopUp()}
      {window.screen.width < 950 ? (
        <View>
          <View style={styles.headingView}>
            <TouchableOpacity
              style={styles.leftArrowIconWrapper}
              onPress={() => navigation.goBack()}
            >
              <img
                src={require("../assets/leftArrow1.png")}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TouchableOpacity>
            <Text style={styles.headingh}>Generate Sessions Report </Text>
          </View>
          <View style={styles.outerCard}>
            <View>
              <Text style={styles.heading1}>Select Facility</Text>
              <View>
                <TextInput
                  placeholder="Search Facility "
                  placeholderTextColor="#b2bac6"
                  style={styles.input}
                  onFocus={() =>
                    setShowlist((preVal: any) => ({
                      ...preVal,
                      facility: true,
                    }))
                  }
                  onBlur={() =>
                    setShowlist((preVal: any) => ({
                      ...preVal,
                      facility: false,
                    }))
                  }
                  onChangeText={(queryText) =>
                    handleSearch(queryText, "facility")
                  }
                  value={Names.facility}
                />
                {renderFacilityList()}
              </View>
              <Text style={styles.heading1}>Select Start Date</Text>
              <View style={styles.pickerContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ marginLeft: 10 }}
                    openTo="date"
                    format="MM/dd/yyyy"
                    views={["year", "month", "date"]}
                    value={selectedDate.start}
                    onChange={(date: any) => handleDateChange(date, "start")}
                  />
                </MuiPickersUtilsProvider>
              </View>
              <Text style={styles.heading1}>Select End Date</Text>
              <View style={styles.pickerContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{ marginLeft: 10 }}
                    openTo="date"
                    format="MM/dd/yyyy"
                    views={["year", "month", "date"]}
                    value={selectedDate.end}
                    onChange={(date: any) => handleDateChange(date, "end")}
                  />
                </MuiPickersUtilsProvider>
              </View>
              <TouchableOpacity
                style={styles.reportBtn}
                onPress={() => getBookings()}
              >
                <Text style={styles.reportText}>Get Report </Text>
              </TouchableOpacity>
            </View>
            {renderMobtable()}
          </View>
        </View>
      ) : (
        <View style={styles.desktopContainer}>
          <DesktopHeader
            heading="Generate Sessions Report"
            navigation={navigation}
          />
          <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={styles.outerCardMain}>
              <View style={styles.outerCardWeb}>
                <View style={styles.innerWebRow}>
                  <View style={styles.formikView}>
                    <Text style={styles.heading1}>Select Facility</Text>
                    <View>
                      <TextInput
                        placeholder="Search Facility "
                        placeholderTextColor="#b2bac6"
                        style={styles.input}
                        onFocus={() =>
                          setShowlist((preVal: any) => ({
                            ...preVal,
                            facility: true,
                          }))
                        }
                        onBlur={() =>
                          setShowlist((preVal: any) => ({
                            ...preVal,
                            facility: false,
                          }))
                        }
                        onChangeText={(queryText) =>
                          handleSearch(queryText, "facility")
                        }
                        value={Names.facility}
                      />
                      {showList.facility && (
                        <View>
                          {loader ? (
                            <ActivityIndicator
                              style={{
                                marginHorizontal: 30,
                                height: 60,
                                width: 60,
                              }}
                              size="large"
                              color="#4e3e71"
                            />
                          ) : (
                            <FlatList
                              data={hospitals}
                              ListEmptyComponent={ListEmptyView()}
                              keyExtractor={(item: any) => item.id}
                              renderItem={({ item }) => (
                                <TouchableOpacity
                                  style={styles.onselect}
                                  onPress={() =>
                                    setNames((preVal: any) => ({
                                      ...preVal,
                                      facility: item.attributes.name,
                                      facility_id: item.id,
                                    }))
                                  }
                                >
                                  <Text style={styles.listText}>
                                    {item.attributes.name}
                                  </Text>
                                </TouchableOpacity>
                              )}
                            />
                          )}
                        </View>
                      )}
                    </View>

                    <Text style={styles.heading1}>Select Start Date</Text>
                    <View style={styles.pickerContainer}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          style={{ marginLeft: 10 }}
                          openTo="date"
                          format="MM/dd/yyyy"
                          views={["year", "month", "date"]}
                          value={selectedDate.start}
                          onChange={(date: any) =>
                            handleDateChange(date, "start")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </View>
                    <Text style={styles.heading1}>Select End Date</Text>
                    <View style={styles.pickerContainer}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          style={{ marginLeft: 10 }}
                          openTo="date"
                          format="MM/dd/yyyy"
                          views={["year", "month", "date"]}
                          value={selectedDate.end}
                          onChange={(date: any) =>
                            handleDateChange(date, "end")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </View>
                    <TouchableOpacity
                      style={styles.reportBtn}
                      onPress={() => getBookings()}
                    >
                      <Text style={styles.reportText}>Get Report </Text>
                    </TouchableOpacity>
                  </View>
                  {loading ? (
                    <ActivityIndicator
                      style={{ margin: "auto", height: 100, width: 100 }}
                      size="large"
                      color="#4e3e71"
                    />
                  ) : (
                    <View style={styles.tableView}>
                      {apiReport.length ? (
                        <>
                          <Text style={styles.heading3}>
                            Data from date range{" "}
                            {new Date(selectedDate.start).toLocaleDateString()}{" "}
                            - {new Date(selectedDate.end).toLocaleDateString()}
                          </Text>
                          <View>
                            <ScrollView
                              showsHorizontalScrollIndicator={false}
                              style={{ overflow: "scroll", height: 450 }}
                            >
                              {tableRenderer()}
                            </ScrollView>
                          </View>
                          <View style={styles.generateBtn}>
                            <TouchableOpacity
                              style={styles.reportBtn}
                              onPress={(e) => exportToCsv(e)}
                            >
                              <Text style={styles.reportText}>
                                Export to CSV
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </>
                      ) : (
                        ListEmptyViewTable()
                      )}
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  outerCardMain: { flex: 0.85, overflowY: "scroll" },
  emptyMob:{display:'flex', margin :'auto', paddingVertical: 50},
  generateBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
  },
  topWebHeading: {
    borderBottomWidth: 2,
    borderBottomColor: "#ededed",
    paddingHorizontal: 26,
    paddingBottom: 20,
  },
  webHeading: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#4e3e71",
  },
  innerWebRow: {
    display: "flex",
    flexDirection: "row",
  },
  scrollTableWeb: {
    flex: 1,
    flexDirection: "column",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  headingh: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  heading: {
    marginTop: 30,
    marginHorizontal: 20,
    color: "#b2bac6",
    fontSize: 16,
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "center",
  },
  heading2: {
    margin: "auto",
    color: "#b2bac6",
    fontSize: 18,
    fontWeight: "bold",
    flexDirection: "row",
    justifyContent: "center",
  },
  onselect: {
    maxWidth: "60%",
  },
  listText: {
    color: "#4e3e71",
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderColor: "#e5e5e5",
    borderBottomWidth: 1,
    marginHorizontal: 2,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#e5e5e5",
    borderBottomWidth: 1,
  },
  tableHeading: {
    borderRadius: 6,
    paddingHorizontal: 5,
    flex: 1,
    minWidth: 120,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input: {
    outlineStyle: "none",
    height: 50,
    borderRadius: 6,
    maxWidth: "60%",
    paddingLeft: 10,
    borderColor: "#e5e5e5",
    borderWidth: 1,
  },
  tableHeadingText: {
    color: "#4e3e71",
    fontSize: 16,
    minWidth: 120,
    fontWeight: "bold",
    paddingVertical: 10,
    paddingHorizontal: 2,
    backgroundColor: "#e5e5e5",
    borderLeftWidth: 1,
    borderLeftColor: "grey",
  },
  heading1: {
    color: "#4e3e71",
    fontSize: 18,
    fontWeight: "bold",
    paddingVertical: 10,
  },
  heading3: {
    color: "#4e3e71",
    fontSize: 18,
    fontWeight: "bold",
    paddingVertical: 20,
    textAlign: "center",
  },
  reportBtn: {
    maxWidth: "60%",
    backgroundColor: "#4e3e71",
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 6,
    marginVertical: 10,
  },
  tableData: {
    color: "#4e3e71",
    flex: 0.8,
    width: Scale(20),
    textAlign: "left",
    fontSize: 14,
    paddingVertical: 3,
    backgroundColor: "pink",
  },
  formikView: {
    flex: 0.2,
    flexDirection: "column",
  },
  tableView: {
    flex: 0.8,
    flexDirection: "column",
  },
  reportText: {
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  outerCard: {
    flex: 1,
    margin: 20,
  },
  outerCardWeb: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 15,
    margin: 15,
    flexDirection: "column",
    minHeight: "auto",
  },
  pickerContainer: {
    borderWidth: 1,
    borderRadius: 6,
    maxWidth: "60%",
    borderColor: "#e5e5e5",
    paddingVertical: 10,
  },
  picker: {
    height: 50,
    color: "#4e3e71",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
  },
});
// Customizable Area End
