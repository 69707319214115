// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import scale from "../../../framework/src/Scale";

export default function CartList({ navigation }: { navigation: any }) {
  const order = JSON.parse(localStorage.getItem("order") || "{}");
  const SelectedMaterails = JSON.parse(
    localStorage.getItem("selectedMaterialList") || "[]"
  );
  const requestedMaterials = JSON.parse(
    localStorage.getItem("requestedMaterials") || "[]"
  );
  const userdata = JSON.parse(localStorage.getItem("loggedinUserInfo") || "{}");

  const filteredArr = SelectedMaterails.reduce((unique: any, o: any) => {
    if (
      !unique.some(
        (obj: any) => obj.material_id === o.material_id && obj.size === o.size
      )
    ) {
      unique.push(o);
    }
    return unique;
  }, []);

  const filteredArr2 = requestedMaterials.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.name === o.name)) {
      unique.push(o);
    }
    return unique;
  }, []);

  const [list, setlist] = useState(filteredArr);
  console.log(list);
  const [reqMaterials, setReqMaterials] = useState(filteredArr2);
  console.log(reqMaterials);

  const increaseQuant = (id: any) => {
    let newList = list.map((x: any) => {
      if (x.uniqueID === id && x.quantity < 10) {
        x.quantity = x.quantity + 1;
      }
      return x;
    });
    setlist(newList);
  };

  const increaseQuant2 = (id: any) => {
    let newList1 = reqMaterials.map((x: any) => {
      if (x.material_id === id && x.quantity < 10) {
        x.quantity = x.quantity + 1;
      }
      return x;
    });
    setReqMaterials(newList1);
  };

  const decreaseQuant = (id: any) => {
    let newList = list.map((x: any) => {
      if (x.uniqueID === id && x.quantity > 1) {
        x.quantity = x.quantity - 1;
      }
      return x;
    });
    setlist(newList);
  };

  const decreaseQuant2 = (id: any) => {
    let newList1 = reqMaterials.map((x: any) => {
      if (x.material_id === id && x.quantity > 1) {
        x.quantity = x.quantity - 1;
      }
      return x;
    });
    setReqMaterials(newList1);
  };

  const deleteItem = (item: any) => {
    const newArr = list.filter((y: any) => y.uniqueID !== item.uniqueID);
    setlist(newArr);
    console.log(item);
    localStorage.setItem("selectedMaterialList", JSON.stringify(newArr));
  };

  const deleteReqItem = (item: any) => {
    const newArr = reqMaterials.filter(
      (y: any) => y.material_id !== item.material_id
    );
    setReqMaterials(newArr);
    localStorage.setItem("requestedMaterials", JSON.stringify(newArr));
  };

  const navigateTo = () => {
    localStorage.setItem("selectedMaterialList", JSON.stringify(list));
    navigation.navigate("BookingSummary");
  };

  return window.screen.width < 950 ? (
    <View style={styles.container}>
      <View>
        <View style={styles.headingView}>
          <TouchableOpacity
            style={styles.leftArrowIconWrapper}
            onPress={() => navigation.goBack()}
          >
            <img
              src={require("../assets/leftArrow1.png")}
              style={{
                height: 24,
                width: 24,
              }}
            />
          </TouchableOpacity>
          <Text style={styles.heading}>Cart List</Text>
        </View>

        <View style={styles.card}>
          <Text style={styles.color}>{order.hospital_name}</Text>
          <View style={styles.profileDetails}>
            <img
              src={
                order.patient_imageDemo
                  ? order.patient_imageDemo
                  : order.patient_gender === "Male"
                  ? require("../assets/patient_male.png")
                  : require("../assets/patient_female.png")
              }
              style={{ height: 66, width: 66, borderRadius: 6 }}
            />
            <View style={styles.names}>
              <Text numberOfLines={2} style={styles.info}>
                <Text style={{ color: "#b2bac6" }}>Patient Name:</Text>{" "}
                {order.first_name + " " + order.last_name}{" "}
              </Text>
              <Text numberOfLines={2} style={styles.info}>
                <Text style={{ color: "#b2bac6" }}>Doctor Name: </Text>
                {userdata.first_name + " " + userdata.last_name}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.BottomInfo}>
          <Text style={styles.textC}>Implants & Instrumentation</Text>
          <TouchableOpacity
            onPress={() => navigation.navigate("SelectManufacture")}
          >
            <Text style={styles.addMoreBtn}>Add More</Text>
          </TouchableOpacity>
        </View>
        <ScrollView style={{ maxHeight: 350 }}>
          <FlatList
            data={list}
            keyExtractor={(item: any) => item.uniqueID}
            renderItem={({ item }) => (
              <View style={styles.material}>
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <img
                      src={
                        item.image
                          ? item.image
                          : require("../assets/material2.png")
                      }
                      style={{ height: 72, width: 72, padding: 5 }}
                    />
                  </View>
                  <View style={{ flexDirection: "column" }}>
                    <Text style={styles.info}>{item.material_name}</Text>
                    <Text
                      style={{
                        color: "#4e3e71",
                        fontSize: 15,
                        paddingVertical: 10,
                      }}
                    >
                      Size: {item.size}
                    </Text>
                    <View style={styles.quantity}>
                      <TouchableOpacity
                        onPress={() => increaseQuant(item.uniqueID)}
                      >
                        <Text style={styles.btn}>+</Text>
                      </TouchableOpacity>

                      <Text style={styles.btn2}>
                        {item.quantity === 10
                          ? item.quantity
                          : "0" + item.quantity}
                      </Text>

                      <TouchableOpacity
                        onPress={() => decreaseQuant(item.uniqueID)}
                      >
                        <Text style={styles.btn}>-</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <TouchableOpacity onPress={() => deleteItem(item)}>
                  <img
                    src={require("../assets/delete2.png")}
                    style={{ height: 24, width: 24 }}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
          {requestedMaterials.length < 1 ? null : (
            <View>
              <View style={styles.BottomInfo}>
                <Text style={styles.textB}>Requested Material</Text>
              </View>
              <FlatList
                data={reqMaterials}
                keyExtractor={(item: any) => item.material_id}
                renderItem={({ item }) => (
                  <View style={styles.material}>
                    <View style={{ flexDirection: "row" }}>
                      <img
                        src={
                          item.image
                            ? item.image
                            : require("../assets/material2.png")
                        }
                        style={{ height: 72, width: 72 }}
                      />
                      <View style={{ flexDirection: "column" }}>
                        <Text
                          style={{
                            fontSize: 20,
                            color: "#2b2b2b",
                            maxWidth: scale(250),
                          }}
                          numberOfLines={2}
                        >
                          {item.name}
                        </Text>
                        <Text
                          style={{
                            color: "#4e3e71",
                            fontSize: 15,
                            paddingVertical: 10,
                          }}
                        >
                          Size: {item.size}
                        </Text>
                        <View style={styles.quantity}>
                          <TouchableOpacity
                            onPress={() => increaseQuant2(item.material_id)}
                          >
                            <Text style={styles.btn}>+</Text>
                          </TouchableOpacity>

                          <Text style={styles.btn2}>
                            {item.quantity === 10
                              ? item.quantity
                              : "0" + item.quantity}
                          </Text>

                          <TouchableOpacity
                            onPress={() => decreaseQuant2(item.material_id)}
                          >
                            <Text style={styles.btn}>-</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <TouchableOpacity onPress={() => deleteReqItem(item)}>
                      <img
                        src={require("../assets/delete2.png")}
                        style={{ height: 24, width: 24 }}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              />
            </View>
          )}
        </ScrollView>
      </View>
      <TouchableOpacity onPress={() => navigateTo()}>
        <View style={styles.button}>
          <Text style={styles.buttonText}>Order Confirm</Text>
        </View>
      </TouchableOpacity>
    </View>
  ) : (
    <View style={styles.desktopContainer}>
      <DesktopHeader heading={"Cart List"} navigation={navigation} />
      <View style={styles.bottomDesktopCard}>
        <DesktopMenue navigation={navigation} />
        <View style={{ flex: 0.85 }}>
          <View style={styles.outerCard}>
            <View style={styles.container}>
              <View style={styles.cardWeb}>
                <Text style={styles.color}>{order.hospital_name}</Text>
                <View style={styles.profileDetails}>
                  <img
                    src={
                      order.patient_imageDemo
                        ? order.patient_imageDemo
                        : order.patient_gender === "Male"
                        ? require("../assets/patient_male.png")
                        : require("../assets/patient_female.png")
                    }
                    style={{ height: 66, width: 66, borderRadius: 6 }}
                  />
                  <View style={styles.names}>
                    <Text style={styles.info}>
                      <Text style={{ color: "#b2bac6" }}>Patient Name: </Text>
                      {order.first_name + " " + order.last_name}{" "}
                    </Text>
                    <Text style={styles.info}>
                      <Text style={{ color: "#b2bac6" }}>Doctor Name:</Text>{" "}
                      {userdata.first_name + " " + userdata.last_name}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.BottomInfo}>
                <Text style={styles.textB}>Implants & Instrumentation</Text>
                <TouchableOpacity
                  onPress={() => navigation.navigate("SelectManufacture")}
                >
                  <Text style={styles.addMoreBtn}>Add More</Text>
                </TouchableOpacity>
              </View>
              <ScrollView style={{ maxHeight: 300 }}>
                <FlatList
                  data={list}
                  keyExtractor={(item: any) => item.uniqueID}
                  renderItem={({ item }) => (
                    <View style={styles.materialWeb}>
                      <View style={{ flexDirection: "row" }}>
                        <img
                          src={
                            item.image
                              ? item.image
                              : require("../assets/material2.png")
                          }
                          style={{ height: 72, width: 72, padding: 10 }}
                        />
                        <View style={{ flexDirection: "column" }}>
                          <Text numberOfLines={3} style={styles.infoWeb}>
                            {item.material_name} 
                          </Text>
                          <Text
                            style={{
                              color: "#4e3e71",
                              fontSize: 15,
                              paddingVertical: 10,
                            }}
                          >
                            Size: {item.size}
                          </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <View style={styles.quantity}>
                          <TouchableOpacity
                            onPress={() => increaseQuant(item.uniqueID)}
                          >
                            <Text style={styles.btn}>+</Text>
                          </TouchableOpacity>

                          <Text style={styles.btn2}>
                            {item.quantity === 10
                              ? item.quantity
                              : "0" + item.quantity}
                          </Text>

                          <TouchableOpacity
                            onPress={() => decreaseQuant(item.uniqueID)}
                          >
                            <Text style={styles.btn}>-</Text>
                          </TouchableOpacity>
                        </View>
                        <TouchableOpacity
                          onPress={() => deleteItem(item)}
                          style={{
                            marginVertical: "auto",
                            marginHorizontal: 20,
                          }}
                        >
                          <img
                            src={require("../assets/delete2.png")}
                            style={{ height: 24, width: 24, paddingLeft: 5 }}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                />

                {requestedMaterials.length < 1 ? null : (
                  <View>
                    <View style={styles.BottomInfo}>
                      <Text style={styles.textB}>Requested Material</Text>
                    </View>
                    <FlatList
                      data={reqMaterials}
                      keyExtractor={(item: any) => item.material_id}
                      renderItem={({ item }) => (
                        <View style={styles.materialWeb}>
                          <View style={{ flexDirection: "row" }}>
                            <img
                              src={require("../assets/material2.png")}
                              style={{ height: 72, width: 72 }}
                            />
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontSize: 20,
                                  color: "#2b2b2b",
                                  width: scale(80),
                                }}
                                numberOfLines={2}
                              >
                                {item.name}
                              </Text>
                              <Text
                                style={{
                                  color: "#4e3e71",
                                  fontSize: 15,
                                  paddingVertical: 10,
                                }}
                              >
                                Size: {item.size}
                              </Text>
                            </View>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <View style={styles.quantity}>
                              <TouchableOpacity
                                onPress={() => increaseQuant2(item.material_id)}
                              >
                                <Text style={styles.btn}>+</Text>
                              </TouchableOpacity>

                              <Text style={styles.btn2}>
                                {item.quantity === 10
                                  ? item.quantity
                                  : "0" + item.quantity}
                              </Text>

                              <TouchableOpacity
                                onPress={() => decreaseQuant2(item.material_id)}
                              >
                                <Text style={styles.btn}>-</Text>
                              </TouchableOpacity>
                            </View>
                            <TouchableOpacity
                              style={{
                                marginVertical: "auto",
                                marginHorizontal: 20,
                              }}
                            >
                              <img
                                src={require("../assets/delete2.png")}
                                style={{ height: 24, width: 24 }}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    />
                  </View>
                )}
              </ScrollView>
              <TouchableOpacity onPress={() => navigateTo()}>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>Order Confirm</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffff",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  addMoreBtn: {
    color: "#4e3e71",
    fontSize: 20,
    textDecorationLine: "underline",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  outerCard: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    margin: 12,
    flexDirection: "column",
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  quantity: {
    flexDirection: "row",
    borderRadius: 5,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    margin: "auto",
    justifyContent: "space-between",
  },
  btn: {
    fontSize: 20,
    color: "#4e3e71",
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  btn2: {
    fontSize: 15,
    color: "#4e3e71",
    padding: 2,
    backgroundColor: "#cce9e5",
    borderRadius: 4,
    margin: 5,
    paddingHorizontal: 10,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
    marginTop: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  material: {
    maxWidth: 400,
    marginHorizontal: 24,
    marginVertical: 5,
    paddingVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: "#d8d8d8",
    borderRadius: 8,
  },
  materialWeb: {
    width: scale(120),
    marginHorizontal: 24,
    marginVertical: 5,
    paddingVertical: 10,
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#d8d8d8",
    borderRadius: 8,
  },
  textB: {
    fontSize: 24,
    color: "#4e3e71",
  },
  textC: {
    fontSize: 20,
    color: "#4e3e71",
  },
  BottomInfo: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  color: {
    color: "#4e3e71",
    fontSize: 24,
  },
  card: {
    flexDirection: "column",
    marginTop: 24,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 11,
    marginHorizontal: 24,
    marginVertical: 6,
    borderWidth: 1,
    borderColor: "#4e3e71",
  },
  cardWeb: {
    flexDirection: "column",
    marginTop: 24,
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 11,
    marginHorizontal: 24,
    borderWidth: 1,
    borderColor: "#4e3e71",
  },
  horizontalBorder: {
    borderBottomColor: "#939ca3",
    borderBottomWidth: 1,
    padding: 15,
    borderColor: "grey",
    flexDirection: "row",
    justifyContent: "center",
  },
  profileDetails: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 20,
    marginBottom: 20,
  },
  names: {
    paddingHorizontal: 16,
  },
  image: {},
  info: {
    color: "#4e3e71",
    width: scale(250),
    fontSize: 16,
    padding: 2,
  },
  infoWeb: {
    color: "#4e3e71",
    width: scale(90),
    fontSize: 16,
    padding: 2,
  },
});
// Customizable Area End
