import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  ImageBackground,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import RolesPermissions2Controller, {
  Props,
  configJSON
} from "./RolesPermissions2Controller";


export default class RolesPermissions2 extends RolesPermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  async componentDidMount() {
    const token = localStorage.getItem('authToken')
    if (token) {
      console.log("token present")
      this.props.navigation.goBack()
    } 
  }

  

  render() {
    const { navigation } = this.props

    // console.log("width", window.screen.width)
    // console.log("hight", window.screen.height)
    // console.log("scroll", window.pageXOffset)
    // console.log(this.props, this.props.navigation)
  
 
    
    return (
      // Customizable Area Start
      window.screen.width < 950 ? 
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
         {/* ------------------------------New Data----------------------------------------- */}
          <View style={styles.container}>
      
          <View style={styles.card}>
              <img src={require("../assets/illustration-register-copy@3x.png")} style={{
            width: 216,
            height: 234,
            position: 'absolute',
            top: -165,
            left: 180,
            zIndex: 99
            }}/>

      <View style={styles.cardContent}>
            <Text style={styles.heading}>Welcome</Text>
            <Text style={styles.para}>Find best materials and equipments for your next surgery</Text>
            <TouchableOpacity onPress={() => this.navigateToNext('1')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>I Am A Surgeon </Text>
                        </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToNext('2')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>I Am A Material Manager</Text>
                        </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToNext('3')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>I Am A Booking Coordinator</Text>
                        </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToNext('4')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>I Am A Sales Representative</Text>
                        </View>
            </TouchableOpacity>
            
            <Text style={styles.para1}>By continuing you agree to our {'\n'}
            <TouchableOpacity onPress={() => navigation.navigate('TermsCondition')}>
            <Text style={{fontWeight: "bold", color: '#4e3e71'}}>Terms and Conditions.</Text>
            </TouchableOpacity>
            </Text>

      </View>
      </View>

</View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
        </ScrollView>
        :
        
        <View style={styles.desktopContainer}>
          <ImageBackground source={require('../assets/group.png')} resizeMode="cover" style={styles.image}>
            <View style={styles.upmedlogo}>
              <img src={require("../assets/upmed.png")} style={{height: 56, width: 140, paddingBottom: 20}} />
       
          <View style={styles.desktopCard}>
             <View style={styles.cardContent}>
            <Text style={styles.deskheading}>Welcome</Text>
            <Text style={styles.deskpara}>Find best materials and equipments for your next surgery</Text>
            <TouchableOpacity onPress={() => this.navigateToNext('1')}>
                        <View style={styles.button2}>
                        <Text style={styles.buttonText2}>I Am A Surgeon </Text>
                        </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToNext('2')}>
                        <View style={styles.button2}>
                        <Text style={styles.buttonText2}>I Am A Material Manager</Text>
                        </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToNext('3')}>
                        <View style={styles.button2}>
                        <Text style={styles.buttonText2}>I Am A Booking Coordinator</Text>
                        </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.navigateToNext('4')}>
                        <View style={styles.button2}>
                        <Text style={styles.buttonText2}>I Am A Sales Representative</Text>
                        </View>
            </TouchableOpacity>
            
            <Text style={styles.para1}>By continuing you agree to our {'\n'}
            <TouchableOpacity onPress={() => navigation.navigate('TermsCondition')}>
            <Text style={{fontWeight: "bold", color: '#4e3e71'}}>Terms and Conditions.</Text>
            </TouchableOpacity>
            </Text>

      </View>
         </View>
         </View>
         </ImageBackground>
        </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#4e3e71",
    flexDirection: 'column-reverse',
    
  },
  upmedlogo: {
    margin: 'auto',
    alignItems: 'center'
  },
    image: {
    flex: 1,
    justifyContent: "center"
  },
  desktopContainer: {
    flex: 1,

  },
  desktopCard: {
    backgroundColor: '#e8fafe',
    marginHorizontal: 'auto',
    borderRadius: 40,
    Width: 450
  },
  card: {
    position: 'relative',
    borderTopRightRadius: 24,
    backgroundColor: '#e8fafe',
    // paddingHorizontal: 24
    
  },
  cardContent: {
    marginHorizontal: 24,
    marginVertical: 20,
  },
  heading: {
    color: '#4e3e71',
    fontSize: 32,
    justifyContent: 'center',
    paddingTop: 50,
    paddingHorizontal: 5

  },
  para: {
    color: '#4e3e71',
    width: 251,
    height: 44,
    fontSize: 15,
    marginVertical: 20,
    paddingBottom: 10,

  },
  deskheading: {
    color: '#4e3e71',
    fontSize: 32,
    justifyContent: 'center',
    paddingTop: 30,
    paddingHorizontal: 5,
    marginHorizontal: 'auto'

  },
  deskpara: {
    color: '#4e3e71',
    fontSize: 15,
    marginVertical: 20,
    marginHorizontal: 'auto',
    paddingBottom: 10,

  },
  para1: {
    width: 220,
    height: 44,
        color: '#4e3e71',
        fontSize: 15,
        paddingVertical:10,
        marginVertical: 20,
        marginHorizontal: 'auto',
        textAlign: 'center'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: '#4e3e71',
    marginVertical: 11,
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  button2: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 54,
    backgroundColor: '#4e3e71',
    marginVertical: 11,
    height: 54,
    marginHorizontal: 30
  },
  buttonText2: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
});
// Customizable Area End
