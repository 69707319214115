import React ,{useState, useEffect } from 'react';
import {StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Picker,
      ActivityIndicator } from 'react-native';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { putAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';
import CustomPopUp from '../../../framework/src/CustomPopUp';
import { Formik} from "formik";
import * as yup from "yup";
import Dialog from '@material-ui/core/Dialog';
import ConfirmByOTP from "./ConfirmByOtp";
import GlobalCss from '../../../framework/src/GlobalCss';


const validation_Schema = yup.object({
      fname: yup.string().required("First name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      lname: yup.string().required("Last name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      email: yup.string()
      .email("Please enter a valid Email")
      .required("Email is required"),
      fullphone: yup.string().required("Phone is required").min(10, "must be atleast 10 digits"),
     
    })

export default function Profile({ navigation, parent , role}: { navigation: any, parent: any, role: any}) {


      const order = JSON.parse(localStorage.getItem('profileData') || '{}')
      const [image, setImage] = useState<any>(null)
      const [imageDemo, setImageDemo] = useState<any>(null)
      // const role = localStorage.getItem('role');
      
      const [open , setOpen] = useState(false)
            
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      const [loader, setLoader] = useState(false);
      
      const handleClose = () => {
            setOpen(false)
      }

      const ClosePopUp = () => {
            if(window.screen.width < 950){
                  navigation.goBack()
            }
            setPopup(false)
          }

      const handleDateChange = (date : any) => {
            console.log(date);
            setSelectedDate(date);
          };
            const onImageChange = (event :any) => {
                  console.log(event.target.files)
                  setImageDemo(URL.createObjectURL(event.target.files[0]));
                  setImage(event.target.files[0])
                  }

            const date = order?.attributes?.date_of_birth === 0 ? new Date() : order?.attributes?.date_of_birth
            var d = new Date(date);
            const [selectedDate, setSelectedDate] = useState(d.toLocaleDateString());

      const UpdateProfile = async (value: any) => {
            setLoader(true)
            if (image) {
                        var imageData = new FormData()
                        imageData.append('full_phone_number', value.fullphone);
                        imageData.append('email', value.email);
                        imageData.append('profile_image', image ? image : null, image.name ? image.name : null);
                        imageData.append('first_name', value.fname.trim());
                        imageData.append('last_name', value.lname.trim());
                        imageData.append('address', value.address);
                        imageData.append('gender', value.gender,);
                        imageData.append('date_of_birth', Date.parse(selectedDate).toString());
            } else {
                  var imageData = new FormData()
                  imageData.append('full_phone_number', value.fullphone);
                  imageData.append('email', value.email);
                  // imageData.append('profile_image', image ? image : null, image.name ? image.name : null);
                  imageData.append('first_name', value.fname.trim());
                  imageData.append('last_name', value.lname.trim());
                  imageData.append('address', value.address);
                  imageData.append('gender', value.gender,);
                  imageData.append('date_of_birth', Date.parse(selectedDate).toString());
                  }
                  let url = "/account_block/accounts/update_account";
                  await putAxiosAPI(url, imageData).then((res: any) => {
                    if(res.error){
                      console.log(res.data);
                      setPopup(true)
                      setMessage(res.data)
                      setLoader(false)
                    } else {
                      setLoader(false)
                      setPopup(true)
                      setMessage("Profile Updated Successfully")
                      console.log(res.data);
                      navigationTo(res.data)
                    }
                  });
                }



      const navigationTo = (res: any) => {
                  if(res.email_otp) {
                        // console.log("email changed ", res.email_otp.meta.token, res.email_otp.data.attributes.email, res.email_otp.data.attributes.pin)
                        let data = {
                              otp: res.email_otp.data.attributes.pin,
                              sendedAt: res.email_otp.data.attributes.email,
                              token: res.email_otp.meta.token
                        }
                        localStorage.setItem('EditingProfileOtpData', JSON.stringify(data))
                        if (window.screen.width < 950) {
                              navigation.navigate('ConfirmByOtp')
                        } else {
                              setOpen(true)
                        }
                
                  } else if (res.sms_otp){
                        let data = {
                              otp: res.sms_otp.data?.attributes?.pin,
                              sendedAt: res.sms_otp?.data.attributes.full_phone_number,
                              token: res.sms_otp.meta.token
                        }
                        // console.log("phone changed", res.sms_otp.meta.token, res.sms_otp.data.attributes.full_phone_number, res.sms_otp.data.attributes.pin)
                        localStorage.setItem('EditingProfileOtpData', JSON.stringify(data))
                        if (window.screen.width < 950) {
                              navigation.navigate('ConfirmByOtp')
                        } else {
                              setOpen(true)
                        }
                  } else if (window.screen.width < 950){
                        console.log("only values changed")
                        // navigation.goBack()
                  } else {
                        // parent(res)
                  }
            }


      return (
       
            <View style={{flex: 1, flexDirection: 'column', justifyContent: 'space-between'}}>
                  {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
                  <View style={styles.bottomContainer}>
            <View style={styles.container}>
                              {window.screen.width < 950 ?  <View style={styles.headingView}>
                                    <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                          <img src={require("../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24,
                                          }} />
                                    </TouchableOpacity>
                  <Text style={styles.heading}>Edit Profile</Text>
            </View> : null }
               
                        <View style={styles.profileImage} >
                              {
                                    imageDemo ? 
                                    <img src={imageDemo} alt="preview image" style={{width: 110, height: 110, borderRadius: '50%', }}/> :
                                    <img src={order?.attributes?.profile_image ? order.attributes.profile_image : require("../assets/avatar-chat1.png")} style={{height: 110, width: 110, paddingTop: 10, borderRadius: '50%' }} />
                              }

                        </View>
                        
                  <label htmlFor="file">
                 
                  <View style={ window.screen.width < 950 ?  styles.editIcon : styles.editIconWeb}>
                        <TouchableOpacity>
                        <EditIcon style={{fontSize: 25}} />
                        </TouchableOpacity>
                  </View>
                  
                  </label>
                  
            
            <input id="file" type="file" accept="image/*" style={{display:'none'}} onChange={onImageChange}/>
            

            </View>
            <Formik
              initialValues={{
            fname: order.attributes.first_name ? order.attributes.first_name : '',
            lname: order.attributes.last_name ? order.attributes.last_name : '',
            email: order.attributes.email ? order.attributes.email : '',
            date_of_birth: order.attributes.date_of_birth ? order.attributes.date_of_birth : d.toLocaleDateString(),
            address: order.attributes.address ? order.attributes.address : '',
            gender: order.attributes.gender ? order.attributes.gender : '',
            fullphone: order.attributes.full_phone_number ? order.attributes.full_phone_number : '',
            }}
              validationSchema={validation_Schema}
                onSubmit={(values, actions) => {

                  console.log(values);
                  UpdateProfile(values)
                  // actions.resetForm(); 
                }}
              >
                {
                  ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                  <View style={styles.list}>
                  <Text style={styles.inputlabel}>First Name </Text>
                  <TextInput 
                       style={styles.input}
                        placeholder="First Name"
                        onBlur={handleBlur('fname')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('fname')}
                        value={values.fname}
                 />
                 {touched.fname && errors.fname ?   <Text style={{color: 'red', padding: 10}} >{errors.fname}</Text> : null}
                  <Text style={styles.inputlabel}>Last Name</Text>
                  <TextInput 
                       style={styles.input}
                        placeholder="Last Name"
                        onBlur={handleBlur('lname')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('lname')}
                        value={values.lname}
                 />
                 {touched.lname && errors.lname ?   <Text style={{color: 'red', padding: 10}} >{errors.lname}</Text> : null}
                  <Text style={styles.inputlabel}>Date of Birth</Text>
             
                        
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                        style={{
                              fontSize: 18,
                              paddingTop: 10,
                              paddingBottom: 10
                        }}
                        placeholder="MM/DD/YYYY"
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="MM/dd/yyyy"
                        />
                        </MuiPickersUtilsProvider>
                 {touched.date_of_birth && errors.date_of_birth ?   <Text style={{color: 'red', padding: 10}} >{errors.date_of_birth}</Text> : null}

                  <Text style={styles.inputlabel}>Phone Number</Text>
                  <TextInput 
                       style={styles.input}
                        placeholder="Phone Number"
                        onBlur={handleBlur('fullphone')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('fullphone')}
                        value={values.fullphone}
                 />
                 {touched.fullphone && errors.fullphone ?   <Text style={{color: 'red', padding: 10}} >{errors.fullphone}</Text> : null}

                  <Text style={styles.inputlabel}>Address</Text>
                  <TextInput 
                       style={styles.input}
                       multiline
                        placeholder="Address"
                        onBlur={handleBlur('address')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('address')}
                        value={values.address}
                 />
                 {touched.address && errors.address ?   <Text style={{color: 'red', padding: 10}} >{errors.address}</Text> : null}

                  <Text style={styles.inputlabel}>Email</Text>
                  <TextInput 
                       style={styles.input}
                        placeholder="Email"
                        onBlur={handleBlur('email')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('email')}
                        value={values.email}
                 />
                 {touched.email && errors.email ?   <Text style={{color: 'red', padding: 10}} >{errors.email}</Text> : null}
                  
                  <Text style={styles.inputlabel}>Gender</Text>
                  <View style={styles.pickerContainer}>
                  <Picker
                    selectedValue={values.gender}
                    style={styles.picker}
                    onValueChange={handleChange('gender')}
                  > 
                    <Picker.Item value={null} label='Select gender' />
                    <Picker.Item label="Male" value="Male" />
                    <Picker.Item label="Female " value="Female" />
                    <Picker.Item label="Other" value="Other" />
                   
                  </Picker>
                  {role === '2' || role === '3' ?
                  <View>
                        <Text style={styles.inputlabel}>Contact Priority </Text>
                        <Text style={styles.input}>{order?.attributes?.is_primary_contact ? "Primary" : "Secondary"}</Text>
                  </View> : null}
                  </View>
                  <TouchableOpacity disabled={loader ? true : false} onPress={() => handleSubmit()}>
                  <View style={loader ? GlobalCss.buttonViewDisable : GlobalCss.buttonView}>
                  <Text style={GlobalCss.buttonText}>Save changes</Text>
                  {loader && <ActivityIndicator style={styles.loader} size="large" color="#2e2442" />}
                  </View>
            </TouchableOpacity>
                  </View>
                  
                   )
                  }
  
                </Formik>
            </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <ConfirmByOTP parent={parent} navigation={ navigation }/>
                        </Dialog>
            </View>
      )

}
const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff"
          },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
     
      },
      loader: {position: 'absolute', left: 150, bottom:6},
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
          title:{
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            fontWeight: 'bold'
          },
          body: {
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            borderBottomColor: '#e5e5e5',
            borderBottomWidth: 1,
            paddingBottom: 20
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          inputlabel:{
            color: '#4e3e71',
            fontSize: 18,
            paddingVertical: 5,
            fontWeight: 'bold'
          },
          pickerContainer :{
            borderBottomWidth: 1,
            borderBottomColor: '#e5e5e5',
            paddingVertical: 10
          },
          picker: {
            width: '100%',
            height: 50,
            color: '#4e3e71',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: 'white'
          },
          input: {
outlineStyle: 'none',
            flex: 1,
            marginVertical: 10,
            paddingVertical:15,
            paddingLeft: 10,
            fontSize: 18,
            color: '#112950',
            borderBottomWidth: 1,
            borderBottomColor: '#e5e5e5'
          },
          button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 38,
            marginTop: 10
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
          profileImage: {
            flexDirection: 'row',
            marginHorizontal: 20,
            position: 'relative'
            },
            editIconWeb: {
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 95,
                  left: 102,
                  elevation: 10,
                  backgroundColor:  '#e9e9e9' ,
                  borderRadius: 5   
            },
            editIcon: {
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 155,
                  left: 102,
                  elevation: 10,
                  backgroundColor:  '#e9e9e9' ,
                  borderRadius: 5   
            },
            bottomContainer: {      
            },
            list: {
                  flexDirection: 'column',
                  marginHorizontal: 20,
                  marginVertical: 20,
                  justifyContent: 'center'
            },
            text: {
                  padding: 18,
                  backgroundColor: '#f1f1f1',
                  borderRadius: 5,
                  margin: 8,
                  flexDirection: 'row',
                  justifyContent: 'space-between'
            },
            icons : {
                  justifyContent: 'flex-end',
            }
})    
