import React from 'react';
import {
      StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Button,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      Platform
} from 'react-native';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AddIcon from '@material-ui/icons/Add';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TodayIcon from '@material-ui/icons/Today';


export default function menu({ navigation, state }: { navigation: any, state: any }) {
      const changeRoute = (route: any) => {
            state({ left: false })
            navigation.navigate(route)
      }
      return (
            <View style={styles.container}>
                  <View style={styles.profile}>
                        <Text style={styles.profileImage}> </Text>
                        <Text style={styles.profileText}>Place name Here</Text>
                        <Text style={styles.profileText2}>City, Country Name Here</Text>
                  </View>
                  <View style={styles.list}>
                        <View style={styles.listItem}>
                              <HomeIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Dashboard')}>Home</Text>
                        </View>
                        <View style={styles.listItem}>
                              <PersonIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Profile')}>Profile</Text>
                        </View>
                        <View style={styles.listItem}>
                              <MenuBookIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('ExBooking')}>All Booking</Text>
                        </View>
                        <View style={styles.listItem}>
                              <ListAltIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Tracking')}>Notification</Text>
                        </View>
                        <View style={styles.listItem}>
                              <ListAltIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Tracking')}>History</Text>
                        </View>
                        <View style={styles.listItem}>
                              <AddIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Tracking')}>Add New Material</Text>
                        </View>
                        <View style={styles.listItem}>
                              <ErrorOutlineIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Tracking')}>Confirm Material Details</Text>
                        </View>
                        <View style={styles.listItem}>
                              <TodayIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('Tracking')}>Out Of Office</Text>
                        </View>
                        <View style={styles.listItem}>
                              <ExitToAppIcon style={{ fontSize: 30, color: '#fff' }} />
                              <Text style={styles.listText} onPress={() => changeRoute('/')}>Logout</Text>
                        </View>
                  </View>
            </View>
      )
}



const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#eff8ff",
            flexDirection: 'column'
      },
      profileText: {
            color: '#0778df',
            fontSize: 20
      },
      profileText2: {
            color: '#939ca3',
            fontSize: 15
      },
      profile: {
            paddingHorizontal: 30,
            paddingVertical: 10,
            flex: 0.4

      },
      profileImage: {
            marginTop: 50,
            backgroundColor: 'white',
            padding: 50,
            borderRadius: 40,
            height: 50,
            width: 50
      },
      list: {
            flex: 1,
            backgroundColor: '#476484',
            paddingLeft: 20,
            paddingRight: 50
      },
      listItem: {
            flexDirection: 'row',
            paddingVertical: 10,

      },
      listText: {
            color: 'white',
            paddingLeft: 10,
            fontSize: 20
      }

})