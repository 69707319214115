import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../../framework/src/IBlock";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  collaspeData: any;
  materialList: any;
  scannerString: any;
  openScanner: boolean;
  result: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PatientManerialListController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountAPICallId: any;
  googleUser: any;
  apiAddMaterialId: any;

  constructor(props: Props) {
    super(props);
    // this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ]);

    // Customizable Area Start
    this.state = {
      data: JSON.parse(localStorage.getItem("previous_booking") || "{}"),
      collaspeData: [],
      materialList: [],
      scannerString: [],
      openScanner: false,
      result: ''
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiAddMaterialId !== null &&
      this.apiAddMaterialId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiAddMaterialId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.materialAddedSuccessfully(responseJson.data);
      } else {
        //Check Error Response
        this.materialAddedFailed();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();

    const user = JSON.parse(localStorage.getItem("previous_booking") || "{}");
    const role_id: any = JSON.parse(localStorage.getItem("role") || "{}");
    const materails: any = [];
    const a =
      user &&
      user.attributes &&
      user.attributes.ordered_materials &&
      user.attributes.ordered_materials.data
        .filter((x: any) =>
          x.attributes.assign_to_type === (role_id=== 2 || role_id === 3?
            "BxBlockHospital::Hospital"
            : "AccountBlock::Account")
        )
        .map((x: any) => {
          materails.push(x);
          return false;
        });
    this.setState({ collaspeData: a, materialList: materails });
  }
  chats = (id: any) => {
    const chatApiParams = {
      material_id: id,
      order_id: this.state.data.id
    };
    localStorage.setItem("chatApiParams", JSON.stringify(chatApiParams));
    this.props.navigation.navigate("SharedNotes");
  };

  _handleCheckBox = (event: any, index: any) => {
    this.state.materialList[index].attributes.status = event.target.value;
    this.setState({ materialList: this.state.materialList });
  };
  _handleConfirmMaterial = async () => {
    const header = {
      // Accept: "application/form-data",
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const ordered_materials = this.state.materialList.map((a: any) => {
      return {
        id: a.id,
        availability_status: a.attributes.status
      };
    });
    const body = {
      id: this.state.data.id,
      ordered_materials: ordered_materials
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddMaterialId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_order/orders/check_off_materials_of_order"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  materialAddedSuccessfully = (data: any) => {
    this.showAlert("Success", "Material update successfully");
    localStorage.setItem("previous_booking", JSON.stringify(data));
    setTimeout(() => {
      this.props.navigation.navigate("ExBooking");
    }, 3000);
  };

  materialAddedFailed = () => {};
}
