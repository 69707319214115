// Customizable Area Start
import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CallIcon from "@material-ui/icons/Call";
import MaterialManagetSurgeryDetail from "./materialManager/SurgeryDetail/SurgeryDetails";
import DesktopSurgeryDetails from "./DesktopSurgeryDetails";
import Dialog from "@material-ui/core/Dialog";
import { hideName, ageWithMonth } from "../../../framework/src/Utilities";
import scale from "../../../components/src/Scale";
import GlobalCss from "../../../framework/src/GlobalCss";
import {
  getAxiosAPI,
  getErrorMessage,
  patchAxiosAPI,
} from "../../../framework/src/Blocks/AxiosAPIBlock";

export default function SurgeryDetails({ navigation }: { navigation: any }) {
  const [checked, setChecked] = useState(true);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [checked3, setChecked3] = useState(true);
  const user = JSON.parse(localStorage.getItem("previous_booking") || "{}");
  const role_idNew = localStorage.getItem("role");
  const date = user.attributes.booking_datetime;
  let d = new Date(date);
  const [surgeryData, setSurgeryData] = useState<any>(null);
  const [salesRep, setSalesRep] = useState<any>({});
  const [materialManager, setMaterialmanager] = useState<any>({});

  const getSingleOrder = async () => {
    let url = `/bx_block_order/orders/${user.id}`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        console.log(msg);
      } else {
        console.log(res.data.data);
        localStorage.setItem("previous_booking", JSON.stringify(res.data.data));
        setSurgeryData(res.data.data)
        res.data.data.attributes.ordered_materials?.data?.filter((x: any) => x.attributes.assign_to_type === "AccountBlock::Account").slice(0, 1).map((x: any, y: any) => {
              setSalesRep(x.attributes.assign_to.data.attributes)
        })
        res.data.data.attributes.ordered_materials?.data?.filter((x: any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").slice(0, 1).map((x: any, y: any) => {
              setMaterialmanager(x.attributes.assign_to.data.attributes)
        })
      }
    });
  };
  useEffect(() => {
    getSingleOrder();
  }, []);

  const chats = (id: any) => {
    console.log(id);
    const chatApiParams = {
      material_id: id,
      order_id: user.id,
    };
    localStorage.setItem("chatApiParams", JSON.stringify(chatApiParams));
    navigation.navigate("SharedNotes");
  };

  const ConfirmSurgery = async () => {
    const postData = {
      id: user.id,
      order: {
        confirmed_by_bc: true,
      },
    };
    let url = `/bx_block_order/orders/modify_order_by_bc`;
    await patchAxiosAPI(url, postData).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        console.log(msg);
      } else {
        console.log(res.data);
        navigation.goBack();
      }
    });
  };

  const proposeDate = () => {
    const proposeApiData = {
      order_id: user.id,
      surgeon_id: user.attributes.doctor.data.id,
    };
    localStorage.setItem("proposeApiData", JSON.stringify(proposeApiData));
    navigation.navigate("ProposedDateTime");
  };

  const BookingStatus = (item: any) => {
    if (item?.attributes?.confirmed_by_bc === true &&
    item?.attributes?.confirmed_by_material_manager === true &&
    item?.attributes?.confirmed_by_sales_rep === true) {
          return "Confirmed"
    } else {
          return "Pending"
    }
  };

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  console.log(salesRep);

  const [contactDetailsDrawer, setContactDetailsDrawer] = useState<any>({});

  const openContactDialog = (role: any, name: any, number: any) => {
    const data = {
      role: role,
      name: name,
      number: number,
    };
    setContactDetailsDrawer(data);
    setOpen2(true);
    // console.log(role, name , number)
  };

  const handleClose = () => {
    // setOpen2(false);
  };

  const openMaterialDetails = (item: any) => {
    setOpen(true);
    localStorage.setItem("MaterialDialogDetails", JSON.stringify(item));
    console.log(item);
  };
  const role_id: any = JSON.parse(localStorage.getItem("role") || "{}");
  const MaterialDialogDetails: any = JSON.parse(
    localStorage.getItem("MaterialDialogDetails") || "{}"
  );

  const editBookingDetails = () => {
    const data = {
      bookingId: surgeryData.id,
      bookingDateTime: surgeryData.attributes.booking_datetime,
      hospitalId: surgeryData.attributes.hospital.data.id,
    };
    localStorage.setItem("editBookingProps", JSON.stringify(data));
    navigation.navigate("EditBookingDateTime");
  };

  const renderMMDetails = () => {
    if (Object.keys(materialManager).length) {
      return (
        <View style={styles.detailsList3}>
          <View style={{ flexDirection: "row" }}>
            <View>
              <img
                src={require("../assets/personsmall.png")}
                style={{ height: 16, width: 16 }}
              />
            </View>
            <View style={{ flexDirection: "column", paddingHorizontal: 5 }}>
              <Text style={styles.fontOne}>{materialManager?.first_name}  {materialManager?.last_name}</Text>
              <Text style={styles.fontTwo}>Material Manager</Text>
            </View>
          </View>
          <Text>
            <TouchableOpacity
            onPress={() => openContactDialog("Material Manager", materialManager?.first_name + " " + materialManager?.last_name, materialManager?.full_phone_number )}
            >
              <CallIcon
                style={{
                  color: "#cce9e5",
                  fontSize: 20,
                  backgroundColor: "#4e3e71",
                  borderRadius: "50%",
                  padding: "5px",
                  justifyContent: "center",
                }}
              />
            </TouchableOpacity>
          </Text>
        </View>
      );
    } else return null;
  };

  const renderSRDetails = () => {
    if (Object.keys(salesRep).length) {
      return (
        <View style={styles.detailsList3}>
          <View style={{ flexDirection: "row" }}>
            <View>
              <img
                src={require("../assets/personsmall.png")}
                style={{ height: 16, width: 16 }}
              />
            </View>
            <View style={{ flexDirection: "column", paddingHorizontal: 5 }}>
              <Text style={{ color: '#7f7f7f', fontSize: 14 }}>{salesRep?.first_name + " " + salesRep?.last_name}</Text>

              <Text style={styles.fontTwo}>Sales Rep</Text>
            </View>
          </View>
          <Text>
            <TouchableOpacity
            onPress={() => openContactDialog("Sales Rep", salesRep?.first_name + " " + salesRep?.last_name, salesRep?.full_phone_number)}
            >
              <CallIcon
                style={{
                  color: "#cce9e5",
                  fontSize: 20,
                  backgroundColor: "#4e3e71",
                  borderRadius: "50%",
                  padding: "5px",
                  justifyContent: "center",
                }}
              />
            </TouchableOpacity>
          </Text>
        </View>
      );
    } else return null;
  };
  

  return window.screen.width < 950 ? (
    <>
      <Text style={GlobalCss.watermarkTextMobile}>CONFIDENTIAL</Text> 
      {role_id === 1 || role_id === 3 ? (
        <View style={styles.container}>
          <View style={styles.headingView}>
            <TouchableOpacity
              style={styles.leftArrowIconWrapper}
              onPress={() => navigation.goBack()}
            >
              <img
                src={require("../assets/leftArrow1.png")}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TouchableOpacity>
            <Text style={styles.heading}> Surgery Details </Text>
          </View>
          <View style={GlobalCss.mobileSuregryCard}>
            <View style={styles.horizontalBorder}>
              <Text style={styles.HName}>Hospital Name: </Text>
              <Text style={styles.color}>{user.attributes.hospital.data?.attributes.name}</Text>
              <Text style={styles.newBtn}>{user.attributes.patient_type}</Text>
            </View>
            <View style={styles.profileDetails}>
              <img
                src={
                  user.attributes.patient_image
                    ? user.attributes.patient_image
                    : user.attributes.patient_gender === "Male"
                    ? require("../assets/patient_male.png")
                    : require("../assets/patient_female.png")
                }
                style={{ height: 110, width: 92, borderRadius: 6 }}
              />
              <View style={styles.patientInfoOne}>
                <Text numberOfLines={2} style={styles.patientName}>Patient Name: <Text style={styles.patientName2}>{role_id === '4' ? hideName(user.attributes?.first_name, user.attributes?.last_name) : user.attributes?.first_name+" "+user.attributes?.last_name} </Text>  </Text>
                <Text style={styles.patientInfoTwo}>
                  Age:{" "}
                  <Text style={styles.patientInfoThree}>
                    {ageWithMonth(user.attributes.patient_age)}{" "}
                  </Text>{" "}
                </Text>
                <Text style={styles.patientInfoTwo}>
                  Email:{" "}
                  <Text style={styles.patientInfoThree}>
                    {user.attributes.patient_email}{" "}
                  </Text>{" "}
                </Text>
                <Text style={styles.patientInfoTwo}>
                  Phone:{" "}
                  <Text style={styles.patientInfoThree}>
                    {user.attributes.patient_phone}{" "}
                  </Text>{" "}
                </Text>
              </View>
            </View>

            <View style={styles.buttonsRow}>
              <TouchableOpacity>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>
                    {d.toLocaleDateString()}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>
                    {d.toLocaleTimeString([], { timeStyle: "short" })}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>
                    {BookingStatus(surgeryData)}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            <View>
              <View style={styles.details}>
                <Text style={styles.infoOne}>Insurance Information:- </Text>
              </View>
              <View style={styles.detailsList1}>
                <Text style={styles.patientInfo}>
                  1) Insurance carrier:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.insurance_carrier}
                  </Text>
                </Text>
                <Text style={styles.patientInfo}>
                  2) Policy/member id:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.policy_member_id}
                  </Text>
                </Text>
                <Text style={styles.patientInfo}>
                  3) Authorization:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.authorization}
                  </Text>
                </Text>
                <Text style={styles.patientInfo}>
                  4) Benefits:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.benefits}
                  </Text>
                </Text>
              </View>
              <View style={styles.details}>
                <Text style={styles.infoOne}>Surgery Details</Text>
                {role_idNew == "1" && user.attributes.booking_datetime >= new Date().getTime() ? (
                  <TouchableOpacity onPress={() => editBookingDetails()}>
                    <Text style={styles.editText}>Edit Details</Text>
                  </TouchableOpacity>
                ) : null}
              </View>
              <View style={styles.detailsList1}>
                <Text style={styles.patientInfo}>
                  1) Patient Status:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.patient_type}
                  </Text>
                </Text>
                <Text style={styles.patientInfo}>
                  2) Latex Allergies:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.latex_allergies}
                  </Text>
                </Text>
                <Text style={styles.patientInfo}>
                  3) O.R. Table:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.or_table}
                  </Text>
                </Text>
                <Text style={styles.postText}>Postop</Text>
                <Text style={styles.patientInfo}>
                  4) Physiotherapy:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.physio_therapy ? "Yes" : "No"}
                  </Text>
                </Text>
                <Text style={styles.patientInfo}>
                  5) Homecare:{" "}
                  <Text style={GlobalCss.purpleColor}>
                    {user.attributes.homecare ? "Yes" : "No"}
                  </Text>
                </Text>

                {role_idNew === "1" ? (
                  <View>
                    <TouchableOpacity
                      onPress={() =>
                        checked ? setChecked(false) : setChecked(true)
                      }
                    >
                      <View style={styles.rowSpaced}>
                        <Text style={styles.imaging}>Imaging</Text>
                        {checked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </View>
                    </TouchableOpacity>
                    <Collapse in={checked}>
                      {user.attributes.imagings.map((x: any, y: any) => {
                        return (
                          <View style={styles.drawerItem}>
                            <Text style={styles.info}>
                              {y + 1}) {x}
                            </Text>
                          </View>
                        );
                      })}
                    </Collapse>
                  </View>
                ) : null}
              </View>
              <View style={styles.detailsList1}>
                {user.attributes.ordered_materials.data.filter(
                  (x: any) =>
                    x.attributes.assign_to_type === "BxBlockHospital::Hospital"
                ).length ? (
                  <TouchableOpacity
                    onPress={() =>
                      checked1 ? setChecked1(false) : setChecked1(true)
                    }
                  >
                    <View style={styles.rowSpaced}>
                      <Text style={styles.surgeryHeadings}>
                        Surgery equipment(Facility)
                      </Text>
                      {checked1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </View>
                  </TouchableOpacity>
                ) : null}
                <Collapse in={checked1}>
                  {user.attributes.ordered_materials.data
                    .filter(
                      (x: any) =>
                        x.attributes.assign_to_type ===
                        "BxBlockHospital::Hospital"
                    )
                    .map((x: any, y: any) => {
                      return (
                        <View style={styles.drawerItem}>
                          <TouchableOpacity
                            onPress={() => openMaterialDetails(x)}
                          >
                            <Text style={styles.materials}>
                              {y + 1}) Material {y + 1}
                            </Text>
                          </TouchableOpacity>
                          <View
                            style={{
                              backgroundColor:
                                x.attributes.status === "A"
                                  ? "#288600"
                                  : x.attributes.status === "TBH"
                                  ? "blue"
                                  : "red",
                              height: 16,
                              width: 16,
                              borderRadius: 8,
                              marginVertical: "auto",
                              marginHorizontal: 10,
                            }}
                          />
                          <TouchableOpacity
                            style={{ flex: 0.1 }}
                            onPress={() => chats(x.attributes.material_id)}
                          >
                            <img
                              src={
                                x.attributes.flagged
                                  ? require("../assets/chatIconRed.png")
                                  : require("../assets/chatIcon.png")
                              }
                              style={{ height: 15, width: 15, paddingTop: 5 }}
                            />
                          </TouchableOpacity>
                          <Text style={styles.quant}>
                            {x.attributes.quantity}
                          </Text>
                        </View>
                      );
                    })}
                </Collapse>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <View style={styles.dialogMB}>
                    <View style={styles.dialogInnerM}>
                      <Text style={styles.dialogH}>{MaterialDialogDetails?.attributes?.materials?.data?.attributes?.name} </Text>

                      <View style={styles.dialogSpace}>
                        <Text style={styles.lightText}>Status:</Text>
                        <Text style={styles.themeText}>{MaterialDialogDetails?.attributes?.status === "A" ? "Available" : "Pending" }</Text>
                      </View>
                      <View style={styles.dialogSpace}>
                        <Text style={styles.lightText}>Size:</Text>
                        <Text style={styles.themeText}>{MaterialDialogDetails?.attributes?.size}</Text>
                      </View>
                      <View style={styles.dialogSpace}>
                        <Text style={styles.lightText}>Quantity: </Text>
                        <Text style={styles.themeText}>{MaterialDialogDetails?.attributes?.quantity} Pieces</Text>
                      </View>
                      <View style={styles.dialogSpace}>
                        <Text style={styles.lightText}>Assigned To:</Text>
                        <Text style={styles.themeText}>{MaterialDialogDetails?.attributes?.assign_to_type === "BxBlockHospital::Hospital" ? "Facility" : "Sales Rep"}</Text>
                      </View>
                    </View>
                    <TouchableOpacity
                      onPress={() => chats(MaterialDialogDetails?.attributes?.material_id)}
                    >
                      <View style={styles.button1}>
                        <Text style={styles.buttonText1}>Chat</Text>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setOpen(false)}>
                      <View style={styles.button1}>
                        <Text style={styles.buttonText1}>Close</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </Dialog>

                {user.attributes.ordered_materials.data.filter(
                  (x: any) =>
                    x.attributes.assign_to_type === "AccountBlock::Account"
                ).length ? (
                  <TouchableOpacity
                    onPress={() =>
                      checked2 ? setChecked2(false) : setChecked2(true)
                    }
                  >
                    <View style={styles.rowSpaced}>
                      <Text style={styles.surgeryHeadings}>
                        Surgery equipment(Salesrep)
                      </Text>
                      {checked2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </View>
                  </TouchableOpacity>
                ) : null}

                <Collapse in={checked2}>
                  {user.attributes.ordered_materials.data
                    .filter(
                      (x: any) =>
                        x.attributes.assign_to_type === "AccountBlock::Account"
                    )
                    .map((x: any, y: any) => {
                      return (
                        <View style={styles.drawerItem}>
                          <TouchableOpacity
                            onPress={() => openMaterialDetails(x)}
                          >
                            <Text style={styles.materials}>
                              {y + 1}) Material {y + 1}{" "}
                            </Text>
                          </TouchableOpacity>
                          <View
                            style={{
                              backgroundColor:
                                x.attributes.status === "A"
                                  ? "#288600"
                                  : x.attributes.status === "TBH"
                                  ? "blue"
                                  : "red",
                              height: 16,
                              width: 16,
                              borderRadius: 8,
                              marginVertical: "auto",
                              marginHorizontal: 10,
                            }}
                          />
                          <TouchableOpacity
                            style={{ flex: 0.1 }}
                            onPress={() => chats(x.attributes.material_id)}
                          >
                            <img
                              src={require("../assets/chatIcon.png")}
                              style={{ height: 15, width: 15, paddingTop: 5 }}
                            />
                          </TouchableOpacity>
                          <Text style={styles.quant}>
                            {x.attributes.quantity}
                          </Text>
                        </View>
                      );
                    })}
                </Collapse>
              </View>
              {role_idNew === "1" ? (
                <View style={styles.paddings}>
                  <Text style={styles.listH}>Disposable List</Text>
                  <Text style={styles.lightText2}>
                    1) Drapes:{" "}
                    <Text style={GlobalCss.purpleColor}>
                      {user.attributes.draps}
                    </Text>
                  </Text>
                  <Text style={styles.lightText2}>
                    2) Sutures:{" "}
                    <Text style={GlobalCss.purpleColor}>
                      {user.attributes.sutures}
                    </Text>
                  </Text>
                  <Text style={styles.lightText2}>
                    3) Sutures Size:{" "}
                    <Text style={GlobalCss.purpleColor}>
                      {user.attributes.sutures_size}
                    </Text>
                  </Text>
                </View>
              ) : null}

              <TouchableOpacity
                onPress={() =>
                  checked3 ? setChecked3(false) : setChecked3(true)
                }
              >
                <View style={styles.rowSpaced}>
                  <Text style={styles.surgeryHeadings}>Contact Details:</Text>
                  {checked3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </View>
              </TouchableOpacity>

              <Collapse in={checked3}>
                <View style={styles.detailsList3}>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <img
                        src={require("../assets/personsmall.png")}
                        style={{ height: 16, width: 16 }}
                      />
                    </View>
                    <View
                      style={{ flexDirection: "column", paddingHorizontal: 5 }}
                    >
                      <Text style={styles.fontOne}>{user.attributes?.booking_coordinator?.data.attributes?.first_name} {user.attributes?.booking_coordinator?.data?.attributes?.last_name}</Text>
                      <Text style={styles.fontTwo}>Booking Co-ordinator </Text>
                    </View>
                  </View>
                  <Text>
                    <TouchableOpacity
                    onPress={() => openContactDialog("Booking Co-ordinator", user.attributes.booking_coordinator.data.attributes?.first_name + " " + user.attributes.booking_coordinator.data.attributes?.last_name, user.attributes.booking_coordinator.data.attributes?.full_phone_number )}
                    >
                      <CallIcon
                        style={{
                          color: "#cce9e5",
                          fontSize: 20,
                          backgroundColor: "#4e3e71",
                          borderRadius: "50%",
                          padding: "5px",
                          justifyContent: "center",
                        }}
                      />
                    </TouchableOpacity>
                  </Text>
                </View>
                {renderMMDetails()}
                {renderSRDetails()}
              </Collapse>
              <Dialog open={open2} onClose={handleClose}>
                <TouchableOpacity
                  style={styles.flexEnd}
                  onPress={() => setOpen2(false)}
                >
                  <img
                    src={require("../assets/cancel.png")}
                    style={{ height: 30, width: 30, margin: 10 }}
                  />
                </TouchableOpacity>
                <View style={styles.dialogView}>
                  <View>
                    <img
                      src={require("../assets/personsmall.png")}
                      style={{ height: 20, width: 20 }}
                    />
                  </View>
                  <View style={styles.column}>
                    <Text style={styles.Dname}>{contactDetailsDrawer?.name}({contactDetailsDrawer?.role})</Text>
                    <Text style={styles.DNumber}>{contactDetailsDrawer?.number}</Text>             
                  </View>
                </View>
              </Dialog>

              {role_idNew === "1" ? (
                <TouchableOpacity
                  onPress={() => navigation.navigate("Tracking2")}
                >
                  <View style={styles.button1}>
                    <Text style={styles.buttonText1}>Track Status</Text>
                  </View>
                </TouchableOpacity>
              ) : (
                <View>
                  {user.attributes.confirmed_by_bc ||
                  new Date().getTime().toString() >
                    user.attributes.booking_datetime ? null : (
                    <TouchableOpacity onPress={() => ConfirmSurgery()}>
                      <View style={styles.button1}>
                        <Text style={styles.buttonText1}>Confirm Surgery </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                  {new Date().getTime().toString() <
                  user.attributes.booking_datetime ? (
                    <>
                      <TouchableOpacity onPress={() => proposeDate()}>
                        <View style={styles.button1}>
                          <Text style={styles.buttonText1}>
                            Propose Date or Time
                          </Text>
                        </View>
                      </TouchableOpacity>
                      {user.attributes.confirmed_by_material_manager ? null : (
                        <TouchableOpacity
                          onPress={() =>
                            navigation.navigate("PatientMaterialList")
                          }
                        >
                          <View style={styles.button1}>
                            <Text style={styles.buttonText1}>
                              Take on a MM role
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </>
                  ) : null}
                </View>
              )}
            </View>
          </View>
        </View>
      ) : (
        <MaterialManagetSurgeryDetail navigation={navigation} />
      )}
    </>
  ) : (
    <View>
      <DesktopSurgeryDetails user={user} navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  DNumber: { color: "#4e3e71", fontSize: 15, paddingTop: 5 },
  Dname: { color: "#7f7f7f", fontSize: 17, width: scale(280) },
  column: { flexDirection: "column" },
  dialogView: {
    width: 280,
    height: 80,
    marginVertical: 30,
    marginHorizontal: 20,
    flexDirection: "row",
  },
  flexEnd: { flexDirection: "row", justifyContent: "flex-end" },
  listH: { color: "#4e3e71", fontWeight: "bold", fontSize: 20 },
  paddings: { paddingHorizontal: 10, paddingVertical: 20 },
  themeText: { color: "#4e3e71", fontSize: 17 },
  lightText: { color: "#7f7f7f", fontSize: 17 },
  lightText2: { padding: 5, fontSize: 16, color: "#7f7f7f" },
  dialogSpace: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  dialogH: {
    fontWeight: "bold",
    paddingVertical: 15,
    paddingHorizontal: "auto",
    color: "#4e3e71",
    fontSize: 20,
    borderBottomColor: "grey",
    borderBottomWidth: 1,
  },
  dialogInnerM: { margin: 20, height: 200, width: 280 },
  dialogMB: { marginBottom: 10 },
  quant: { color: "#7f7f7f", flex: 0.2, fontSize: 17, paddingLeft: 20 },
  materials: { color: "#7f7f7f", flex: 0.5, fontSize: 17 },
  surgeryHeadings: {
    fontWeight: "bold",
    padding: 5,
    color: "#4e3e71",
    fontSize: 20,
  },
  imaging: { fontWeight: "bold", padding: 5, color: "#4e3e71", fontSize: 20 },
  rowSpaced: { flexDirection: "row", justifyContent: "space-between" },
  postText: { color: "#4e3e71", fontWeight: "bold", fontSize: 18 },
  editText: {
    fontWeight: "bold",
    color: "#4e3e71",
    fontSize: 18,
    borderBottomColor: "#4e3e71",
    borderBottomWidth: 1,
  },
  infoOne: { fontWeight: "bold", color: "#4e3e71", fontSize: 20 },
  patientName2: { fontSize: 20, color: "#4e3e71" },
  patientInfoThree: { fontSize: 18, color: "#4e3e71" },
  patientInfoTwo: { fontSize: 16, color: "#7f7f7f" },
  patientName: { fontSize: 16, color: "#7f7f7f", width: scale(280) },
  patientInfoOne: {
    marginHorizontal: 16,
    flexDirection: "column",
    marginVertical: "auto",
  },
  HName: { color: "#7f7f7f", fontSize: 17 },
  fontOne: {
    color: "#7f7f7f",
    fontSize: 14,
  },
  iconStyle: {
    height: 16,
    width: 16,
  },
  fontTwo: {
    color: "#4e3e71",
    fontSize: 17,
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  patientInfo: { padding: 5, fontSize: 16, color: "#7f7f7f" },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    height: 72,
    backgroundColor: "#4e3e71",
    // marginBottom: 10,
  },
  newBtn: {
    backgroundColor: "#4e3e71",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 12,
    color: "#fff",
    fontSize: 12,
    marginHorizontal: 5,
  },
  button1: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
    marginTop: 10,
  },
  buttonText1: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  topHeading: {
    paddingTop: 40,
    fontSize: 18,
    color: "#0778df",
    paddingVertical: 5,
  },
  info: {
    color: "#7f7f7f",
    paddingVertical: 5,
    fontSize: 17,
  },
  color: {
    color: "#4e3e71",
    fontSize: 17,
  },
  drawers: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  drawerItem: {
    paddingHorizontal: 5,
    flexDirection: "row",
    marginVertical: 10,
    justifyContent: "space-between",
  },
  drawerItem2: {
    paddingVertical: 5,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 2,
    borderBottomColor: "#d8d8d8",
  },
  //     material: {
  //       marginHorizontal: 20,
  //       flexDirection: 'row',
  //       justifyContent: 'space-between',
  //     },
  textB: {
    fontSize: 20,
    fontWeight: "bold",
  },
  BottomInfo: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  material: {
    padding: 5,
    justifyContent: "space-between",
    flexDirection: "row",
    color: "#0778df",
  },
  detailsList1: {
    padding: 5,
    borderBottomColor: "#939ca3",
    borderBottomWidth: 1,
  },
  detailsList2: {
    margin: 20,
    fontWeight: "bold",
    color: "#0778df",
    justifyContent: "space-between",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#939ca3",
  },
  detailsList3: {
    margin: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 20,
    marginHorizontal: 10,
  },
  //     color: {
  //       color: '#0778df',
  //     },
  buttonsRow: {
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  button: {
    borderRadius: 18,
    paddingVertical: 5,
    paddingHorizontal: 15,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: "#4e3e71",
  },
  buttonText: {
    color: "#cce9e5",
    fontSize: 15,
    textAlign: "center",
  },
  horizontalBorder: {
    borderBottomColor: "#c1c1c1",
    borderBottomWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 5,
    flexDirection: "row",
    // justifyContent: 'center',
  },
  profileDetails: {
    flexDirection: "row",
    margin: 16,
  },
});
// Customizable Area End
