Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "/account_block/otp_confirmations";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "account_block/otp_confirmations";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "Enter OTP";

exports.labelInfo =
  "Check your SMS inbox, we have sent you the code at";

exports.submitButtonColor = "#6200EE";
