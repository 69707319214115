import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';
import Roles from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import LoginPage from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import WelcomePage from "../../blocks/email-account-login/src/WelocmePage";

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import AddFacilityAdapter from "../../blocks/adapters/src/AddFacilityAdapter";
import AddNewFacilityAdapter from "../../blocks/adapters/src/AddNewFacilitiesAdapter";
import FacilityNameAdapter from "../../blocks/adapters/src/FacilityNameAdapter";
import AddTeamAdapter from "../../blocks/adapters/src/AddTeamAdapter";
import ProfileAdapter from "../../blocks/adapters/src/ProfileAdapter";
import ExBookingAdapter from "../../blocks/adapters/src/ExBookingAdapter";
import BookingInfoAdapter from "../../blocks/adapters/src/BookingInfoAdapter";
import SurgeryDetailsAdapter from "../../blocks/adapters/src/SurgeryDetailsAdapter";
import CreateBooking from "../../blocks/adapters/src/CreateBookingAdapter";
import SelectedFacilitiesAdapter from "../../blocks/adapters/src/SelectedFacilitiesAdapter";
import ChooseMaterialAdapter from "../../blocks/adapters/src/ChooseMaterialAdpater";
import SelectManufactureAdapter from "../../blocks/adapters/src/SelectManufactureAdapter"
import MaterialCategoryAdapter from "../../blocks/adapters/src/MaterialCategoryAdapter";
import RequestMaterialAdapter from "../../blocks/adapters/src/RequestMaterialAdapter";
import SelectedMaterialAdapter from "../../blocks/adapters/src/SelectedMaterialAdapter";
import CartListAdapter from "../../blocks/adapters/src/CartListAdapter";
import BookingSummaryAdapter from "../../blocks/adapters/src/BookingSummaryAdapter";
import GoogleAuthAdepter from "../../blocks/adapters/src/GoogleAuthAdepter";


//Assembler generated adapters start
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const addFacilityAdapeter = new AddFacilityAdapter();
const addNewFacilityAdapter = new AddNewFacilityAdapter();
const facilityNameAdapter = new FacilityNameAdapter();
const addTeamAdapter = new AddTeamAdapter();
const profileAdapter = new ProfileAdapter();
const exBookingAdapter = new ExBookingAdapter();
const bookingInfoAdapter = new BookingInfoAdapter();
const surgeryDetailsAdapter = new SurgeryDetailsAdapter();
const createBookingAdpater = new CreateBooking();
const selectedFacilitiesAdapter = new SelectedFacilitiesAdapter();
const chooseMaterialAdapter = new ChooseMaterialAdapter();
const selectManufactureAdapter = new SelectManufactureAdapter();
const materialCategoryAdapter = new MaterialCategoryAdapter();
const requestMaterialAdapter = new RequestMaterialAdapter();
const selectedMaterialAdapter = new SelectedMaterialAdapter();
const cartListAdapter = new CartListAdapter();
const bookingSummaryAdapter = new BookingSummaryAdapter();
const googleAuthAdepter = new GoogleAuthAdepter()





//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken')
    if (token) {
      console.log("token present")
      this.props.navigation.navigate('Dashboard')
    } else {
      console.log("not logged in ")
    }
  
  }

  render() {
    const { navigation } = this.props;
    const _this = this;
    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
           {   window.screen.width < 950 ? 
            <WelcomePage navigation={navigation}  /> : 
            <LoginPage {...this.props}/>
           }
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;