//Customizable Area Start
'use strict';

const baseURL = `__MARKER_FOR_BACKEND_URL_REPLACEMENT`;
// const baseURL = 'https://surgicalsuppliesv2-62119-ruby.62119.dev.ap-southeast-1.aws.svc.builder.cafe'

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.baseURL = baseURL;
//Customizable Area End
