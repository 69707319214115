import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  TextInput
} from "react-native";
import SurgeonConfirmedController from "./SurgeonConfirmedController";
export default class SurgeonConfirmed extends SurgeonConfirmedController {
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.headerWrapper}>
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.leftArrowIconWrapper}
          >
            <Image
              source={require("../../../assets/leftArrow.png")}
              style={styles.leftArrowIcon}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Surgeon Confirmed</Text>
        </View>
        <View style={styles.subWrapper}>
          <Text
            style={styles.managerAddedText}
          >{`Surgeon added \n sucessfully`}</Text>
        </View>
        <View style={styles.bottomWrapper}>
          <TouchableOpacity
            style={styles.backButton}
            onPress={() =>
              this.props.navigation.navigate("ValidateNewSurgeonBlock")
            }
          >
            <Text style={styles.backText}>Back</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  subWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },

  bottomWrapper: { position: "absolute", bottom: 20, width: "100%" },
  backButton: {
    marginHorizontal: 24,
    height: 54,
    backgroundColor: "#4e3e71",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  backText: { fontSize: 17, color: "#e8fafe" },
  managerAddedText: { color: "#4e3e71", fontSize: 24, textAlign: "center" }
});
