import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarCSS.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "../src/progressBar/progress.css";
import DesktopCreateBooking from "./DesktopCreateBooking";
import "react-step-progress/dist/index.css";
import ProgressBar from "./StepperComponent";

export default function NewBooking({ navigation }: { navigation: any }) {
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const order: any = {
    booking_datetime: "",
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const navigateTo = () => {
    order.booking_datetime = Date.parse(
      value.toLocaleDateString() + " " + selectedDate.toLocaleTimeString()
    );
    localStorage.setItem("order", JSON.stringify(order));
    navigation.navigate("SelectedFacilities");
  };

  return window.screen.width < 950 ? (
    <View style={styles.container}>
      <View>
        <View>
          <View style={styles.headingView}>
            <TouchableOpacity
              style={styles.leftArrowIconWrapper}
              onPress={() => navigation.goBack()}
            >
              <img
                src={require("../assets/leftArrow1.png")}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </TouchableOpacity>
            <Text style={styles.heading}>Create Booking</Text>
          </View>
          <View>
            <ProgressBar activeStepCount={0} />
          </View>
          <View />
          <Text style={{ color: "#4e3e71", fontSize: 24, padding: 24 }}>
            Select Date & Time
          </Text>
          <View style={{ marginHorizontal: "auto" }}>
            <Calendar
              minDate={new Date()}
              defaultActiveStartDate={new Date()}
              onChange={onChange}
              value={value}
              className="calenderNew"
            />
          </View>
          <View />
          <View>
            <Text style={{ color: "#4e3e71", padding: 24, fontSize: 24 }}>
              Select Time
            </Text>
            <View style={styles.timePicker}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </View>
          </View>
        </View>
        <TouchableOpacity onPress={() => navigateTo()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  ) : (
    <DesktopCreateBooking navigation={navigation} />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  timePicker: {
    //     margin: 20,
    paddingHorizontal: 24,
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 24,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
});
