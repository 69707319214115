import React, {useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
Platform,
TouchableOpacity,
ActivityIndicator,
TextInput,
FlatList,
ScrollView, } from 'react-native';
import scale from "../../../../../framework/src/Scale";
import DesktopLayout from "./DesktopSelectManufac"
import { getAxiosAPI, getErrorMessage, postAxiosAPI } from '../../../../../framework/src/Blocks/AxiosAPIBlock';
import { SortData } from '../../../../../framework/src/Utilities';
import GlobalCss from '../../../../../framework/src/GlobalCss';




export default function SelectManfac({navigation}: {navigation: any}) {

  const order = JSON.parse(localStorage.getItem('order') || '{}')

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [brand_id, setbrand_id ] = useState(order.manfacturer_id ? order.manfacturer_id : ''  )
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(false);
  const role_id = localStorage.getItem('role') || ''

  const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand') || '[]')

  const getListOfManufac = async (search: any = '') => {
    setLoader(true)
    let url = `/bx_block_catalogue/brands?search=${search}&limit=10&offset=${offset}`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            console.log(msg)
            setLoader(false)
          } else {
            setData(res.data.data)
            // setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes.name.toLowerCase() ,b.attributes.name.toLowerCase())))
            setLoader(false)
          }
        })
    }


  useEffect(() => { 
    if (selectedBrand.length > 0) {
      setData(selectedBrand)
      console.log(selectedBrand)
    } else {
    getListOfManufac();
  }
  }, [offset]);
  
   console.log(data)

  const handleSearch = (text : any) => {
    setQuery(text)
    getListOfManufac(text)
  };

  const handleChange = (item:any) => {
    let newdata: any = data.map((x: any) => {
      
      if(x.id === item.id ) {
        x.ischecked = !x.ischecked
      } else {
        x.ischecked = false
      }
      return x
    })
    setData(newdata)
    setbrand_id(item.id)
    // setHospital_name(item.attributes.name)
    
  };


  const navigateTo = async () => {
      setLoader((pre:any) => ({...pre, list: true}))
      let url = "/account_block/accounts/select_brands_for_sales_rep";
      var data = new FormData();
      data.append("brand_ids", brand_id);
      
      await postAxiosAPI(url, data).then((res: any) => {
        if(res.error){
          let msg = getErrorMessage(res.data.errors[0])
              console.log(res.data);
              setLoader((pre:any) => ({...pre, list: false}))
        } else {
            setLoader((pre:any) => ({...pre, list: false}))
            localStorage.setItem("brandID", brand_id);
            navigation.navigate('SelectMaterial')
        }
  })
}

  const breakstirng = (address: any) => {

  }
  
  return (
    window.screen.width < 950 ?
    <View style={styles.container}>

        <View style={styles.headingView}>
          <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
            <img src={require("../../../assets/leftArrow1.png")} style={{
              height: 24, width: 24,
            }} />
          </TouchableOpacity>
    <Text style={styles.heading}>Select Manufacturer</Text>
    { role_id === '4' && <TouchableOpacity onPress={() => navigation.navigate('CreateManufacturer')}>
          <img  src={require("../../../assets/add copy.png")}  style={{height: 24, width: 24, paddingRight: 24}} />
          </TouchableOpacity>}
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <View style={styles.searchBox}>
                <TextInput
                          style={styles.input}
                          placeholder="Search test"
                          autoCapitalize="none"
                          autoCorrect={false}
                          clearButtonMode="always"
                          placeholderTextColor="#939ca3"
                          value={query}
                          onChangeText={queryText => handleSearch(queryText)}
                  />
                <TouchableOpacity onPress={() => setQuery('')}>
              <img src={query === '' ? require("../../../assets/search.png" ) : require("../../../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
              </TouchableOpacity> 
              </View> 
         </View>     
                     {loader ? <ActivityIndicator
                      style={{ margin: 'auto', height: 100, width: 100, }}
                      size="large" color="#4e3e71" />
                      :
                       <View style={styles.card}>
    <ScrollView style={{flex: 1}}>
    {
                      (query.length > 0 && data.length > 0) ? 
                      <View style={styles.add}>
                      <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                      </View> 
                           : 
                           (query.length > 0 && data.length === 0) ? 
                           <View style={styles.add}>
                           <Text style={styles.resultText}>Searched Manufacturer not found, {'\n'} 
                          </Text>
                           </View> : null
                     }
                      
                     <FlatList
                      data={data}
                      keyExtractor={(item: any) => item.id}
                      renderItem={({ item }) => (
                      <TouchableOpacity onPress={() => handleChange(item)}>
                                    <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                        <View style={{flexDirection: 'row' , flex: 0.9}}>
                                          <img src={require("../../../assets/manifac_img.png")} style={{ height: 56, width: 56}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                               <Text style={styles.names} numberOfLines={1}>{item.attributes.name}</Text>    
                                                  <Text style={styles.role} numberOfLines={2}>{item.attributes.address}</Text>
                                                </View>
                                        </View>
                                        <View  style={{marginVertical: 'auto', flex: 0.1}}>
                                       <img src={item.ischecked ? require("../../../assets/radio_active.png") : require("../../../assets/radio_unactive.png")}  style={{height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }}/>      
                                       </View>
                                    </View>
                      </TouchableOpacity>
                     
                      )}
                      />
                       <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                        {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71'}}>{"< Prev"}</Text></TouchableOpacity>}
                        {data.length < 10 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71'}}>{"Next >"}</Text></TouchableOpacity>}
                      </View>
                      </ScrollView>
                      
                      </View> }
 
         {brand_id ?  
         <TouchableOpacity onPress={() => navigateTo()}>
          <View style={styles.button}>
          <Text style={styles.buttonText}>Continue</Text>
          </View>
    </TouchableOpacity> : 
     <View >
     <View style={styles.button0}>
     <Text style={styles.buttonText}>Continue</Text>
     </View>
</View>
    }
      </View>
      :
      <DesktopLayout navigation={navigation} />
)
}
const styles = StyleSheet.create({
container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: 'column'
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  listItem: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#e5e5e5',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listItem_active: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#4e3e71',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  role: {
    color: '#b2bac6',
    paddingHorizontal: 11,
    width: scale(220),
    
  },
  names: {
    color: '#4e3e71',
    fontSize: 15,
    paddingHorizontal: 11,
    width: scale(220),
    
  },
  
  checkboxContainer: {
    flex: 0.1,
    paddingRight: 10,
    paddingTop: 80
  },
  checkbox: {
    alignSelf: "center",
  },
  add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
  headingView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 27,
    backgroundColor: '#4e3e71',
    marginBottom: 20,

  },
  heading:{
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'       
  },
  crossIcon: {
    position: 'absolute',
    top: 40,
    right: 10,
    elevation: 10,
},      
searchBox: {
  flex: 1,
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 10,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50
},
    input: {
outlineStyle: 'none',
      flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
    },
  card: {
    flex: 1,
    marginTop:10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#fff',
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard1 : {
    flex: 1,
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#eff8ff",
    marginHorizontal: 30,
    marginVertical: 6,
    position: 'relative',
    justifyContent: 'space-between'
  },
  innerCard1: {
    flex: 0.2,
    height: 100,
    backgroundColor: "white",
    margin: 10,
    borderRadius: 10
  },
  innerCard2: {
    flex: 0.2,
    backgroundColor: "#eff8ff",
  },
  nameaddress:{
    flex: 0.6,
    backgroundColor: "#eff8ff",
  },

  addressText: {
        marginVertical: 20,
        marginLeft: 20
  },
  cardHeading: {
    marginTop: 10,
    color: '#0579ed',
    fontSize: 18,
    fontWeight: 'bold',
    justifyContent: 'center'
},    
para: {
    color: '#939ca3'
  },
  paraDis: {   
    color: '#0579ed'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#aa9cc9',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
})
