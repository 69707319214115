// Customizable Area Start
import React, { useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
FlatList,
ActivityIndicator,
ScrollView,} from 'react-native';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CallIcon from '@material-ui/icons/Call';
import DesktopDesign from "./DesktopBookingSummary";
import Dialog from '@material-ui/core/Dialog';
import scale, { verticalScale } from "../../../framework/src/Scale";
import CustomPopUp from "../../../framework/src/CustomPopUp";
import { ageWithMonth } from "../../../framework/src/Utilities";
import { getAxiosAPI,patchAxiosAPI, postAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import GlobalCss from '../../../framework/src/GlobalCss';





export default function BookingSummary({navigation}: {navigation: any}) {
      let order = JSON.parse(localStorage.getItem('order') || '{}')
  const SelectedMaterails = JSON.parse(localStorage.getItem('selectedMaterialList') || '[]')
  const requestedMaterials = JSON.parse(localStorage.getItem('requestedMaterials') || '[]')
      
  function urltoFile(url : any, filename : any, mimeType : any){
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
  }

  const date = order.booking_datetime
  var d = new Date(date);
      const [open, setOpen] = useState(false);
      const [checked1, setChecked1]  = useState(true);
      const [checked2, setChecked2]  = useState(true);
      const [checked3, setChecked3]  = useState(true);
      const [checked4, setChecked4]  = useState(true);
      const [checked5, setChecked5] = useState(false);
      const [loader, setLoader] = useState(false)

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)

      const handleClose = () => {
            //setOpen(false);
      };
      const openMaterialDetails = (item: any) => {

            setOpen(true)
            localStorage.setItem('MaterialDialogDetails', JSON.stringify(item))
            console.log(item)
      }
      const MaterialDialogDetails: any = JSON.parse(localStorage.getItem("MaterialDialogDetails") || '{}')

      const [contactDetailsDrawer, setContactDetailsDrawer] = useState<any>({})
      const [open2, setOpen2] = useState(false);

      const openContactDialog = (role: any, name: any, number: any) => {
            const data = {
                  role: role,
                  name: name,
                  number: number
            }
            setContactDetailsDrawer(data)
            setOpen2(true)
      }

      const navigateTo = async (id : any) => {
            if(requestedMaterials.length || order.patient_imageDemo ) {
                  if(requestedMaterials.length) {
                  let reqMaterials = requestedMaterials.map((x:any) => ({
                        name:x.name,
                        order_id: +id,
                        brand_id: +order.manfacturer_id,
                        category_id: +order.category_id,
                        material_manager_id: +order.savedHospital.attributes.material_manager.id,
                        sales_representative_id: +order.saleRep_id,
                        description: x.description,
                        status: "pending"
      
                  })
                  )
                  let postreqData = {
                        material_requests: reqMaterials
                  }
                  console.log(postreqData)

                  let url = "/bx_block_materials/material_request";
                  await postAxiosAPI(url, postreqData).then((res: any) => {
                    if(res.error){
                      let msg = getErrorMessage(res.data.errors[0])
                          console.log(res.data);
                          setPopup(true)
                          setMessage(msg)
                          setLoader(false)
                    } else {
                      console.log(res);
                      navigation.navigate('OrderConfirmed')
                    }
                  });
            } else if (order.patient_imageDemo){
                  var imageData = new FormData()
                  urltoFile(order.patient_imageDemo, order.patient_image.name,order.patient_image.type)
                  .then(async function(file : any){ 
                        console.log(file);
                        imageData.append('patient_image', file ? file : null, order.patient_image.name ? order.patient_image.name : null);
                  
                        let url = `/bx_block_order/orders/patient_image?id=${id}`;
                        await patchAxiosAPI(url, imageData).then((res: any) => {
                          if(res.error){
                            let msg = getErrorMessage(res.data.errors[0])
                                console.log(res.data);
                                setPopup(true)
                                setMessage(msg)
                                setLoader(false)
                          } else {
                            navigation.navigate('OrderConfirmed')
                          }
                        });

                  }); 

            } else {
                  navigation.navigate('OrderConfirmed')
            }
            } else {
                 navigation.navigate('OrderConfirmed')
            }
        
      } 
   
      const [salesRepName, setSalesRepName] = useState<any>(null)
      const makeAPICall = async () => {
            setLoader(true)
            if (salesRepName) {
                  const material = SelectedMaterails.map((x: any) => {
                        if (x.assign_to_type === "AccountBlock::Account") {
                              x.assign_to_id = salesRepName.id 
                        }
                        return x
                  })
                  order.ordered_materials_attributes = material

            } else {
                  order.ordered_materials_attributes = SelectedMaterails
            }
            order.cpt_codes = order?.cpt_codes?.map((x:any) => (
                  {
                        code: +(x.code)
                  }
            ))
            order.diagnosis_codes = order?.diagnosis_codes?.map((x:any) => (
                  {
                      code: x.code,
                      laterality: x.laterality  
                  }
            ))

            let finalBookingData = {
                  hospital_id: order.hospital_id,
                  booking_datetime: order.booking_datetime,
                  patient_type: order.patient_type,
                  patient_gender: order.patient_gender,
                  latex_allergies: order.latex_allergies,
                  surgery_detail: order.surgery_detail,
                  patient_phone: order.patient_phone,
                  patient_age: parseInt(order.patient_age),
                  procedure_duration: order.procedure_duration,
                  or_table: order.or_table,
                  imagings: order.imagings,
                  microscope: order.microscope ? order.microscope : false,
                  pr_op_antibiotics: order.pr_op_antibiotics ? true : false,
                  first_name: order.first_name,
                  last_name: order.last_name,
                  order_status: "pending",
                  cpt_codes: order.cpt_codes,
                  diagnosis_codes: order.diagnosis_codes,
                  ordered_materials_attributes: order.ordered_materials_attributes,
                  insurance_carrier: order.insurance_carrier,
                  policy_member_id: order.policy_member_id,
                  authorization: order.authorization,
                  draps: order.draps,
                  sutures: order.sutures,
                  sutures_size: order.sutures_size,
                  patient_email: order.patient_email,
                  benefits: order.benefits,
                  physio_therapy: order.physiotherapy === "Yes" ? true : false,
                  homecare: order.homecare === "Yes" ? true : false,
                  
            }

            const postData = {
                  order: finalBookingData
            }
            console.log(postData)
            let url = "/bx_block_order/orders";
            await postAxiosAPI(url, postData).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
                    setPopup(true)
                    setMessage(msg)
                    setLoader(false)
              } else {
                console.log(res);
                navigateTo(res.data.data.id)
              }
            });
      }


      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [data, setData] = useState([])

      const getHospitals = async () => {
            let url = "/bx_block_order/orders/sales_representative_list";
            await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
                    setPopup(true)
                    setMessage(msg)
                    setLoader(false)
              } else {
                console.log(res);
                setData(res.data.data);
              }
            })    
      };

      useEffect(() => {
      if(window.screen.width < 950){
            getHospitals();
      }
          
      }, []);

      const handleSearch = (text: any) => {
            setQuery(text)
            if (text !== "") {
                  const newData = data.filter((name: any) => {
                        return Object.values(name.attributes)
                              .join(" ")
                              .toLowerCase()
                              .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
            } else {
                  setsearchData(data);
            }
      };
      

      const ListEmptyView = () => {
            return (
                  <View style={{ marginBottom: 80 }}>
                        <Text style={styles.heading2}>No Record Found</Text>
                       
                  </View>

            )
      }

      const [salesRep, setSalesRep] = useState(null)

      const handleChange = (item: any) => {
            let newdata: any = data.map((x: any) => {

                  if (x.id === item.id) {
                        x.ischecked = !x.ischecked
                  } else {
                        x.ischecked = false
                  }
                  return x
                  
            })
            setData(newdata)
            setSalesRep(item)
     
      }

      const ClosePopUp = () => {
            setPopup(false)
      }
      
      
      const ChangeSalesRep = () => {
            setSalesRepName(salesRep)

      }

      const newImagings = [order.imagings.filter((x:any) => x !== null)]
      

      return (
            window.screen.width < 950 ?
            <>
                  <Text style={GlobalCss.watermarkTextMobile}>CONFIDENTIAL</Text> 
              {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
            <View style={styles.container}>
                              <View style={styles.headingView}>
                                    <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                          <img src={require("../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24,
                                          }} />
                                    </TouchableOpacity>
            <Text style={styles.heading}>Booking Summary</Text>
            </View>
            <View style={GlobalCss.mobileSuregryCard}>
                    
            <View style={styles.horizontalBorder}>

            <Text style={styles.hospitalcss}>Hospital Name: </Text>
            <Text style={styles.color}>{order.hospital_name}</Text> 
            <Text style={styles.newBtn}>{order.patient_type}</Text>

            </View>
            <View style={styles.profileDetails}>
            <img src={order.patient_imageDemo ? order.patient_imageDemo : order.patient_gender === 'Male' ?  require("../assets/patient_male.png") : require("../assets/patient_female.png") } 
            style={{height: 92, width: 92, borderRadius: 6}}/>    
                  <View style={{marginHorizontal: 16, flexDirection: 'column', marginVertical: 'auto'}}>     
                  <Text style={{fontSize: 16, color: '#7f7f7f', width: scale(280) }}>Patient Name: <Text style={styles.patientDetails2}>{order.first_name+" "+order.last_name} </Text>  </Text>
                  <Text style={styles.patientDetails}>Age:  <Text style={styles.patientDetails2}>{ageWithMonth(order.patient_age)} </Text> </Text>
                  <Text style={styles.patientDetails}>Email:  <Text style={styles.patientDetails2}>{order.patient_email} </Text> </Text>
                  <Text style={styles.patientDetails}>Phone:  <Text style={styles.patientDetails2}>{order.patient_phone} </Text> </Text>
                  </View>
                  </View>
                  <View style={styles.buttonsRow}>
                  <TouchableOpacity >
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>{d.toLocaleDateString()}</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity >
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>{d.toLocaleTimeString([], {timeStyle: 'short'})}</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity >
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Pending</Text>
                        </View>
                  </TouchableOpacity>
                                    </View>
                    <View style={styles.details}>
                    <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20 }}>Insurance Information:</Text>
                                    </View>
                                    <View style={styles.detailsList1}>
                                          <Text style={styles.insurInfo}>1) Insurance carrier: <Text style={GlobalCss.purpleColor}>{order.insurance_carrier}</Text></Text>
                                          <Text style={styles.insurInfo}>2) Policy/member id: <Text style={GlobalCss.purpleColor}>{order.policy_member_id}</Text></Text>
                                          <Text style={styles.insurInfo}>3) Authorization: <Text style={GlobalCss.purpleColor}>{order.authorization}</Text></Text>
                                          <Text style={styles.insurInfo}>3) Benefits: <Text style={GlobalCss.purpleColor}>{order.benefits}</Text></Text>
                                    </View>
                  <View style={styles.details}>
                              <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20 }}>Booking Information:</Text>
                              {/* <Text style={{fontWeight: 'bold', color: '#4e3e71',fontSize: 18 , borderBottomColor: '#4e3e71', borderBottomWidth: 1 }}>Edit Details</Text> */}
                        </View>
                        <View style={styles.detailsList1}>
                              <Text style={styles.patientDetails3}>1) Patient Status: <Text style={GlobalCss.purpleColor}>{order.patient_type}</Text></Text>
                              <Text style={styles.patientDetails3}>2) Latex Allergies: <Text style={GlobalCss.purpleColor}>{order.latex_allergies}</Text></Text>
                              <Text style={styles.patientDetails3}>3) O.R. Table: <Text style={GlobalCss.purpleColor}>{order.or_table}</Text></Text>
                              <View style={styles.details}>
                              <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20 }}>Postop</Text>
                              </View>
                              <Text style={styles.patientDetails3}>1) Physiotherapy: <Text style={GlobalCss.purpleColor}>{order.physiotherapy === "Yes" ? "Yes" : "No"}</Text></Text>
                              <Text style={styles.patientDetails3}>2) Homecare: <Text style={GlobalCss.purpleColor}>{order.homecare === "Yes" ? "Yes" : "No"}</Text></Text>
                        </View>
                        <TouchableOpacity onPress={() => checked1 ? setChecked1(false) : setChecked1(true)}>
                       <View style={styles.drawers}>
                             <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20}}>Imaging</Text>
                             {checked1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                       </View>
                       </TouchableOpacity>
                       <Collapse in={checked1}>
                             <View style={styles.drawerItem}>
                                   {
                                         newImagings[0].map((x:any, index:any) => {
                                               return (
                                                <Text style={styles.info}>{index+1}) {x}</Text>
                                               )
                                         })
                                   }
                             
                              
                             </View>
                       </Collapse>
                       <TouchableOpacity onPress={() => checked2 ? setChecked2(false) : setChecked2(true)}>
                       <View style={styles.drawers}>
                             <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20}}>Implants & Instrumentation</Text>
                             {checked2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                       </View>
                       </TouchableOpacity>
                       
                                    <Collapse in={checked2}>
                                          {SelectedMaterails.filter((x: any) => x.assign_to_type === "BxBlockHospital::Hospital").length ?
                                                <Text style={{ fontWeight: 'bold', paddingHorizontal: 24, color: '#4e3e71', fontSize: 16 }}>Facility :-</Text> : null }
                             {
                                                SelectedMaterails.filter((x: any) => x.assign_to_type === "BxBlockHospital::Hospital").map((x: any, y: any) => {
                                                      return (
                                    <TouchableOpacity onPress={() => openMaterialDetails(x)}>
                                          <View style={styles.drawerItem2}>
                                                     <Text style={styles.info}>{y + 1}) Material { y+1 }</Text>
                                          {/* <Text style={styles.info}>{x.quantity}</Text> */}
                                         </View>
                                    </TouchableOpacity>
                                         )
                                   })
                                          }
                                          {SelectedMaterails.filter((x: any) => x.assign_to_type === "AccountBlock::Account").length ?
                                                <Text style={{ fontWeight: 'bold', paddingHorizontal: 24, color: '#4e3e71', fontSize: 16 }}>Salesrep :-</Text> : null }
                                          {
                                                SelectedMaterails.filter((x: any) => x.assign_to_type === "AccountBlock::Account").map((x: any, y: any) => {
                                                      return (
                                                            <TouchableOpacity onPress={() => openMaterialDetails(x)}>
                                                            <View style={styles.drawerItem2}>
                                                                  <Text style={styles.info}>{y + 1}) Material {y + 1}</Text>
                                                                  {/* <Text style={styles.info}>{x.quantity}</Text> */}
                                                            </View>
                                                            </TouchableOpacity>
                                                      )
                                                })
                                          }
                            
                           
                       </Collapse>
                       <TouchableOpacity onPress={() => checked3 ? setChecked3(false) : setChecked3(true)}>
                       <View style={styles.drawers}>
                             <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20}}>Disposable</Text>
                          {checked3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                       </View>
                       </TouchableOpacity>
                       <Collapse in={checked3}>
                        <View style={styles.drawerItem}>
                                                <Text style={styles.info}>Drapes : {order.draps}</Text>
                                                <Text style={styles.info}>Sutures : {order.sutures} </Text>
                                                <Text style={styles.info}>Size : {order.sutures_size} </Text>
                        </View>
                       </Collapse>
                       
                       <TouchableOpacity onPress={() => checked4 ? setChecked4(false) : setChecked4(true)}>
                       <View style={styles.drawers}>
                             <Text style={{fontWeight: 'bold', color: '#4e3e71', fontSize: 20}}>Contact Details: </Text>
                             {checked4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                       </View>
                       </TouchableOpacity>
                       <Collapse in={checked4}>
                       <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <img src={require("../assets/personsmall.png")} style={{height: 16, width: 16}}/>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#7f7f7f', fontSize: 14}}>{order.savedHospital?.attributes?.material_manager?.first_name} {order.savedHospital?.attributes?.material_manager?.last_name}</Text>
                                  <Text style={{color: '#4e3e71', fontSize: 17}}>Material Manager</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <TouchableOpacity onPress={() => openContactDialog('Material Manager', order.savedHospital?.attributes?.material_manager?.first_name+" "+order.savedHospital?.attributes?.material_manager?.last_name, order.savedHospital?.attributes.material_manager?.full_phone_number )}>      
                        <CallIcon 
                        style={{ 
                        color: '#cce9e5',
                        fontSize: 20,
                        backgroundColor: '#4e3e71', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </TouchableOpacity>
                        
                        </Text>
                        </View>
                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                            <img src={require("../assets/personsmall.png")} style={{height: 16, width: 16}}/>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#7f7f7f', fontSize: 14}}>{order.savedHospital?.attributes?.booking_coordinator?.first_name} {order.savedHospital?.attributes?.booking_coordinator?.last_name}</Text>
                                  <Text style={{color: '#4e3e71', fontSize: 17}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                         <TouchableOpacity onPress={() => openContactDialog('Booking Co-ordinator', order.savedHospital?.attributes?.booking_coordinator?.first_name+" "+order.savedHospital?.attributes?.booking_coordinator?.last_name, order.savedHospital?.attributes.booking_coordinator?.full_phone_number)} > 
                        <CallIcon 
                        style={{ 
                        color: '#cce9e5',
                        fontSize: 20,
                        backgroundColor: '#4e3e71', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />          
                        </TouchableOpacity>
                        </Text>
                        </View>

                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                            <img src={require("../assets/personsmall.png")} style={{height: 16, width: 16}}/>
                                                      </View>
                                                      <TouchableOpacity onPress={() => checked5 ? setChecked5(false) : setChecked5(true)}>
                                                            <View style={styles.drawersSalesrep}>
                                                                  <View style={{ flexDirection: 'column', paddingHorizontal: 5 }}>
                                                                        <Text style={{ color: '#7f7f7f', fontSize: 14 }}>{salesRepName ? salesRepName.attributes.first_name + " " + salesRepName.attributes.last_name : order?.salesRep}</Text>
                                                                        <Text style={{ color: '#4e3e71', fontSize: 17 }}>Sales Representative</Text>
                                                                  </View>
                                                                  {checked5 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                            </View>
                                                      </TouchableOpacity>
                           
                           
                        </View>
                        <TouchableOpacity onPress={() => openContactDialog('Sales Representative',order?.salesRep, order?.saleRep_no)} > 
                        <CallIcon style={{ 
                        color: '#cce9e5',
                        fontSize: 20,
                        backgroundColor: '#4e3e71', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </TouchableOpacity>
                        </View>
                        
                                    </Collapse>
                                   

                        <Collapse in={checked5}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Search "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                               
                                          </View>
                                          {salesRep ?
                                                <TouchableOpacity style={{ width: 150, justifyContent: 'flex-end', marginLeft: 170 }} onPress={() => ChangeSalesRep()}>
                                                      <View style={styles.button2}>
                                                            <Text style={styles.buttonText2}>Change Sales Rep</Text>
                                                      </View>
                                                </TouchableOpacity>
                                                :
                                                <TouchableOpacity style={{ width: 150, justifyContent: 'flex-end', marginLeft: 170 }} onPress={() => navigation.goBack()}>
                                                <View style={styles.button3}>
                                                      <Text style={styles.buttonText3}>Change Sales Rep</Text>
                                                </View>
                                                </TouchableOpacity>
                                          }
                                          <ScrollView style={{ flex: 1, height: 400, width: 380 }}>
                                               
                                          <FlatList
                                                data={query.length < 1 ? data : searchData}
                                                ListEmptyComponent={ListEmptyView()}
                                                keyExtractor={(item: any) => item.id}
                                                renderItem={({ item }) => (
                                                      <View style={item.ischecked ? styles.salescardActive : styles.salescard}>
                                                            <Text style={{ flex: 0.8, padding: 10, color: item.ischecked ? '#4e3e71' : '#8a8a8a', fontSize: 16}}>{item.attributes.first_name + " " + item.attributes.last_name} </Text>
                                                                  <TouchableOpacity onPress={() => handleChange(item)} style={{ margin: 'auto', flex: 0.1 }}>
                                                                        <img src={item.ischecked ? require("../assets/radio_active.png") : require("../assets/radio_unactive.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />
                                                                  </TouchableOpacity>            
                                                            </View>
                                                
                                                )}
                                          />
                                          </ScrollView>
                                    
                                    </Collapse>
                                    <Dialog
                                          open={open2}
                                          onClose={handleClose}
                                    >

                                          <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'flex-end' }} onPress={() => setOpen2(false)}>
                                                <img src={require("../assets/cancel.png")} style={{ height: 30, width: 30, margin: 10 }} />
                                          </TouchableOpacity>
                                          <View style={{ width: 280, height: 60, marginVertical: 30, marginHorizontal: 20, flexDirection: 'row' }}>
                                                <View>
                                                      <img src={require("../assets/personsmall.png")} style={{ height: 20, width: 20 }} />
                                                </View>
                                                <View style={{ flexDirection: 'column' }}>
                                                      <Text style={{ color: '#7f7f7f', fontSize: 17 }}>{contactDetailsDrawer?.name}({contactDetailsDrawer?.role})</Text>
                                                      <Text style={{ color: '#4e3e71', fontSize: 15, paddingTop: 5 }}>{contactDetailsDrawer?.number}</Text>

                                                </View>

                                          </View>
                                    </Dialog>
                        <View style={{paddingVertical: 20}}>
                  <TouchableOpacity disabled={loader}  onPress={() => navigation.goBack()}>
                        <View style={loader ? styles._unactivebtn : styles.button1}>
                        <Text style={styles.buttonText1}>Back</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity disabled={loader}  onPress={() => makeAPICall()}>
                        <View style={loader ? styles._unactivebtn : styles.button1}>
                        <Text style={styles.buttonText1}>Confirm Booking</Text>
                        {loader ? <ActivityIndicator style={{position: 'absolute', right: 150}} size="large" color="#2e2442" /> : null }
                        </View>
                  </TouchableOpacity>
                
                  </View> 


            </View>
                              <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                              >
                                    <View style={{ marginBottom: 10 }}>
                                          <View style={{ margin: 20, height: 200, width: 280 }}>
                                                <Text style={{ fontWeight: 'bold', paddingVertical: 15, paddingHorizontal: 'auto', color: '#4e3e71', fontSize: 20, borderBottomColor: 'grey', borderBottomWidth: 1 }}>{MaterialDialogDetails?.material_name} </Text>

                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                                      <Text style={{ color: "#7f7f7f", fontSize: 17 }} >Status:</Text>
                                                      <Text style={{ color: "#4e3e71", fontSize: 17 }}>Pending</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                                      <Text style={{ color: "#7f7f7f", fontSize: 17 }}>Size:</Text>
                                                      <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.size}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                                      <Text style={{ color: "#7f7f7f", fontSize: 17 }}>Quantity: </Text>
                                                      <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.quantity} Pieces</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                                      <Text style={{ color: "#7f7f7f", fontSize: 17 }}>Assigned To:</Text>
                                                      <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.assign_to_type === "BxBlockHospital::Hospital" ? "Facility" : "Sales Rep"}</Text>
                                                </View>


                                          </View>
                                          <TouchableOpacity onPress={() => setOpen(false)}>
                                                <View style={styles.button1}>
                                                      <Text style={styles.buttonText1}>Close</Text>
                                                </View>
                                          </TouchableOpacity>
                                    </View>

                              </Dialog>
                             
            </View>
                  </>
                  :
                  <DesktopDesign order={order} navigation={navigation} />
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            // backgroundColor: "#fff",
            flexDirection: 'column'
          },
      patientDetails: {fontSize: 16, color: '#7f7f7f' },
      patientDetails2: {fontSize: 18, color: '#4e3e71'},
      patientDetails3: {padding: 5, fontSize: 16, color: '#7f7f7f'},
      insurInfo: { padding: 5, fontSize: 16, color: '#7f7f7f' },
      hospitalcss: {color: '#7f7f7f',fontSize: 17},
      searchBox: {
            flexDirection: 'row',
            marginHorizontal: 26,
            marginVertical: 5,
            borderBottomWidth: 1,
            borderBottomColor: '#8a8a8a',
            // borderRadius: 50

      },
      salescard: {
            flex:1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 50,
            marginVertical: 5,
            borderRadius: 12,
            borderColor: '#8a8a8a',
            borderWidth: 1
      },
      salescardActive: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 50,
            marginVertical: 5,
            borderRadius: 12,
            borderColor: '#4e3e71',
            borderWidth: 1
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 5
      },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            backgroundColor: '#4e3e71',
     
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
          newBtn: {
            backgroundColor: '#4e3e71',
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 12,
            color: '#fff',
            fontSize: 12,
            marginHorizontal: 5
          },
          button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
          },
          _unactivebtn: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#8e7bb7',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText1: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
          button2: {
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginHorizontal: 2,
            backgroundColor: '#4e3e71',
            // marginBottom: 10,
            // marginTop: 10
          },
          buttonText2: {
            color: '#e8fafe',
            fontSize: 12,
            textAlign: 'center',
          },
          button3: {
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginHorizontal: 2,
            backgroundColor: '#8a8a8a',
            // marginBottom: 10,
            // marginTop: 10
          },
          buttonText3: {
            color: '#e8fafe',
            fontSize: 12,
            textAlign: 'center',
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
      },
      heading2: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
          topHeading: {
            paddingTop: 40,
            fontSize: 18,
            color: '#0778df',
            paddingVertical: 5
          },
          info: {
            color: '#7f7f7f',
            paddingVertical: 5,
            fontSize: 17,
      },
          color: {
            color: '#4e3e71',
            fontSize: 17,
          },
          drawers: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingVertical: 10
          }, 
          drawersSalesrep: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            // paddingHorizontal: 20,
            paddingVertical: 10
          }, 
          drawerItem: {
            paddingHorizontal: 20
          },
          drawerItem2: {
                paddingVertical: 5,
            marginHorizontal: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomWidth: 2,
            borderBottomColor: '#d8d8d8'
          },
      //     material: {
      //       marginHorizontal: 20,
      //       flexDirection: 'row',
      //       justifyContent: 'space-between',
      //     },
          textB: {
            fontSize: 20,
            fontWeight: 'bold'
          },      
          BottomInfo: {
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },    
          material: {
            padding: 5,
            justifyContent: 'space-between',
            flexDirection: 'row',
            color: '#0778df'
          },      
          detailsList1: {
            padding: 5,
            borderBottomColor: '#939ca3',
            borderBottomWidth: 1,
          }, 
          detailsList2: {
            margin: 20,
            fontWeight: 'bold',
            color: '#0778df',
            justifyContent: 'space-between',
            flexDirection :'row',
            borderBottomWidth: 1,
            borderColor: '#939ca3'

          },
          detailsList3: {
            margin: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'

          },
          details: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 10,
            marginHorizontal: 10
          },      
      //     color: {
      //       color: '#0778df',
      //     },
          buttonsRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            paddingLeft: 20,
            paddingRight: 20
          },
          button: {
            borderRadius: 18,
            paddingVertical: 5,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: '#4e3e71',
          },
          buttonText: {
            color: '#cce9e5',
            fontSize: 15,
            textAlign: 'center',
          },
          horizontalBorder: {
            borderBottomColor: '#c1c1c1',
            borderBottomWidth: 1,
            paddingVertical: 15,
            paddingHorizontal: 5,
            flexDirection: 'row',
            // justifyContent: 'center',
          },
          profileDetails:{
            flexDirection: 'row',   
            margin: 16
          },
})
// Customizable Area End