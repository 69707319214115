import React from 'react';
import "./table.css";


const GenerateReportCustomTable = ({ header, apiReport = [] }) => {
    // console.log(Object.keys(apiReport[0]).join(","))

    const renderValue = (items) => {
        let values = Object.values(items)
        return (
            values.map(x => <td>{x}</td>)
        )
    }

  return (
    <div className='outertable'>
         <table>
    <tr>
      { header.map(item => <th>{item}</th>)}
    </tr>
    {
        apiReport.map(item =>    
    <tr>
     {renderValue(item)}
    </tr>
    )}
 
  </table>
    </div>
  )
}

export default GenerateReportCustomTable