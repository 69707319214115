import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
// Customizable Area End
import DashboardController, { Props } from "../DashboardController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import './index.css';
import Drawer from '@material-ui/core/Drawer';
import NewHeader from "./header2";
import Footer from "./Footer";

import moment from "moment";
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDashboardItems = ({ item }: any) => {
    return (
      <View style={styles.dashboardItemView}>
        <TouchableOpacity
          style={{ flex: 1, flexDirection: "row", height: hp("7%") }}
        >
          <View
            style={{
              flex: 2,
              marginLeft: wp("6%"),
              justifyContent: "space-evenly",
              alignItems: "flex-start"
            }}
          >
            <Text style={{ fontSize: this.isPlatformWeb() ? 18 : RFValue(18), textAlign: "center" }}>
              {item.attributes.title}
            </Text>
            <Text style={{ fontSize: this.isPlatformWeb() ? 12 : RFValue(12), textAlign: "center" }}>
              {moment(item.attributes.created_at).format("DD MMM YYYY")}
            </Text>
          </View>
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Text style={{ fontSize: this.isPlatformWeb() ? 14 : RFValue(14), textAlign: "center" }}>
              {item.attributes.value}/-
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    return (
      //Merge Engine DefaultContainer
      <View style={{ flex: 1 }}>
        <View style={styles.containerNew}>
          <ScrollView>
            <NewHeader navigation={navigation} />

            <View style={styles.calendar}>
              <Calendar
                onChange={() => this.setState({ onChange: this.state.value })}
                value={this.state.value}
              />
            </View>

            <TouchableOpacity >
              <View style={styles.button}>
                <Text style={styles.buttonText}>See All Surgeries </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails')}>
              <View style={styles.outerCard}>
                <View style={styles.innerCard}>
                  <Text>Image</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View>
                    <Text style={styles.cardHeading}>Aakash</Text>
                    <Text style={styles.paraDis}>Hospital</Text>
                  </View>
                  <Text style={styles.para}>Confirmed</Text>
                </View>

              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails')}>
              <View style={styles.outerCard}>
                <View style={styles.innerCard}>
                  <Text>Image</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View>
                    <Text style={styles.cardHeading}>Aakash</Text>
                    <Text style={styles.paraDis}>Hospital</Text>
                  </View>
                  <Text style={styles.para}>Confirmed</Text>
                </View>

              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails')}>
              <View style={styles.outerCard}>
                <View style={styles.innerCard}>
                  <Text>Image</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View>
                    <Text style={styles.cardHeading}>Aakash</Text>
                    <Text style={styles.paraDis}>Hospital</Text>
                  </View>
                  <Text style={styles.para}>Confirmed</Text>
                </View>

              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails')}>
              <View style={styles.outerCard}>
                <View style={styles.innerCard}>
                  <Text>Image</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View>
                    <Text style={styles.cardHeading}>Aakash</Text>
                    <Text style={styles.paraDis}>Hospital</Text>
                  </View>
                  <Text style={styles.para}>Confirmed</Text>
                </View>

              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails')}>
              <View style={styles.outerCard}>
                <View style={styles.innerCard}>
                  <Text>Image</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View>
                    <Text style={styles.cardHeading}>Aakash</Text>
                    <Text style={styles.paraDis}>Hospital</Text>
                  </View>
                  <Text style={styles.para}>Confirmed</Text>
                </View>

              </View>
            </TouchableOpacity>
          </ScrollView>

        </View>
        <View style={{ flex: 0.1 }}>
          <Footer navigation={navigation} />
        </View>
      </View>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerNew: {
    flex: 0.9,
    flexDirection: 'column',
    backgroundColor: 'rgb(239, 247, 255)',
    padding: 20,
  },
  outerCard: {
    flexDirection: 'row',
    marginTop: 20,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: '#fff',
    marginHorizontal: 5,
    marginVertical: 6,
  },
  innerCard: {
    margin: 10,
    borderRadius: 15,
    elevation: 3,
    backgroundColor: "#eff8ff",
    padding: 25,

  },

  paraDis: {
    color: '#0778df'
  },
  para: {
    marginVertical: 35,
    color: '#0778df',
    marginLeft: 100,
  },
  cardHeading: {
    paddingTop: 15,
    color: '#0778df',
    fontSize: 20,
    fontWeight: 'bold',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 20
  },
  headerText: {
    color: '#0579ed',
    fontSize: 20
  },
  calendar: {
    flexDirection: 'row',
    justifyContent: 'center',
  },

  
  button: {
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 50,
    marginHorizontal: 10,
    marginVertical: 10,
    backgroundColor: '#0579ed',
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    //padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffff"
  },
  dashboardItemView: {
    flex: 1,
    marginHorizontal: 5,
    marginVertical: hp("2%"),
    width: Platform.OS === "web" ? "80vw" : wp("90%"),
    maxWidth: Platform.OS === "web" ? 600 : wp("90%"),
    borderRadius: hp("3%"),
    backgroundColor: "#ffffff",
    shadowColor: "#c3c3c3",
    shadowOffset: {
      width: 2,
      height: 3
    },
    shadowOpacity: 0.6,
    shadowRadius: 5.62,
    elevation: 6
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
