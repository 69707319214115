import React, {useState, useEffect} from 'react';
import {StyleSheet,
      Text,
      View,
      TouchableOpacity,
  TextInput,
  ActivityIndicator,
      FlatList,
      Alert ,
      ScrollView,
      TouchableWithoutFeedback,
      Platform } from 'react-native';
import DesktopAddTeam from "./DesktopAddTeam";
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import CustomPopUp from "../../../framework/src/CustomPopUp";


export default function AddFacility({navigation}: {navigation: any}) {


  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState(false);
  const [searchData, setsearchData] = useState([]);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [offset, setOffset] = useState(0);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)
  // const [loading, setLoading] = useState(true);

  const getListOfAccounts = async (search: any = '') => {
    setLoader(true)
    let url = `/account_block/accounts?search=${search}&limit=10&offset=${offset}`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            console.log(res.data);
            setPopup(true)
            setMessage(msg)
            setLoader(false)
          } else {
            // console.log(res.data.data)
            setLoader(false)
            setData(res.data.data)
          }
        });
    }

  useEffect(() => { 
    getListOfAccounts();
  }, [offset]);

  // console.log(data)

 
 const goToAddTeam = () => {
      navigation.navigate('Dashboard')
  }

  const roleName = (id: any) => {
    if(id === 1) {
      return "Surgeon"
    } else if (id === 2){
      return "Material Manager"
    } else if (id === 3){
      return "Booking Coordinator"
    } else if (id === 4){
      return "Sales Representative"
    } else {
      return "Not Defined"
    }
  }

  const handleSearch = (text : any) => {
    setQuery(text)
    getListOfAccounts(text)
  };

  const ClosePopUp = () => {
    setPopup(false)
}

  return (
    window.screen.width < 950 ? 
    <View style={styles.container}>
      {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
      <View style={styles.headingView}>
        <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
          <img src={require("../assets/leftArrow1.png")} style={{
            height: 24, width: 24,
          }} />
        </TouchableOpacity>
    <Text style={styles.heading}>Invite Users</Text>
          
      </View>
             <View style={styles.searchBox}>
      <TextInput
                style={styles.input}
                placeholder="Search for existing user"
                autoCapitalize="none"
                autoCorrect={false}
                clearButtonMode="always"
                placeholderTextColor="#939ca3"
                value={query}
                onChangeText={queryText => handleSearch(queryText)}
         />
      <TouchableOpacity onPress={() => setQuery('')}>
     <img src={require("../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
     </TouchableOpacity> 
      </View> 
      {loader ? <ActivityIndicator
        style={{ margin: 'auto', height: 100, width: 100, }}
        size="large" color="#4e3e71" />
        :
        <View style={styles.card}>
          <ScrollView style={{ flex: 1 }}>
            {
              (query.length > 0 && data.length > 0) ?
                <View style={styles.add}>
                  <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                </View>
                :
                (query.length > 0 && data.length === 0) ?
                  <View style={styles.add}>
                    <Text style={styles.resultText}>Searched member not found, {'\n'}
                      <Text style={{ color: '#4e3e71', fontWeight: 'bold', paddingHorizontal: 50 }} onPress={() => navigation.navigate('InviteUser')}>Invite User</Text></Text>
                  </View> : null
            }
                      
            <FlatList
              data={data}
              keyExtractor={(item: any) => item.id}
              renderItem={({ item }) => (
                <TouchableOpacity>
                  <View style={styles.listItem}>
                    <View style={{ flexDirection: 'row' }}>
                      <img src={require("../assets/dummy.png")} style={{ height: 50, width: 50 }} />
                      <View style={{ flexDirection: 'column', marginVertical: 'auto' }}>
                        <Text style={styles.names}>{item.attributes.first_name ? item.attributes.first_name + " " + item.attributes.last_name : 'Undefined'}</Text>
                        <Text style={styles.role}>{item.attributes.role_id ? roleName(item.attributes.role_id) : 'Undefined'}</Text>
                      </View>
                    </View>
                    {/* <View style={{ marginVertical: 'auto' }}>
                      <img src={require("../assets/radiobtn.png")} style={{ height: 20, width: 20, color: '#4e3e71' }} />
                    </View> */}
                  </View>
                </TouchableOpacity>
                             
              )}
            />
             <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                        {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71'}}>{"< Prev"}</Text></TouchableOpacity>}
                        {data.length < 10 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71'}}>{"Next >"}</Text></TouchableOpacity>}
                      </View>
          </ScrollView>
        </View>
      }
 
          <TouchableOpacity onPress={() => goToAddTeam()}>
          <View style={styles.button}>
          <Text style={styles.buttonText}>Continue</Text>
          </View>
    </TouchableOpacity>
      </View> : 
      <View>
        <DesktopAddTeam navigation={navigation} />
      </View>
)
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffff",
    flexDirection: 'column',
    justifyContent: 'space-between'

  },
  role: {
    color: '#b2bac6',
    paddingHorizontal: 11
  },
  headingView: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"

  },
  heading:{
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'       
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  names: {
    color: '#4e3e71',
    fontSize: 15,
    paddingHorizontal: 11
    
  },
  logo: {
    backgroundColor: 'white',
    height: 60,
    width: 60,
    borderRadius: 60,
    marginRight:10
  },
  listItem: {
   height: 88,
   marginHorizontal:26,
   borderColor: '#e5e5e5',
   borderWidth: 1,
   borderRadius: 12,
   marginVertical: 5,
   padding: 20,
   flexDirection: 'row',
   justifyContent: 'space-between'
  },
  checkboxContainer: {
    paddingRight: 20,
    paddingTop: 100
  },
  checkbox: {
    alignSelf: "center",
  },
  add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
  crossIcon: {
    position: 'absolute',
    top: 40,
    right: 10,
    elevation: 10,
},      
searchBox: {
    flexDirection: 'row',
    marginHorizontal:20,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 50
    
  },
  searchIcon: {
    // paddingLeft:30,
    paddingVertical:20
  },
  input: {
outlineStyle: 'none',
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: '#4e3e71',
    fontSize: 16,
    paddingStart: 10
  },
  card: {
    flex: 1,
    marginTop:20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard : {
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: '#fff',
    marginHorizontal: 30,
    marginVertical: 6,
    position: 'relative',
    justifyContent: 'space-between'
  },
  innerCard: {
    margin:10,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: '#fff',
    paddingHorizontal: 30,
    paddingVertical: 60

  },
  addressText: {
        marginVertical: 20,
        marginLeft: 20
  },
  cardHeading: {
    marginVertical: 20,
    color: '#0579ed',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center'
},    
para: {
    color: '#939ca3'
  },
  paraDis: {   
    color: '#0579ed'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
})
