import React,{useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';

const InviteUser = () => {
  const [data, setData] = useState('');

  const handleInvite = () => {
    setData("nikki panchal")
    window.localStorage.setItem('name', data);

  }

      return (
            <View style={styles.container}>
           <View style={styles.card}>
           <View style={styles.cardContent}>
                 <Text style={styles.heading}>Invite User</Text>
                 <TextInput 
                       style={styles.input}
                        placeholder="Email"
                        placeholderTextColor="#0579ed"
                 />
                  <TextInput 
                       style={styles.input}
                        placeholder="Full Name"
                        placeholderTextColor="#0579ed"
                 />
                   <Text style={styles.inputCollapse}> Type of Member <ArrowDropDownIcon style={{paddingLeft: "40px"}}/></Text>
                <View style={styles.signUpLogin}>
                 <TouchableOpacity onPress={() => handleInvite}>
      <View style={styles.button}>
        <Text style={styles.buttonText}>Invite User </Text>
      </View>
    </TouchableOpacity>
                 </View> 
                 </View>
                 </View>
                 </View>
      )
}

export default InviteUser;

const styles = StyleSheet.create({
      container: {
            flex: 1,
            padding: 1,
            backgroundColor: "#eff8ff"
          },
          card: {
            margin:50,
          },
          cardContent: {
          justifyContent: 'center'
          },
          input: {
outlineStyle: 'none',
            height: 50,
            margin: 6,
            borderRadius: 5,
            backgroundColor: 'white',
            paddingLeft: 10
          },
          inputCollapse: {
            paddingVertical: 18,
            paddingLeft: 10,
            margin: 6,
            borderRadius: 5,
            color: '#0579ed',
            backgroundColor: 'white',
          },
          button: {
            borderRadius: 8,
            paddingVertical: 10,
            paddingHorizontal: 50,
            backgroundColor: '#0579ed',
            marginVertical: 10,
          },
          buttonText: {
            color: 'white',
            fontSize: 20,
            textAlign: 'center',
          },
          signUpLogin: {
            flexDirection: 'row',
            justifyContent:'space-between',
            margin: 2
          },
          heading: {
            color: '#0579ed',
            fontSize: 30,
            fontWeight: 'bold'

          },
          para: {
            color: '#939ca3'
          },
          forgotpass: {
            color: '#94c3e0',
            justifyContent: 'flex-end',
            marginBottom: 50
          }, 
          link: {
            justifyContent: 'flex-end'
          }
});
