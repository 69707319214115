import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      TextInput,
      Platform,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DesktopMenue from "./DesktopMenue";
import CallIcon from '@material-ui/icons/Call';
import Dialog from '@material-ui/core/Dialog';
import OrderConfirmed from "./OrderConfirmed";
import Collapse from '@material-ui/core/Collapse';
import { ageWithMonth, SortData } from "../../../framework/src/Utilities";
import CustomPopUp from "../../../framework/src/CustomPopUp";
import { getAxiosAPI, patchAxiosAPI, postAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import GlobalCss from "../../../framework/src/GlobalCss";



export default function DesktopSurgeryDetails({ navigation, order }: { navigation: any, order: any }) {
      // console.log(order)

      const date = order.booking_datetime
      var d = new Date(date);
      const SelectedMaterails = JSON.parse(localStorage.getItem('selectedMaterialList') || '[]')
      const requestedMaterials = JSON.parse(localStorage.getItem('requestedMaterials') || '[]')


      const [open, setOpen] = React.useState(false);
      const [contactDetailsDrawer, setContactDetailsDrawer] = React.useState<any>({})
      const [loader, setLoader] = useState(false)

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)

      const handleClose2 = () => {
            //setOpen(false);
      };
      const [checked5, setChecked5] = React.useState(false);

      const [open2, setOpen2] = React.useState(false)

      const [open3, setOpen3] = React.useState(false)
      const openContactDialog = (role: any, name: any, number: any) => {
            const data = {
                  role: role,
                  name: name,
                  number: number
            }
            setContactDetailsDrawer(data)
            setOpen2(true)
            // console.log(role, name , number)
      }

      const MaterialDialogDetails: any = JSON.parse(localStorage.getItem("MaterialDialogDetails") || '{}')

      const handleClose = () => {
            const removeKey = [
                  'order',
                  'selectedMaterialList',
                  'requestedMaterials',
                  'selectedMaterial',
                  'selectedFacility',
                  'selectedCode',
                  'selectedCodeList',
                  'isCptCodeFlow'
            ]
            removeKey.forEach((k: any) => {
                  localStorage.removeItem(k)
            })
            navigation.navigate('Dashboard')
      };

      function urltoFile(url: any, filename: any, mimeType: any) {
            return (fetch(url)
                  .then(function (res) { return res.arrayBuffer(); })
                  .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
      }

      const navigateTo = async (id: any) => {
            if (requestedMaterials.length || order.patient_imageDemo) {
                  if (requestedMaterials.length) {
                        let reqMaterials = requestedMaterials.map((x: any) => ({
                              name: x.name,
                              order_id: id,
                              brand_id: order.manfacturer_id,
                              category_id: order.category_id,
                              material_manager_id: order.savedHospital.attributes.material_manager.id,
                              sales_representative_id: order.saleRep_id,
                              description: x.description,
                              status: "pending"

                        })
                        )
                        let postreqData = {
                              data: reqMaterials
                        }
                        console.log(reqMaterials)

                        let url = "/bx_block_materials/material_request";
                        await postAxiosAPI(url, reqMaterials).then((res: any) => {
                              if (res.error) {
                                    let msg = getErrorMessage(res.data.errors[0])
                                    console.log(res.data);
                                    setPopup(true)
                                    setMessage(msg)
                                    setLoader(false)
                              } else {
                                    setLoader(false)
                                    setOpen(true)
                              }
                        });

                  } else if (order.patient_imageDemo) {
                        var imageData = new FormData()
                        //Usage example:
                        urltoFile(order.patient_imageDemo, order.patient_image.name, order.patient_image.type)
                              .then(async function (file: any) {
                                    console.log(file);
                                    imageData.append('patient_image', file ? file : null, order.patient_image.name ? order.patient_image.name : null);

                                    let url = `/bx_block_order/orders/patient_image?id=${id}`;
                                    await patchAxiosAPI(url, imageData).then((res: any) => {
                                          if (res.error) {
                                                let msg = getErrorMessage(res.data.errors[0])
                                                console.log(res.data);
                                                setPopup(true)
                                                setMessage(msg)
                                                setLoader(false)
                                          } else {
                                                setLoader(false)
                                                console.log(res);
                                                setOpen(true)
                                          }
                                    });

                              });

                  } else {
                        setOpen(true)
                  }
            } else {
                  setOpen(true)
            }

      }

      const makeAPICall = async () => {
            setLoader(true)
            order.ordered_materials_attributes = SelectedMaterails
            order.material_manager_id = order.savedHospital.attributes?.material_manager.id ? order.savedHospital.attributes.material_manager.id : 68
            order.cpt_codes = order?.cpt_codes?.map((x: any) => (
                  {
                        code: +(x.code)
                  }
            ))
            order.diagnosis_codes = order?.diagnosis_codes?.map((x: any) => (
                  {
                        code: x.code,
                        laterality: x.laterality
                  }
            ))

            let finalBookingData = {
                  hospital_id: order.hospital_id,
                  booking_datetime: order.booking_datetime,
                  patient_type: order.patient_type,
                  patient_gender: order.patient_gender,
                  latex_allergies: order.latex_allergies,
                  surgery_detail: order.surgery_detail,
                  patient_age: parseInt(order.patient_age),
                  procedure_duration: order.procedure_duration,
                  or_table: order.or_table,
                  imagings: order.imagings,
                  microscope: order.microscope ? order.microscope : false,
                  pr_op_antibiotics: order.pr_op_antibiotics ? true : false,
                  first_name: order.first_name,
                  last_name: order.last_name,
                  order_status: "pending",
                  cpt_codes: order.cpt_codes,
                  diagnosis_codes: order.diagnosis_codes,
                  ordered_materials_attributes: order.ordered_materials_attributes,
                  insurance_carrier: order.insurance_carrier,
                  policy_member_id: order.policy_member_id,
                  authorization: order.authorization,
                  draps: order.draps,
                  sutures: order.sutures,
                  sutures_size: order.sutures_size,
                  patient_email: order.patient_email,
                  patient_phone: order.patient_phone,
                  benefits: order.benefits,
                  physio_therapy: order.physiotherapy === "Yes" ? true : false,
                  homecare: order.homecare === "Yes" ? true : false,
            }

            const postData = {
                  order: finalBookingData
            }
            console.log(postData)

            let url = "/bx_block_order/orders";
            await postAxiosAPI(url, postData).then((res: any) => {
                  if (res.error) {
                        let msg = getErrorMessage(res.data.errors[0])
                        console.log(res.data);
                        setPopup(true)
                        setMessage(msg)
                        setLoader(false)
                  } else {
                        navigateTo(res.data.data.id)
                  }
            });
      }


      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [data, setData] = useState([])

      useEffect(() => {
            const getSalesRepList = async () => {
                  let url = `/bx_block_order/orders/sales_representative_list`
                  await getAxiosAPI(url).then((res: any) => {
                        if (res.error) {
                              let msg = getErrorMessage(res.data.errors[0])
                              console.log(msg)
                        } else {
                              setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code, b.attributes.code)))
                        }
                  })
            };
            getSalesRepList();
      }, []);

      const handleSearch = (text: any) => {
            setQuery(text)
            if (text !== "") {
                  const newData = data.filter((name: any) => {
                        return Object.values(name.attributes)
                              .join(" ")
                              .toLowerCase()
                              .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
            } else {
                  setsearchData(data);
            }
      };
      console.log(searchData);


      const ListEmptyView = () => {
            return (
                  <View style={{ marginBottom: 80 }}>
                        <Text style={styles.heading2}>No Record Found</Text>

                  </View>

            )
      }

      const [salesRep, setSalesRep] = useState(null)

      const handleChange = (item: any) => {
            let newdata: any = data.map((x: any) => {

                  if (x.id === item.id) {
                        x.ischecked = !x.ischecked
                  } else {
                        x.ischecked = false
                  }
                  return x

            })
            setData(newdata)
            setSalesRep(item)

      }
      const [salesRepName, setSalesRepName] = useState<any>(null)

      const ChangeSalesRep = () => {
            setSalesRepName(salesRep)

      }

      const ClosePopUp = () => {
            setPopup(false)
      }





      return (
            <View style={styles.desktopContainer}>
                  {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message} />}
                  <DesktopHeader heading={'Booking Summary'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={styles.mainPatientCard}>
                              <View style={styles.outerCard}>
                              <Text style={GlobalCss.watermarkText}>CONFIDENTIAL</Text>
                                    <View style={styles.card}>
                                          <View style={styles.leftData}>
                                                <img
                                                      src={order.patient_imageDemo ? order.patient_imageDemo : order.patient_gender === 'Male' ? require("../assets/patient_male.png") : require("../assets/patient_female.png")}
                                                      style={{ borderRadius: 12, height: 150, }} />
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.fontThemeBold}>Information</Text>
                                                      <Text style={styles.fontTheme}>{order.patient_type}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Patient name:</Text>
                                                      <Text style={styles.fontTheme}>{order.first_name + " " + order.last_name}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Age:</Text>
                                                      <Text style={styles.fontTheme}>{ageWithMonth(order.patient_age)}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Email:</Text>
                                                      <Text style={styles.fontTheme}>{order.patient_email}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Phone:</Text>
                                                      <Text style={styles.fontTheme}>{order.patient_phone}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Hospital name:</Text>
                                                      <Text style={styles.fontTheme}>{order.hospital_name}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Type:</Text>
                                                      <Text style={styles.fontTheme}>{order.patient_type}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Date: </Text>
                                                      <Text style={styles.fontTheme}>{d.toLocaleDateString()}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Time: </Text>
                                                      <Text style={styles.fontTheme}>{d.toLocaleTimeString([], { timeStyle: 'short' })}</Text>
                                                </View>
                                                <View style={styles.infoRow}>
                                                      <Text style={styles.keycolor}>Status: </Text>
                                                      <Text style={styles.fontTheme}>Pending</Text>
                                                </View>
                                          </View>
                                          <View style={GlobalCss.watermarkRightCard}>

                                                <View style={styles.infoLeftData}>
                                                      <Text style={styles.fontWSize}>Insurance Information: </Text>
                                                      <Text style={styles.info}>1) Insurance carrier: <Text style={styles.fontTheme}>{order.insurance_carrier}</Text></Text>
                                                      <Text style={styles.info}>2) Policy/Member id: <Text style={styles.fontTheme}>{order.policy_member_id}</Text></Text>
                                                      <Text style={styles.info}>3) Authorization: <Text style={styles.fontTheme}>{order.authorization}</Text></Text>
                                                      <Text style={styles.info}>4) Benefits: <Text style={styles.fontTheme}>{order.benefits}</Text></Text>


                                                      <Text style={styles.fontWSize}>Imaging: </Text>
                                                      <View style={styles.drawerItemI}>
                                                            {
                                                                  order.imagings.map((x: any, index: any) => {
                                                                        return (
                                                                              <Text style={styles.info}>{x ? index + 1 + ") " + x : null}</Text>
                                                                        )
                                                                  })
                                                            }
                                                      </View>
                                                      <Text style={styles.fontWSize}>Booking Information: </Text>
                                                      <Text style={styles.info}>1) Patient Status: <Text style={styles.fontTheme}>{order.patient_type}</Text></Text>
                                                      <Text style={styles.info}>2) Latex Allergies: <Text style={styles.fontTheme}>{order.latex_allergies}</Text></Text>
                                                      <Text style={styles.info}>3) O.R. Table: <Text style={styles.fontTheme}>{order.or_table}</Text></Text>

                                                      <Text style={styles.fontWSize}>Postop</Text>
                                                      <Text style={styles.info}>1) Physiotherapy: <Text style={styles.fontTheme}>{order.physiotherapy === "Yes" ? "Yes" : "No"}</Text></Text>
                                                      <Text style={styles.info}>2) Homecare: <Text style={styles.fontTheme}>{order.homecare === "Yes" ? "Yes" : "No"}</Text></Text>

                                                      <Text style={styles.fontWSize}>Implants & Instruments: </Text>

                                                      {
                                                            SelectedMaterails.map((x: any, y: any) => {
                                                                  return (
                                                                        <View style={styles.drawerItem}>
                                                                              <Text style={{ color: '#7f7f7f', flex: 0.5, fontSize: 17 }}>{y + 1}) {x.material_name}</Text>
                                                                              <Text style={{ color: '#7f7f7f', flex: 0.2, fontSize: 17, paddingLeft: 20 }}>{x.quantity}</Text>

                                                                        </View>
                                                                  )
                                                            })
                                                      }

                                                </View>


                                                <View style={styles.infoRightData}>


                                                      <Text style={styles.fontWSize}>Disposables List</Text>

                                                      <Text style={styles.info}>Drapes : {order.draps}</Text>
                                                      <Text style={styles.info}>Sutures : {order.sutures} </Text>
                                                      <Text style={styles.info}>Size : {order.sutures_size} </Text>
                                                      <Text style={styles.fontWSize}>Contact Details: </Text>

                                                      <View style={styles.detailsList3}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                  <View>
                                                                        <img src={require("../assets/personsmall.png")} style={{ height: 16, width: 16 }} />
                                                                  </View>
                                                                  <View style={{ flexDirection: 'column', paddingHorizontal: 5 }}>
                                                                        <Text style={{ color: '#7f7f7f', fontSize: 14 }}>{order.savedHospital?.attributes.booking_coordinator?.first_name} {order.savedHospital?.attributes.booking_coordinator?.last_name}</Text>
                                                                        <Text style={{ color: '#4e3e71', fontSize: 17 }}>Booking Co-ordinator </Text>
                                                                  </View>

                                                            </View>
                                                            <Text >
                                                                  <TouchableOpacity onPress={() => openContactDialog('Booking Co-ordinator', order.savedHospital?.attributes?.booking_coordinator?.first_name + " " + order.savedHospital?.attributes?.booking_coordinator?.last_name, order.savedHospital?.attributes.booking_coordinator?.full_phone_number)} >
                                                                        <CallIcon
                                                                              style={{
                                                                                    color: '#cce9e5',
                                                                                    fontSize: 20,
                                                                                    backgroundColor: '#4e3e71',
                                                                                    borderRadius: "50%",
                                                                                    padding: "5px",
                                                                                    justifyContent: 'center'
                                                                              }} />
                                                                  </TouchableOpacity>
                                                            </Text>
                                                      </View>
                                                      <View style={styles.detailsList3}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                  <View>
                                                                        <img src={require("../assets/personsmall.png")} style={{ height: 16, width: 16 }} />
                                                                  </View>
                                                                  <View style={{ flexDirection: 'column', paddingHorizontal: 5 }}>
                                                                        <Text style={{ color: '#7f7f7f', fontSize: 14 }}>{order.savedHospital?.attributes?.material_manager?.first_name}  {order.savedHospital?.attributes.material_manager?.last_name}</Text>
                                                                        <Text style={{ color: '#4e3e71', fontSize: 17 }}>Material Manager</Text>
                                                                  </View>

                                                            </View>
                                                            <Text >
                                                                  <TouchableOpacity onPress={() => openContactDialog('Material Manager', order.savedHospital?.attributes?.material_manager?.first_name + " " + order.savedHospital?.attributes?.material_manager?.last_name, order.savedHospital?.attributes.material_manager?.full_phone_number)}>
                                                                        <CallIcon
                                                                              style={{
                                                                                    color: '#cce9e5',
                                                                                    fontSize: 20,
                                                                                    backgroundColor: '#4e3e71',
                                                                                    borderRadius: "50%",
                                                                                    padding: "5px",
                                                                                    justifyContent: 'center'
                                                                              }} />
                                                                  </TouchableOpacity>
                                                            </Text>
                                                      </View>
                                                      <View style={styles.detailsList3}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                  <View>
                                                                        <img src={require("../assets/personsmall.png")} style={{ height: 16, width: 16 }} />
                                                                  </View>
                                                                  <TouchableOpacity onPress={() => checked5 ? setChecked5(false) : setChecked5(true)}>
                                                                        <View style={styles.drawersSalesrep}>
                                                                              <View style={{ flexDirection: 'column', paddingHorizontal: 5 }}>
                                                                                    <Text style={{ color: '#7f7f7f', fontSize: 14 }}>{salesRepName ? salesRepName.attributes.first_name + " " + salesRepName.attributes.last_name : order?.salesRep}</Text>
                                                                                    <Text style={{ color: '#4e3e71', fontSize: 17 }}>Sales Representative</Text>
                                                                              </View>
                                                                              {checked5 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                                                        </View>
                                                                  </TouchableOpacity>

                                                            </View>
                                                            <Text >
                                                                  <TouchableOpacity onPress={() => openContactDialog('Sales Representative', order?.salesRep, order?.saleRep_no)} >
                                                                        <CallIcon
                                                                              style={{
                                                                                    color: '#cce9e5',
                                                                                    fontSize: 20,
                                                                                    backgroundColor: '#4e3e71',
                                                                                    borderRadius: "50%",
                                                                                    padding: "5px",
                                                                                    justifyContent: 'center'
                                                                              }} />
                                                                  </TouchableOpacity>
                                                            </Text>
                                                      </View>

                                                      <Collapse in={checked5}>
                                                            <View style={styles.searchBox}>
                                                                  <TextInput
                                                                        style={styles.input}
                                                                        placeholder="Search "
                                                                        autoCapitalize="none"
                                                                        autoCorrect={false}
                                                                        clearButtonMode="always"
                                                                        placeholderTextColor="#939ca3"
                                                                        value={query}
                                                                        onChangeText={queryText => handleSearch(queryText)}
                                                                  />
                                                                  <TouchableOpacity onPress={() => setQuery('')}>
                                                                        <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                                  </TouchableOpacity>

                                                            </View>
                                                            {salesRep ?
                                                                  <TouchableOpacity style={{ width: 150, justifyContent: 'flex-end', marginLeft: 20 }} onPress={() => ChangeSalesRep()}>
                                                                        <View style={styles.button4}>
                                                                              <Text style={styles.buttonText4}>Change Sales Rep</Text>
                                                                        </View>
                                                                  </TouchableOpacity>
                                                                  :
                                                                  <TouchableOpacity style={{ width: 150, justifyContent: 'flex-end', marginLeft: 20 }} onPress={() => navigation.goBack()}>
                                                                        <View style={styles.button3}>
                                                                              <Text style={styles.buttonText3}>Change Sales Rep</Text>
                                                                        </View>
                                                                  </TouchableOpacity>
                                                            }
                                                            <ScrollView style={{ flex: 1, height: 200, width: 450 }}>

                                                                  <FlatList
                                                                        data={query.length < 1 ? data : searchData}
                                                                        ListEmptyComponent={ListEmptyView()}
                                                                        keyExtractor={(item: any) => item.id}
                                                                        renderItem={({ item }) => (
                                                                              <View style={item.ischecked ? styles.salescardActive : styles.salescard}>
                                                                                    <Text style={{ flex: 0.8, padding: 10, color: item.ischecked ? '#4e3e71' : '#8a8a8a', fontSize: 16 }}>{item.attributes.first_name + " " + item.attributes.last_name} </Text>
                                                                                    <TouchableOpacity onPress={() => handleChange(item)} style={{ margin: 'auto', flex: 0.1 }}>
                                                                                          <img src={item.ischecked ? require("../assets/radio_active.png") : require("../assets/radio_unactive.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />
                                                                                    </TouchableOpacity>
                                                                              </View>

                                                                        )}
                                                                  />
                                                            </ScrollView>

                                                      </Collapse>
                                                </View>

                                          </View>


                                    </View>

                                    <View style={{ margin: 15, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <TouchableOpacity disabled={loader} onPress={() => navigation.goBack()}>
                                                <View style={styles.button2}>
                                                      <Text style={styles.buttonText2}>Back</Text>
                                                </View>
                                          </TouchableOpacity>

                                          <TouchableOpacity disabled={loader} onPress={() => makeAPICall()}>
                                                <View style={loader ? styles._unactivebtn : styles.button1}>
                                                      <Text style={styles.buttonText1}>Confirm Booking</Text>
                                                      {loader ? <ActivityIndicator style={{ position: 'absolute', right: 90, bottom: 5 }} size="large" color="#2e2442" /> : null}
                                                </View>
                                          </TouchableOpacity>
                                    </View>
                              </View>
                        </View>
                  </View>
                  <Dialog
                        open={open2}
                        onClose={handleClose2}
                  >

                        <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'flex-end' }} onPress={() => setOpen2(false)}>
                              <img src={require("../assets/cancel.png")} style={{ height: 30, width: 30, margin: 10 }} />
                        </TouchableOpacity>
                        <View style={{ width: 280, height: 60, marginVertical: 30, marginHorizontal: 20, flexDirection: 'row' }}>
                              <View>
                                    <img src={require("../assets/personsmall.png")} style={{ height: 20, width: 20 }} />
                              </View>
                              <View style={{ flexDirection: 'column' }}>
                                    <Text style={{ color: '#7f7f7f', fontSize: 17 }}>{contactDetailsDrawer?.name}({contactDetailsDrawer?.role})</Text>
                                    <Text style={{ color: '#4e3e71', fontSize: 15, paddingTop: 5 }}>{contactDetailsDrawer?.number}</Text>

                              </View>

                        </View>
                  </Dialog>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ width: 400, height: 300 }}>
                              <OrderConfirmed navigation={navigation} />
                        </View>

                  </Dialog>
            </View>
      )
}


const styles = StyleSheet.create({
      mainPatientCard: { flex: 0.85, overflowY: 'scroll' },
      infoRightData: { width: '50%', margin: 10, height: '100%' },
      infoLeftData: { width: '50%', margin: 10, borderRightWidth: 1, borderRightColor: '#7f7f7f' },
      fontThemeBold: {
            color: '#4e3e71',
            fontWeight: 'bold'
      },
      fontWSize: { 
            color: '#4e3e71', 
            fontWeight: 'bold', 
            fontSize: 16 
      },
      fontTheme: {
             color: '#4e3e71'
             },
      leftData: { flex: 0.2, borderRadius: 12, margin: 15, padding: 15, borderWidth: 1, borderColor: '#7f7f7f' },
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      keycolor: { color: '#7f7f7f' },
      info: { color: '#7f7f7f', paddingVertical: 5 },
      details: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 10,
            marginHorizontal: 10
      },
      button3: {
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginHorizontal: 2,
            backgroundColor: '#8a8a8a',
            // marginBottom: 10,
            // marginTop: 10
      },
      drawersSalesrep: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            // paddingHorizontal: 20,
            paddingVertical: 10
      },
      buttonText3: {
            color: '#e8fafe',
            fontSize: 12,
            textAlign: 'center',
      },
      heading: {
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'
      },
      heading2: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      searchBox: {
            flexDirection: 'row',
            marginHorizontal: 26,
            marginVertical: 5,
            borderBottomWidth: 1,
            borderBottomColor: '#8a8a8a',
            // borderRadius: 50

      },
      salescard: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 50,
            marginVertical: 5,
            borderRadius: 12,
            borderColor: '#8a8a8a',
            borderWidth: 1
      },
      salescardActive: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: 50,
            marginVertical: 5,
            borderRadius: 12,
            borderColor: '#4e3e71',
            borderWidth: 1
      },
      input: {
            outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 5
      },
      desktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      drawerItem: {
            paddingHorizontal: 5,
            flexDirection: 'row',
            marginVertical: 10,
            justifyContent: 'space-between'

      },
      drawerItemI: {
            paddingHorizontal: 5,
            flexDirection: 'column',
            // marginVertical: 10,
            // justifyContent: 'space-between'

      },
      detailsList3: {
            paddingVertical: 10,
            paddingHorizontal: 10,
            flexDirection: 'row',
            marginRight: 50,
            justifyContent: 'space-between'

      },
      button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            backgroundColor: '#4e3e71',
      },
      _unactivebtn: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            backgroundColor: '#8e7bb7',
      },
      buttonText1: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      button2: {
            borderRadius: 12,
            paddingVertical: 12,
            paddingHorizontal: 60,
            borderColor: '#4e3e71',
            borderWidth: 1,
            backgroundColor: '#fff',
      },
      buttonText2: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
      },
      button4: {
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginHorizontal: 2,
            backgroundColor: '#4e3e71',
            // marginBottom: 10,
            // marginTop: 10
      },
      buttonText4: {
            color: '#e8fafe',
            fontSize: 12,
            textAlign: 'center',
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'

      },
      infoRow: { flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 6 },
      button: {
            borderRadius: 18,
            paddingVertical: 5,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: '#4e3e71',
      },
      buttonText: {
            color: '#cce9e5',
            fontSize: 15,
            textAlign: 'center',
      },
      card: {
            flex: 1,
            flexDirection: 'row',
            minHeight: 'auto'
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            // padding: 15,
            margin: 15,
            flexDirection: 'column',
            position: 'relative',
            minHeight: 'auto',
      }
})
