import React,{useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
TextInput,
FlatList
 } from 'react-native';
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';

const CmfpMaterials = ({navigation} : {navigation: any,}) => {

      const [data, setData] = useState<any>([]);
      const [Err, setErr] = useState(false)
      const code = localStorage.getItem('selectedCptCode')

      useEffect(() => {

            const getHospitals = async () => {
                  let url = `/bx_block_order/orders/fetch_cpt_code_materials?cpt_code=${code}`
                  await getAxiosAPI(url).then((response: any) => {
                        if(response.error){
                              setErr(true)
                        } else {
                              console.log(response.data.data);
                              let arr2 = response.data.data.attributes.material_data
                              let arr1 = response.data.data.attributes.cpt_materials.data.map((x:any) => (
                                    {
                                          material_name: x.attributes.name,
                                          material_id: parseInt(x.id),
                                          selectedCptId: response.data.data.id,
                                    }
                              ))
                              let merged = [];
                              for(let i=0; i<arr1.length; i++) {
                              merged.push({
                              ...arr1[i], 
                              ...(arr2.find((itmInner :any ) => itmInner.material_id === arr1[i].material_id))}
                              );
                              }
                              //console.log(merged);
                              setData(merged);
                        }
                      })
            };
            
            getHospitals();

          }, []);
          //console.log(data)

        
      

          const navigationTo = (x:any) => {
                console.log(x, code);

                localStorage.setItem('selectedCodeList', JSON.stringify(x))
                //localStorage.setItem('selectedCode', code)
                
            navigation.navigate('CMFPB')
          }

    
      return ( 
            <View>
                  {
                        Err ? 
                  <View>
                        <Text style={styles.heading}>No Record Found</Text>
                  </View> : 
                  <View>
                  {
                        data.map((item:any, y:any) => {
                              return (
                                    <Text style={styles.info} key={y}>{y+1}) {item.material_name}</Text> 
                              )
                        })
                  }
                  <TouchableOpacity onPress={() => navigationTo(data)}>
                                    <View style={styles.button}>
                                    <Text style={styles.buttonText}>View Full List </Text>
                                    </View>
                                    </TouchableOpacity> 

                  </View>
            }
            </View>
      )
}

export default CmfpMaterials

const styles = StyleSheet.create({
          info: {
            paddingVertical: 5,
            fontSize: 20,

          },
          heading:{
            marginVertical: 10,
            marginHorizontal: 'auto',
            color: '#0579ed',
            fontSize: 18,
            fontWeight: 'bold',
            flexDirection : 'row',
            justifyContent: 'center'
          },
          button: {
            borderRadius: 6,
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical : 5,
            backgroundColor: '#0778df',
          },
          buttonText: {
            color: 'white',
            fontSize: 18,
            textAlign: 'center',
          },
})
