import React from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export default class ValidateMaterial extends React.Component<Props> {
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.headerWrapper}>
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.leftArrowIconWrapper}
          >
            <Image
              source={require("../../../assets/leftArrow.png")}
              style={styles.leftArrowIcon}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Validate</Text>
        </View>
        <TouchableOpacity
          onPress={() =>this.props.navigation.navigate('NewMaterial') }
          style={styles.button}
        >
          <Text style={styles.buttonText}>Add New Material</Text>
        </TouchableOpacity>
        <View style={styles.text}>
          <Text style={styles.orText}>Or</Text>
        </View>
        <TouchableOpacity
          onPress={() =>{this.props.navigation.navigate('RequestedMaterial')}}
          style={styles.button2}
        >
          <Text style={styles.buttonText}>Requested Material Details</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  heading: {
    color: "#4e3e71",
    fontWeight: "bold",
    marginVertical: 30,
    marginHorizontal: 30,
    fontSize: 20
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
    padding: 20
  },
  button: {
    borderRadius: 8,
    paddingVertical: 20,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginHorizontal: 30,
    marginTop:65
  },
  button2: {
    borderRadius: 8,
    paddingVertical: 20,
    paddingHorizontal: 10,
    backgroundColor: "#4e3e71",
    marginHorizontal: 30
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 20,
    textAlign: "center"
  },
  orText: { fontSize: 24, color: "#4e3e71", textAlign: "center" },
  modalWrapper: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.48)",
    position: "absolute",
    zIndex: 99,
    justifyContent: "center",
    alignItems: "center"
  },
  modalSubWapper: {
    backgroundColor: "#fff",
    width: "90%",
    height: 218,
    justifyContent: "space-evenly"
  },
  modalText: {
    color: "#4e3e71",
    fontSize: 18,
    textAlign: "center",
    paddingHorizontal: 25
  }
});


