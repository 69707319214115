// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import AppLogout from './AppLogout';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import BarcodesScanner from '../../blocks/BarcodesScanner/src/BarcodesScanner';
import ItemGrouper from '../../blocks/ItemGrouper/src/ItemGrouper';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import Sms2 from '../../blocks/Sms2/src/Sms2';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import CfPwaAdjustment2 from '../../blocks/CfPwaAdjustment2/src/CfPwaAdjustment2';
import ProjecttaskTracking2 from '../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import CfPwaAdjustment1 from '../../blocks/CfPwaAdjustment1/src/CfPwaAdjustment1';
import IcalendarSupport from '../../blocks/IcalendarSupport/src/IcalendarSupport';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import BarcodeSettings from '../../blocks/BarcodeSettings/src/BarcodeSettings';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import CfPwaAdjustment32 from '../../blocks/CfPwaAdjustment32/src/CfPwaAdjustment32';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOtp';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import CustomForm2 from '../../blocks/CustomForm2/src/CustomForm2';
import Library2 from '../../blocks/Library2/src/Library2';
import Calendar from '../../blocks/Calendar/src/Calendar';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import CameraAccess from '../../blocks/CameraAccess/src/CameraAccess';
import AddFacility from '../../blocks/email-account-registration/src/AddFacility';
import FailityName from '../../blocks/email-account-registration/src/FacilitiesName';
import AddNewFacility from '../../blocks/email-account-registration/src/AddNewFacilities';
import FacilityName from '../../blocks/email-account-registration/src/FacilitiesName';
import AddTeam from '../../blocks/email-account-registration/src/AddTeam';
import InviteUser from '../../blocks/email-account-registration/src/InviteUser';
import Profile from '../../blocks/dashboard/src/Profile';
import ExBooking from '../../blocks/dashboard/src/ExBooking';
import BookingInfo from '../../blocks/dashboard/src/BookingInfo';
import SurgeryDetails from '../../blocks/dashboard/src/SurgeryDetails';
import SurgeryDetailsBc from '../../blocks/dashboard/src/SurgeryDetailsBc';
import CreateBooking from '../../blocks/dashboard/src/CreateBooking';
import SelectedFacilities from '../../blocks/dashboard/src/SelectedFacilities';
import ChooseMaterial from '../../blocks/dashboard/src/ChooseMaterial';
import SelectManufacture from '../../blocks/dashboard/src/SelectManfac';
import MaterialCategory from '../../blocks/dashboard/src/MaterialCategory';
import RequestMaterial from '../../blocks/dashboard/src/RequestMaterial';
import SelectedMaterial from '../../blocks/dashboard/src/SelectedMaterial';
import CartList from '../../blocks/dashboard/src/CartList';
import BookingSummary from '../../blocks/dashboard/src/BookingSummary';
import OrderConfirmed from '../../blocks/dashboard/src/OrderConfirmed';
import CMFPA from '../../blocks/dashboard/src/CMFP-1';
import CMFPB from '../../blocks/dashboard/src/CMFP-2';
import CMFPC from '../../blocks/dashboard/src/CMFP-3A';
import CmfpMaterials from '../../blocks/dashboard/src/CmfpMaterials';
import CMFPconfirm from '../../blocks/dashboard/src/CMFP-confirm';
import History from '../../blocks/dashboard/src/History';
import History2 from '../../blocks/dashboard/src/History2';
import Tracking from '../../blocks/dashboard/src/Tracking';
import Tracking2 from '../../blocks/dashboard/src/Tracking2';
import Notification from '../../blocks/dashboard/src/Notification';
import Dashboard4 from '../../blocks/dashboard/src/SalesRep/Dashboard4';
import Dashboard2 from '../../blocks/dashboard/src/materialManager/Dashboard2';
import Dashboard3 from '../../blocks/dashboard/src/bookingcor/Dashboard3';
import ContactPriority from '../../blocks/dashboard/src/ContactPriority';
import SelectManufacture4 from '../../blocks/dashboard/src/SalesRep/SelectManu4';
import Profile3 from '../../blocks/dashboard/src/bookingcor/Profile3';
import BookingList from '../../blocks/ProjecttaskTracking2/src/BookingCoordinator/BookingList';
import SurgeryDetails3 from '../../blocks/ProjecttaskTracking2/src/BookingCoordinator/SurgeryDetails';
import SelectMaterialBrandCatergory from '../../blocks/dashboard/src/MaterialBrandCategory';
import UserFacilities from '../../blocks/dashboard/src/UserFacilities';
import EditProfile from '../../blocks/dashboard/src/EditProfile';
import ConfirmByOtp from '../../blocks/dashboard/src/ConfirmByOtp';
import TermsCondition from '../../blocks/email-account-registration/src/Terms&Condition';
import ValidateMaterial from '../../blocks/dashboard/src/materialManager/ValidateMaterial/ValidateMaterial';
import ValidateManager from '../../blocks/dashboard/src/materialManager/ValidateManager/ValidateManagerBlock';
import ValidateManagerDetail from '../../blocks/dashboard/src/materialManager/ValidateManagerDetail/ValidateManagerDetail';
import ValidateNewSurgeonBlock from '../../blocks/dashboard/src/materialManager/ValidateNewSurgeon/ValidateNewSurgeonBlock';
import ValidateNewSurgeonDetail from '../../blocks/dashboard/src/materialManager/ValidateNewSurgeonDetail/ValidateNewSurgeonDetail';
import MaterialConfirmed from '../../blocks/dashboard/src/materialManager/MaterialConfirmed/MaterialConfirmed';
import SharedNotes from '../../blocks/dashboard/src/SharedNotes';
import SurgeonConfirmed from '../../blocks/dashboard/src/materialManager/SurgeonConfirmed/SurgeonConfirmed';
import NewMaterial from '../../blocks/dashboard/src/materialManager/NewMaterial/NewMaterial';
import VerifyMaterialDetail from '../../blocks/dashboard/src/materialManager/VerifyMaterialDetail/VerifyMaterialDetail';
import RequestedMaterial from '../../blocks/dashboard/src/materialManager/RequestedMaterials/RequestedMaterial';
import SalesSelectManufacture from '../../blocks/dashboard/src/SalesRep/SelectManufactor/SelectManufactor';
import SelectMaterial from '../../blocks/dashboard/src/SalesRep/SelectMaterial/SelectMaterial';
import ProfileUser from '../../blocks/dashboard/src/SalesRep/Profile/Profile';
import EditProfile1 from '../../blocks/dashboard/src/SalesRep/EditProfile/EditProfile';
import SalesRequestedMaterial from '../../blocks/dashboard/src/SalesRep/RequestedMaterial/RequestedMaterial';
import VerifyMaterial1 from '../../blocks/dashboard/src/SalesRep/VerifyMaterial/VerifyMaterial';
import HistoryList from '../../blocks/dashboard/src/SalesRep/HistoryList/HistoryList';
import VerifyNotification from '../../blocks/dashboard/src/SalesRep/VerifyNotification/VerifyNotification';
import OutOfOffice from '../../blocks/dashboard/src/SalesRep/OutOfOffice/OutOfOffice';
import PatientMaterialList from '../../blocks/dashboard/src/materialManager/PatientMaterialList/PatientMaterialList';
import ReassignMaterial from '../../blocks/dashboard/src/materialManager/ReassignedMaterials/ReassignMaterial';
import Dashborard from '../../blocks/dashboard/src/SalesRep/Dashboard/Dashboard';
import ValidateBC from '../../blocks/dashboard/src/bookingcor/ValidateBC';
import ValidateBc2 from '../../blocks/dashboard/src/bookingcor/ValidateBc2';
import ProposedDateTime from '../../blocks/dashboard/src/bookingcor/ProposedDate&Time';
import CheckOffMaterial from '../../blocks/dashboard/src/DesktopCheckoffMaterial';
import EditBookingDateTime from '../../blocks/dashboard/src/EditBookingDate&Time';
import EditBookingFacility from '../../blocks/dashboard/src/EditBookingFacility';
import NotificationDetails from '../../blocks/dashboard/src/NotificationDetails';
import ChooseAddMaterialType from '../../blocks/dashboard/src/ChooseAddMaterialType';
import CatalogueManufactur from '../../blocks/dashboard/src/CatalougeManufac';
import CatalougeCategories from '../../blocks/dashboard/src/CatalougeCategories';
import CatalougeMaterials from '../../blocks/dashboard/src/CatalougeMaterials';
import * as firebase from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import MaterialDetails from '../../blocks/dashboard/src/MaterialDetails';
import GenerateReports from '../../blocks/dashboard/src/GenerateReports';
import UserManufacturers from '../../blocks/dashboard/src/Usermanufac';
import CreateManufacturer from '../../blocks/dashboard/src/SalesRep/SelectManufactor/CreateManufac';
import AuthenticateAccount from '../../blocks/email-account-login/src/GoogleAuth';
import { SessionOutModal } from '../../framework/src/CustomPopUp';
import UserActivityReport from "../../blocks/dashboard/src/UserActivityReport";
import SessionReports from "../../blocks/dashboard/src/GenerateSessionReport";

const routeMap = {
  //---------------------New Screens ----------------

  SessionReports: {
    component: SessionReports,
    path: '/SessionReports'
  },
  UserActivityReport: {
    component: UserActivityReport,
    path: '/UserActivityReport'
  },
  AuthenticateAccount: {
    component: AuthenticateAccount,
    path: '/AuthenticateAccount'
  },

  CreateManufacturer: {
    component: CreateManufacturer,
    path: '/CreateManufacturer'
  },
  UserManufacturers: {
    component: UserManufacturers,
    path: '/UserManufacturers'
  },
  GenerateReports: {
    component: GenerateReports,
    path: '/GenerateReports'
  },
  MaterialDetails: {
    component: MaterialDetails,
    path: '/MaterialDetails'
  },

  CatalougeMaterials: {
    component: CatalougeMaterials,
    path: '/CatalougeMaterials'
  },
  CatalougeCategories: {
    component: CatalougeCategories,
    path: '/CatalougeCategories'
  },

  CatalogueManufacturer: {
    component: CatalogueManufactur,
    path: '/CatalogueManufacturer'
  },

  ChooseAddMaterialType: {
    component: ChooseAddMaterialType,
    path: '/ChooseAddMaterialType'
  },

  NotificationDetails: {
    component: NotificationDetails,
    path: '/NotificationDetails'
  },

  EditBookingFacility: {
    component: EditBookingFacility,
    path: '/EditBookingFacility'
  },
  EditBookingDateTime: {
    component: EditBookingDateTime,
    path: '/EditBookingDateTime'
  },
  CheckOffMaterial: {
    component: CheckOffMaterial,
    path: '/CheckOffMaterial'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },

  ProposedDateTime: {
    component: ProposedDateTime,
    path: '/ProposedDateTime'
  },
  SharedNotes: {
    component: SharedNotes,
    path: '/SharedNotes'
  },
  ValidateBc2: {
    component: ValidateBc2,
    path: '/ValidateBc2'
  },
  ValidateBC: {
    component: ValidateBC,
    path: '/ValidateBC'
  },
  TermsCondition: {
    component: TermsCondition,
    path: '/TermsCondition'
  },

  ConfirmByOtp: {
    component: ConfirmByOtp,
    path: '/ConfirmByOtp'
  },

  UserFacilities: {
    component: UserFacilities,
    path: '/UserFacilities'
  },

  EditProfile: {
    component: EditProfile,
    path: '/EditProfile'
  },

  SelectMaterialBrandCatergory: {
    component: SelectMaterialBrandCatergory,
    path: '/SelectMaterialBrandCatergory'
  },

  InviteUser: {
    component: InviteUser,
    path: '/InviteUser'
  },

  SurgeryDetails3: {
    component: SurgeryDetails3,
    path: '/SurgeryDetails3'
  },

  BookingList: {
    component: BookingList,
    path: '/BookingList'
  },

  Profile3: {
    component: Profile3,
    path: '/Profile3'
  },

  SelectManufacture4: {
    component: SelectManufacture4,
    path: '/SelectManufacture4'
  },

  ContactPriority: {
    component: ContactPriority,
    path: '/ContactPriority'
  },

  Notification: {
    component: Notification,
    path: '/Notification'
  },

  CmfpMaterials: {
    component: CmfpMaterials,
    path: '/CmfpMaterials'
  },
  Dashboard4: {
    component: Dashboard4,
    path: '/Dashboard4'
  },

  Dashboard2: {
    component: Dashboard2,
    path: '/Dashboard2'
  },

  Dashboard3: {
    component: Dashboard3,
    path: '/Dashboard3'
  },

  TermsCondition: {
    component: TermsCondition,
    path: '/TermsCondition'
  },
  ValidateMaterial: {
    component: ValidateMaterial,
    path: '/ValidateMaterial'
  },
  ValidateManager: {
    component: ValidateManager,
    path: '/ValidateManager'
  },
  ValidateManagerDetail: {
    component: ValidateManagerDetail,
    path: '/ValidateManagerDetail'
  },
  ValidateNewSurgeonBlock: {
    component: ValidateNewSurgeonBlock,
    path: '/ValidateNewSurgeonBlock'
  },
  ValidateNewSurgeonDetail: {
    component: ValidateNewSurgeonDetail,
    path: '/ValidateNewSurgeonDetail'
  },
  MaterialConfirmed: {
    component: MaterialConfirmed,
    path: '/MaterialConfirmed'
  },
  SurgeonConfirmed: {
    component: SurgeonConfirmed,
    path: '/SurgeonConfirmed'
  },
  NewMaterial: {
    component: NewMaterial,
    path: '/NewMaterial'
  },
  VerifyMaterialDetail: {
    component: VerifyMaterialDetail,
    path: '/VerifyMaterialDetail'
  },
  SalesRequestedMaterial: {
    component: SalesRequestedMaterial,
    path: '/SalesRequestedMaterial'
  },
  Tracking: {
    component: Tracking,
    path: '/Tracking'
  },

  Tracking2: {
    component: Tracking2,
    path: '/Tracking2'
  },

  History2: {
    component: History2,
    path: '/History2'
  },

  History: {
    component: History,
    path: '/History'
  },

  CMFPC: {
    component: CMFPC,
    path: '/CMFPC'
  },

  CMFPconfirm: {
    component: CMFPconfirm,
    path: '/CMFPconfirm'
  },

  CMFPA: {
    component: CMFPA,
    path: '/CMFPA'
  },

  CMFPB: {
    component: CMFPB,
    path: '/CMFPB'
  },

  OrderConfirmed: {
    component: OrderConfirmed,
    path: '/OrderConfirmed'
  },

  BookingSummary: {
    component: BookingSummary,
    path: '/BookingSummary'
  },

  CartList: {
    component: CartList,
    path: '/CartList'
  },

  RequestMaterial: {
    component: RequestMaterial,
    path: '/RequestMaterial'
  },

  SelectedMaterial: {
    component: SelectedMaterial,
    path: '/SelectedMaterial'
  },

  MaterialCategory: {
    component: MaterialCategory,
    path: '/MaterialCategory'
  },

  SelectManufacture: {
    component: SelectManufacture,
    path: '/SelectManufacture'
  },

  ChooseMaterial: {
    component: ChooseMaterial,
    path: '/ChooseMaterial'
  },

  SelectedFacilities: {
    component: SelectedFacilities,
    path: '/SelectedFacilities'
  },

  AddFacility: {
    component: AddFacility,
    path: '/AddFacility'
  },

  AddNewFacility: {
    component: AddNewFacility,
    path: '/AddNewFacility'
  },
  //---------------------New Screens ----------------
  SurgeryDetailsBc: {
    component: SurgeryDetailsBc,
    path: '/SurgeryDetailsBc'
  },

  CmfpMaterials: {
    component: CmfpMaterials,
    path: '/CmfpMaterials'
  },
  Dashboard4: {
    component: Dashboard4,
    path: '/Dashboard4'
  },

  Dashboard2: {
    component: Dashboard2,
    path: '/Dashboard2'
  },

  Dashboard3: {
    component: Dashboard3,
    path: '/Dashboard3'
  },

  ValidateMaterial: {
    component: ValidateMaterial,
    path: '/ValidateMaterial'
  },
  ValidateManager: {
    component: ValidateManager,
    path: '/ValidateManager'
  },
  ValidateManagerDetail: {
    component: ValidateManagerDetail,
    path: '/ValidateManagerDetail'
  },
  ValidateNewSurgeonBlock: {
    component: ValidateNewSurgeonBlock,
    path: '/ValidateNewSurgeonBlock'
  },
  ValidateNewSurgeonDetail: {
    component: ValidateNewSurgeonDetail,
    path: '/ValidateNewSurgeonDetail'
  },
  MaterialConfirmed: {
    component: MaterialConfirmed,
    path: '/MaterialConfirmed'
  },
  SurgeonConfirmed: {
    component: SurgeonConfirmed,
    path: '/SurgeonConfirmed'
  },
  NewMaterial: {
    component: NewMaterial,
    path: '/NewMaterial'
  },
  VerifyMaterialDetail: {
    component: VerifyMaterialDetail,
    path: '/VerifyMaterialDetail'
  },
  RequestedMaterial: {
    component: RequestedMaterial,
    path: '/RequestedMaterial'
  },
  PatientMaterialList: {
    component: PatientMaterialList,
    path: '/PatientMaterialList'
  },
  ReassignMaterial: {
    component: ReassignMaterial,
    path: '/ReassignMaterial'
  },

  SalesRequestedMaterial: {
    component: SalesRequestedMaterial,
    path: '/SalesRequestedMaterial'
  },
  Tracking: {
    component: Tracking,
    path: '/Tracking'
  },

  Tracking2: {
    component: Tracking2,
    path: '/Tracking2'
  },

  History2: {
    component: History2,
    path: '/History2'
  },

  History: {
    component: History,
    path: '/History'
  },

  CMFPC: {
    component: CMFPC,
    path: '/CMFPC'
  },

  CMFPconfirm: {
    component: CMFPconfirm,
    path: '/CMFPconfirm'
  },

  CMFPA: {
    component: CMFPA,
    path: '/CMFPA'
  },

  CMFPB: {
    component: CMFPB,
    path: '/CMFPB'
  },

  OrderConfirmed: {
    component: OrderConfirmed,
    path: '/OrderConfirmed'
  },

  BookingSummary: {
    component: BookingSummary,
    path: '/BookingSummary'
  },

  CartList: {
    component: CartList,
    path: '/CartList'
  },

  RequestMaterial: {
    component: RequestMaterial,
    path: '/RequestMaterial'
  },

  SelectedMaterial: {
    component: SelectedMaterial,
    path: '/SelectedMaterial'
  },

  MaterialCategory: {
    component: MaterialCategory,
    path: '/MaterialCategory'
  },

  SelectManufacture: {
    component: SelectManufacture,
    path: '/SelectManufacture'
  },

  ChooseMaterial: {
    component: ChooseMaterial,
    path: '/ChooseMaterial'
  },

  SelectedFacilities: {
    component: SelectedFacilities,
    path: '/SelectedFacilities'
  },

  AddFacility: {
    component: AddFacility,
    path: '/AddFacility'
  },

  AddNewFacility: {
    component: AddNewFacility,
    path: '/AddNewFacility'
  },

  FacilityName: {
    component: FacilityName,
    path: '/FacilityName'
  },

  AddTeam: {
    component: AddTeam,
    path: '/AddTeam'
  },

  Profile: {
    component: CustomisableUserProfiles,
    path: '/Profile'
  },

  ExBooking: {
    component: ExBooking,
    path: '/ExBooking/:id?'
  },

  BookingInfo: {
    component: BookingInfo,
    path: '/BookingInfo'
  },

  SurgeryDetails: {
    component: SurgeryDetails,
    path: '/SurgeryDetails'
  },

  CreateBooking: {
    component: CreateBooking,
    path: '/CreateBooking'
  },

  FacilityName: {
    component: FacilityName,
    path: '/FacilityName'
  },

  AddTeam: {
    component: AddTeam,
    path: '/AddTeam'
  },

  ExBooking: {
    component: ExBooking,
    path: '/ExBooking'
  },

  BookingInfo: {
    component: BookingInfo,
    path: '/BookingInfo'
  },

  SurgeryDetails: {
    component: SurgeryDetails,
    path: '/SurgeryDetails'
  },

  CreateBooking: {
    component: CreateBooking,
    path: '/CreateBooking'
  },

  SalesSelectManufacture: {
    component: SalesSelectManufacture,
    path: '/SalesSelectManufacture'
  },

  SelectMaterial: {
    component: SelectMaterial,
    path: '/SelectMaterial'
  },

  Dashborard: {
    component: Dashborard,
    path: '/Dashborard'
  },

  ProfileUser: {
    component: ProfileUser,
    path: '/ProfileUser'
  },

  EditProfile1: {
    component: EditProfile1,
    path: '/EditProfile1'
  },

  RequestedMaterial: {
    component: RequestedMaterial,
    path: '/RequestedMaterial'
  },

  VerifyMaterial1: {
    component: VerifyMaterial1,
    path: '/VerifyMaterial1'
  },

  HistoryList: {
    component: HistoryList,
    path: '/HistoryList'
  },

  OutOfOffice: {
    component: OutOfOffice,
    path: '/OutOfOffice'
  },
  //----------------------New Screens Ends------------

  BarcodesScanner: {
    component: BarcodesScanner,
    path: '/BarcodesScanner'
  },
  ItemGrouper: {
    component: ItemGrouper,
    path: '/ItemGrouper'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  Sms2: {
    component: Sms2,
    path: '/Sms2'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  CfPwaAdjustment2: {
    component: CfPwaAdjustment2,
    path: '/CfPwaAdjustment2'
  },
  ProjecttaskTracking2: {
    component: ProjecttaskTracking2,
    path: '/ProjecttaskTracking2'
  },

  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },

  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  CfPwaAdjustment1: {
    component: CfPwaAdjustment1,
    path: '/CfPwaAdjustment1'
  },
  IcalendarSupport: {
    component: IcalendarSupport,
    path: '/IcalendarSupport'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  BarcodeSettings: {
    component: BarcodeSettings,
    path: '/BarcodeSettings'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },

  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  CfPwaAdjustment32: {
    component: CfPwaAdjustment32,
    path: '/CfPwaAdjustment32'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  CustomForm2: {
    component: CustomForm2,
    path: '/CustomForm2'
  },
  Library2: {
    component: Library2,
    path: '/Library2'
  },
  Calendar: {
    component: Calendar,
    path: '/Calendar'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

class App extends Component {
  componentDidMount() {
    const config = {
      apiKey: 'AIzaSyC2dnaAmRawirtCpkOp82COaxT0N5r0deY',
      authDomain: 'upmed-331020.firebaseapp.com',
      projectId: 'upmed-331020',
      storageBucket: 'upmed-331020.appspot.com',
      messagingSenderId: '720919278861',
      appId: '1:720919278861:web:87ec1c9ce2c3223ba16c44'
    };

    firebase.initializeApp(config);

    this.generateToken();
  }

  generateToken() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('./firebase-messaging-sw.js')
        .then(async function(registration) {
          // console.log("Registration successful, scope is:", registration.scope);
          const msg = getMessaging();
          const token = await getToken(msg, {
            vapidKey:
              'BBE0fGg76W96ZsK7dZk_xX4e8eAP9JsUNsNyFuxHwGhSBmmALJbtEe-wb4OUEd-N0cjtjK7fBKDbpLCB85elUbg'
          });
          // console.log('FCM token', token);
          localStorage.setItem('FCMtoken', token);
        })
        .catch(function(err) {
          // console.log("Service worker registration failed, error:"  , err );
        });
    }
  }

  render() {
    return (
      <AppLogout>
        <SessionOutModal />
        <View>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </AppLogout>
    );
  }
}

export default App;
