import React, {useState, useEffect, useRef } from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
Image,
TextInput,
ScrollView,
ActivityIndicator,
FlatList
 } from 'react-native';
import DesktopDesign from "./CatalougeDesktopMaterials";
import { getAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';
import { SortData } from '../../../framework/src/Utilities';
import scale, { verticalScale } from "../../../framework/src/Scale";


export default function MaterialCategory({navigation}: {navigation:any}) {
    

      const [error, setError]  = useState(false);
      const [errormsg, seterrorMsg] = useState([])
      const [data, setData]  = useState<any>([])
      const [query, setQuery] = useState('');
      const [searchData, setSearchData] = useState([]);
      const order = JSON.parse(localStorage.getItem('order') || '{}')
  console.log(order);
    
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(false);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)

  const role_id = localStorage.getItem('role') || ''

  useEffect(() => {
    if(window.screen.width < 950) {
        getListOfMaterials();
    }
  }, [offset]);

  const getListOfMaterials = async (search: any = '') => {
        setLoader(true)
        let url = `/bx_block_materials/materials?brand_id=${order.manfacturer_id}&category_id=${order.category_id}&limit=12&offset=0&search=${search}`
    
        // let url = `/bx_block_materials/materials?search=${search}&limit=10&offset=${offset}&brand_id=${order.manfacturer_id}&category_id=${order.category_id}`
        await getAxiosAPI(url).then((res: any) => {
          if(res.data.errors){
            setData([])
            setLoader(false)
            setOffset(0)
              } else {
                setLoader(false)
                if(role_id === '4') {
                  setData(res.data.data.sort((a: any,b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
                    //   let arr: any = []
                    //   res.data.data.map((x: any) => x.attributes.materials.data.map((y: any) => arr.push(y)))
                    //  console.log(arr)
                    //   setData(arr.sort((a: any,b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
                } else {
                      setData(res.data.data.sort((a: any,b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
                }
              }
            })
        
        }

  console.log(data)

  const handleSearch = (text : any) => {
        setQuery(text)
        getListOfMaterials(text)
      };
          
  const ListEmptyView = () => {
      return (
        <View>
          <Text style={styles.heading2}>No Record Found</Text>
          </View>
          )}
          


          const navigateTo = (item: any) => {
            localStorage.setItem('selectedMaterial', JSON.stringify(item));
            navigation.navigate('MaterialDetails')
          }

          
      
  return (
    window.screen.width < 950 ?
            <View style={styles.container}>
        <View style={styles.headingView}>
          <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
            <img src={require("../assets/leftArrow1.png")} style={{
              height: 24, width: 24,
            }} />
          </TouchableOpacity>
    <Text style={styles.heading}> Materials List </Text>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 24}}>
      <View style={styles.searchBox}>
                <TextInput
                          style={styles.input}
                          placeholder="Search "
                          autoCapitalize="none"
                          autoCorrect={false}
                          clearButtonMode="always"
                          placeholderTextColor="#939ca3"
                          value={query}
                          onChangeText={queryText => handleSearch(queryText)}
                  />
                <TouchableOpacity onPress={() => setQuery('')}>
              <img src={query === '' ? require("../assets/search.png" ) : require("../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
              </TouchableOpacity> 
              </View> 
              <View style={styles.filterIcon}>
                <TouchableOpacity>
                  <img src={require("../assets/filtericon.png" )} style={{height: 30, width: 30, paddingRight: 15}}/>
                </TouchableOpacity>
              </View>
              </View>
              {loader ? <ActivityIndicator
                    style={{ margin: 'auto', height: 100, width: 100, }}
                    size="large" color="#4e3e71" />
                    :
                <View style={styles.card}>
                 {
                              (query.length > 0 && data.length > 0) ? 
                              <View style={styles.add}>
                              <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                              </View> 
                                   : 
                                   (query.length > 0 && data.length === 0) ? 
                                   <View style={styles.add}>
                                   <Text style={styles.resultText}>Searched Material not in the catalogue</Text>
                                   </View> : null
                             }
            <ScrollView style={{flex: 1}}>
                              <FlatList 
                              data={data}
                              numColumns={2}
                              ListEmptyComponent={ListEmptyView()}
                              keyExtractor={(item: any, indx: any) => indx}
                              renderItem={({ item }) => (
                                <TouchableOpacity onPress={() => navigateTo(item)}>
                             {/* { 
                              item.attributes.is_active ?  */}

                                <View>
                                    <View style={styles.listm}>
                                    {
                                      item.attributes.image ? <img src={item.attributes.image} style={{height: 124, width: 124, borderRadius: 12}} /> :
                                      <img src={require("../assets/dummyMaterial.png")} style={{height: 124, width: 124, borderRadius: 12}}  />}
                                    <View>
                                    <Text numberOfLines={2} style={styles.name}>{item.attributes.name}</Text>
                                    <View style={styles.priceView}>
                                    <Text style={styles.discount_price}>{item.attributes.discount_price ? "$"+item.attributes.discount_price : "$0.00"}</Text>
                                    <Text style={styles.price}>{item.attributes.price ? "$"+item.attributes.price : "$0.00"}</Text>
                                    </View>
                                    </View>
                                    </View>     
                              </View>
                            {/* : null
                          }           */}
                           </TouchableOpacity>
                             
                              )}
                              />
                                 <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                                                            {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"< Prev"}</Text></TouchableOpacity>}
                                                            {data.length < 10 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"Next >"}</Text></TouchableOpacity>}
                                                      </View>
                              </ScrollView>
                              </View> 
              }
            </View>
      : <DesktopDesign navigation={navigation} />
            )
};
      
const styles = StyleSheet.create({
            container: {
                  flex: 1,
                  backgroundColor: "#fff",
                  flexDirection: 'column',
              
  },
  priceView: {flex: 1, flexDirection: 'row', justifyContent: 'space-between'},
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  price: {
    color: '#b2bac6',
    paddingVertical: 5,
    fontSize: 13,
    textDecorationLine: 'line-through',
    width: scale(40)
},
discount_price: {
    color: '#4e3e71',
    paddingVertical: 5,
    fontSize: 14,
    width: scale(40)
},
  heading2: {
      marginVertical: 100,
      marginHorizontal: 'auto',
      color: '#b2bac6',
      fontSize: 24,
      fontWeight: 'bold',
      flexDirection : 'row',
      justifyContent: 'center'
    },
                discount: {
                  position: 'absolute',
                  top: 110,
                  left: 30,
                  backgroundColor: '#cce9e5',
                  borderRadius: 12,
                  paddingVertical: 2,
                  paddingHorizontal: 4        
                },
                filterIcon: {
                  height: 48,
                  width: 48,
                  borderRadius: 24,
                  padding: 8,
                  borderWidth: 1,
                  borderColor: '#4e3e71',
                  marginVertical: 10,
                  marginHorizontal: 11
                },
                add: {
                  flexDirection: 'row',
                  justifyContent: "center"
                },
                listm: {
                  width: 160,
                  height: 230,
                  borderRadius: 12,
                  padding: 16,
                  borderColor: '#f2f4f5',
                  borderWidth: 1,
                  marginHorizontal: 10,
                  marginVertical: 10
                },
                headingView: {
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 27,
                  backgroundColor: '#4e3e71',
                  marginBottom: 20,
              
                },
                heading:{
                  color: '#e8fafe',
                  fontSize: 20,
                  marginHorizontal: 'auto'       
                },
                resultText: {
                  marginVertical: 10,
                  marginHorizontal: 'auto',
                  fontSize: 18,
                  flexDirection : 'row',
                  justifyContent: 'center'

                },      
                listItem: {
                  flexDirection: 'row',
                  backgroundColor: '#e2f0fe',
                  paddingHorizontal: 10,
                  paddingVertical: 10,
                  borderBottomWidth: 1,
                  borderColor: '#d8d8d8'
                },
                itemImage: {
                  backgroundColor: '#fff',
                  padding: 20,
                  margin: 15    
                },
                button: {
                  borderRadius: 12,
                  paddingVertical: 15,
                  paddingHorizontal: 50,
                  marginHorizontal: 20,
                  backgroundColor: '#4e3e71',
                  marginBottom: 10,
                  marginTop: 10
                },
                buttonText: {
                  color: '#e8fafe',
                  fontSize: 17,
                  textAlign: 'center',
                },
                searchContent: {
                      marginHorizontal: 30,
                  
                },
                card: {
                    flex: 1,
              marginHorizontal:24,
              borderRadius: 6,
              marginVertical: 10,
                },
                image: {
                  padding: 20,
                  borderRadius: 10,
                  backgroundColor: '#fff',
                  marginHorizontal : 20,
                  marginVertical: 5

                },  
                name: {
                  color: '#4e3e71',
                  paddingVertical: 5,
                  fontSize: 14,
                }, 
                text: {
                  fontSize: 20,
                  paddingHorizontal: 30,
                  marginVertical: 20,
                  fontWeight: 'bold'
                },
                text2: {
                  paddingHorizontal: 30,
                  paddingVertical: 20,
                  justifyContent: 'flex-end',
                  flexDirection: 'row'
                },
                material: {
                  flexDirection: 'row',
                  paddingHorizontal: 10,
                  marginTop: 20
                },      
                searchBox: {
                  flexDirection: 'row',
                  // marginHorizontal:26,
                  marginVertical: 10,
                  borderWidth: 1,
                  borderColor: '#8a8a8a',
                  borderRadius: 50
                  
                },
                    input: {
outlineStyle: 'none',
                      flex: 1,
                  paddingLeft: 10,
                  width: 250,
                  height: 48,
                  color: '#4e3e71',
                  fontSize: 16,
                  paddingStart: 20
                    },
})