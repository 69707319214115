import React, {useEffect, useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
Platform } from 'react-native';
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';


export default function Tracking2({navigation}: {navigation: any}) {

      const user = JSON.parse(localStorage.getItem('previous_booking') || '{}')

      const [data, setData] = useState<any>(null)

      const getSingleOrder = async() => {
            let url = `/bx_block_order/orders/${user.id}`
              await getAxiosAPI(url).then((res: any) => {
                    if(res.error){
                      let msg = getErrorMessage(res.data.errors[0])
                      console.log(msg)
                    } else {
                        console.log(res.data.data)
                        setData(res.data.data)
                    }
                  })
            
      }

      useEffect(() => {
           getSingleOrder()
      },[])
console.log(data)
      return (
            <View style={styles.container}>
                  {window.screen.width < 950 ?  <View style={styles.headingView}>
                   <Text style={styles.heading}>Status</Text>
                   </View> : null}
            <View>
                  
                  
                  <View style={styles.status}> 
                  <View style={styles.icon}>
                  {data?.attributes?.confirmed_by_sales_rep ? <img style={{ height: 60, width:60}} src={require("../assets/tick.png")} /> : <img style={{ height: 60, width:60}} src={require("../assets/tickEmpty.png")} />}
                  </View>
                  <View style={styles.info}>
                        <Text style={styles.text1}>Sales Representative</Text>
                                    {data?.attributes?.confirmed_by_sales_rep ? <Text style={styles.text2}>Material is ready</Text> : <Text style={styles.text2}>Material is not ready</Text>}
                  </View>
                  </View>

                  <View style={styles.status}> 
                  <View style={styles.icon}>
                  {data?.attributes.confirmed_by_material_manager ? <img style={{ height: 60, width:60}} src={require("../assets/tick.png")} /> : <img style={{ height: 60, width:60}} src={require("../assets/tickEmpty.png")} /> }
                  </View>
                  <View style={styles.info}>
                        <Text style={styles.text1}>Material Manager</Text>
                                    {data?.attributes.confirmed_by_material_manager ? <Text style={styles.text2}>Your order is confirmed</Text> : <Text style={styles.text2}>Material is not ready</Text>}
                  </View>
                  </View>
                  <View style={styles.status}> 
                  <View style={styles.icon}>
                  {data?.attributes.confirmed_by_bc ? <img style={{ height: 60, width:60}} src={require("../assets/tick.png")} /> : <img style={{ height: 60, width:60}} src={require("../assets/tickEmpty.png")} /> }
                  
                  </View>
                  <View style={styles.info}>
                        <Text style={styles.text1}>Booking Coordinator</Text>
                                    {data?.attributes.confirmed_by_bc ? <Text style={styles.text2}>Booking Date is confirmed</Text> : <Text style={styles.text2}>Date and equipment is not confirmed</Text>}
                  </View>
                  </View>
                  
            </View>
            <View>
                        {window.screen.width < 950 ?  <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Back To Home</Text>
                        </View>
                  </TouchableOpacity> : null}
            </View>
            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff",
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          icon: {

      },
      image_styles: {
            height: 60,
            width: 60,
        
      },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
            
            },
            heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
            },
          text: {
                marginVertical: 20,
                marginHorizontal: 20,
                color: '#4e3e71',
                fontSize: 20,
            //     fontWeight: 'bold'
          },
          status: {
            flexDirection:'row',
            marginHorizontal: 20,
            marginVertical: 20,
          },
          info: {
                flexDirection: 'column',
                justifyContent: 'center',
                paddingHorizontal: 10
          },
          text1: {
            color: '#4e3e71',
            fontSize: 18,
            fontWeight: 'bold',
            paddingBottom: 2
          },
          text2: {
          fontSize: 15,
          
    }  ,
    button: {
      borderRadius: 12,
      paddingVertical: 15,
      paddingHorizontal: 50,
      marginHorizontal: 20,
      backgroundColor: '#4e3e71',
      marginBottom: 10,
      marginTop: 10
    },
    buttonText: {
      color: '#e8fafe',
      fontSize: 17,
      textAlign: 'center',
    },
})