import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../../../framework/src/StorageProvider";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  route:any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  surgeonDetail:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ValidateNewSurgeonDetailController extends BlockComponent<Props, S, SS> {
  apiAcceptSurgeonId:any;
  apiRejectSurgeonId:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      surgeonDetail: JSON.parse(localStorage.getItem('surgeonData')|| '{}')
    };

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiAcceptSurgeonId !== null &&
      this.apiAcceptSurgeonId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiAcceptSurgeonId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.acceptSurgeonSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.acceptSurgeonFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiRejectSurgeonId !== null &&
      this.apiRejectSurgeonId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiRejectSurgeonId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson ) {
        this.rejectSurgeonSuccess();
      } else {
        //Check Error Response
        this.rejectSurgeonFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }


  _handleAcceptSurgeon = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAcceptSurgeonId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_material_manager/material_manager/accept_surgeon?id=${this.state.surgeonDetail.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  acceptSurgeonSuccess = (data: any) => {
    this.props.navigation.navigate("SurgeonConfirmed")
  };

  acceptSurgeonFail = () => {};

  _handleRejectSurgeon = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRejectSurgeonId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_material_manager/material_manager/reject_manager?id=${this.state.surgeonDetail.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  rejectSurgeonSuccess = () => {
    this.showAlert('Sucess','Invitation Rejected')
  };

  rejectSurgeonFail = () => {};
  // Customizable Area End
}
