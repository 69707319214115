import React, {useState, useEffect} from 'react';
import {StyleSheet,
      Text,
      View,
  TouchableOpacity,
  ActivityIndicator,
      TextInput,
      FlatList,
      Alert ,
      ScrollView,
      TouchableWithoutFeedback,
      Platform } from 'react-native';
import axios from "axios"
axios.defaults.headers.token = localStorage.getItem('authToken');
let config = require('../../../framework/src/config')
import scale, { verticalScale } from "../../../framework/src/Scale";
import DesktopAddFacility from "./DesktopAddFacility";
import { asyncLocalStorage } from "../../../framework/src/Utilities"
import CustomPopUp from "../../../framework/src/CustomPopUp"
import { getAxiosAPI, postAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';

export default function AddFacility({navigation}: {navigation: any}) {


  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState([])
  const role_id = localStorage.getItem('role') || ''
  const [hospital_id, setHospital_id] = useState('');
  const [loader, setLoader] = useState(false);
  const [offset, setOffset] = useState(0);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)

  const ClosePopUp = () => {
    setPopup(false)
  }
  const ClosePopUp2 = () => {
    setPopup(false)
    if (role_id === '4') {
      navigation.navigate('SalesSelectManufacture')
    } else {
      navigation.navigate('ContactPriority')
    }
  }

  //console.log(config.baseURL)
  const getHospitals =  async (search: any = '') => {
    setLoader(true)
    let url = `/bx_block_hospitals/hospitals?search=${search}&limit=10&offset=${offset}`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            console.log(res.data);
            setPopup(true)
            setMessage(msg)
            setLoader(false)
          } else {
            setLoader(false)
            setData(res.data.data.sort(function (a: any, b: any) 
            { return (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase())
               ? 1 : ((b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase())
                ? -1 : 0); }))
          }
        })
      }

  const getUserProfile = async () => {
    let url = `/account_block/accounts/account_detail`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            console.log(res.data);
          } else {
            asyncLocalStorage.setItem('activated', res.data.data.attributes.activated);
            asyncLocalStorage.setItem('isPrimary', res.data.data.attributes.is_primary_contact);
          }
            })
  }

  useEffect(() => { 
    getHospitals();
    getUserProfile();
  }, []);

  useEffect(() => { 
    getHospitals();
    // getUserProfile();
  }, [offset]);
  console.log(offset)



  const postUserHospital = async (id: any) => {
    setLoader(true)
   const postData = {
      data: {
        hospital_id: id
      }
    }
    console.log(postData);
    localStorage.setItem("current_hospital_id", id)
    let url = "/bx_block_hospitals/facilities";
    await postAxiosAPI(url, postData).then((res: any) => {
      if(res.error){
        let msg = getErrorMessage(res.data.errors[0])
            console.log(res.data);
            setPopup(true)
            setMessage(msg)
            setLoader(false)
      } else {
        setLoader(false)
        localStorage.removeItem('selectedFacility')
        getHospitals()
        if(role_id === '2'){
          navigation.navigate("ContactPriority")
        }
      }
    });
  }
  
  const assignHospital = async () => {
    if(hospital_id === '') {
      setPopup(true)
      setMessage("Please select a facility ")
      // alert("Please Select a Facility ")
    } else {
      console.log(hospital_id)
      const postData = {
        data: {
          hospital_id: hospital_id
        }
      }
      localStorage.setItem("current_hospital_id", hospital_id)
      let url = "/bx_block_hospitals/facilities";
      await postAxiosAPI(url, postData).then((res: any) => {
        if(res.error){
          let msg = getErrorMessage(res.data.errors[0])
          console.log(res.data);
          setPopup(true)
          setMessage(msg)
          setLoader(false)
        } else {
          setLoader(false)
          navigation.navigate("ContactPriority")
        }
      }); 
     
    }
  }
  

 const goToAddTeam = () => {
  
    const comingFromBookingFlow = localStorage.getItem('comingFromBookingFlow')
    const comingfromProfile = localStorage.getItem('comingfromProfile')
    console.log( role_id)
    if (role_id === '1' && comingFromBookingFlow === 'yes')  {
      if( window.screen.width < 950 ) {
        navigation.navigate('SelectedFacilities')
      } else {
        navigation.navigate("CreateBooking")
      }
    } else if (role_id === '1' && comingfromProfile === 'yes'){
      navigation.navigate('UserFacilities')
    } else if (role_id === '1' ){
      navigation.navigate('AddTeam')
    } else if (role_id === '4'){
      navigation.navigate("SalesSelectManufacture")
    } else {
      assignHospital()
    }
     
  }

  const handleSearch = (text : any) => {
    setQuery(text)
    setChecked(true)
    getHospitals(text)
  };

  const handleChange = (item:any) => {
    let newdata: any = data.map((x: any) => {
      
      if(x.id === item.id ) {
        x.ischecked = !x.ischecked
      } else {
        x.ischecked = false
       // setHospital_id('')
      }
      return x
    })
    setData(newdata)
    setHospital_id(item.id)
    // setHospital_name(item.attributes.name)
    // setSaveHospital(item)
    
  };

  const facilityDetails = (item:any) => {
    console.log(item)
    localStorage.setItem('selectedFacility', JSON.stringify(item))
    navigation.navigate('FacilityName') 
  }

  const handleEdit = (item: any) => {
      localStorage.setItem('selectedFacility', JSON.stringify(item));
     navigation.navigate("AddNewFacility") 
  }


  const handleButton = () => {
    if(role_id === '1' ){
      return (
        <TouchableOpacity onPress={() => goToAddTeam()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      )
    }
    if((role_id === '3') && hospital_id) {
      return (
      <TouchableOpacity onPress={() => assignHospital()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      )
    }
    if(role_id === '2') {
      return (
      <TouchableOpacity onPress={() => navigation.navigate("Dashboard")}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      )
    }
    if(role_id == '4') {
      return (
      <TouchableOpacity onPress={() =>  navigation.navigate("SalesSelectManufacture")}>
      <View style={styles.button}>
        <Text style={styles.buttonText}>Continue</Text>
      </View>
    </TouchableOpacity>
      )
    }
    else {
      return (
        <View style={styles.button0}>
          <Text style={styles.buttonText}>Continue</Text>
        </View>
      )
    }
  }
  const isPrimary = localStorage.getItem('isPrimary')

  const renderEditButton = (item: any) => {
    console.log(isPrimary,role_id)
    if(role_id === '1') {
          return (
            <TouchableOpacity onPress={() => handleEdit(item)} >
    <img src={require("../assets/edit_icon.webp")} style={{ height: 26, paddingBottom: 5,  width: 26, borderRadius: '50%' }} /> 
  </TouchableOpacity>
          )
    } 
    if((role_id === '2'&& isPrimary === "true") || (role_id === '3' && isPrimary === "true" )) {
      return (
        <TouchableOpacity onPress={() => navigation.navigate("AddNewFacility")} >
        <img src={require("../assets/edit_icon.webp")} style={{ height: 26, paddingBottom: 5,  width: 26, borderRadius: '50%' }} /> 
      </TouchableOpacity>
      )
      }
    
    else {
          return null
    }
}


  return (
    window.screen.width < 950 ? 
    <View style={styles.container}>
       {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
    <View style={styles.headingView}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <img src={require("../assets/leftArrow1.png")} style={{ height: 24, width: 24, marginLeft: 24 }} />
        </TouchableOpacity>
        <Text style={styles.heading}>Select Your Facilities</Text>
    { role_id === '1' && <TouchableOpacity onPress={() => navigation.navigate('AddNewFacility')}>
          <img  src={require("../assets/add.png")}  style={{height: 24, width: 24, paddingRight: 24}} />
          </TouchableOpacity>}
          </View>

          <View style={styles.searchBox}>
      <TextInput
                style={styles.input}
                placeholder="Search "
                autoCapitalize="none"
                autoCorrect={false}
                clearButtonMode="always"
                placeholderTextColor="#939ca3"
                value={query}
                onChangeText={queryText => handleSearch(queryText)}
         />
      <TouchableOpacity onPress={() => setQuery('')}>
     <img src={query === '' ? require("../assets/search.png" ) : require("../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
     </TouchableOpacity> 
        </View>
        {loader ? <ActivityIndicator
          style={{ margin: 'auto', height: 100, width: 100, }}
          size="large" color="#4e3e71" />
          :
          <View style={styles.card}> 
            <ScrollView style={{ flex: 1 }}>
              {
                (query.length > 0 && data.length > 0) ?
                  <View style={styles.add}>
                    <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                  </View>
                  :
                  (query.length > 0 && data.length === 0) ?
                    <View style={styles.add}>
                      <Text style={styles.resultText}>Searched facility not found {'\n'}
                      { role_id === '1' && <Text style={{ color: '#4e3e71', fontWeight: 'bold', paddingHorizontal: 50 }} onPress={() => navigation.navigate('AddNewFacility')}>Add Facility </Text>}
                        </Text>
                    </View> : null
              }
                      
              <FlatList
                data={data}
                keyExtractor={(item: any) => item.id}
                renderItem={({ item }) => (
                  <TouchableOpacity onPress={() => facilityDetails(item)}>
                    {role_id === '3' ?
                      <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                        <View style={{ flexDirection: 'row', flex: 0.9 }}>
                          <img src={item.attributes.logo ? item.attributes.logo : require("../assets/hospital copy.png")} style={{ height: 56, width: 56 }} />
                          <View style={{ flexDirection: 'column', marginVertical: 'auto', }}>
                            <Text style={styles.names}>{item.attributes.name}</Text>
                            <Text style={styles.role} numberOfLines={2}>{item.attributes.address}</Text>
                          </View>
                        </View>
                        <TouchableOpacity onPress={() => handleChange(item)} style={{ marginVertical: 'auto', flex: 0.1 }}>
                          <img src={item.ischecked ? require("../assets/radio_active.png") : require("../assets/radio_unactive.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />
                        </TouchableOpacity>
                      </View>
                      :
                      <View style={item.attributes.is_favourite ? styles.listItem_active : styles.listItem}>
                        <View style={{ flexDirection: 'row', flex: 0.9 }}>
                          <img src={item.attributes.logo ? item.attributes.logo : require("../assets/hospital.png")} style={{ height: 56, width: 56 }} />
                          <View style={{ flexDirection: 'column', marginVertical: 'auto', }}>
                            <Text style={styles.names}>{item.attributes.name}</Text>
                            <Text style={styles.role} numberOfLines={2}>{item.attributes.address}</Text>
                          </View>
                        </View>
                        {item.attributes.is_favourite ?
                          <View style={styles.iconView}>
                            {renderEditButton(item)}
                            <img src={require("../assets/tick.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />  
                          </View>
                            :                         
                          <TouchableOpacity onPress={() => postUserHospital(item.id)} style={styles.iconView}>
                            {renderEditButton(item)}
                          <img src={require("../assets/add.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />
                        </TouchableOpacity>}
                      </View>
                    }
                                     
                  </TouchableOpacity>
                             
                )}
              />
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                        {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71'}}>{"< Prev"}</Text></TouchableOpacity>}
                        {data.length < 10 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71'}}>{"Next >"}</Text></TouchableOpacity>}
                      </View>
            </ScrollView>
          </View>
        }
        {handleButton()}
    
      </View>
      :
      <View>
        <DesktopAddFacility navigation={navigation}/>
      </View>
)
}
const styles = StyleSheet.create({
container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: 'column'
  },
  logo: {
    backgroundColor: 'white',
    height: 50,
    width: 50,
    borderRadius: 10,
  },
  iconView: { marginVertical: 'auto', flex: 0.1, justifyContent: 'space-between', flexDirection: 'column' },
  listItem_active: {
    minHeight: 90,
    marginHorizontal:26,
    borderColor: '#4e3e71',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  role: {
    color: '#b2bac6',
    paddingHorizontal: 11,
    width: scale(220),
    
  },
  names: {
    color: '#4e3e71',
    fontSize: 15,
    paddingHorizontal: 11
    
  },
  
  listItem: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#e5e5e5',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  checkboxContainer: {
    paddingRight: 20,
    paddingTop: 100
  },
  checkbox: {
    alignSelf: "center",
  },
  add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18,
    color: '#4e3e71'

  },
  headingView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 24,
    backgroundColor: '#4e3e71',
  },
  heading:{
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'       
  },
  crossIcon: {
    position: 'absolute',
    top: 40,
    right: 10,
    elevation: 10,
},      
searchBox: {
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 20,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50
  
},
input: {
outlineStyle: 'none',
  flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
},
  card: {
    flex: 1,
    marginTop:2,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#fff',
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard : {
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: '#eff8ff',
    marginHorizontal: 30,
    marginVertical: 6,
    position: 'relative',
    justifyContent: 'space-between'
  },
  innerCard: {
    margin:10,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: '#fff',
    paddingHorizontal: 30,
    paddingVertical: 60

  },
  addressText: {
        marginVertical: 20,
        marginLeft: 20
  },
  cardHeading: {
    marginVertical: 20,
    color: '#0579ed',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center'
},    
para: {
    color: '#939ca3'
  },
  paraDis: {   
    color: '#0579ed'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#aa9cc9',
    marginBottom: 10,
    marginTop: 10
},
})
