import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  ImageBackground
} from "react-native";

import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./react-intl-tel-input/index.css";

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { getUserIpAddress } from "../../../framework/src/Utilities";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const activated = localStorage.getItem("activated");
    const userIPAddres = await getUserIpAddress();
    this.setState({ userIp: userIPAddres });
    this.getValidations();
    if (token && activated === "true") {
      this.props.navigation.goBack();
    } else if (token && !activated) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("timer");
    } else {
      this.role_name();
    }
  }

  render() {
    const { navigation } = this.props;
    console.log(this.state.passwordValidation.min_length);
    return window.screen.width < 950 ? (
      <View style={styles.container}>
        <ScrollView>
          {/* Customizable Area Start */}
          <View style={styles.card}>
            <img
              src={require("../assets/illustration-register-copy@3x.png")}
              style={{
                width: 216,
                height: 234,
                position: "absolute",
                top: -165,
                left: 180,
                zIndex: 99,
              }}
            />
            <View style={styles.headline}>
              {this.isPlatformWeb() ? (
                <View>
                  <Text style={styles.signUpText}>Sign Up</Text>
                  <Text style={styles.bottomText}>
                    Sign Up As A {this.state.role_Name}
                  </Text>
                </View>
              ) : null}
            </View>
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                email: "",
                password: "",
                rePassword: "",
                fullphone: "",
              }}
              validationSchema={this.validationSchema}
              onSubmit={(values, actions) => {
                this.setState({ firstName: values.fname });
                this.setState({ lastName: values.lname });
                this.setState({ email: values.email });
                this.setState({ password: values.password });
                this.setState({ reTypePassword: values.rePassword });
                this.setState({ phone: values.fullphone });
                this.createAccount();
                console.log(values);
                // actions.resetForm();
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
              }) => (
                <View>
                  <TextInput
                    testID={"txtInputFirstName"}
                    style={
                      Platform.OS === "web" ? styles.inputWeb : styles.bgInput
                    }
                    placeholder={this.labelFirstName}
                    onBlur={handleBlur("fname")}
                    placeholderTextColor="#b2bac6"
                    onChangeText={handleChange("fname")}
                    value={values.fname}
                  />
                  {touched.fname && errors.fname ? (
                    <Text style={styles.errorMsg}>{errors.fname}</Text>
                  ) : null}

                  <TextInput
                    testID={"txtInputLastName"}
                    style={
                      Platform.OS === "web" ? styles.inputWeb : styles.bgInput
                    }
                    placeholder={this.lastName}
                    onBlur={handleBlur("lname")}
                    placeholderTextColor="#b2bac6"
                    onChangeText={handleChange("lname")}
                    value={values.lname}
                  />
                  {touched.lname && errors.lname ? (
                    <Text style={styles.errorMsg}>{errors.lname}</Text>
                  ) : null}
                  <TextInput
                    testID={"txtInputEmail"}
                    style={
                      Platform.OS === "web" ? styles.inputWeb : styles.bgInput
                    }
                    placeholder={this.labelEmail}
                    onBlur={handleBlur("email")}
                    placeholderTextColor="#b2bac6"
                    onChangeText={handleChange("email")}
                    value={values.email}
                  />
                  {touched.email && errors.email ? (
                    <Text style={styles.errorMsg}>{errors.email}</Text>
                  ) : null}

                  <PhoneInput
                    isValid={(value: any, country: any) => {
                      if (value.match(/12345/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else if (value.match(/1234/)) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    country={"in"}
                    countryCodeEditable={false}
                    buttonClass="flagsdropdown"
                    containerClass="PhoneInput"
                    inputClass="PhoneInputInput"
                    placeholder="Enter phone number"
                    value={values.fullphone}
                    onChange={handleChange("fullphone")}
                    onBlur={handleBlur("fullphone")}
                  />
                  {touched.fullphone && errors.fullphone ? (
                    <Text style={styles.errorMsg}>{errors.fullphone}</Text>
                  ) : null}

                  <TextInput
                    testID={"txtInputPassword"}
                    style={styles.inputWeb}
                    onBlur={handleBlur("password")}
                    placeholder={this.labelPassword}
                    placeholderTextColor="#b2bac6"
                    onChangeText={handleChange("password")}
                    value={values.password}
                    secureTextEntry={true}
                  />

                  {touched.password && errors.password ? (
                    <Text style={styles.errorMsg}>{errors.password}</Text>
                  ) : null}
                  <Text>{this.state.passwordHelperText}</Text>

                  <TextInput
                    testID={"txtInputConfirmPassword"}
                    style={styles.inputWeb}
                    placeholder={this.labelRePassword}
                    onBlur={handleBlur("rePassword")}
                    placeholderTextColor="#b2bac6"
                    onChangeText={handleChange("rePassword")}
                    value={values.rePassword}
                    secureTextEntry={true}
                  />

                  {touched.rePassword && errors.rePassword ? (
                    <Text style={styles.errorMsg}>{errors.rePassword}</Text>
                  ) : null}
                  <TouchableOpacity
                    disabled={this.state.loader}
                    onPress={() => handleSubmit()}
                  >
                    <View
                      style={
                        this.state.loader ? styles._unactivebtn : styles.button
                      }
                    >
                      <Text style={styles.buttonText}>Sign Up </Text>
                      {this.state.loader ? (
                        <ActivityIndicator
                          style={{ position: "absolute" }}
                          size="large"
                          color="#2e2442"
                        />
                      ) : null}
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </Formik>
            <View style={styles.SignUp}>
              <Text style={styles.bottomText}>
                Already have an account ? {"\n"}
              </Text>
              <Text
                style={styles.lowerTextcolor}
                onPress={() => navigation.navigate("EmailAccountLoginBlock")}
              >
                Sign In
              </Text>
            </View>
          </View>
          {/* Customizable Area End */}
        </ScrollView>
      </View>
    ) : (
      <View style={styles.desktopContainer}>
        <ImageBackground source={require('../assets/group.png')} resizeMode="cover" style={styles.image}>
        <View style={styles.upmedlogo}>
          <img
            src={require("../assets/upmed.png")}
            style={{ height: 40, width: 120, paddingBottom: 10 }}
          />
          <View style={styles.desktopCard}>
            <View style={styles.desktopheadline}>
              {this.isPlatformWeb() ? (
                <View>
                  <Text style={styles.signUpText}>Sign Up</Text>
                  <Text style={styles.bottomText}>
                    Sign Up As A {this.state.role_Name}
                  </Text>
                </View>
              ) : null}
            </View>
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                email: "",
                password: "",
                rePassword: "",
                fullphone: "",
              }}
              validationSchema={this.validationSchema}
              onSubmit={(values, actions) => {
                this.setState({ firstName: values.fname });
                this.setState({ lastName: values.lname });
                this.setState({ email: values.email });
                this.setState({ password: values.password });
                this.setState({ reTypePassword: values.rePassword });
                this.setState({ phone: values.fullphone });
                this.createAccount();
                console.log(values);
                // actions.resetForm();
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
              }) => (
                <View>
                  <View style={styles.innerDiv}>
                    <TextInput
                      testID={"txtInputFirstName"}
                      style={
                        Platform.OS === "web"
                          ? styles.desktopinputWeb
                          : styles.bgInput
                      }
                      placeholder={this.labelFirstName}
                      onBlur={handleBlur("fname")}
                      placeholderTextColor="#b2bac6"
                      onChangeText={handleChange("fname")}
                      value={values.fname}
                    />
                    {touched.fname && errors.fname ? (
                      <Text style={styles.errorMsg}>{errors.fname}</Text>
                    ) : null}
                  </View>
                  <View style={styles.innerDiv}>
                    <TextInput
                      testID={"txtInputLastName"}
                      style={
                        Platform.OS === "web"
                          ? styles.desktopinputWeb
                          : styles.bgInput
                      }
                      placeholder={this.lastName}
                      onBlur={handleBlur("lname")}
                      placeholderTextColor="#b2bac6"
                      onChangeText={handleChange("lname")}
                      value={values.lname}
                    />
                    {touched.lname && errors.lname ? (
                      <Text style={styles.errorMsg}>{errors.lname}</Text>
                    ) : null}
                  </View>
                  <View style={styles.innerDiv}>
                    <TextInput
                      testID={"txtInputEmail"}
                      style={
                        Platform.OS === "web"
                          ? styles.desktopinputWeb
                          : styles.bgInput
                      }
                      placeholder={this.labelEmail}
                      onBlur={handleBlur("email")}
                      placeholderTextColor="#b2bac6"
                      onChangeText={handleChange("email")}
                      value={values.email}
                    />
                    {touched.email && errors.email ? (
                      <Text style={styles.errorMsg}>{errors.email}</Text>
                    ) : null}
                  </View>
                  <View style={styles.innerDiv}>
                    <PhoneInput
                      isValid={(value: any, country: any) => {
                        if (value.match(/12345/)) {
                          return (
                            "Invalid value: " + value + ", " + country.name
                          );
                        } else if (value.match(/1234/)) {
                          return false;
                        } else {
                          return true;
                        }
                      }}
                      countryCodeEditable={false}
                      country={"in"}
                      buttonClass="flagsdropdown"
                      containerClass="PhoneInput"
                      inputClass="PhoneInputInput"
                      placeholder="Enter phone number"
                      value={values.fullphone}
                      onChange={handleChange("fullphone")}
                      onBlur={handleBlur("fullphone")}
                    />
                    {touched.fullphone && errors.fullphone ? (
                      <Text style={styles.errorMsg}>{errors.fullphone}</Text>
                    ) : null}
                  </View>
                  <View style={styles.innerDiv}>
                    <TextInput
                      testID={"txtInputPassword"}
                      style={styles.desktopinputWeb}
                      onBlur={handleBlur("password")}
                      placeholder={this.labelPassword}
                      placeholderTextColor="#b2bac6"
                      onChangeText={handleChange("password")}
                      value={values.password}
                      secureTextEntry={true}
                    />

                    {touched.password && errors.password ? (
                      <Text style={styles.errorMsg}>{errors.password}</Text>
                    ) : null}
                  </View>
                  <Text>{this.state.passwordHelperText}</Text>
                  <View style={styles.innerDiv}>
                    <TextInput
                      testID={"txtInputConfirmPassword"}
                      style={styles.desktopinputWeb}
                      placeholder={this.labelRePassword}
                      onBlur={handleBlur("rePassword")}
                      placeholderTextColor="#b2bac6"
                      onChangeText={handleChange("rePassword")}
                      value={values.rePassword}
                      secureTextEntry={true}
                    />

                    {touched.rePassword && errors.rePassword ? (
                      <Text style={styles.errorMsg}>{errors.rePassword}</Text>
                    ) : null}
                  </View>
                  <TouchableOpacity
                    disabled={this.state.loader}
                    onPress={() => handleSubmit()}
                  >
                    <View
                      style={
                        this.state.loader ? styles._unactivebtn : styles.button
                      }
                    >
                      <Text style={styles.buttonText}>Sign Up </Text>
                      {this.state.loader ? (
                        <ActivityIndicator
                          style={{ position: "absolute" }}
                          size="large"
                          color="#2e2442"
                        />
                      ) : null}
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </Formik>
            <View style={styles.desktopSignUp}>
              <Text style={styles.bottomText}>
                Already have an account ? {"\n"}
              </Text>
              <Text
                style={styles.lowerTextcolor}
                onPress={() => navigation.navigate("EmailAccountLoginBlock")}
              >
                Sign In
              </Text>
            </View>
          </View>
          {/* </View> */}
        </View>
        </ImageBackground>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    flexDirection: "column-reverse",
    backgroundColor: "#4e3e71",
  },
  errorMsg: {
    color: "red",
    // position: 'absolute',
    // left: '10px',
    // bottom: '-10px'
  },
  innerDiv: {
    // position:'relative'
  },
  upmedlogo: {
    margin: "auto",
    alignItems: "center",
  },
  lowerTextcolor: {
    fontWeight: "bold",
    marginHorizontal: "auto",
    color: "rgb(75, 103, 134)",
  },
  desktopContainer: {
    flex: 1,
  },
  image: {
    flex: 1,
    height: "100vh",
    justifyContent: "center",
  },
  desktopCard: {
    backgroundColor: "#e8fafe",
    margin: "auto",
    borderRadius: 40,
    paddingVertical: 10,
    paddingHorizontal: 30,
    width: 450,
  },
  SignUp: {
    flexDirection: "column",
    marginHorizontal: "auto",
    paddingBottom: 50,
    paddingTop: 30,
  },
  desktopSignUp: {
    flexDirection: "column",
    marginHorizontal: "auto",
    paddingBottom: 10,
    paddingTop: 10,
  },
  phoneInput: {
    marginVertical: 5,
  },
  desktopcard: {
    marginTop: 20,
    position: "relative",
    borderTopRightRadius: 24,
    backgroundColor: "#e8fafe",
    paddingHorizontal: 24,
  },
  card: {
    marginTop: 200,
    position: "relative",
    borderTopRightRadius: 24,
    backgroundColor: "#e8fafe",
    paddingHorizontal: 24,
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    outlineStyle: "none",
    marginVertical: 10,
    paddingVertical: 15,
    paddingLeft: 10,
    color: "#112950",
    fontSize: 18,
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
  },
  desktopinputWeb: {
    flex: 1,
    outlineStyle: "none",
    marginVertical: 8,
    paddingVertical: 3,
    paddingLeft: 10,
    color: "#112950",
    fontSize: 18,
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 5,
    marginVertical: 5,
    padding: 10,
    includeFontPadding: true,
    backgroundColor: "rgb(239, 247, 255)",
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 50,
  },
  desktopheadline: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 5,
  },
  signUpText: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 5,
    paddingBottom: 10,
  },
  phoneAndCountry: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  _unactivebtn: {
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#8e7bb7",
    marginVertical: 11,
  },
  button: {
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginVertical: 11,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    // textAlign: 'center',
  },
  bottomText: {
    color: "#281c41",
    justifyContent: "center",
    fontSize: 15,
  },
  // Customizable Area End
});
