import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  FlatList,
  ActivityIndicator,
} from "react-native";
import DesktopHeader from "../../dashboard/src/DesktopHeader";
import DesktopMenue from "../../dashboard/src/DesktopMenue";
import axios from "axios";
axios.defaults.headers.token = localStorage.getItem("authToken");
import scale from "../../../framework/src/Scale";
import Dialog from "@material-ui/core/Dialog";
import AddNewFacilities from "./AddNewFacilities";
import ContactPriority from "../../dashboard/src/ContactPriority";
import CustomPopUp from "../../../framework/src/CustomPopUp";
import {
  getAxiosAPI,
  postAxiosAPI,
  getErrorMessage,
} from "../../../framework/src/Blocks/AxiosAPIBlock";
import GlobalCss from "../../../framework/src/GlobalCss";
import { SortData, handleCondition } from "../../../framework/src/Utilities";

export default function DesktopSurgeryDetails({
  navigation,
}: {
  navigation: any;
}) {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const role_id = localStorage.getItem("role");
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState(false);

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);

  const [open, setOpen] = useState(false);

  const [open2, setOpen2] = useState(false);

  const [isPrimary, setIsPrimary] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = (val: boolean) => {
    setOpen2(val);
  };
  const getHospitals = async (search: any = "") => {
    setLoader(true);
    let url = `/bx_block_hospitals/hospitals?search=${search}&limit=12&offset=${offset}`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setPopup(true);
        setMessage(msg);
        setLoader(false);
      } else {
        setLoader(false);
        setData(
          res.data.data.sort((a: any, b: any) =>
            SortData(a.attributes.name, b.attributes.name)
          )
        );
      }
    });
  };
  const getUserDetails = async (search: any = "") => {
    setLoader(true);
    let url = `/account_block/accounts/account_detail`;
    await getAxiosAPI(url).then((res: any) => {
      if (res.error) {
        setLoader(false);
      } else {
        setLoader(false);
        setIsPrimary(res.data.data.attributes.is_primary_contact);
      }
    });
  };

  useEffect(() => {
    getHospitals();
    getUserDetails();
  }, [offset]);

  const handleSearch = (text: any) => {
    setQuery(text);
    getHospitals(text);
  };

  const ClosePopUp = () => {
    setPopup(false);
  };

  const navigateTo = () => {
    const comingFromBookingFlow = localStorage.getItem("comingFromBookingFlow");
    const comingfromProfile = localStorage.getItem("comingfromProfile");
    if (role_id === "1" && comingFromBookingFlow === "yes") {
      navigation.navigate("CreateBooking");
    } else if (role_id === "1" && comingfromProfile === "yes") {
      navigation.navigate("UserFacilities");
    } else if (role_id === "1") {
      navigation.navigate("AddTeam");
    } else {
      navigation.navigate("Dashboard");
    }
  };

  const postUserHospital = async (id: any) => {
    const postData = {
      data: {
        hospital_id: id,
      },
    };
    localStorage.setItem("current_hospital_id", id);
    let url = "/bx_block_hospitals/facilities";
    await postAxiosAPI(url, postData).then((res: any) => {
      if (res.error) {
        let msg = getErrorMessage(res.data.errors[0]);
        setPopup(true);
        setMessage(msg);
        setLoader(false);
      } else {
        setLoader(false);
        if (role_id === "2" || role_id === "3") {
          setOpen2(true);
        }

        getHospitals();
        localStorage.removeItem("selectedFacility");
      }
    });
  };

  const checkStatus = (item: any) => {
    if (item.attributes.is_ambulatory) {
      return "Ambulatory";
    } else if (item.attributes.is_hospital) {
      return "Main O.R";
    } else if (item.attributes.is_or) {
      return "Main O.R";
    } else {
      return "Unknown";
    }
  };

  const handleButton = () => {
    if (role_id == "4") {
      return (
        <TouchableOpacity
          onPress={() => navigation.navigate("SalesSelectManufacture")}
        >
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      );
    } else {
      return (
        <TouchableOpacity onPress={() => navigateTo()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Continue</Text>
          </View>
        </TouchableOpacity>
      );
    }
  };

  const [editStateFacility, setEditStateFacility] = useState({});

  const editFacility = (item: any) => {
    setOpen(true);
    setEditStateFacility(item);
  };

  const renderEditButton = (item: any) => {
    if (role_id === "1") {
      return (
        <TouchableOpacity
          style={styles.buttonView}
          onPress={() => editFacility(item)}
        >
          <Text style={styles.buttonText}>Edit </Text>
        </TouchableOpacity>
      );
    }
    if (
      (role_id === "2" && isPrimary === true) ||
      (role_id === "3" && isPrimary === true)
    ) {
      return (
        <TouchableOpacity
          style={styles.buttonView}
          onPress={() => editFacility(item)}
        >
          <Text style={styles.buttonText}>Edit</Text>
        </TouchableOpacity>
      );
    } else {
      return null;
    }
  };

  const handleMessage = () => {
    if (query.length > 0 && data.length > 0) {
      return (
        <View style={styles.add}>
          <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
        </View>
      );
    } else if (query.length > 0 && data.length === 0) {
      return (
        <View style={styles.add}>
          <Text style={styles.resultText}>
            Searched facility not found, {"\n"}
            {role_id === "1" && (
              <Text
                style={{
                  color: "#4e3e71",
                  fontWeight: "bold",
                  paddingHorizontal: 50,
                }}
                onPress={() => setOpen(true)}
              >
                Add Facility{" "}
              </Text>
            )}
          </Text>
        </View>
      );
    } else {
      return null;
    }
  };

  const renderUpperText = () => {
    if (loader) {
      return (
        <ActivityIndicator
          style={{ margin: "auto", height: 100, width: 100 }}
          size="large"
          color="#4e3e71"
        />
      );
    } else {
      return (
        <View style={styles.card}>
          <View style={{ flex: 1 }}>
            {handleMessage()}
            <FlatList
              style={{ height: 420 }}
              data={data}
              keyExtractor={(item: any) => item.id}
              horizontal={false}
              numColumns={3}
              renderItem={({ item }) => (
                <>
                  <View>
                    {role_id === "2" || role_id === "3" ? (
                      <View
                        style={handleCondition(
                          item.attributes.is_favourite,
                          styles.listItem_active,
                          styles.listItem
                        )}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            borderBottomColor: "#e5e5e5",
                            borderBottomWidth: 1,
                          }}
                        >
                          <img
                            src={handleCondition(
                              item.attributes.logo,
                              item.attributes.logo,
                              require("../assets/hospital.png")
                            )}
                            style={{
                              height: 56,
                              width: 56,
                              paddingLeft: 10,
                              paddingBottom: 10,
                            }}
                          />
                          <View style={styles.nameView}>
                            <Text style={styles.names}>
                              {item.attributes.name}
                            </Text>
                          </View>

                          <View
                            style={{
                              flexDirection: "column",
                              margin: "auto",
                            }}
                          >
                            <Text style={styles.typeOf}>
                              {checkStatus(item)}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.mmbcView}>
                          <Text style={styles.role2}>
                            Booking Co-ordinator:
                          </Text>
                          <Text style={styles.role}>
                            {item.attributes.booking_coordinator
                              ? item.attributes.booking_coordinator.first_name +
                                " " +
                                item.attributes.booking_coordinator.last_name
                              : "-"}{" "}
                          </Text>
                        </View>
                        <View style={styles.mmbcView}>
                          <Text style={styles.role2}>Material Manager:</Text>
                          <Text style={styles.role}>
                            {item.attributes.material_manager
                              ? item.attributes.material_manager.first_name +
                                " " +
                                item.attributes.material_manager.last_name
                              : "-"}
                          </Text>
                        </View>
                        <View style={styles.mmbcView}>
                          <Text style={styles.role2}>Address:</Text>
                          <Text style={styles.role} numberOfLines={2}>
                            {item.attributes.address}
                          </Text>
                        </View>
                        <View style={styles.mmbcView}>
                          <TouchableOpacity
                            onPress={() => postUserHospital(item.id)}
                            disabled={item.attributes.is_favourite}
                            style={styles.buttonView}
                          >
                            <Text style={GlobalCss.buttonText}>
                              {handleCondition(
                                item.attributes.is_favourite,
                                "Added",
                                "Add"
                              )}
                            </Text>
                          </TouchableOpacity>
                          {renderEditButton(item)}
                        </View>
                      </View>
                    ) : (
                      <View
                        style={handleCondition(
                          item.attributes.is_favourite,
                          styles.listItem_active,
                          styles.listItem
                        )}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            borderBottomColor: "#e5e5e5",
                            borderBottomWidth: 1,
                          }}
                        >
                          <img
                            src={handleCondition(
                              item.attributes.logo,
                              item.attributes.logo,
                              require("../assets/hospital.png")
                            )}
                            style={{
                              height: 56,
                              width: 56,
                              paddingLeft: 10,
                              paddingBottom: 10,
                            }}
                          />
                          <View style={styles.nameView}>
                            <Text style={styles.names}>
                              {item.attributes.name}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              margin: "auto",
                            }}
                          >
                            <Text style={styles.typeOf}>
                              {checkStatus(item)}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.mmbcView}>
                          <Text style={styles.role2}>
                            Booking Co-ordinator:
                          </Text>
                          <Text style={styles.role}>
                            {
                              item.attributes.booking_coordinator ?
                              item.attributes.booking_coordinator.first_name +
                                " " +
                                item.attributes.booking_coordinator.last_name :
                              "-"
                            }
                          </Text>
                        </View>
                        <View style={styles.mmbcView}>
                          <Text style={styles.role2}>Material Manager:</Text>
                          <Text style={styles.role}>
                            {
                              item.attributes.material_manager ?
                              item.attributes.material_manager.first_name +
                                " " +
                                item.attributes.material_manager.last_name :
                              "-"
                            }
                          </Text>
                        </View>
                        <View style={styles.mmbcView}>
                          <Text style={styles.role2}>Address:</Text>
                          <Text style={styles.role} numberOfLines={2}>
                            {item.attributes.address}
                          </Text>
                        </View>
                        <View style={styles.mmbcView}>
                          <TouchableOpacity
                            onPress={() => postUserHospital(item.id)}
                            disabled={item.attributes.is_favourite}
                            style={styles.buttonView}
                          >
                            <Text style={GlobalCss.buttonText}>
                              {handleCondition(
                                item.attributes.is_favourite,
                                "Added",
                                "Add"
                              )}
                            </Text>
                          </TouchableOpacity>
                          {renderEditButton(item)}
                        </View>
                      </View>
                    )}
                  </View>
                </>
              )}
            />
          </View>
          <View style={styles.flexEnd}>
            <View style={styles.flexCenter}>
              {handleCondition(
                offset < 1,
                null,
                <TouchableOpacity
                  disabled={offset < 1}
                  onPress={() => setOffset(offset - 10)}
                >
                  <Text style={styles.paginationsText}>{"<Prev"}</Text>
                </TouchableOpacity>
              )}
              {handleCondition(
                data.length < 12,
                null,
                <TouchableOpacity
                  disabled={data.length < 12}
                  onPress={() => setOffset(offset + 10)}
                >
                  <Text style={styles.paginationsText}>{"Next>"}</Text>
                </TouchableOpacity>
              )}
            </View>
            {handleButton()}
          </View>
        </View>
      );
    }
  };

  return (
    <View style={styles.desktopContainer}>
      {popup && (
        <CustomPopUp
          ClosePopUp={ClosePopUp}
          btnText={"Back"}
          message={message}
        />
      )}
      <DesktopHeader heading={"Add Facility"} navigation={navigation} />
      <View style={styles.bottomDesktopCard}>
        <DesktopMenue navigation={navigation} />
        <View style={{ flex: 0.85 }}>
          <View style={styles.outerCard}>
            <Text
              style={{ fontSize: 20, color: "#4e3e71", fontWeight: "bold" }}
            >
              Add Facility{" "}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={styles.searchBox}>
                  <TextInput
                    style={styles.input}
                    placeholder="Search "
                    autoCapitalize="none"
                    autoCorrect={false}
                    clearButtonMode="always"
                    placeholderTextColor="#939ca3"
                    value={query}
                    onChangeText={(queryText) => handleSearch(queryText)}
                  />
                  <TouchableOpacity onPress={() => setQuery("")}>
                    <img
                      src={handleCondition(
                        query,
                        require("../assets/cross.png"),
                        require("../assets/search.png")
                      )}
                      style={{
                        height: 30,
                        width: 30,
                        paddingRight: 15,
                        paddingTop: 10,
                      }}
                    />
                  </TouchableOpacity>
                </View>
                {handleCondition(
                  role_id === "1",
                  <TouchableOpacity onPress={() => setOpen(true)}>
                    <View style={styles.button}>
                      <Text style={styles.buttonText}>Add Facility</Text>
                    </View>
                  </TouchableOpacity>,
                  null
                )}
              </View>
            </View>
            <View>
              <View>{renderUpperText()}</View>
            </View>
          </View>
        </View>
      </View>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <View style={{ width: 550, height: 750 }}>
          <AddNewFacilities
            isForEditProfile={true}
            editStateFacility={editStateFacility}
            handleClose={handleClose}
            navigation={navigation}
          />
        </View>
      </Dialog>

      <Dialog
        open={open2}
        onClose={() => handleClose2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <View style={{ width: 400, height: 350 }}>
          <ContactPriority
            handleClose2={handleClose2}
            id={"id"}
            navigation={navigation}
          />
        </View>
      </Dialog>
    </View>
  );
}

const styles = StyleSheet.create({
  paginationsText: { color: "#4e3e71", fontWeight: "bold" },
  flexEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  flexCenter: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  desktopContainer: {
    flex: 1,
    flexDirection: "column",
  },
  buttonView: {
    backgroundColor: "#4e3e71",
    borderRadius: 6,
    paddingVertical: 5,
    paddingHorizontal: 30,
    marginVertical: 5,
    marginHorizontal: 5,
  },
  mmbcView: {
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 10,
  },
  nameView: { flexDirection: "column" },
  editText: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    backgroundColor: "#4e3e71",
    borderRadius: 6,
    margin: 5,
    fontSize: 14,
    color: "#ffff",
  },
  listItem_active: {
    // height: 88,
    marginHorizontal: 5,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 6,
    marginVertical: 5,
    paddingVertical: 10,
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  listItem: {
    // height: 88,
    marginHorizontal: 5,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    borderRadius: 6,
    marginVertical: 5,
    paddingVertical: 10,
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  typeOf: {
    color: "#fff",
    borderRadius: 6,
    marginHorizontal: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginVertical: 5,
    fontWeight: "200",
    backgroundColor: "#4e3e71",
  },
  button: {
    backgroundColor: "#4e3e71",
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginVertical: 10,
    marginHorizontal: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  topButton: {
    backgroundColor: "#4e3e71",
    fontSize: 16,
    color: "#ffff",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 4,
    height: 35,
    width: 120,
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginVertical: 10,
    backgroundColor: "#aa9cc9",
    // width: '20%',
    marginHorizontal: 10,
  },
  buttonText0: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },

  activeList: {
    maxWidth: 400,
    marginHorizontal: 20,
    borderColor: "#4e3e71",
    borderWidth: 1,
    borderRadius: 4,
    marginVertical: 20,
    paddingVertical: 12,
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  role: {
    color: "#4e3e71",
    paddingVertical: 6,
    marginBottom: 5,
    fontSize: 14,
    width: scale(50),
  },
  role2: {
    color: "#8c8c8c",
    paddingVertical: 6,
    fontSize: 14,
    width: scale(50),
  },
  names: {
    color: "#4e3e71",
    fontSize: 18,
    // marginTop: 15,
    fontWeight: "bold",
    paddingHorizontal: 11,
    width: scale(50),
  },

  add: {
    flexDirection: "row",
    justifyContent: "center",
  },
  resultText: {
    // paddingVertical: 10,
    fontSize: 18,
  },
  card: {
    flex: 1,
    // height: 300,
    marginTop: 10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
  },
  filterIcon: {
    height: 48,
    width: 48,
    borderRadius: 24,
    padding: 8,
    borderWidth: 1,
    borderColor: "#4e3e71",
    marginVertical: "auto",
    marginHorizontal: "auto",
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: "row",
  },
  picker: {
    height: 50,
    width: 160,
    color: "white",
    paddingHorizontal: 15,
    backgroundColor: "#4e3e71",
    borderRadius: 50,
  },
  searchBox: {
    flex: 1,
    flexDirection: "row",
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "#8a8a8a",
    borderRadius: 50,
  },
  input: {
    outlineStyle: "none",
    flex: 1,
    // paddingLeft: 10,
    height: 48,
    color: "#4e3e71",
    fontSize: 16,
    paddingStart: 20,
    // width: 1100
  },
  outerCard: {
    flex: 1,
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#7f7f7f",
    padding: 15,
    margin: 15,
    flexDirection: "column",
  },
});
