import React from "react";
// @ts-ignore
import Quagga from 'quagga'

import BarcodesScannerController, {
  Props,
} from "./BarcodesScannerController";

export default class BarcodesScanner extends BarcodesScannerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 async componentDidMount() {
   this.initQugga()
  }

  initQugga = () => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: 300,
            height: 300,
            facingMode: 'environment', // or user
          },
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers: 4,
        decoder: {
          readers: ['code_128_reader'],
        },
        locate: true,
      },
      function(err : any) {
        if (err) {
          return console.log(err+" error 32")
        }
        Quagga.start()
      },
    )
    Quagga.onDetected(this._onDetected)
  }

 async componentWillUnmount() {
    Quagga.offDetected(this._onDetected)
  }

  _onDetected = (result : any) => {
    this.props.onDetected(result)
  }

  render() {
    return (
      // Customizable Area Start
      <div id="interactive" className="viewport" />
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
