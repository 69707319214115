import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  TextInput,
  ScrollView,
  Picker,
  ActivityIndicator
} from "react-native";
import NewMaterialController from "./NewMaterialController";
import Dropzone from "react-dropzone";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import DesktopNewMaterial from "./DesktopNewMaterial";
import { getErrorMessage, postAxiosAPI, putAxiosAPI } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import GlobalCss from "../../../../../framework/src/GlobalCss";
import CustomPopUp from "../../../../../framework/src/CustomPopUp";

const validation_Schema = yup.object({
  manufacturerName: yup.string().required("Manufacturer name is required"),
  materialCategory: yup.string().required("Material category is required"),
  materialName: yup.string().required("Material name is required"),
  // division: yup.string().required("Division is required"),
  // catlogNo: yup.string().required("Catlog no is required"),
  // barcode: yup.string().required("Barcode is required"),
  gtinNo: yup
    .string()
    .length(14, "Gtin no should be 14 characters")
    .required("Gtin no is required")
});
export default class NewMaterial extends NewMaterialController {

  _onHandleDrop = (acceptedFiles: any, rejectedFiles: any, event: any) => {
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr: any = reader.result;
      this.setState({ imagePath: binaryStr });
      // console.log(binaryStr)
    };
    console.log(acceptedFiles[0])
    this.setState({ imageData: acceptedFiles[0] });

    reader.readAsDataURL(acceptedFiles[0]);
  };

  EditMaterial = JSON.parse(localStorage.getItem('editMaterialData') || '{}')
  role_id = localStorage.getItem('role') || ''

  addMaterial = async (values: any, actions: any) => {
    this.setState({loader: true})
    // let active = true
   const {materialCategory,imageData , manufacturerName  } = this.state
    console.log(values, {materialCategory,imageData , manufacturerName })
     var postData = new FormData();
     if(imageData) {
      var postData = new FormData();
      postData.append("material[name]", values.materialName);
      postData.append("material[division]", values.division);
      postData.append("material[catalog_no]", values.catlogNo);
      postData.append("material[barcode]", values.barcode);
      postData.append("material[gtin_no]", values.gtinNo);
      postData.append("category_id", values.materialCategory);
      postData.append("material[image]", imageData ? imageData : null  , imageData.name ? imageData.name : null );
      postData.append("brand_id", values.manufacturerName);
      postData.append("material[description]", values.description);
      postData.append("material[price]", values.price);
      postData.append("material[discount_price]", values.discount_price);
      postData.append("material[is_active]", JSON.stringify(true));
} else {
      var postData = new FormData();
      postData.append("material[name]", values.materialName);
      postData.append("material[division]", values.division);
      postData.append("material[catalog_no]", values.catlogNo);
      postData.append("material[barcode]", values.barcode);
      postData.append("material[gtin_no]", values.gtinNo);
      postData.append("category_id", values.materialCategory);
      // postData.append("material[image]", imageData ? imageData : null  , imageData.name ? imageData.name : null );
      postData.append("brand_id", values.manufacturerName);
      postData.append("material[description]", values.description);
      postData.append("material[price]", values.price);
      postData.append("material[discount_price]", values.discount_price);
      postData.append("material[is_active]", JSON.stringify(true));
}
if(Object.keys(this.EditMaterial).length && this.role_id === '4') {
  let url = `/bx_block_materials/materials/${this.EditMaterial.id}`;
  await putAxiosAPI(url, postData).then((res: any) => {
        if(res.error){
        let msg = getErrorMessage(res.data.errors[0])
        console.log(res.data);
        this.showAlert("Error", msg)
        this.setState({loader: false})
        } else {
        console.log(res.data);
        localStorage.setItem("selectedMaterial", JSON.stringify(res.data.data))
        this.setState({loader: false, popup:true})
        setTimeout(() => {
          this.props.navigation.goBack()
        },2000)
      }

  }); 
} else {
    let url = "/bx_block_materials/materials";
    await postAxiosAPI(url, postData).then((res: any) => {
      if(res.error){
        let msg = getErrorMessage(res.data.errors[0])
        console.log(res.data);
        this.showAlert("Error", msg)
        this.setState({loader: false})
      } else {
        actions.resetForm()
        this.materialAddedSuccessfully()
        this.setState({loader: false})
      }
    }); 
  }
  }

  onImageChange = (event :any) => {
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr: any = reader.result;
      this.setState({ imagePath: binaryStr });
      // console.log(binaryStr)
    };
    console.log(event.target.files[0])
    this.setState({ imageData: event.target.files[0] });

    reader.readAsDataURL(event.target.files[0]);
}

  render() {
    return (
      window.screen.width < 950 ? 
      <ScrollView>
        {this.state.popup && <CustomPopUp ClosePopUp={() => this.setState({popup: false})} btnText={"Back"} message={"Material updated successfully "}/>}
        <View style={styles.container}>
          <View style={styles.headerWrapper}>
            <TouchableOpacity
              onPress={() => this.props.navigation.goBack()}
              style={styles.leftArrowIconWrapper}
            >
              <Image
                source={require("../../../assets/leftArrow.png")}
                style={styles.leftArrowIcon}
              />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>{this.EditMaterial?.attributes?.name ? "Edit" : "Add"} Material</Text>
          </View>
          <View style={styles.subWrapper}>
          
            <Dropzone onDrop={this._onHandleDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <View style={styles.imageContainer}>
                  {this.state.imagePath === "" ? (
                    <div {...getRootProps()}>
                      <View style={styles.uploadIconWrapper}>
                        <Image
                          source={require("../../../assets/upload.png")}
                          style={styles.uploadIcon}
                        />
                      </View>
                      <Text style={styles.dragAndDropText}>
                        Drag and Drop or Browse to upload
                      </Text>
                      <input {...getInputProps()} />{" "}
                    </div>
                  ) : (
                    <Image
                      source={{ uri: this.state.imagePath }}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "center"
                      }}
                    />
                  )}
                </View>
              )}
            </Dropzone>

            <input id="file" type="file" accept="image/*" style={{display:'none'}} onChange={this.onImageChange}/>
            <label style={{height:50}} htmlFor="file">
            {
            this.state.imagePath && 
            <TouchableOpacity style={GlobalCss.buttonView}>
                  <Text style={GlobalCss.buttonText}>Change image</Text>
            </TouchableOpacity>
            } 
            </label>
            <Formik
              initialValues={{
                manufacturerName: this.EditMaterial?.attributes?.brand.data.id ? this.EditMaterial?.attributes?.brand.data.id : "",
                materialCategory: this.EditMaterial?.attributes?.category.data.id ? this.EditMaterial?.attributes?.category.data.id : "",
                materialName: this.EditMaterial?.attributes?.name ? this.EditMaterial?.attributes?.name : "",
                division:  this.EditMaterial?.attributes?.division ? this.EditMaterial?.attributes?.division : "",
                description: this.EditMaterial?.attributes?.description ? this.EditMaterial?.attributes?.description :  "",
                catlogNo:  this.EditMaterial?.attributes?.catalog_no ? this.EditMaterial?.attributes?.catalog_no : "",
                barcode:  this.EditMaterial?.attributes?.barcode ? this.EditMaterial?.attributes?.barcode : "",
                gtinNo:  this.EditMaterial?.attributes?.gtin_no ? this.EditMaterial?.attributes?.gtin_no : "",
                price: this.EditMaterial?.attributes?.price ? this.EditMaterial?.attributes?.price : "",
                discount_price:  this.EditMaterial?.attributes?.discount_price ? this.EditMaterial?.attributes?.discount_price : ""
              }}
              validationSchema= {validation_Schema}
              onSubmit={(values, actions) => {
                console.log(values)
                this.setState({ manufacturerName: values.manufacturerName });
                this.setState({ materialCategory: values.materialCategory });
                this.setState({ materialName: values.materialName });
                this.setState({ division: values.division });
                this.setState({ gtinNo: values.gtinNo });
                this.setState({ barcode: values.barcode });
                this.setState({ catlogNo: values.catlogNo });
                this.setState({ price: values.price });
                this.setState({ discount_price: values.discount_price });
                this.setState({ description: values.description });
                this.addMaterial(values, actions);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
                setFieldValue
              }) => (
                <>
                  <View style={styles.textInputStyle}>
                    <Picker
                      selectedValue={values.manufacturerName}
                      style={
                        values.manufacturerName === "" || Object.keys(this.EditMaterial).length
                          ? styles.disablepicker
                          : styles.enablePicker
                      }
                      onValueChange={(e: any) => {
                        setFieldValue("manufacturerName", e);
                        this.getMaterailCategories(e);
                      }}
                      enabled={this.state.brandLoader ? false : Object.keys(this.EditMaterial).length ? false : true}
                    >
                      <Picker.Item value="" label={this.state.brandLoader ? "Loading..." : "Select Manufacturer Name"} />
                      {this.state.manufacturerList &&
                        this.state.manufacturerList.length > 0 &&
                        this.state.manufacturerList.map((item: any) => {
                          return (
                            <Picker.Item
                              label={item.attributes.name}
                              value={item.id}
                              key={item.id}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                  <Text style={{ color: "red" }}>
                    {touched.manufacturerName && errors.manufacturerName
                      ? errors.manufacturerName
                      : ""}
                  </Text>
                  <View style={styles.textInputStyle}>
                   {!Object.keys(this.EditMaterial).length ? <Picker
                      selectedValue={values.materialCategory}
                      style={[
                        values.materialCategory === ""
                          ? styles.disablepicker
                          : styles.enablePicker
                      ]}
                      onValueChange={handleChange("materialCategory")}
                      enabled={this.state.categoryLoader ? false : Object.keys(this.EditMaterial).length ? false : true}
                    >
                      <Picker.Item value="" label={this.state.categoryLoader ? "Loading... " : "Select Material Category"} />
                      {this.state.materailCategorieList &&
                        this.state.materailCategorieList.length > 0 &&
                        this.state.materailCategorieList.map((item: any) => {
                          return (
                            <Picker.Item
                              label={item.attributes.name}
                              value={item.id}
                              key={item.id}
                            />
                          );
                        })}
                    </Picker> :
                    <Picker
                      selectedValue={values.materialCategory}
                      style={styles.disablepicker}
                      onValueChange={handleChange("materialCategory")}
                      enabled={false}
                    >
                      <Picker.Item value="" label={this.EditMaterial?.attributes?.category?.data?.attributes?.name} />
                    </Picker>}
                  </View>
                  <Text style={{ color: "red" }}>
                    {touched.materialCategory && errors.materialCategory
                      ? errors.materialCategory
                      : ""}
                  </Text>
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Material Name"
                    placeholderTextColor="#b2bac6"
                    value={values.materialName}
                    onBlur={handleBlur("materialName")}
                    onChangeText={handleChange("materialName")}
                  />
                  <Text style={{ color: "red" }}>
                    {touched.materialName && errors.materialName
                      ? errors.materialName
                      : ""}
                  </Text>
                  
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Division"
                    placeholderTextColor="#b2bac6"
                    value={values.division}
                    onBlur={handleBlur("division")}
                    onChangeText={handleChange("division")}
                    editable={Object.keys(this.EditMaterial).length && this.role_id === '4' ? false : true}
                  />
                  <Text style={{ color: "red" }}>
                    {touched.division && errors.division ? errors.division : ""}
                  </Text>
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Catlog No"
                    placeholderTextColor="#b2bac6"
                    value={values.catlogNo}
                    onBlur={handleBlur("catlogNo")}
                    onChangeText={handleChange("catlogNo")}
                  />
                  <Text style={{ color: "red" }}>
                    {touched.catlogNo && errors.catlogNo ? errors.catlogNo : ""}
                  </Text>
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Barcode"
                    placeholderTextColor="#b2bac6"
                    value={values.barcode}
                    onBlur={handleBlur("barcode")}
                    onChangeText={handleChange("barcode")}
                    editable={Object.keys(this.EditMaterial).length && this.role_id === '4' ? false : true}
                  />
                  <Text style={{ color: "red" }}>
                    {touched.barcode && errors.barcode ? errors.barcode : ""}
                  </Text>
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Gtin No"
                    placeholderTextColor="#b2bac6"
                    value={values.gtinNo}
                    onBlur={handleBlur("gtinNo")}
                    onChangeText={handleChange("gtinNo")}
                  />
                  <Text style={{ color: "red" }}>
                    {touched.gtinNo && errors.gtinNo ? errors.gtinNo : ""}
                  </Text>
                  <TextInput
                    style={styles.textInputStyle}
                    keyboardType="numeric"
                    maxLength={10}
                    placeholder="Price"
                    placeholderTextColor="#b2bac6"
                    value={values.price}
                    onBlur={handleBlur("price")}
                    onChangeText={handleChange("price")}
                  />
                
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Discounted Price"
                    placeholderTextColor="#b2bac6"
                    keyboardType="numeric"
                    maxLength={10}
                    value={values.discount_price}
                    onBlur={handleBlur("discount_price")}
                    onChangeText={handleChange("discount_price")}
                  />
                  <TextInput
                    style={styles.textInputStyle}
                    placeholder="Description"
                    multiline={true}
                    // maxLength={5}
                    numberOfLines={3}
                    placeholderTextColor="#b2bac6"
                    value={values.description}
                    onBlur={handleBlur("description")}
                    onChangeText={handleChange("description")}
                  />
                 
                  <TouchableOpacity
                    style={this.state.loader ? GlobalCss.buttonViewDisable: GlobalCss.buttonView}
                    onPress={() => 
                    // this.setState({loader: true})
                      handleSubmit()
                    }
                    disabled={this.state.loader}
                  >
                    <Text style={GlobalCss.buttonText}>
                      Save & Finish 
                    </Text>
                    {this.state.loader &&  <ActivityIndicator style={{position: 'absolute', right: 150, top: 7}} size="large" color="#2e2442" /> }
                  </TouchableOpacity>
                </>
              )}
            </Formik>
          </View>
        </View>
        </ScrollView>
        : 
        <DesktopNewMaterial navigation={this.props.navigation}/>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  subWrapper: {
    paddingTop: 20,
    width: "100%",
    paddingHorizontal: 24
  },
  textInputStyle: {
    outlineStyle: 'none',
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderColor: "#e5e5e5",
    borderBottomWidth: 1,
    // fontSize: 15,
    marginTop: 15
  },
  acceptManagerButton: {
    marginHorizontal: 24,
    height: 54,
    backgroundColor: "#4e3e71",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  disablepicker: {
    width: "100%",
    borderColor: "#fff",
    color: "#b2bac6"
  },
  enablePicker: {
    width: "100%",
    borderColor: "#fff",
    color: "#000"
  },
  acceptManagerText: { fontSize: 17, color: "#e8fafe" },
  imageContainer: {
    height: 126,
    borderWidth: 2,
    borderColor: "rgba(143, 146, 161, 0.2)",
    width: "100%",
    borderRadius: 16,
    marginBottom: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(143, 146, 161, 0.05)",
    borderStyle: "dashed"
  },
  uploadIconWrapper: {
    borderRadius: 16,
    width: 48,
    height: 48,
    backgroundColor: "rgba(143,146,161,0.5)",
    opacity: 0.5,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    marginHorizontal: "auto"
  },
  uploadIcon: {
    height: 24,
    width: 24,
    alignItems: "center",
    justifyContent: "center"
  },
  dragAndDropText: { fontSize: 14, color: "#4e3e71" }
});
