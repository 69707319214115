import React from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';
import SearchIcon from '@material-ui/icons/Search';
import TodayIcon from '@material-ui/icons/Today';



export default function BookingList({navigation}: {navigation: any}) {
      return (
            <View style={styles.container}>
             <View style={styles.searchBox}>
              {/* <SearchIcon style={{paddingLeft: '10px'}} size={24} color="disabled" /> */}
                   <TextInput
                        style={styles.input}
                        placeholder="Search"
                        autoCapitalize="none"
                        autoCorrect={false}
                        clearButtonMode="always"
                        placeholderTextColor="#939ca3"
                 />
                 </View> 

                 <View style={styles.dates}>
                  <TouchableOpacity>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>4/12</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>5/12</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>6/12</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>7/12</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}><TodayIcon style={{fontSize: 30 }} color="primary" /></Text>
                        </View>
                  </TouchableOpacity>
                  </View>

                  <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 30, marginHorizontal: 50}}>
                        <TouchableOpacity>
                        <View style={styles.button3}>
                        <Text style={styles.buttonText3}>Confirmed</Text>
                        </View>
                        </TouchableOpacity>
                        <TouchableOpacity>
                        <View style={styles.button2}>
                        <Text style={styles.buttonText2}>Pending</Text>
                        </View>
                        </TouchableOpacity>
                  </View>

                  <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails3')}>
                  <View style={styles.outerCard}>
                        <View style={styles.innerCard}>
                        <Text>Image</Text>
                        </View>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View>
                              <Text style={styles.cardHeading}>Aakash</Text>
                              <Text style={styles.paraDis}>Hospital</Text>
                        </View>
                        <Text style={styles.para}>Confirmed</Text>
                        </View>
                             
                        </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails3')}>
                  <View style={styles.outerCard}>
                        <View style={styles.innerCard}>
                        <Text>Image</Text>
                        </View>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View>
                              <Text style={styles.cardHeading}>Aakash</Text>
                              <Text style={styles.paraDis}>Hospital</Text>
                        </View>
                        <Text style={styles.para}>Confirmed</Text>
                        </View>
                             
                        </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails3')}>
                  <View style={styles.outerCard}>
                        <View style={styles.innerCard}>
                        <Text>Image</Text>
                        </View>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View>
                              <Text style={styles.cardHeading}>Aakash</Text>
                              <Text style={styles.paraDis}>Hospital</Text>
                        </View>
                        <Text style={styles.para}>Confirmed</Text>
                        </View>
                             
                        </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate('SurgeryDetails3')}>
                  <View style={styles.outerCard}>
                        <View style={styles.innerCard}>
                        <Text>Image</Text>
                        </View>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View>
                              <Text style={styles.cardHeading}>Aakash</Text>
                              <Text style={styles.paraDis}>Hospital</Text>
                        </View>
                        <Text style={styles.para}>Confirmed</Text>
                        </View>
                             
                        </View>
                        </TouchableOpacity>

            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#eff8ff",
            flexDirection: 'column'
          },
          dates:{
            flexDirection: 'row'
          }, 
          outerCard : {
            flexDirection: 'row',
            marginTop:20,
            borderRadius: 15,
            elevation: 3,
            backgroundColor: '#fff',
            marginHorizontal: 20,
            marginVertical: 6,
          },
          innerCard: {
            margin:10,
            borderRadius: 15,
            elevation: 3,
            backgroundColor: "#eff8ff",
            padding: 25,

          },
          paraDis: {   
            color: '#0778df'
          },
          para: {
            marginVertical: 35,    
            color: '#0778df',
            marginLeft: 100,
          },
          cardHeading: {
            paddingTop: 15,
            color: '#0778df',
            fontSize: 20,
            fontWeight: 'bold',
      },     
          searchBox: {
            marginVertical: 50,
            marginLeft: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 2,
            borderRadius: 50,
            borderColor: '#939ca3',
            margin: 10,
            height: 50,
            marginHorizontal: 30
      },
      searchBox2: {
            marginHorizontal: 20,
            borderRadius: 50,
            borderWidth: 2,
      },
          searchIcon: {
            paddingLeft: 10
          }, 
          input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 50
          },
          button: {
            borderRadius: 5,
            paddingVertical: 5,
            paddingHorizontal: 10,
            marginHorizontal: 15,
            backgroundColor: 'transparent',
          },
          buttonText: {
            color: 'black',
            fontSize: 15,
            textAlign: 'center',
          },
          button2: {
            borderRadius: 5,
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginHorizontal: 5,
            backgroundColor: 'white',
          },
          buttonText2: {
            color: '#0778df',
            fontSize: 15,
            textAlign: 'center',
          },
          button3: {
            borderRadius: 5,
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginHorizontal: 5,
            backgroundColor: '#0778df',
          },
          buttonText3: {
            color: '#fff',
            fontSize: 15,
            textAlign: 'center',
          },
})