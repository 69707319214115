import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  TextInput
} from "react-native";
import ValidateManagerDetailController from './validateManagerDetailController'
export default class ValidateManagerDetail extends ValidateManagerDetailController {
  render() {
    const data = this.state.managerDetail && this.state.managerDetail.attributes

    return (
      <View style={styles.container}>
        <View style={styles.headerWrapper}>
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.leftArrowIconWrapper}
          >
            <Image
              source={require("../../../assets/leftArrow.png")}
              style={styles.leftArrowIcon}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Manager Information</Text>
        </View>
        <View style={styles.subWrapper}>
          <View style={styles.itemWrapper}>
            <View style={styles.itemTopWrapper}>
              <Image source={{uri:data.profile_image}} style={styles.itemImage} />
              <View style={{ marginLeft: 14 }}>
    <Text style={styles.doctorName}>{data.first_name} {data.last_name}</Text>
                <Text style={styles.hospitalName}>{data.hospitals && data.hospitals.length>0&& data.hospitals[0].name}</Text>
              </View>
            </View>
            <View style={styles.itemBottomWrapper}>
              <Text style={styles.infoTitle}>1) Phone Number: </Text>
              <Text style={styles.infoText}>{data.phone_number}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.infoTitle}>2) Email: </Text>
              <Text style={styles.infoText}>{data.email}</Text>
            </View>
          </View>
        </View>
        <View style={styles.bottomWrapper}>
          <TouchableOpacity
            style={styles.acceptManagerButton}
            onPress={() => this._handleAcceptManager()}
          >
            <Text style={styles.acceptManagerText}>Accept Manager</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.rejectManagerButton} onPress={()=>this._handleRejectManager()}>
            <Text style={styles.rejectManagerText}>Reject Manager</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24,
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  subWrapper: {
    marginHorizontal: 24
  },
  doctorList: {
    color: "#4e3e71",
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 30
  },
  itemWrapper: {
    paddingLeft: 24,
    height: 190,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#d0d0d0",
    marginTop: 16
  },
  itemImage: {
    height: 70,
    width: 70,
    borderRadius: 3,
    backgroundColor: "#d0d0d0"
  },
  doctorName: { color: "#4e3e71", fontSize: 17 },
  hospitalName: { color: "#8e9aab", fontSize: 14 },
  itemTopWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16
  },
  itemBottomWrapper: { flexDirection: "row", marginVertical: 15 },
  infoTitle: { color: "#7f7f7f", fontSize: 16 },
  infoText: { color: "#4e3e71", fontSize: 16 },
  bottomWrapper: { position: "absolute", bottom: 20, width: "100%" },
  acceptManagerButton: {
    marginHorizontal: 24,
    height: 54,
    backgroundColor: "#4e3e71",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  rejectManagerButton: {
    marginHorizontal: 24,
    height: 54,
    backgroundColor: "#ffffff",
    borderRadius: 12,
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#e3e3e3",
    alignItems: "center",
    marginTop: 16
  },
  rejectManagerText: { fontSize: 17, color: "#4e3e71" },
  acceptManagerText: { fontSize: 17, color: "#e8fafe" }
});
