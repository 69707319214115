import React ,{useState, useEffect } from 'react';
import {StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Picker,
Platform } from 'react-native';
import OtpInput from 'react-otp-input';
import { Formik} from "formik";
import * as yup from "yup";
import { getAxiosAPI, getErrorMessage, postAxiosAPI } from '../../../framework/src/Blocks/AxiosAPIBlock';



const validation_Schema = yup.object({
      otp: yup.string().required("Opt is required").matches(/^[0-9]+$/, "Only numbers are allowed").min(4, "must be atleast 4 digits")
     
    })

export default function ConfirmByOtp({ navigation, parent }: { navigation: any, parent: any}) {

      const otpData = JSON.parse(localStorage.getItem('EditingProfileOtpData') || '{}')
     
      const [Token, setToken] = useState('');
     
      const [hidecounter, setHideCounter] = useState(false) ;
      const [counter, setCounter] = useState<any>(localStorage.getItem("timer") ? localStorage.getItem("timer") : 60);

      const isTimer = localStorage.getItem('isTimer')
      //console.log(isTimer)
   

      setTimeout(function(){
            if( !isTimer && counter >= 0) {
                  setCounter(counter-1)
                  localStorage.setItem("timer", counter)
            } else{
                  let time: any = true
                  localStorage.removeItem('timer')
                  localStorage.setItem("isTimer", time)
            }
          
      },1000)

      const renderer = ({ minutes, seconds, completed }: {minutes: any, seconds:any, completed: any}) => {
      
            if (completed) {
                  setHideCounter(true)
              // Render a completed state
             return  null
            } else {
              // Render a countdown
              return <span style={{color: "#4e3e71", fontWeight: 'bold'}}>{minutes < 10 ? "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds : seconds}</span>;
            }
          };

        const getProfile = async () => {
          let url = `/account_block/accounts/account_detail`
          await getAxiosAPI(url).then((res: any) => {
                if(res.error){
                  let msg = getErrorMessage(res.data.errors[0])
                  console.log(msg)
                } else {
                  console.log(res.data.data);
                   // parent(response.data);   
                }
              })
        }

          const resendCode = async () => {
            localStorage.removeItem("isTimer")
            setCounter(60)
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let value = otpData.sendedAt
            if (value.match(regexEmail)) {
                  console.log("email")
                  let postData1 = {
                        email: value
                  }
                  let url = "/account_block/otp_resends";
                  await postAxiosAPI(url, postData1).then((res: any) => {
                    if(res.error){
                      let msg = getErrorMessage(res.data.errors)
                      console.log(res.data);
                    } else {
                      setToken(res.data.email_otp.meta.token)
                    }
                  }); 
            } else {
                  let postData1 = {
                        full_phone_number: value
                  }
                  console.log("Phone")
                  let url = "/account_block/otp_resends";
                  await postAxiosAPI(url, postData1).then((res: any) => {
                    if(res.error){
                      let msg = getErrorMessage(res.data.errors)
                      console.log(res.data);
                    } else {
                      setToken(res.data.sms_otp.meta.token)
                    }
                  }); 
            }
          }

          
          const submitData = async (otp: any) => {
                const postData = {
                      data: {
                            token: Token === '' ? otpData.token : Token,
                            otp_code: parseInt(otp)
                      }
                }
                console.log(postData)
                let url = `/account_block/otp_confirmations`
                await postAxiosAPI(url, postData).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors)
                    console.log(msg);
                    localStorage.removeItem("isTimer")
                  } else {
                    console.log(res.data);
                    localStorage.removeItem("isTimer")
                localStorage.setItem('authToken', res.data.messages[0].otp.token)
                if (window.screen.width < 950) {
                  navigation.navigate('Profile')
                } else {
                  getProfile()
                }
                  }
                }); 
          }
      return (
            <Formik
            initialValues={{ otp: ''}}
            validationSchema={validation_Schema}
              onSubmit={(values, actions) => {
                console.log(values);
                submitData(values.otp)
      
              }}
            >
              {
                ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                  <View style={window.screen.width < 950 ? styles.container : styles.desktopContainer}>
                  <View style={window.screen.width < 950 ? styles.card : styles.cardDesktop}>
                      {window.screen.width < 950 ?   <img src={require("../assets/illustration-register-copy@3x.png")} style={{
                    width: 216,
                    height: 234,
                    position: 'absolute',
                    top: -165,
                    left: 180,
                    zIndex: 99
                    }}/> : null }
                  <View style={styles.bottomvalues}>
                        <Text style={styles.heading1}>Verify Code</Text>
                        <Text style={styles.lableText}>Check your SMS inbox, we have sent you the code at {otpData.sendedAt}</Text>
                  </View>
                
                        <OtpInput
                        numInputs={4}
                        //value={this.state.otp}
                        inputStyle={{margin: 10, height: 50, width: 50, borderRadius: 5, fontSize: 20, backgroundColor: '#e8fafe'}}
                        // containerStyle={{borderRadius: 5, height: 20, width: 20}}
                        //onChange={(otp: string) => this.setState({otp: otp})}
                        //onBlur={handleBlur('otp')}
                        onChange={handleChange('otp')}
                        value={values.otp}
                        />

                  <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
                        <View>
                  <Text style={{color: 'rgb(72, 100, 132)', paddingTop: 20, fontSize: 18 }}>Did not receive the code?</Text>
                      { counter > 0 && !isTimer? 
                        <View>
                      <Text style={styles.resendCode_unActive}>Resend Code</Text>   
                      </View> :
                      <TouchableOpacity onPress={() => resendCode()} disabled={false}>
                      <Text style={styles.resendCode}>Resend Code</Text>   
                      </TouchableOpacity>
                      }
                      </View>
                      <View>
                      { counter > 0 && !isTimer ?
                            <Text style={{ fontSize: 16, color: '#112950', paddingTop: 22}}>{counter < 10 ? "00:0"+counter : "00:"+counter}</Text>
                            : null
                            }
                      </View>
                            
                          
                      </View>
                  <TouchableOpacity  onPress={() => handleSubmit()}>
                  <View style={styles.button}>
                    <Text style={styles.buttonText}>Continue </Text>
                  </View>
                </TouchableOpacity>
                </View>
                </View>
                      )}
                      </Formik>
      )

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column-reverse',
    backgroundColor: "#4e3e71",
  },
  desktopContainer: {
    flex: 1,
    backgroundColor: "#ffff",
  },
  card: {
    position: 'relative',
    borderTopRightRadius: 24,
    backgroundColor: '#e8fafe',
    paddingHorizontal: 24
  },
  cardDesktop: {
    backgroundColor: 'white',
    margin: 20,

  },
  resendCode:{
    color: "#4e3e71",
    paddingVertical:5,
    fontWeight: 'bold',
  },
  resendCode_unActive:{
    color: "#767676",
    paddingVertical:5,
    fontWeight: 'bold',
  },
      bottomvalues: {
            paddingBottom: 20
      },
      lableText:{
        color: '#4e3e71',
        justifyContent: 'center',
        fontSize: 18,
      },
      heading: {
            marginVertical: 5,
            marginHorizontal: 'auto',
            color: '#0579ed',
            fontSize: 30,
            fontWeight: 'bold',
            flexDirection : 'row',
            justifyContent: 'center'
          },
          heading1: {
            fontSize: 32,
            textAlign: "left",
            color: "#4e3e71",
            paddingTop: 96,
            paddingBottom: 20
          },
          OtpInputView: {
            marginVertical: 10
           },
           button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            backgroundColor: '#4e3e71',
            marginBottom: 38,
            marginTop: 150
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
})