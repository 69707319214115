import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    FlatList
} from "react-native";

import VerifyNotificationController, {
    Props,
} from "./VerifyNotificationController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../../../dashboard/src/assets';

//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";

class VerifyNotification extends VerifyNotificationController {
    constructor(props: Props) {
        super(props)
    }
    

    renderHaderContainer = () => {
        return (
            <View style={styles.renderHaderContainer}>
                <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
                <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                </TouchableOpacity>
                <Text style={styles.renderHaderTextContainer}>Notification</Text>
                <Image source={IMG_CONST.setting} style={styles.settingImg} />
            </View>
        )
    }

    renderProfileContainer = () => {
        return (
            <View style={styles.renderProfileContainer}>
                <View style={styles.renderProfileSubContainer}>
                    <Image source={IMG_CONST.personImg} style={styles.personImg} />
                    <View style={styles.renderProfileSubSubContainer}>
                        <Text style={styles.renderProfileSubSubTextContainer}>Doctor Name:</Text>
                        <Text style={styles.renderProfileSubSubTextContainer}>Hospital Name:</Text>
                    </View>
                </View>
                <Text style={styles.BookingText}>Booking information</Text>
                <Text style={styles.newText}>Material Manger added New Material to catlog</Text>
                {this.renderButtonContainer()}
            </View>
        )
    }

    renderButtonContainer = () => {
        return (
            <TouchableOpacity style={styles.renderButtonContainer}>
                <Text style={styles.renderButtonTextContainer}>
                    Verify Detalis
                </Text>
            </TouchableOpacity>
        )
    }

    render() {
        return (
            <View style={styles.container}>
                {this.renderHaderContainer()}
                {this.renderProfileContainer()}
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,

    },

    renderHaderContainer: {
        height: scale(72),
        backgroundColor: "#4e3e71",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        paddingLeft: scale(24),
        paddingRight: scale(24)
    },

    leftArrowImg: {
        width: scale(24),
        height: scale(24)
    },

    renderHaderTextContainer: {
        color: "#e8fafe",
        fontSize: scale(20),
    },

    settingImg: {
        width: scale(24),
        height: scale(24)
    },

    renderProfileContainer: {
        width: scale(360),
        alignSelf: "center",
        marginTop: verticalScale(51),
        borderRadius: scale(12),
        borderWidth: scale(1),
        borderColor: "#8e9aab",
        paddingLeft: scale(16),
        paddingRight: scale(11),
        paddingBottom:verticalScale(20)
    },

    personImg: {
        width: scale(70),
        height: scale(70),
        resizeMode: 'contain'
    },

    renderProfileSubContainer: {
        flexDirection: 'row',
        marginTop: verticalScale(16)
    },

    renderProfileSubSubContainer: {
        marginLeft: scale(18),
        marginTop: verticalScale(15)
    },

    renderProfileSubSubTextContainer: {
        color: '#7f7f7f',
        lineHeight: scale(24),
        fontSize: scale(16)
    },

    BookingText: {
        color: '#4e3e71',
        fontSize: scale(17),
        lineHeight: scale(22),
        fontWeight: 'bold',
        marginTop: verticalScale(7)
    },

    newText: {
        color: "#8e9aab",
        fontSize: scale(13.9),
        lineHeight: scale(24),
        marginTop: verticalScale(12)
    },

    renderButtonContainer: {
        width: scale(307),
        height: scale(54),
        backgroundColor: '#4e3e71',
        borderRadius: scale(12),
        alignSelf: 'center',
        marginTop: verticalScale(25),
        justifyContent: 'center',
        alignItems: 'center'
    },

    renderButtonTextContainer: {
        color: "#e8fafe",
        lineHeight: scale(22),
        fontSize: scale(17)
    }
})


export default VerifyNotification;