import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export interface Props {
  // navigation: any;
  id: string;
  onDetected: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BarcodesScannerController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
   

    // Customizable Area Start
    // Customizable Area End

  }
 

  // async receive(from: string, message: Message) {

  //   runEngine.debugLog("Message Recived", message);

  //   if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
  //     let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

  //     this.showAlert(
  //       "Change Value",
  //       "From: " + this.state.txtSavedValue + " To: " + value + message.id
  //     );

  //     this.setState({ txtSavedValue: value });
  //   }

  //   // Customizable Area Start
  //   // Customizable Area End

  // }

  






  // Customizable Area Start
  // Customizable Area End
  
}
