import React,{ useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';

export default function OrderConfirmed({navigation}: {navigation: any}) {
      const navigateTo = () => {     
      const removeKey = [
      'order',
      'selectedMaterialList',
      'requestedMaterials',
      'selectedMaterial',
      'selectedFacility',
      'selectedCode',
      'selectedCodeList',
      'selectedBrand',
      'selectedCategory',
      'isCptCodeFlow'
      ]
      removeKey.forEach((k:any) =>{
            localStorage.removeItem(k)
      })
      navigation.navigate('Dashboard')
      }
      return (
            <View style={styles.container}>
                  {
                        window.screen.width < 950 ? 
                              <View style={styles.headingView}>
                                    <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                          <img src={require("../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24,
                                          }} />
                                    </TouchableOpacity>
                                    <Text style={styles.heading}>Booking Confirmed</Text>
                              </View> : null
                  }
                  
                  
                  <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                        <Text style={styles.text}>Your booking has been completed</Text>
                  </View>
                  <TouchableOpacity onPress={() => navigateTo()}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Back To Home</Text>
                        </View>
                  </TouchableOpacity>
            </View>
      )
};

const styles = StyleSheet.create({
      container: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: "#ffff"

      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
        
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          text: {
            paddingVertical: 50,
            paddingHorizontal: 50,
            fontSize: 24,
            color: '#4e3e71'
          },
          button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
})