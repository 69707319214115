import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import CfPwaAdjustment2Controller, {
  Props,
  configJSON
} from "./CfPwaAdjustment2Controller";

export default class CfPwaAdjustment2 extends CfPwaAdjustment2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View style={styles.container}>
                  <Text style={styles.heading}> Add Facilities</Text>
                  <View style={styles.firstRow}>
                  <View style={styles.searchBox}>
                  {/* <Feather style={styles.searchIcon} name="search" size={24} color="#939ca3" /> */}
                  <TextInput 
                       style={styles.input}
                        placeholder="Search"
                        placeholderTextColor="#939ca3"
                 />
                 </View>
                 <Text style={styles.topText}>Search On Map</Text>
                  </View>
                  <View style={styles.card}>
                        <View style={styles.outerCard}>
                              <View style={styles.innerCard}><Text> </Text> </View>
                              <Text style={styles.addressText}>
                              <Text style={styles.cardHeading}>Apollo Hospital {'\n'}</Text>
                              <Text style={styles.paraDis}>4260 Benson Park Drive {'\n'} YOUNG AMERICA {'\n'} LO - Minnesota</Text>
                              </Text>
                        </View>
                        <View style={styles.outerCard}>
                              <View style={styles.innerCard}><Text> </Text> </View>
                              <Text style={styles.addressText}>
                              <Text style={styles.cardHeading}>CHL Hospital {'\n'}</Text>
                              <Text style={styles.paraDis}>4260 Benson Park Drive {'\n'} YOUNG AMERICA {'\n'} LO - Minnesota</Text>
                              </Text>
                        </View>
                        <View style={styles.outerCard}>
                              <View style={styles.innerCard}><Text> </Text> </View>
                              <Text style={styles.addressText}>
                              <Text style={styles.cardHeading}>Apollo Hospital {'\n'}</Text>
                              <Text style={styles.paraDis}>4260 Benson Park Drive {'\n'} YOUNG AMERICA {'\n'} LO - Minnesota</Text>
                              </Text>
                        </View>
                        
                  </View>
                  <TouchableOpacity >
      <View style={styles.button}>
        <Text style={styles.buttonText}>Continue </Text>
      </View>
    </TouchableOpacity>
            </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 1,
    backgroundColor: "#eff8ff",
  },
  searchBox: {
    marginLeft: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 50,
    borderColor: '#939ca3',
    margin: 10,
    height: 50
},
  searchIcon: {
    paddingLeft: 10
  }, 
  input: {
outlineStyle: 'none',
    flex: 1,
    paddingLeft: 10,
  }, 
  firstRow: {
    flexDirection: 'row',
  },
  heading: {
    color: '#0579ed',
    fontSize: 30,
    fontWeight: 'bold',
    justifyContent: 'center'
},
cardHeading: {
    marginTop: 30,
    color: '#0579ed',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center'
},    
para: {
    color: '#939ca3'
  },
  paraDis: {
    paddingVertical: 20,    
    color: '#0579ed'
  },

  topText: {
    color: '#0579ed',
    margin: 10,
  },
  card: {
    marginTop:20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#fff',
    marginHorizontal: 4,
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard : {
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#eff8ff',
    marginHorizontal: 20,
    marginVertical: 6,
  },
  innerCard: {
    margin:10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#fff',
    padding: 50,

  },
  addressText: {
        marginVertical: 20,
        marginLeft: 20
  },
  button: {
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 50,
    marginHorizontal:10,
    marginVertical: 50,
    backgroundColor: '#0579ed',
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
  },
});
// Customizable Area End
