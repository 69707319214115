import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    FlatList,
    TextInput
} from "react-native";

import HistoryListController, {
    Props,
} from "./HistoryListController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../assets'

//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";
import { fontSize } from "@mui/system";


class HistoryList extends HistoryListController {

    renderHaderContainer = () => {
        return (
            <View style={styles.renderHaderContainer}>
                <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
                <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                </TouchableOpacity>
                <Text style={styles.renderHaderTextContainer}>History</Text>
            </View>
        )
    }

    renderCellContainer = (item: any) => {
        return (
            <View style={styles.renderCellContainer}>
                <View style={styles.renderCellSubContainer}>
                    <Image source={item.image} style={styles.personImg} />
                    <View style={styles.renderCellTextContainer}>
                        <Text style={styles.itemNameText}>
                            {item.name}
                        </Text>
                        <Text style={styles.itemtextText}>
                            {item.text}
                        </Text>
                    </View>
                </View>
                  <View style={styles.dateContainer}>
                      <Text style={styles.dateText}>Surgery Date & time:</Text>
                      <Text style={styles.itemDateText}>{item.date}</Text>
                  </View>
                <View style={styles.lineView} />
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.confirmedButton}>
                        <Text style={styles.comfirmedText}>Confirmed</Text>
                    </TouchableOpacity>
                    <TouchableOpacity>
                        <Text style={styles.viewDetailsText}>View Details </Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    renderListContainre = () => {
        return (
            <View style={styles.renderListContainre}>
                <FlatList
                    data={this.state.data}
                    renderItem={({ item }) => this.renderCellContainer(item)}
                />
            </View>
        )
    }

    render() {
        return (
            <View style={styles.container}>
                {this.renderHaderContainer()}
                {this.renderListContainre()}
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,

    },

    renderHaderContainer: {
        height: scale(72),
        backgroundColor: '#4e3e71',
        flexDirection: 'row',
        alignItems: 'center'
    },

    leftArrowImg: {
        marginLeft: scale(24),
        width: scale(24),
        height: scale(24),
    },

    renderHaderTextContainer: {
        color: "#e8fafe",
        marginLeft: scale(120),
        fontSize: scale(20),
    },

    renderListContainre: {
        marginTop: verticalScale(10),
        paddingLeft: scale(24)
    },

    renderCellContainer: {
        width: scale(360),
        paddingTop: verticalScale(16),
        // paddingLeft: scale(16),
        paddingRight: scale(16),
        paddingBottom: scale(18),
        borderRadius: scale(12),
        borderColor: "#d0d0d0",
        borderWidth: scale(1),
        marginTop: verticalScale(24)
    },

    personImg: {
        width: scale(70),
        height: scale(70),
        borderRadius: scale(12),
        resizeMode: "contain"
    },

    renderCellSubContainer: {
        flexDirection: 'row',
        paddingLeft:scale(16)
    },

    renderCellTextContainer: {
        marginLeft: scale(14),
        marginTop: verticalScale(10)
    },

    itemNameText: {
        color:'#4e3e71',
        fontSize:scale(17),
        lineHeight:scale(22)
    },

    itemtextText: {
        color:'#8e9aab',
        fontSize:scale(14),
        lineHeight:scale(24)
    },

    lineView: {
        width:scale(358),
        marginTop:verticalScale(15),
        height:scale(1),
        backgroundColor:'#d9d9d9'
    },

    dateContainer: {
        flexDirection:'row',
        justifyContent:'space-between',
        marginTop:verticalScale(11),
        paddingLeft:scale(16)
    },

    dateText: {
        color:"#b2bac6",
        lineHeight:scale(18),
        fontSize:scale(12)
    },

    itemDateText: {
        color:"#5e6f88",
        fontSize:scale(12),
        lineHeight:scale(18)
    },

    confirmedButton: {
        width:scale(70),
        height:scale(20),
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#4e3e71',
        borderRadius:scale(12)
    },

    buttonContainer : {
        flexDirection:'row',
        justifyContent:'space-between',
        paddingLeft:scale(16),
        marginTop:verticalScale(11),
        paddingRight:scale(16)
    },

    comfirmedText: {
        color:"#ffffff",
        lineHeight:scale(18),
        fontSize:scale(12)
    },


    viewDetailsText: {
        fontSize:scale(12),
        lineHeight:scale(18),
        color:'#4e3e71'
    }
})

export default HistoryList;