import React, { useState } from "react";
import { StyleSheet, Text, View,ActivityIndicator,  TouchableOpacity, Image } from "react-native";
import { Overlay } from "react-native-elements";
import GlobalCss from "../../../framework/src/GlobalCss";
import ContactPriorityController from "./ContactPriorityController";
import CustomPopUp from "../../../framework/src/CustomPopUp"
import Dialog from '@material-ui/core/Dialog';
import Scale from "../../../framework/src/Scale";
export default class Contact extends ContactPriorityController {
  render() {
    return (
      <View style={styles.container}>
        {this.state.isDisplayModal && (
          <View style={styles.modalWrapper}>
            <View style={styles.modalSubWapper}>
              <Text style={styles.modalText}>
                {this.state.userData && this.state.userData.first_name}
                {this.state.userData && this.state.userData.last_name}(
                {this.state.userData && this.state.userData.email}) is currently
                the primary manager of your facility
              </Text>
              <TouchableOpacity
                onPress={() => this.setState({ isDisplayModal: false })}
                style={styles.button}
              >
                <Text style={styles.buttonText}>Back</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
       {window.screen.width < 950 && <View style={styles.headerWrapper}>
          <TouchableOpacity
            onPress={() => this.props.navigation.goBack()}
            style={styles.leftArrowIconWrapper}
          >
            <Image
              source={require("../assets/leftArrow.png")}
              style={styles.leftArrowIcon}
            />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Contact Priority</Text>
        </View>}
        <Text style={styles.heading}>Select your contact in facility</Text>
        <TouchableOpacity
          disabled={this.state.loader}
          onPress={() => this._handleConntactPriority("primary")}
          style={this.state.loader ? GlobalCss.buttonViewDisable : GlobalCss.buttonView}
        >
          <Text style={GlobalCss.buttonText}>Primary</Text>
          {this.state.loader && this.state.loaderFor.a  ? <ActivityIndicator style={styles.loader} size="large" color="#2e2442" /> : null }
        </TouchableOpacity>
        <View style={styles.text}>
          <Text style={styles.orText}>Or</Text>
        </View>
        <TouchableOpacity
          disabled={this.state.loader}
          onPress={() => this._handleConntactPriority("secondary")}
          style={this.state.loader ? GlobalCss.buttonViewDisable : GlobalCss.buttonView}
        >
          <Text style={GlobalCss.buttonText}>Secondary</Text>
          {this.state.loader && this.state.loaderFor.b ? <ActivityIndicator style={styles.loader} size="large" color="#2e2442" /> : null }
        </TouchableOpacity>
        <Dialog
            
            open={this.state.modalOpen}
            onClose={this.ClosePopUp}
            style={{borderRadius: 15}}
      >
            <View style={styles.box}>
                  <Text numberOfLines={3} style={styles.message}>{this.state.modalOpenMsg} </Text>
                  <TouchableOpacity onPress={this.ClosePopUp}>
                  <View style={GlobalCss.buttonView}>
                    <Text style={GlobalCss.buttonText}>Back</Text>
                  </View>
                </TouchableOpacity>
            </View>

      </Dialog>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column"
  },
  box: { width: 320, height: 200, flexDirection: 'column', justifyContent: 'space-between', marginVertical: 10 },
  message:{
    flex: 1,
    width: Scale(280),
    color: '#4e3e71',
    fontSize: 20,
    fontWeight: 'bold',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {position: 'absolute', left: 160, bottom:7},
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  heading: {
    color: "#4e3e71",
    fontWeight: "bold",
    marginVertical: 30,
    marginHorizontal: 30,
    fontSize: 20
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
    padding: 15
  },
  button: {
    borderRadius: 8,
    paddingVertical: 20,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginHorizontal: 30
  },
  button2: {
    borderRadius: 8,
    paddingVertical: 20,
    paddingHorizontal: 10,
    backgroundColor: "#4e3e71",
    marginHorizontal: 30
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 20,
    textAlign: "center"
  },
  orText: { fontSize: 24, color: "#4e3e71", textAlign: "center" },
  modalWrapper: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.48)",
    position: "absolute",
    zIndex: 99,
    justifyContent: "center",
    alignItems: "center"
  },
  modalSubWapper: {
    backgroundColor: "#fff",
    width: "90%",
    height: 218,
    justifyContent: "space-evenly"
  },
  modalText: {
    color: "#4e3e71",
    fontSize: 18,
    textAlign: "center",
    paddingHorizontal: 25
  }
});
