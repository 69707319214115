// Customizable Area Start
import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
} from "react-native";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import PatientManerialListController from "./PatientManerialListController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BarcodeScanner from "./BarcodeScanner";
import GlobalCss from "../../../../../framework/src/GlobalCss";
class patientMaterialList extends PatientManerialListController {
  _onDetected = (result: any) => {
    alert(result);
    this.setState({ result: result });
  };

  handleScan2 = (data: any) => {
    let scanStr;
    const scanCode: any = [];
    this.state.materialList.map((item: any) => {
      scanStr = item.attributes.materials.data.attributes.barcode;
      if (scanStr === this.state.result) {
        this.setState({ data: "A" });
      } else {
        this.setState({ data: "NA" });
      }
      scanCode.push(scanStr);
    });
    if (data) {
      this.setState({
        result: data,
      });
    }
  };
  handleError2 = (err: any) => {
    console.error(err);
  };

  renderHaderConatiner = () => {
    console.log("====", this.state.materialList);
    return (
      <View style={styles.headerWrapper}>
        <TouchableOpacity
          onPress={() => this.props.navigation.goBack()}
          style={styles.leftArrowIconWrapper}
        >
          <Image
            source={require("../../../assets/leftArrow.png")}
            style={styles.leftArrowIcon}
          />
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Patient Material List</Text>
      </View>
    );
  };

  renderProfileDetails = () => {
    const user = this.state.data;
    return (
      <View>
        <Text style={styles.renderDetailsSubTextContainer}>
          {user &&
            user.attributes &&
            user.attributes.hospital &&
            user.attributes.hospital.data &&
            user.attributes.hospital.data.attributes &&
            user.attributes.hospital.data.attributes.name}
        </Text>
        <View style={styles.lineView} />
        <View style={styles.renderProfileContainer}>
          <Image
            source={
              user.attributes.patient_image
                ? user.attributes.patient_image
                : user.attributes.patient_gender === "Male"
                ? require("../../../assets/patient_male.png")
                : require("../../../assets/patient_female.png")
            }
            style={styles.personImg}
          />
          <View style={styles.personDetailsTextContainer}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.personDetailsText}>Patient Name:</Text>
              <Text numberOfLines={2} style={styles.personalDetialName}>
                {user.attributes.first_name + " " + user.attributes.last_name}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.personDetailsSecondText}>Doctor Name: </Text>
              <Text numberOfLines={2} style={styles.personDetailsSecondname}>
                {user &&
                  user.attributes &&
                  user.attributes.doctor &&
                  user.attributes.doctor.data &&
                  user.attributes.doctor.data.attributes &&
                  user.attributes.doctor.data.attributes.first_name}{" "}
                {user &&
                  user.attributes &&
                  user.attributes.doctor &&
                  user.attributes.doctor.data &&
                  user.attributes.doctor.data.attributes &&
                  user.attributes.doctor.data.attributes.last_name}{" "}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  renderSurgerycontainer = () => {
    const user = this.state.data;
    return (
      <View>
        <View style={{ flexDirection: "row", marginTop: verticalScale(25) }}>
          <Text style={styles.titleText}>Surgery Materials</Text>
          <TouchableOpacity
            onPress={() => this.setState({ openScanner: true })}
          >
            <Text style={styles.scanAll}>Scan All </Text>
          </TouchableOpacity>
        </View>
        {this.state.materialList &&
          this.state.materialList.length > 0 &&
          this.state.materialList.map((x: any, y: any) => {
            return (
              <View>
                <TouchableOpacity
                  style={styles.renderDetailsSubContainer}
                  onPress={() => {
                    this.state.collaspeData[y] = !this.state.collaspeData[y];
                    this.setState({ collaspeData: this.state.collaspeData });
                  }}
                >
                  <View style={styles.renderDetailesRed}>
                    <Text numberOfLines={2} style={styles.renderDetailsSubText}>
                      {y + 1}) {x.attributes.materials.data.attributes.name}
                    </Text>
                    <TouchableOpacity
                      onPress={() => this.chats(x.attributes.material_id)}
                    >
                      <Image
                        source={require("../../../assets/messageIcon.png")}
                        style={styles.chatImg}
                      />
                    </TouchableOpacity>
                    <View
                      style={{
                        backgroundColor:
                          x.attributes.status === "A"
                            ? "#288600"
                            : x.attributes.status === "TBH"
                            ? "blue"
                            : "red",
                        height: 12,
                        width: 12,
                        borderRadius: 6,
                        marginVertical: "auto",
                        marginHorizontal: 10,
                      }}
                    />
                  </View>
                  {this.state.collaspeData[y] ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </TouchableOpacity>
                {this.renderbuttomContainer(y, x)}
              </View>
            );
          })}
      </View>
    );
  };

  renderQRreder = () => {
    const previewStyle = {
      height: 240,
      width: 280,
      borderWidth: 0.5,
    };
    return (
      <div style={{ alignSelf: "center", marginTop: 20 }}>
        <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
          <Image
            source={require("../../../assets/cross.png")}
            style={{
              width: scale(30),
              height: scale(30),
              resizeMode: "contain",
            }}
          />
        </TouchableOpacity>

        <span>Barcode Scanner</span>
        <View style={{ width: 300, height: 300 }}>
          <BarcodeScanner onDetected={this._onDetected} />
        </View>

        <Text>Result :- {this.state.result}</Text>
      </div>
    );
  };

  renderbuttomContainer = (index: any, data: any) => {
    const status = data.attributes.status;
    return (
      <Collapse in={this.state.collaspeData[index]}>
        <View style={styles.renderbuttomContainer}>
          <RadioGroup
            defaultValue=""
            aria-label="gender"
            name="customized-radios"
            row
          >
            <FormControlLabel
              value="A"
              control={
                <Checkbox
                  style={{ color: "#000" }}
                  checked={status === "A" ? true : false}
                  onChange={(event) => this._handleCheckBox(event, index)}
                />
              }
              label="A"
              style={{ fontSize: 16, color: "#7f7f7f" }}
            />
            <FormControlLabel
              value="TBH"
              control={
                <Checkbox
                  style={{ color: "#000" }}
                  checked={status === "TBH" ? true : false}
                  onChange={(event) => this._handleCheckBox(event, index)}
                />
              }
              label="TBH"
              style={{ fontSize: 16, color: "#7f7f7f" }}
            />
            <FormControlLabel
              value="NA"
              control={
                <Checkbox
                  style={{ color: "#000" }}
                  checked={status === "NA" || status === null ? true : false}
                  onChange={(event) => this._handleCheckBox(event, index)}
                />
              }
              label="NA"
              style={{ fontSize: 16, color: "#7f7f7f" }}
            />
          </RadioGroup>
        </View>
      </Collapse>
    );
  };

  renderButtonContainer = () => {
    return (
      <TouchableOpacity
        style={styles.renderButtonContainer}
        onPress={() => this._handleConfirmMaterial()}
      >
        <Text style={styles.renderButtonTextContainer}>Confirm Material</Text>
      </TouchableOpacity>
    );
  };

  renderDetailsContainer = () => {
    return (
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <Text style={styles.renderDetailsTextContainer}>Surgery Details</Text>
        <View style={styles.renderDetailsContainer}>
          {this.renderProfileDetails()}
          {this.renderSurgerycontainer()}
        </View>
      </View>
    );
  };

  render() {
    if (this.state.openScanner) {
      return (
        <ScrollView style={styles.container}>{this.renderQRreder()}</ScrollView>
      );
    } else {
      return (
        <>
        <Text style={GlobalCss.watermarkTextMobile}>CONFIDENTIAL</Text> 
        <View style={styles.container}>
          {this.renderHaderConatiner()}
          {this.renderDetailsContainer()}
          {this.renderButtonContainer()}
          {/* {} */}
        </View>
        </>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  renderHaderConatiner: {
    height: scale(72),
    backgroundColor: "#4e3e71",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: scale(24),
  },

  leftArrowImg: {
    width: scale(24),
    height: scale(24),
  },

  renderHaderTextConatiner: {
    fontSize: scale(20),
    color: "#e8fafe",
    marginLeft: scale(55),
  },

  renderDetailsTextContainer: {
    marginTop: verticalScale(28),
    marginLeft: scale(23),
    fontSize: scale(20),
    color: "#4e3e71",
    fontWeight: "bold",
  },

  renderDetailsContainer: {
    width: "90%",
    height: "aoto",
    borderRadius: scale(12),
    borderColor: "#d0d0d0",
    marginTop: 25,
    borderWidth: scale(1),
    paddingVertical: 20,
    paddingHorizontal: 15,
  },

  renderDetailsSubTextContainer: {
    color: "#4e3e71",
    fontWeight: "bold",
    fontSize: scale(18),
  },

  lineView: {
    width: "100%",
    height: scale(1),
    backgroundColor: "#979797",
    alignSelf: "center",
    marginTop: verticalScale(11),
  },

  renderProfileContainer: {
    flexDirection: "row",
  },

  personImg: {
    width: scale(70),
    height: scale(70),
    marginTop: verticalScale(24),
  },

  personDetailsTextContainer: {
    marginLeft: scale(13),
    marginTop: verticalScale(30),
  },

  personDetailsText: {
    fontSize: scale(16),
    color: "#7f7f7f",
    lineHeight: scale(24),
  },
  personalDetialName: {
    fontSize: scale(16),
    color: "#4e3e71",
    lineHeight: scale(24),
    width: scale(150),
  },

  personDetailsSecondText: {
    fontSize: scale(16),
    color: "#7f7f7f",
    lineHeight: scale(24),
    marginTop: verticalScale(6),
  },
  personDetailsSecondname: {
    fontSize: scale(16),
    color: "#4e3e71",
    lineHeight: scale(24),
    marginTop: verticalScale(6),
    width: scale(150),
  },

  titleText: {
    color: "#4e3e71",
    fontSize: scale(18),
  },

  scanAll: {
    color: "#4e3e71",
    fontSize: scale(18),
    marginLeft: scale(100),
  },

  renderDetailsSubContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: scale(27),
    marginTop: verticalScale(15),
  },

  chatImg: {
    width: scale(15),
    height: scale(15),
    marginLeft: scale(10),
    marginTop: verticalScale(5),
    resizeMode: "contain",
  },

  renderDetailsSubText: {
    color: "#7f7f7f",
    fontSize: scale(16),
    lineHeight: scale(24),
    width: scale(200),
  },

  greenRound: {
    width: scale(12),
    height: scale(12),
    borderRadius: scale(100),
    marginLeft: scale(16),
    backgroundColor: "#288600",
    marginTop: verticalScale(6),
  },

  redRound: {
    width: scale(12),
    height: scale(12),
    borderRadius: scale(100),
    marginLeft: scale(16),
    backgroundColor: "#e30000",
    marginTop: verticalScale(6),
  },

  renderDetailesRed: {
    flexDirection: "row",
  },

  arrowImg: {
    width: scale(16),
    height: scale(16),
    resizeMode: "contain",
  },

  renderbuttomContainer: {
    flexDirection: "row",
    marginTop: verticalScale(19),
  },

  renderbuttomSubContainer: {
    flexDirection: "row",
    marginLeft: scale(35),
  },

  renderbuttomSub1Container: {
    flexDirection: "row",
  },

  renderbuttomSubTextContainer: {
    color: "#7f7f7f",
    fontSize: scale(16),
    lineHeight: scale(24),
    marginLeft: scale(8),
  },

  boxImg: {
    width: scale(24),
    height: scale(24),
    resizeMode: "contain",
  },

  renderButtonContainer: {
    width: scale(327),
    height: scale(54),
    borderRadius: scale(12),
    backgroundColor: "#4e3e71",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    marginTop: 50,
    marginBottom: 20,
  },

  renderButtonTextContainer: {
    color: "#e8fafe",
    fontSize: scale(17),
    lineHeight: scale(22),
  },

  greenImg: {
    width: scale(12),
    height: scale(12),
    marginLeft: scale(16),
    marginTop: verticalScale(5),
    resizeMode: "contain",
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  leftArrowIcon: {
    width: 24,
    height: 24,
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20,
  },
});

export default patientMaterialList;
// Customizable Area End
