// Customizable Area Start
import React, { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View, Image } from "react-native";
import Drawer from "@material-ui/core/Drawer";
import CustomisableUserProfiles from "../../CustomisableUserProfiles/src/CustomisableUserProfiles";
import NotiFication from "./Notification";
import { asyncLocalStorage, logOut } from "../../../framework/src/Utilities";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default function DesktopMenue({ navigation }: { navigation: any }) {
  const [userData, setUserData] = useState<any>("");
  const [primary, setIsprimary] = useState(false);
  const [routeList, setrouteList] = useState<any>([]);

  useEffect(() => {
    const assignlist = () => {
      const user = JSON.parse(
        localStorage.getItem("loggedinUserInfo") || "{}"
      );
      let id = user.role_id
      if (id === 1) {
        setrouteList(routeListSurgeon);
      } else if (id === 2) {
        if (primary) {
          setrouteList(routeListMaterialManage);
        } else {
          let newList = routeListMaterialManage.filter(
            (x) => x.routename !== "UserActivityReport"
          );
          setrouteList(newList);
        }
      } else if (id === 3) {
        if (primary) {
          setrouteList(routeListBookingCoordinator);
        } else {
          let newList = routeListBookingCoordinator.filter(
            (x) => x.routename !== "UserActivityReport"
          );
          setrouteList(newList);
        }
      } else {
        setrouteList(routeListSalesRep);
      }
    };

    assignlist();
    getUserProfile();
  }, [userData]);

  const getUserProfile = async () => {
    let status = await asyncLocalStorage.getItem("userStatus");
    let isPrimary: any = JSON.parse(
      localStorage.getItem("loggedinUserInfo") || "{}"
    );
    setIsprimary(isPrimary.is_primary_contact);
    setUserData(status);
  };

  type Anchor = "right";

  const [showPrfole, setShowProfile] = useState(false);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setShowProfile(false);
  };

  const changeRoute = (route: any) => {
    navigation.navigate(route);
  };

  const openDrawer = () => {
    setShowProfile(true);
    setState({
      right: true,
    });
  };
  const openDrawer2 = () => {
    setShowProfile(false);
    setState({
      right: true,
    });
  };

  const routeListSurgeon = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: "confirmed",
      image: require("../assets/homeIcon.png"),
      Desktop: require("../assets/homeDesktop.png"),
    },
    {
      name: "Profile",
      routename: "Profile",
      status: "confirmed",
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png"),
    },
    {
      name: "Start New Booking",
      routename: "CreateBooking",
      status: "confirmed",
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png"),
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: "confirmed",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png"),
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: "confirmed",
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "History",
      routename: "History",
      status: "confirmed",
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Tracking",
      routename: "Tracking",
      status: "confirmed",
      image: require("../assets/tracking.png"),
      Desktop: require("../assets/navigationDesktop.png"),
    },
    {
      name: "Invite Users",
      routename: "AddTeam",
      status: "confirmed",
      image: require("../assets/invite.png"),
      Desktop: require("../assets/personDesktop.png"),
    },
    {
      name: "Notification",
      routename: "Notification",
      status: "confirmed",
      image: require("../assets/bell.png"),
      Desktop: require("../assets/bellDesktop.png"),
    },
    {
      name: "Logout",
      status: "confirmed",
      routename: "Logout",
      image: "",
      Desktop: require("../assets/logout2.png"),
    },
  ];

  const routeListMaterialManage = [
    {
      name: "Dashboard",
      routename: "Dashboard",
      status: "confirmed",
      image: require("../assets/homeIcon.png"),
      Desktop: require("../assets/homeDesktop.png"),
    },
    {
      name: "Profile",
      routename: "Profile",
      status: "confirmed",
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png"),
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: "confirmed",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png"),
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: "confirmed",
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Generate Reports ",
      routename: "UserActivityReport",
      status: "confirmed",
      image: require("../assets/plus.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Validate New Manager",
      routename: "ValidateManager",
      status: userData,
      image: require("../assets/validateNewManager.png"),
      Desktop: require("../assets/cameraDesktop.png"),
    },
    {
      name: "Validate New Surgeon",
      routename: "ValidateNewSurgeonBlock",
      status: userData,
      image: require("../assets/userIcon.png"),
      Desktop: require("../assets/cameraDesktop.png"),
    },
    {
      name: "New Material",
      routename:
        window.screen.width < 950 ? "ChooseAddMaterialType" : "NewMaterial",
      status: userData,
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png"),
    },
    {
      name: "History",
      routename: "History",
      status: userData,
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Notification",
      routename: "Notification",
      image: require("../assets/bell.png"),
      status: "confirmed",
      Desktop: require("../assets/bellDesktop.png"),
    },
    {
      name: "Logout",
      status: "confirmed",
      routename: "Logout",
      image: "",
      Desktop: require("../assets/logout2.png"),
    },
  ];

  const routeListBookingCoordinator = [
    {
      name: "Dashboard",
      status: "confirmed",
      routename: "Dashboard",
      image: require("../assets/homeIcon.png"),
      Desktop: require("../assets/homeDesktop.png"),
    },
    {
      name: "Profile",
      status: "confirmed",
      routename: "Profile",
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png"),
    },
    {
      name: "Generate Reports ",
      routename: "UserActivityReport",
      status: "confirmed",
      image: require("../assets/plus.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Validate BC",
      status: userData,
      routename: "ValidateBC",
      image: require("../assets/userIcon.png"),
      Desktop: require("../assets/cameraDesktop.png"),
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: "confirmed",
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "My Facility",
      status: "confirmed",
      routename: "UserFacilities",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png"),
    },
    {
      name: "History",
      routename: "History",
      status: userData,
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png"),
    },
    {
      name: "Notification",
      routename: "Notification",
      status: "confirmed",
      image: require("../assets/calendar.png"),
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Logout",
      status: "confirmed",
      routename: "Logout",
      image: "",
      Desktop: require("../assets/logout2.png"),
    },
  ];

  const routeListSalesRep = [
    {
      name: "Dashboard",
      status: "confirmed",
      routename: "Dashboard",
      image: require("../assets/home.png"),
      Desktop: require("../assets/homeDesktop.png"),
    },
    {
      name: "Profile",
      status: "confirmed",
      routename: "Profile",
      image: require("../assets/personsmall.png"),
      Desktop: require("../assets/userDesktop.png"),
    },
    {
      name: "My Facility",
      routename: "UserFacilities",
      status: "confirmed",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png"),
    },
    {
      name: "History",
      status: "confirmed",
      routename: "History",
      image: require("../assets/plus.png"),
      Desktop: require("../assets/plusDesktop.png"),
    },
    {
      name: "Manage Manufacturers",
      status: "confirmed",
      routename: "UserManufacturers",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png"),
    },
    {
      name: "Manage Materials",
      status: "confirmed",
      routename: "NewMaterial",
      image: require("../assets/hospital.png"),
      Desktop: require("../assets/hospitalDesktop.png"),
    },
    {
      name: "Catalogue",
      routename: "CatalogueManufacturer",
      status: "confirmed",
      Desktop: require("../assets/calendarDesktop.png"),
    },
    {
      name: "Notification",
      status: "confirmed",
      routename: "Notification",
      image: require("../assets/tracking.png"),
      Desktop: require("../assets/navigationDesktop.png"),
    },
    {
      name: "Out Of Office",
      status: "confirmed",
      routename: "OutOfOffice",
      image: require("../assets/bell.png"),
      Desktop: require("../assets/bellDesktop.png"),
    },
    {
      name: "Logout",
      status: "confirmed",
      routename: "Logout",
      image: "",
      Desktop: require("../assets/logout2.png"),
    },
  ];

  const handlePress = (item: any) => {
    if (item.name === "Logout") {
      logOut();
    } else if (item.name === "Profile") {
      openDrawer();
    } else if (item.name === "Notification") {
      openDrawer2();
    } else {
      changeRoute(item.routename);
    }
  };

  return (
    <View style={styles.desktopMenu}>
      <View>
        {routeList.map((item: any) => {
          return (
            <View key={item.name}>
              {item.status === "pending" ? (
                <View style={styles.menuWrapper}>
                  <View
                    style={{
                      flexDirection: "row",
                      marginVertical: 10,
                      marginHorizontal: 24,
                    }}
                  >
                    <Image
                      source={item.Desktop}
                      style={{ height: 20, width: 20 }}
                    />

                    <Text style={styles.menuName2}>{item.name}</Text>
                  </View>
                </View>
              ) : (
                <TouchableOpacity
                  style={styles.menuWrapper}
                  onPress={() => handlePress(item)}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      marginVertical: 10,
                      marginHorizontal: 24,
                    }}
                  >
                    {item.name === "Logout" ? (
                      <ExitToAppIcon
                        style={{ color: "#ffff", height: 20, width: 20 }}
                      />
                    ) : (
                      <Image
                        source={item.Desktop}
                        style={{ height: 20, width: 20 }}
                      />
                    )}

                    <Text style={styles.menuName}>{item.name}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          );
        })}
        {(["right"] as const).map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              <View style={{ width: 400 }}>
                {showPrfole ? (
                  <CustomisableUserProfiles
                    id="CustomisableUserProfiles"
                    navigation={navigation}
                  />
                ) : (
                  <NotiFication navigation={navigation} />
                )}
              </View>
            </Drawer>
          </React.Fragment>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  menuWrapper: {
    // paddingTop:
  },
  menuName: {
    color: "white",
    fontSize: 15,
    paddingHorizontal: 5,
    marginVertical: "auto",
  },
  menuName2: {
    color: "#b2bac6",
    fontSize: 15,
    paddingHorizontal: 5,
    marginVertical: "auto",
  },
  desktopMenu: {
    flex: 0.15,
    flexDirection: "column",
    backgroundColor: "#4e3e71",
    paddingTop: 20,
  },
});
// Customizable Area End
