import React, {useState } from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';


export default function ChooseMaterial({navigation}: {navigation: any}) {
     
      
      return (
            <View style={styles.container}>
                  <View style={styles.headingView}>
                        <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                              <img src={require("../assets/leftArrow1.png")} style={{
                                    height: 24, width: 24,
                              }} />
                        </TouchableOpacity>
                  <Text style={styles.heading}>Choose Material</Text>
                  </View>
                  
                   <View>
                        <TouchableOpacity onPress={() => navigation.navigate('SelectManufacture')}>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Choose by Manufacturer</Text>
                              </View>
                        </TouchableOpacity>
                        <View style={styles.text}>
                        <Text style={{fontSize: 24,color: '#4e3e71'}}>Or</Text>
                        </View>
                        <View>
                        <TouchableOpacity onPress={() => navigation.navigate('CMFPA')}>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Choose from Procedure List</Text>
                              </View>
                        </TouchableOpacity>
                        </View>
                  </View>
            </View>
      )
};

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff",
            flexDirection: 'column',
        
          },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
        
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          text: {
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 10,
          },      
          button: {
            borderRadius: 8,
            paddingVertical: 15,
            paddingHorizontal: 30,
            backgroundColor: '#4e3e71',
            marginVertical: 20,
            marginHorizontal: 24
          },
          buttonText: {
            color: 'white',
            fontSize: 20,
            textAlign: 'center',
          },
})