import React, {useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ChatIcon from '@material-ui/icons/Chat';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CallIcon from '@material-ui/icons/Call';




export default function SurgeryDetails({navigation}: {navigation: any}) {
      const [checked1, setChecked1]  = useState(false);
      const [checked2, setChecked2]  = useState(false);
      const [checked3, setChecked3]  = useState(false);


      return (
            <View style={styles.container}>
            <Text style={styles.toptext}> Surgery Details </Text>
            <View style={styles.card}>
            <Text style={styles.horizontalBorder}>Hospital Name: <Text style={styles.color}>ARM Hospital</Text></Text>
                  <View style={styles.profileDetails}>
                  <AccountCircleIcon style={{fontSize: 120, color: "#007af2"}}  />
                  <Text style={styles.info}>Patient Name {'\n'} Age: 26</Text>
                  </View>

                  <View style={styles.buttonsRow}>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>12/04/2021</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>12:30 PM</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Confirmed</Text>
                        </View>
                  </TouchableOpacity>
                  </View>

                  <View>
                        <View style={styles.details}>
                              <Text style={{fontWeight: 'bold' }}>Booking Information</Text>
                              <Text style={{fontWeight: 'bold', color: '#0778df', borderBottomColor: '#0778df', borderBottomWidth: 1, }}>Edit Details</Text>
                        </View>
                        <View style={styles.detailsList1}>
                              <Text style={{padding: 5}}>1) Patient Status: <Text style={{color: '#0778df'}}>Ambulatory</Text></Text>
                              <Text style={{padding: 5}}>2) Latex Allergies: <Text style={{color: '#0778df'}}>Yes</Text></Text>
                              <Text style={{padding: 5}}>3) O.R. Table: <Text style={{color: '#0778df'}}>X-ray, antibiotics</Text></Text>
                        </View>
                        <View style={styles.detailsList1}>
                        <TouchableOpacity onPress={() => checked1 ? setChecked1(false) : setChecked1(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontWeight: 'bold', padding: 5 }}>Surgery equipment(facility)</Text>
                        {checked1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity>
                        
                        <Collapse in={checked1}>
                        <View style={styles.drawerItem}>
                              <Text style={{color: '#007af2'}}>1) Materail 1</Text>
                              <FiberManualRecordIcon style={{color: 'blue'}}/>
                              <ChatIcon onClick={() => navigation.navigate('SharedNotes')}/>
                              <Text style={{color: '#007af2'}}>1</Text>

                        </View>
                        <View style={styles.drawerItem}>
                              <Text style={{color: '#007af2'}}>1) Materail 1</Text>
                              <FiberManualRecordIcon />
                              <ChatIcon />
                              <Text style={{color: '#007af2'}}>1</Text>

                        </View>
                        </Collapse>

                        <TouchableOpacity onPress={() => checked2 ? setChecked2(false) : setChecked2(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontWeight: 'bold', padding: 5 }}>Surgery equipment(Salesrep)</Text>
                        {checked2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity>
                        
                        <Collapse in={checked2}>
                        <View style={styles.drawerItem}>
                              <Text style={{color: '#007af2'}}>1) Materail 1</Text>
                              <FiberManualRecordIcon style={{color: 'green'}} />
                              <ChatIcon style={{color: "red"}}/>
                              <Text style={{color: '#007af2'}}>1</Text>

                        </View>
                        <View style={styles.drawerItem}>
                              <Text style={{color: '#007af2'}}>1) Materail 1</Text>
                              <FiberManualRecordIcon style={{color: 'blue'}}/>
                              <ChatIcon />
                              <Text style={{color: '#007af2'}}>1</Text>

                        </View>
                        </Collapse>
                       
                        
                        </View>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, paddingVertical: 20,  }} >
                        <Text style={{color: '#007af2', textDecorationLine: 'underline', fontSize: 18}}>Disposable List</Text>
                        <ArrowForwardRoundedIcon style={{color: '#007af2'}}/>
                        </View>

                        <TouchableOpacity onPress={() => checked3 ? setChecked3(false) : setChecked3(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontWeight: 'bold', padding: 5 }}>Contact Details:</Text>
                        {checked3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity>

                        <Collapse in={checked3}>
                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <Text>1)</Text>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#939ca3'}}>john</Text>
                                  <Text style={{color: '#0778df'}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <CallIcon style={{ 
                        color: '#fff',
                        fontSize: 20,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </Text>
                        </View>
                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <Text>1)</Text>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#939ca3'}}>john</Text>
                                  <Text style={{color: '#0778df'}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <CallIcon style={{ 
                        color: '#fff',
                        fontSize: 20,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </Text>
                        </View>
                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <Text>1)</Text>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#939ca3'}}>john</Text>
                                  <Text style={{color: '#0778df'}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <CallIcon style={{ 
                        color: '#fff',
                        fontSize: 20,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </Text>
                        </View>
                        </Collapse>
                        <TouchableOpacity>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Confirm Surgery</Text>
                              </View>
                        </TouchableOpacity>
                        <TouchableOpacity>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Propose Date or Time</Text>
                              </View>
                        </TouchableOpacity>
                        <TouchableOpacity>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Make on MM role</Text>
                              </View>
                        </TouchableOpacity>

                        
                  </View>
            </View>

            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff",
            flexDirection: 'column'
          },
          toptext: {

          },
          drawerItem: {

          },
          material: {
            padding: 5,
            justifyContent: 'space-between',
            flexDirection: 'row',
            color: '#0778df'
          },      
          detailsList1: {
            padding: 5,
            borderBottomColor: '#939ca3',
            borderBottomWidth: 1,
          }, 
          detailsList2: {
            margin: 20,
            fontWeight: 'bold',
            color: '#0778df',
            justifyContent: 'space-between',
            flexDirection :'row'

          },
          detailsList3: {
            margin: 20,

          },
          details: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: 20,
            marginHorizontal: 10
          },      
          color: {
            color: '#0778df',
          },
          buttonsRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            paddingLeft: 20,
            paddingRight: 20
          },
          button: {
            borderRadius: 2,
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginLeft: 5,
            marginRight: 5,
            marginVertical: 10,
            backgroundColor: '#0778df',
          },
          buttonText: {
            color: 'white',
            fontSize: 15,
            textAlign: 'center',
          },
          info: {
            paddingTop: 40,
            color: '#0778df',
          },
          horizontalBorder: {
            borderBottomColor: '#939ca3',
            borderBottomWidth: 1,
            padding: 15,
            borderColor: 'grey',
            flexDirection: 'row',
            justifyContent: 'center',
          },
          profileDetails:{
            flexDirection: 'row',   
          },
          card : {
            marginTop:20,
            borderRadius: 10,
            elevation: 3,
            backgroundColor: '#cce4fe',
            marginHorizontal: 20,
            marginVertical: 6,
          },
})