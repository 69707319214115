import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  SafeAreaView,
  FlatList,
  TextInput,
  ActivityIndicator
} from "react-native";

import RequestedMaterialController, {
  Props
} from "./RequestedMaterialController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import { ListEmptyView } from "../../../../../framework/src/CustomPopUp";

class RequestedMaterial extends RequestedMaterialController {
  renderHaderContainer = () => {
    return (
      <TouchableOpacity onPress={() => this.props.navigation.goBack()} style={styles.renderHaderContainer}>
        <Image
          source={require("../../../assets/leftArrow.png")}
          style={styles.leftArrowImg}
        />
        <Text style={styles.renderHaderTextContainer}>Requested Material</Text>
      </TouchableOpacity>
    );
  };

  renderCellListContainer = (item: any) => {
      const data = item.attributes
    return (
      <TouchableOpacity
        style={styles.renderCellListContainer}
        onPress={() => {
          this.props.navigation.navigate("NewMaterial");
          localStorage.setItem("editMaterialData", JSON.stringify(item));
        }}
      >
        {data.image === null ? (
          <Image
            source={require("../../../assets/bitmap.png")}
            style={styles.bitMapImg}
          />
        ) : (
          <Image source={{ uri: data.image }} style={styles.bitMapImg} />
        )}
        <Text style={styles.renderCellListTextContainer}>
          Material: {data.name}
        </Text>
        <TouchableOpacity
         
          style={styles.paddingButton}
        >
          <Text style={styles.paddingButtonText}>{data.status}</Text>
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };

  renderFlatListConatiner = () => {
      console.log('=======', this.state.requestList)
    return (
      <View style={styles.renderFlatListConatiner}>
        <FlatList
          ListEmptyComponent={ListEmptyView()}
          data={this.state.requestList}
          renderItem={({ item }) => this.renderCellListContainer(item)}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        {this.renderHaderContainer()}
        <Text style={styles.hadlineText}>Requested Material List</Text>
        { this.state.loader ? 
        <ActivityIndicator
          style={{ margin: 'auto', height: 100, width: 100, }}
          size="large" color="#4e3e71" />
        :this.renderFlatListConatiner()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },

  renderHaderContainer: {
    height: scale(72),
    backgroundColor: "#4e3e71",
    flexDirection: "row",
    alignItems: "center"
  },

  leftArrowImg: {
    marginLeft: scale(24),
    width: scale(24),
    height: scale(24)
  },

  renderHaderTextContainer: {
    color: "#e8fafe",
    marginLeft: scale(64),
    fontSize: scale(20)
  },

  hadlineText: {
    fontSize: scale(18),
    color: "#4e3e71",
    fontWeight: "bold",
    lineHeight: scale(18),
    marginTop: scale(30),
    marginLeft: scale(24)
  },

  renderFlatListConatiner: {
    paddingLeft: scale(18),
    marginTop: verticalScale(33)
  },

  renderCellListContainer: {
    width: '95%',
    borderRadius: scale(12),
    borderWidth: scale(1),
    borderColor: "#d0d0d0",
    paddingHorizontal:18,
    paddingVertical: scale(15),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20
  },

  bitMapImg: {
    width: 72,
    height: 72,
    borderRadius: scale(12),
    resizeMode: "contain",
    zIndex: 999
  },

  renderCellListTextContainer: {
    color: "#4e3e71",
    fontSize: scale(17),
    lineHeight: scale(22),
    marginLeft: 13
  },

  paddingButton: {
    width: scale(72),
    height: scale(30),
    backgroundColor: "#4e3e71",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2
  },

  paddingButtonText: {
    color: "#ffffff",
    fontSize: scale(17)
  }
});

export default RequestedMaterial;
