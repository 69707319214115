import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  Dimensions
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import CustomisableUserProfilesController, {
  Props,
  configJSON
} from "./CustomisableUserProfilesController";

import EditProfile from "../../dashboard/src/EditProfile";
import { back, defaultIcon } from "./assets";
import GlobalCss from "../../../framework/src/GlobalCss";
import { getRole, logOut, getStorageData} from "../../../framework/src/Utilities";
import { SessionOutModal } from "../../../framework/src/CustomPopUp";

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
      await this.getUserProfileData()
    }
  // Customizable Area End

  render() {
    const { navigation } = this.props;
    const { profileData, editPage, role} = this.state;
    const date = profileData?.attributes?.date_of_birth
    let d = new Date(date);

      const NavigateTo = () => {
                  navigation.navigate('UserFacilities') 
      }

    return (
      // Customizable Area Start

      <>
      {this.state.errorMessage ? <SessionOutModal /> : null}
      {
        editPage ? <EditProfile role={role} parent={parent} navigation={navigation}/> : 
                        <View style={styles.topMostView}>
                              <View style={styles.bottomContainer}>
                                    <View style={styles.container}>
                                          {Dimensions.get('window').width < 950 ? <View style={GlobalCss.mobileHeaderView}>
                                                <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                                      <img src={back} style={{height: 24,width: 24,marginLeft: 10 }} />
                                                </TouchableOpacity>
                                                <Text style={GlobalCss.mobileHeaderText}>Profile</Text>
                                          </View> : null}
                                          <View style={styles.profileImage} >
                                                <img src={profileData?.attributes?.profile_image ? profileData.attributes.profile_image : defaultIcon} style={{ height: 96, width: 96, paddingTop: 10, borderRadius: '50%' }} />
                                          </View>
                                    </View>
                                    <Text style={styles.roleText}>Role: {getRole(profileData?.attributes?.role_id)}</Text>
                                    <View style={styles.list}>
                                          <View style={styles.text}>
                                                <Text style={styles.title}>First Name</Text>
                                                <Text style={styles.body}> {profileData?.attributes?.first_name} </Text>
                                          </View>
                                          <View style={styles.text}>
                                                <Text style={styles.title}>Last Name</Text>
                                                <Text style={styles.body}> {profileData?.attributes?.last_name} </Text>
                                          </View>
                                          {profileData?.attributes?.date_of_birth ? <View style={styles.text}>
                                                <Text style={styles.title}>Date of Birth  </Text>
                                                <Text style={styles.body}>{d.toLocaleDateString()}</Text>
                                          </View> : null}
                                          <View style={styles.text}>
                                                <Text style={styles.title}>Phone Number</Text>
                                                <Text style={styles.body}> +{profileData?.attributes?.full_phone_number} </Text>
                                          </View>
                                          {profileData?.attributes?.address ?
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>Address</Text>
                                                      <Text style={styles.body}>{profileData?.attributes?.address}</Text>
                                                </View> : null}
                                          <View style={styles.text}>
                                                <Text style={styles.title}>Email</Text>
                                                <Text style={styles.body}>{profileData?.attributes?.email}</Text>
                                          </View>
                                          {profileData?.attributes?.gender ?
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>Gender </Text>
                                                      <Text style={styles.body}>{profileData?.attributes?.gender}</Text>
                                                </View> : null}
                                          {role === 2 || role === 3 ?
                                                <View style={styles.text}>
                                                      <Text style={styles.title}>Contact Priority </Text>
                                                      <Text style={styles.body}>{profileData?.attributes?.is_primary_contact ? "Primary" : "Secondary"}</Text>
                                                </View> : null}

                                                <TouchableOpacity onPress={() => NavigateTo()}>
                                                      <View style={styles.text1}>
                                                            <Text style={styles.title}>My Facilities  </Text>
                                                            <img style={{ paddingTop: 6, height: 20, width: 20}} src={require("../../dashboard/assets/arrow.png")} />
                                                      </View>
                                                </TouchableOpacity>
                                    </View>
                              </View>
                              <TouchableOpacity 
                              testID={"btnExample"} 
                              onPress={() => this.editProfile()}>
                                    <View style={GlobalCss.buttonView}>
                                          <Text style={GlobalCss.buttonText}>Edit Profile</Text>
                                    </View>
                              </TouchableOpacity>
                        </View>                
      }
      </>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
        flex: 1,
        backgroundColor: "#fff"
  },
  topMostView:{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' },
  leftArrowIconWrapper: {
        width: 24,
        height: 24,
        position: "absolute",
        left: 24
  },
  headingView: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 15,
        backgroundColor: '#4e3e71',
        marginBottom: 20,

  },
  title: {
        color: '#4e3e71',
        fontSize: 18,
        paddingVertical: 5,
        fontWeight: 'bold'
  },
  roleText:{
      color: '#4e3e71',
      fontSize: 16,
      marginHorizontal: 30,
      paddingVertical: 5,
      fontWeight: 'bold'
  },
  body: {
        color: '#4e3e71',
        fontSize: 18,
        paddingVertical: 5,
        borderBottomColor: '#e5e5e5',
        borderBottomWidth: 1,
        paddingBottom: 20
  },
  heading: {
        color: '#fff',
        fontSize: 20,
        marginHorizontal: 'auto'
  },
  button: {
        borderRadius: 12,
        paddingVertical: 15,
        paddingHorizontal: 50,
        marginHorizontal: 20,
        backgroundColor: '#4e3e71',
        marginBottom: 38,
        marginTop: 10
  },
  buttonText: {
        color: '#e8fafe',
        fontSize: 17,
        textAlign: 'center',
  },
  profileImage: {
        flexDirection: 'row',
        marginHorizontal: 20,
        position: 'relative'
  },
  editIcon: {
        justifyContent: 'center',
        position: 'absolute',
        top: 165,
        right: 170,
        elevation: 10,
        backgroundColor: '#e9e9e9'
  },
  bottomContainer: {
  },
  list: {
        flexDirection: 'column',
        marginHorizontal: 24,
        marginVertical: 24,
        justifyContent: 'center'
  },
  text: {
        flexDirection: 'column',
  },
  text1: {
        marginTop: 15,
        padding: 10,
        borderRadius: 6,
      //   backgroundColor: 'pink',
        borderWidth: 1,
        borderColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'space-between'
  },
  icons: {
        justifyContent: 'flex-end',
  }
})
// Customizable Area End
