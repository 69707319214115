import React, {useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
Platform,
TouchableOpacity,
ActivityIndicator,
TextInput,
FlatList,
ScrollView, } from 'react-native';
import scale, { verticalScale } from "../../../../../framework/src/Scale";
import { ListEmptyView } from '../../../../../framework/src/CustomPopUp';
import { getAxiosAPI, getErrorMessage } from '../../../../../framework/src/Blocks/AxiosAPIBlock';
import { SortData } from '../../../../../framework/src/Utilities';
import DesktopHeader from '../../DesktopHeader';
import DesktopMenue from '../../DesktopMenue';
import CustomPopUp from '../../../../../framework/src/CustomPopUp';




export default function SelectMaterialBrandCatergory({navigation}: {navigation: any}) {


  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [searchData, setsearchData] = useState([]);
  const [category_id, setcategory_id ] = useState('')
  const selectedBrand = localStorage.getItem('brandID')
  const [offset, setOffset] = useState(0);
  const [loader, setLoader] = useState({
    data: false,
    submitBtn: false
  });

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false)

  useEffect(() => {
      getListOfCategories();
  }, [offset]);

  const getListOfCategories = async (search: any = '') => {
    setLoader((pre: any) => ({...pre, data: true}))
        let url = `/bx_block_categories/categories?search=${search}&limit=9&offset=${offset}&brand_ids=${selectedBrand}`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                console.log(res.data);
                setLoader((pre: any) => ({...pre, data: false}))
              } else 
              console.log(res.data);
              setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
              setLoader((pre: any) => ({...pre, data: false}))
            })
        }
  console.log(data)

  const handleSearch = (text : any) => {
    setQuery(text)
    getListOfCategories(text)
  };

  const handleChange = (item:any) => {
    let newdata: any = data.map((x: any) => {
      
      if(x.id === item.id) {
        x.ischecked = !x.ischecked
      }
      return x
    })
    setData(newdata)
      if(item.ischecked){
        setcategory_id((preval) => (preval ? preval+','+item.id : item.id))
      }

   
    // setHospital_name(item.attributes.name) 
  };

  const ClosePopUp = () => {
    setPopup(false)
    navigation.navigate('Dashboard')
  }

  console.log(category_id)


  const navigateTo = async () => {
    console.log(" clicked ==> ")
    setLoader((pre: any) => ({...pre, submitBtn: true}))
      let url = `/account_block/accounts/choose_categories_for_sales_rep?category_ids=${category_id}`
      await getAxiosAPI(url).then((res: any) => {
            if(res.error){
              console.log(res.data);
              setLoader((pre: any) => ({...pre, submitBtn: false}))
            } else 
            console.log(res.data);
            setLoader((pre: any) => ({...pre, submitBtn: false}))
            setMessage("Manufacturer added successfully")
            setPopup(true)
          
          })    
  }
  
  return (
    window.screen.width < 950 ?
    <View style={styles.container}>
              {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
        <View style={styles.headingView}>
          <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
            <img src={require("../../../assets/leftArrow1.png")} style={{
              height: 24, width: 24,
            }} />
          </TouchableOpacity>
    <Text style={styles.heading}>Select Category</Text>
         
      </View>
      <View style={styles.searchBoxMob}>
                <TextInput
                          style={styles.input}
                          placeholder="Search "
                          autoCapitalize="none"
                          autoCorrect={false}
                          clearButtonMode="always"
                          placeholderTextColor="#939ca3"
                          value={query}
                          onChangeText={queryText => handleSearch(queryText)}
                  />
                <TouchableOpacity onPress={() => setQuery('')}>
              <img src={query === '' ? require("../../../assets/search.png" ) : require("../../../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
              </TouchableOpacity> 
              </View> 
              {loader.data ? <ActivityIndicator
                        style={{ margin: 'auto', height: 100, width: 100, }}
                        size="large" color="#4e3e71" />
                        :
                    <View style={styles.card}>
    <ScrollView style={{flex: 1}}>
    {
                      (query.length > 0 && data.length > 0) ? 
                      <View style={styles.add}>
                      <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                      </View> 
                           : 
                           (query.length > 0 && data.length === 0) ? 
                           <View style={styles.add}>
                           <Text style={styles.resultText}>Searched category not found
                          </Text>
                           </View> : null
                     }
                      
                     <FlatList
                      data={data}
                      ListEmptyComponent={ListEmptyView()}
                      keyExtractor={(item: any) => item.id}
                      renderItem={({ item }) => (
                      <TouchableOpacity onPress={() => handleChange(item)} >
              
                          <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                        <View style={{flexDirection: 'row' , flex: 0.9}}>
                                          <img src={require("../../../assets/manifac_img.png")} style={{ height: 56, width: 56}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                               <Text style={styles.names} numberOfLines={1}>{item.attributes.name}</Text>    
                                                  {
                                                      item.attributes.materials.data.slice(0, 2).map((x:any, y:any) => {
                                                        return (
                                                          <Text style={styles.role} numberOfLines={2}>{y+1}) {x.attributes.name} </Text> 
                                                          
                                                        )
                                                      })
                                                    }
                                                </View>
                                        </View>
                                        <View style={{marginVertical: 'auto', flex: 0.1}}>
                                       <img src={item.ischecked ? require("../../../assets/radio_active.png") : require("../../../assets/radio_unactive.png")}  style={{height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }}/>      
                                       </View>
                                    </View>
              
                      </TouchableOpacity>
                     
                      )}
                      />
                       <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                          {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"< Prev"}</Text></TouchableOpacity>}
                          {data.length < 9 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"Next >"}</Text></TouchableOpacity>}
                        </View>
                      </ScrollView>
                      </View> 
              }
 
    <TouchableOpacity disabled={category_id && !loader.submitBtn ? false : true} onPress={() => navigateTo()}>
          <View style={category_id && !loader.submitBtn ?  styles.button : styles.button0}>
          <Text style={styles.buttonText}>Continue</Text>
          {loader.submitBtn && <ActivityIndicator
          style={{ margin: 'auto',position: 'absolute', right: 160, top: 7}}
          size="large" color="#4e3e71" />}
          </View>
    </TouchableOpacity>    
      </View>
      :
      <View style={styles.desktopContainer}>
        {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
      <DesktopHeader heading={'Choose Category'} navigation={navigation} />
      <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={{ flex: 0.85}}>
            <View style={styles.outerCard}>
                  <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Select Category  </Text>
                  <View style={{ flexDirection: 'row' }}>
                        <View style={styles.searchBox}>
                              
                              <TextInput
                                    style={styles.input}
                                    placeholder="Search "
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    clearButtonMode="always"
                                    placeholderTextColor="#939ca3"
                                    value={query}
                                    onChangeText={queryText => handleSearch(queryText)}
                              />
                              <TouchableOpacity onPress={() => setQuery('')}>
                                    <img src={query === '' ? require("../../../assets/search.png") : require("../../../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                              </TouchableOpacity>

                        </View>
                     
                  </View>
                  <View>
                     
                              <View>
                                    {loader.data ? <ActivityIndicator
                                    style={{ margin: 'auto', height: 100, width: 100, }}
                                    size="large" color="#4e3e71" />
                                    :
                                    <View style={styles.card}>
                                          <ScrollView style={{ flex: 1 }}>
                                                {
                                                      (query.length > 0 && data.length > 0) ?
                                                            <View style={styles.add}>
                                                                  <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                            </View>
                                                            :
                                                            (query.length > 0 && data.length === 0) ?
                                                                  <View style={styles.add}>
                                                                        <Text style={styles.resultText}>Searched category not found, {'\n'}
                                                                        </Text>
                                                                  </View> : null
                                                }

                                                <FlatList
                                                      data={data}
                                                      horizontal={false}
                                                      numColumns={3}
                                                      keyExtractor={(item: any) => item.id}
                                                      renderItem={({ item }) => (
                                                            <TouchableOpacity onPress={() => handleChange(item)}>
                                                                  <View style={item.ischecked ? styles.listItem_active2 : styles.listItem2}>
                                                                        <View style={{ flexDirection: 'row', flex: 0.9 }}>
                                                                              <img src={require("../../../assets/manifac_img.png")} style={{ height: 56, width: 56 }} />
                                                                              <View style={{ flexDirection: 'column', marginVertical: 'auto', }}>
                                                                                    <Text style={styles.names} numberOfLines={1}>{item.attributes.name}</Text>
                                                                                    {
                                                                                          item.attributes.materials.data.slice(0, 2).map((x: any, y: any) => {
                                                                                                return (
                                                                                                      <Text style={styles.roleWeb} numberOfLines={2}>{y + 1}) {x.attributes.name} </Text>

                                                                                                )
                                                                                          })
                                                                                    }
                                                                              </View>
                                                                        </View>
                                                                        <View  style={{ marginVertical: 'auto', flex: 0.1 }}>
                                                                              <img src={item.ischecked ? require("../../../assets/radio_active.png") : require("../../../assets/radio_unactive.png")} style={{ height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }} />
                                                                        </View>
                                                                  </View>
                                                            </TouchableOpacity>

                                                      )}
                                                />
                                          </ScrollView>
                                          
                                            
                                                      <TouchableOpacity disabled={category_id && !loader.submitBtn ? false : true} onPress={() => navigateTo()} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                            <View style={category_id && !loader.submitBtn ? styles.button : styles.button0}>
                                                                  <Text style={styles.buttonText}>Continue </Text>
                                                                  {loader.submitBtn && <ActivityIndicator
                                                                    style={{ margin: 'auto',position: 'absolute', right: 80, top: 7}}
                                                                    size="large" color="#4e3e71" />}
                                                            </View>
                                                      </TouchableOpacity>
                                            

                                    </View>
                                    }
                              </View> 
                        
                  </View>
            </View>
            </View>
      </View>
</View>
)
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: 'column'
  },
  buttonText0: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
},
  outerCard: {
    flex: 1,
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 12,
    borderColor: '#7f7f7f',
    padding: 25,
    margin: 15,
    flexDirection: 'column'
},
  bottomDesktopCard: {
    flex: 1,
    flexDirection: 'row'
},
  desktopContainer: {
    flex: 1,
    flexDirection: 'column'
},
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  listItem: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#e5e5e5',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listItem_active: {
    height: 88,
    marginHorizontal:26,
    borderColor: '#4e3e71',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listItem2: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: '#e5e5e5',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
},
listItem_active2: {
    height: 88,
    width: 360,
    marginHorizontal: 16,
    borderColor: '#4e3e71',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
},
  role: {
    color: '#b2bac6',
    paddingHorizontal: 11,
    width: scale(250),
    
  },
  roleWeb: {
    color: '#b2bac6',
    paddingHorizontal: 11,
    width: scale(55),
    
  },
  names: {
    color: '#4e3e71',
    fontSize: 15,
    paddingHorizontal: 11,
    width: scale(220),
    
  },
  
  checkboxContainer: {
    flex: 0.1,
    paddingRight: 10,
    paddingTop: 80
  },
  checkbox: {
    alignSelf: "center",
  },
  add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
  headingView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 27,
    backgroundColor: '#4e3e71',
    marginBottom: 20,

  },
  heading:{
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'       
  },
  crossIcon: {
    position: 'absolute',
    top: 40,
    right: 10,
    elevation: 10,
},      
searchBox: {
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 10,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50,
  flex: 1,
  
},
searchBoxMob: {
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 10,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50,
  // flex: 1,
  
},
    input: {
outlineStyle: 'none',
      flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
    },
    inputMob: {
      flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
    },
    input2:{
      flex: 1,
      paddingLeft: 10,
      height: 48,
      color: '#4e3e71',
      fontSize: 16,
      paddingStart: 20,
      width: 950
    },
  card: {
    flex: 1,
    marginTop:10,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: '#fff',
    marginVertical: 6,
  },
  cardContent: {
    marginHorizontal: 18,
    marginVertical: 20,
  },
  outerCard1 : {
    flex: 1,
    flexDirection: 'row',
    marginTop:20,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#eff8ff",
    marginHorizontal: 30,
    marginVertical: 6,
    position: 'relative',
    justifyContent: 'space-between'
  },
  innerCard1: {
    flex: 0.2,
    height: 100,
    backgroundColor: "white",
    margin: 10,
    borderRadius: 10
  },
  innerCard2: {
    flex: 0.2,
    backgroundColor: "#eff8ff",
  },
  nameaddress:{
    flex: 0.6,
    backgroundColor: "#eff8ff",
  },

  addressText: {
        marginVertical: 20,
        marginLeft: 20
  },
  cardHeading: {
    marginTop: 20,
    color: '#0579ed',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center'
},    
para: {
    color: '#939ca3'
  },
  paraDis: {   
    color: '#0579ed'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    marginTop: 10
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: '#aa9cc9',
    marginBottom: 10,
    marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
})
