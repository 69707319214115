import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as yup from "yup";
import { getRegex } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  countryName: string,
  regionName: string,
  query: string,
  checked: boolean,
  searchData: any[],
  apiData: any[],
  hospitalsName: any[],
  arrayHolder: any[],
  token: string,
  hospitals: string,
  showInput: boolean,
  showAmbulatory: boolean,
  ambulatory: string,
  OR: string,
  address: any,
  zip: any,
  is_hospital: any,
  is_or: any,
  is_ambulatory: any,
  selectedValue: any,
  open: any,
  nameError: any,
  role_Name: any,
  isPhoneValid: any,
  loader: boolean
  passwordValidation: any
  userIp: any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  hospitalApiCallId: string = "";
  inviteUserApiCallId: string = "";
  userApiCallId: string = ""

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      countryName: '',
      regionName: '',
      query: '',
      checked: false,
      searchData: [],
      arrayHolder: [],
      token: "",
      apiData : [],
      hospitalsName: [],
      address:'',
      zip: '',
      is_hospital: null,
      hospitals: "Yes",
      showInput: false,
      showAmbulatory: false,
      ambulatory: "Yes",
      OR: "Yes",
      is_or: null,
      is_ambulatory: null,
      selectedValue: '',
      open: false,
      nameError: '',
      role_Name: '',
      isPhoneValid: true,
      loader: false,
      passwordValidation: '',
      userIp:''
      
      // Customizable Area End
    };

    // Customizable Area Start
    
    
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
            this.setState({passwordValidation: responseJson.data})
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            localStorage.removeItem("isTimer")
            localStorage.removeItem("timer")
            window.localStorage.setItem("authToken",responseJson.email_otp.meta.token);
            // window.localStorage.setItem("authData",JSON.stringify(responseJson));
            this.props.navigation.navigate("OTPInputAuth")
            this.setState({ loader: false})

          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            console.log(responseJson)
            this.setState({ loader: false})
          }

          this.parseApiCatchErrorResponse(errorReponse);
          console.log(responseJson)
          this.setState({ loader: false})
        }
      }
    }


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(apiRequestCallId === this.hospitalApiCallId) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.setState({apiData: responseJson.data})
         console.log(this.state.apiData);
         this.state.apiData.map(x => {
          this.state.hospitalsName.push({id: x.id, name: x.attributes.name})
        })
        //console.log(this.state.hospitalsName);
        this.state.hospitalsName.sort(function(a,b) {return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);} )
        console.log("after sort" ,this.state.hospitalsName);
      }
    
    
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(apiRequestCallId === this.inviteUserApiCallId) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.setState({apiData: responseJson.data})
         console.log(this.state.apiData);
         alert("Invited Successfully")
         this.props.navigation.navigate("AddTeam")
      }
    }



    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  //------------------New Functions-------------------------------------------------------------------

  handlePasswordChange = (pass: any) => {
    const {passwordValidation } = this.state
    if(Object.keys(passwordValidation).length){
      try {
        const regexBase = new RegExp(
          "^(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]{" +
          passwordValidation.special_char +
            "," +
            5 +
            "})(?=.*[A-Z]{" +
            passwordValidation.up_case +
            "," +
            5 +
            "})(?=.*[a-z]{" +
            passwordValidation.lower_case +
            "," +
            10 +
            "}).{" +
            passwordValidation.min_length +
            "," +
            passwordValidation.max_length +
            "}$"
        );
        const specialChars = `!@#$%^&*()_+=[\]{}|\\:',./?><~\``.substring(0, passwordValidation.special_char);
        const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z]{${passwordValidation.up_case},})(?=.*\\d{${passwordValidation.min_number},})(?=.*[${specialChars}]{${passwordValidation.special_char},})[a-zA-Z\\d${specialChars}] {8,}$`);
        console.log({isValid: regexBase.test(pass), passwordValidation})
        this.setState({password: pass})

        return regex.test(pass);
      } catch(err) {
        console.log(err)
      }
    } else {
      console.log("empty schema ====> ")
    }
  }

  validationSchema = () => {
    const {passwordValidation } = this.state
    let validation_Schema
    if(passwordValidation){
      const newRegx = getRegex(passwordValidation)
      validation_Schema = yup.object({
        fname: yup
          .string()
          .required("First name is required")
          .matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
        lname: yup
          .string()
          .required("Last name is required")
          .matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
        email: yup
          .string()
          .email("Please enter a valid Email")
          .matches(/^([a-zA-Z][\w+-]+(?:\.\w+)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/,"Invalid email address")
          .required("Email is required"),
        fullphone: yup
          .string()
          .required("Phone is required")
          .min(10, "Must be at least 10 digits"),
        password: yup
          .string()
          .required("Please enter a password")
          .matches(
            newRegx.regEx,
            newRegx.message
          )
          .min(passwordValidation.min_length, `Password must be at least ${passwordValidation.min_length} characters`)
          .max(passwordValidation.max_length, `Maximum ${passwordValidation.max_length} characters allowed`),
        rePassword: yup
          .string()
          .required("Please confirm your password")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
          }),
      });
    } else {
      console.log("state empty ==>")
       validation_Schema = yup.object({
        fname: yup
          .string()
          .required("First name is required")
          .matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
        lname: yup
          .string()
          .required("Last name is required")
          .matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
        email: yup
          .string()
          .email("Please enter a valid Email")
          .required("Email is required"),
        fullphone: yup
          .string()
          .required("Phone is required")
          .min(10, "must be atleast 10 digits"),
        password: yup
          .string()
          .required("Please enter a password")
          .matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#@*^$%&? "])[a-zA-Z0-9!#@*^$%&?]{8,}$/,
            "Requires at least 1 uppercase letter, 1 lowercase letter, 1 number, and one special character, with a length of at least 8 characters"
          )
          .min(8, "Password must be at least 8 characters")
          .max(16, "Maximum 16 characters allowed "),
        rePassword: yup
          .string()
          .required("Please confirm your password")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
          }),
      });
    }
  
    return validation_Schema
  }

  handleSearch = (text : any) => {
    this.setState({query: text})
    this.setState({checked: true})
    if(text !== "") {
          const newData = this.state.hospitalsName.filter((name: any) => {
          return Object.values(name)
                .join(" ")
                .toLowerCase()
                .includes(text.toLowerCase());
          });
          this.setState({searchData: newData});
          //console.log(newData);
          
    } else {
          this.setState({searchData: this.state.hospitalsName});
    }
  };


navigateToNext = (item : any) => {
     console.log(item);
    // this.props.navigation.navigate('FailityName', item)
    const msg: Message = new Message(
      getName(MessageEnum.NavigationFacilityNameMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
}

filterHospitalList() {
  if(!this.state.apiData.length){
    console.log("array is empty ");
  }else {
    this.state.apiData.map(x => {
      this.state.hospitalsName.push({id: x.id, name: x.attributes.name})
    })
    console.log(this.state.hospitalsName);
  }  
}



getHospitalsList() {
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    'token' : localStorage.getItem('authToken')
  };

  const getHospitalsList = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.hospitalApiCallId = getHospitalsList.messageId;

  getHospitalsList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    '/bx_block_hospitals/hospitals'
  );

  getHospitalsList.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  getHospitalsList.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(getHospitalsList.id, getHospitalsList);
  
}




 handelhospital = (e: any) => {
  this.setState({ hospitals: e.target.value })
  console.log(this.state.hospitals)
  }

 handelOR = (e: any) => {
  this.setState({ OR: e.target.value })
  }

 handelambulatory = (e: any) => {
  this.setState({ ambulatory: e.target.value })
}

Handlehospital = () => {
  const postData = {
    hospital: {
      name: this.state.firstName,
      address: this.state.address,
      zip: this.state.zip,
      phone_number: this.state.phone,
      email: this.state.email,
      is_hospital: this.state.is_hospital,
      is_or: this.state.is_or,
    }    
  }
  if(this.state.hospitals === "Yes"){
    postData.hospital.is_hospital = true
  }else {
    postData.hospital.is_hospital = false
  }

  if(this.state.OR === "Yes"){
    postData.hospital. is_or= true
  }else {
    postData.hospital.is_or = false
  }
  console.log(postData);
  
 ////-------------call API --------------////
  {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      'token' : localStorage.getItem('authToken')
    };
  
    const addHospital = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hospitalApiCallId = addHospital.messageId;
  
    addHospital.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_hospitals/hospitals'
    );
  
    addHospital.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addHospital.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(postData)
    );
    addHospital.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    runEngine.sendMessage(addHospital.id, addHospital);
    
  }
}

InviteUserAPI = () => {
  const postData = {
    invite: {
      email: this.state.email,
      name: this.state.firstName,
      role_id: this.state.selectedValue
    }
  }
  console.log(postData);

// ----------------call api ----------------
{
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    'token' : localStorage.getItem('authToken')
  };

  const inviteUser = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.inviteUserApiCallId = inviteUser.messageId;

  inviteUser.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    '/bx_block_invite/invities'
  );

  inviteUser.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  inviteUser.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(postData)
  );
  inviteUser.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'Post'
  );
  runEngine.sendMessage(inviteUser.id, inviteUser);
  
}

  
}




handleClose = () => {
  this.setState({open: false})
}

handleClickOpen = () => {
  this.setState({open: true})
}

getUserList = () => {
  {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      'token' : localStorage.getItem('authToken')
    };
  
    const getUserList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userApiCallId = getUserList.messageId;
  
    getUserList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/accounts'
    );
  
    getUserList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUserList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getUserList.id, getUserList);
    
  }
}


nameValidation = () => {
  if(this.state.nameError === "") {
    this.setState({nameError: "name can't be blank "})
  } else if (isNaN(this.state.nameError)) {
    this.setState({nameError: "must be number"})
  } else {
    this.setState({nameError: ""})
  }
}

role_name = () => {
  const role_id = window.localStorage.getItem('role')
  if(role_id === '1'){
    this.setState({role_Name: "Surgeon"})
  } else if (role_id === '2') {
    this.setState({role_Name: "Material Manager"})
  } else if (role_id === '3'){
    this.setState({role_Name: "Booking Coordinator"})
  } else {
    this.setState({role_Name: "Sales Representative"})
  }
}
  //------------------New Functions Ends--------------------------------------------------------------
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    this.setState({ loader: true})
    
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const data = {
      first_name: this.state.firstName.trim(),
      last_name: this.state.lastName.trim(),
      full_phone_number: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.reTypePassword,
      role_id: window.localStorage.getItem('role'),
      device_ids: localStorage.getItem('FCMtoken'),
      ip_address: this.state.userIp
    };

    console.log(data);
    window.localStorage.setItem('signup_mail', this.state.email)


    const httpBody = {
      data: data,
    };
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // console.log(this.createAccountApiCallId);
  
  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  // btnSignUpProps = {
  //   onPress: () => this.createAccount()
  // };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputAddressPrpos = {
    onChangeText: (text: string) => {
      this.setState({ address: text });

      //@ts-ignore
      this.txtInputAddressPrpos.value = text;
    }
  }

  txtInputZipCodePrpos = {
    onChangeText: (text: string) => {
      this.setState({ zip: text });

      //@ts-ignore
      this.txtInputZipCodePrpos.value = text;
    }
  }

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
