import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { getErrorMessage, postAxiosAPI } from '../../../../framework/src/Blocks/AxiosAPIBlock';



export default function ValidateBc({ navigation }: { navigation: any }) {

      const user = JSON.parse(localStorage.getItem('validateBCDetails') || '{}')

      const CallApi = async () => {
            let url = `/bx_block_material_manager/material_manager/accept_booking_coordinator?id=${user.id}`;
            await postAxiosAPI(url, null).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    console.log(msg);
                    
              } else {
                  console.log(res.data)
                  navigation.goBack()
              }
            });
      }
     
      
      return (
            <View style={styles.container}>
                        <View style={styles.headingView}>
                                    <Text style={styles.heading}>Booking Co-ordinator</Text>
                  </View>

                  <View style={{flex: 1, flexDirection: 'column', justifyContent: 'space-between'}}>
                   <View style={styles.listItem}>
                                        <View style={{flexDirection: 'row'}}>
                                          <img src={require("../../assets/personImg.png")} style={{ height: 70, width: 70}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto'}}>
                                                            <Text style={styles.names}>{user.attributes.first_name ? user.attributes.first_name + " " + user.attributes.last_name : 'Undefined'}</Text>
                                                            <Text style={styles.names2}>{ user.attributes.facilities.data[0].attributes.hospital.data.attributes.name}</Text>
                                                </View>
                              </View>
                              <View style={{paddingTop: 20}}>
                                    <Text style={styles.details}>1) Phone Number : <Text style={{color: '#4e3e71'}}> {user.attributes.full_phone_number}</Text></Text>
                                    <Text style={styles.details}>2) Email : <Text style={{color: '#4e3e71'}}> {user.attributes.email}</Text></Text>
                              </View>
                        </View>
                        <View style={{paddingBottom: 20}}>
                  
                          <TouchableOpacity onPress={() => CallApi()}>
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Accept BC</Text>
                              </View>
                              </TouchableOpacity>
                               <TouchableOpacity onPress={() => navigation.goBack()}>
                              <View style={styles.button1}>
                              <Text style={styles.buttonText1}>Reject BC</Text>
                              </View>
                              </TouchableOpacity>
                                          
                        </View>
                  </View>
                 

                 
            </View>
        )
}

const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: "#fff"
      },
      names2: {
            color: '#8e9aab',
            paddingHorizontal: 11,
      fontSize: 14
      },
      details: {
            color: '#d0d0d0',
            fontSize: 16,
            paddingVertical: 5
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
      button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            borderColor: '#e3e3e3',
            borderWidth: 1,
            backgroundColor: '#fff',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText1: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
          },
      listItem: {
   marginHorizontal:26,
   borderColor: '#e5e5e5',
   borderWidth: 1,
   borderRadius: 12,
   marginVertical: 5,
   padding: 20,
  },
    names: {
    color: '#4e3e71',
    fontSize: 17,
      paddingHorizontal: 11,
    paddingVertical: 5
    
  },
        card: {
    flex: 1,
    marginTop:20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
      },
         add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
      headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 15,
            height: 72,
            backgroundColor: '#4e3e71',
            marginBottom: 20,

      },
        heading: {
            color: '#fff',
            fontSize: 20,
            marginHorizontal: 'auto'
      },
      heading2: {
            color: '#4e3e71',
            fontSize: 18,
            marginHorizontal: 24,
            fontWeight: 'bold'
        },
        searchBox: {
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 20,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50
  
},
input: {
outlineStyle: 'none',
  flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
},

})