import React from 'react';
import { Stepper , Step} from 'react-form-stepper';
import {View } from 'react-native';

export default function StepperComponent({activeStepCount} : {activeStepCount : any}) {
  return (
  <View>
         <Stepper 
                activeStep={activeStepCount}
                style={{color: '#4e3e71'}}
                styleConfig={{
                  activeBgColor: '#4e3e71',
                  activeTextColor: '#4e3e71',
                  completedBgColor: '#4e3e71',
                  completedTextColor: '#4e3e71',
                  inactiveBgColor: '#e5e5e5',
                  inactiveTextColor: '#e5e5e5',
                  size: '1em',
                  circleFontSize: '1rem',
                  labelFontSize: '0.875remrem',
                  borderRadius: '50%',
                  fontWeight: 500

                }}
                connectorStyleConfig={{
                  disabledColor: '#4e3e71',
                  activeColor: '#4e3e71',
                  completedColor: '#4e3e71',
                  size:3,
                  stepSize: '2em',
                  style:'solid'
                }}
                steps={[{ label: 'When' }, { label: 'Facility' }, { label: 'Information' }, { label: 'Material' }]}
                />
  </View>
  )
};


