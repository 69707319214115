import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import Dialog from '@material-ui/core/Dialog';
import scale from '../src/Scale';
import { sessionMessage } from './Utilities';

export const ListEmptyView = () => {
  return (
    <View>
      <Text style={styles.heading}>No Record Found</Text>
    </View>
  );
};

export const showErrorMsg = (item: any) => {
  let msg;
  if (
    !item.attributes.booking_coordinator &&
    !item.attributes.material_manager
  ) {
    msg = 'Material Manager & Booking Coordinator not available ';
  } else if (!item.attributes.booking_coordinator) {
    msg = 'Booking Coordinator not available';
  } else if (!item.attributes.material_manager) {
    msg = 'Material Manager not available';
  } else {
    msg = null;
  }
  return msg;
};

export default function CustomPopUp({
  message,
  ClosePopUp,
  btnText
}: {
  message: any;
  ClosePopUp: any;
  btnText: any;
}) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const isSessionExpired = localStorage.getItem('sessionExpired') || '';
    if (isSessionExpired === 'true') {
      setOpen(true);
    }
  });

  const handleClick = () => {
    if (message === sessionMessage) {
      window.localStorage.clear();
      window.location.href = '/';
    } else {
      ClosePopUp();
    }
  };

  return (
    <View>
      <Dialog open={open} onClose={ClosePopUp} style={{ borderRadius: 15 }}>
        <View style={styles.box}>
          <Text numberOfLines={3} style={styles.message}>
            {message}{' '}
          </Text>
          <TouchableOpacity onPress={() => handleClick()}>
            <View style={styles.button}>
              <Text style={styles.buttonText}>
                {message === sessionMessage ? 'Login' : btnText}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </Dialog>
    </View>
  );
}

export function SessionOutModal() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isSessionExpired = localStorage.getItem('sessionExpired') || '';
    if (isSessionExpired === 'true') {
      setOpen(true);
    }
  });

  const ClosePopUp = () => {
    window.localStorage.clear();
    window.location.href = '/';
  };

  return (
    <Dialog open={open} onClose={ClosePopUp} style={{ borderRadius: 15 }}>
      <View style={styles.box}>
        <Text numberOfLines={3} style={styles.message}>
          Your session has been expired! click here to login{' '}
        </Text>
        <TouchableOpacity onPress={() => ClosePopUp()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Login</Text>
          </View>
        </TouchableOpacity>
      </View>
    </Dialog>
  );
}

const styles = StyleSheet.create({
  box: {
    width: 320,
    height: 180,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginVertical: 10
  },
  message: {
    flex: 1,
    width: scale(280),
    color: '#4e3e71',
    fontSize: 20,
    fontWeight: 'bold',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    margin: 10,
    backgroundColor: '#4e3e71'
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center'
  },
  heading: {
    marginTop: 100,
    marginHorizontal: 'auto',
    color: '#b2bac6',
    fontSize: 24,
    fontWeight: 'bold',
    flexDirection: 'row',
    justifyContent: 'center'
  }
});
