import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
      TextInput,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      Platform,
      Picker,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "../../dashboard/src/DesktopHeader"
import DesktopMenue from "../../dashboard/src/DesktopMenue"
// import SelectManufacture from "./SelectManfac";
import axios from "axios"
axios.defaults.headers.token = localStorage.getItem('authToken');
import scale, { verticalScale } from "../../../framework/src/Scale";
let config = require('../../../framework/src/config');
import Card from '@mui/material/Card';
import Dialog from '@material-ui/core/Dialog';
import InviteUser from "./InviteUser";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [loader, setLoader] = useState(true);
      const [offset, setOffset] = useState(0);

      const [open, setOpen] = useState(false)

      const handleClose = () => {
            setOpen(false)
      }

      const getListOfAccounts = async (search: any = '') => {
            setLoader(true)
            axios.get(`${config.baseURL}/account_block/accounts?search=${search}&limit=12&offset=${offset}`)
              .then((response) => {
                console.log(response.data.data)
                setData(response.data.data)
                // setData(response.data.data.sort(function (a: any, b: any) { return (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase()) ? 1 : ((b.attributes.name.toLowerCase() > a.attributes.name.toLowerCase()) ? -1 : 0); }))
                setLoader(false)
              }, (error) => {
                console.log(error);
                setLoader(false)
              })
              .catch(error => {
                console.log(error)
                setLoader(false)
              }) 
            }

            useEffect(() => { 
                  getListOfAccounts();
                }, [offset]);
      console.log(data)


      const handleSearch = (text: any) => {
            setQuery(text)
            getListOfAccounts(text)
            // if (text !== "") {
            //       const newData = data.filter((name: any) => {
            //             return Object.values(name.attributes)
            //                   .join(" ")
            //                   .toLowerCase()
            //                   .includes(text.toLowerCase());
            //       });
            //       setsearchData(newData);
            //       //console.log(newData);

            // } else {
            //       setsearchData(data);
            // }
      };

     
      const navigateTo = () => {
            navigation.navigate('Dashboard')
      }
     

     

      const roleName = (id: any) => {
            if (id === 1) {
                  return "Surgeon"
            } else if (id === 2) {
                  return "Material Manager"
            } else if (id === 3) {
                  return "Booking Coordinator"
            } else if (id === 4) {
                  return "Sales Representative"
            } else {
                  return "Not Defined"
            }
      }

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'Invite Users'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>

                     
                        <View style={styles.outerCard}>
                              <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Search for existing user"
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>
                                          {/* <TouchableOpacity >
                                                <View style={styles.button}>
                                                      <Text style={styles.buttonText}>Invite Users </Text>
                                                </View>
                                          </TouchableOpacity> */}
                                    </View>
                              </View>
                              <View>
                                    <View>
                                          {loader ? <ActivityIndicator
                                                style={{ margin: 'auto', height: 100, width: 100, }}
                                                size="large" color="#4e3e71" />
                                                :
                                                <View style={styles.card}>
                                                <View style={{ flex: 1, }}>
                                                      {
                                                            (query.length > 0 && data.length > 0) ?
                                                                  <View style={styles.add}>
                                                                        <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                                  </View>
                                                                  :
                                                                  (query.length > 0 && data.length === 0) ?
                                                                        <View style={styles.add}>
                                                                              <Text style={styles.resultText}>Searched user not found, {'\n'}
                                                                                    <Text style={{ color: '#4e3e71', fontWeight: 'bold', paddingHorizontal: 50 }} onPress={() => setOpen(true)}>Invite User</Text></Text>
                                                                        </View> : null
                                                      }

                                                      <FlatList
                                                            style={{ height: 420 }}
                                                            data={data}
                                                            keyExtractor={(item: any) => item.id}
                                                            horizontal={false}
                                                            numColumns={3}
                                                            renderItem={({ item }) => (
                                                                  <TouchableOpacity>
                                                                        <View style={styles.listItem}>
                                                                              <View style={{ flexDirection: 'row' }}>
                                                                                    <img src={require("../assets/dummy.png")} style={{ height: 50, width: 50 }} />
                                                                                    <View style={{ flexDirection: 'column', marginVertical: 'auto' }}>
                                                                                          <Text style={styles.names}>{item.attributes.first_name ? item.attributes.first_name + " " + item.attributes.last_name : 'Undefined'}</Text>
                                                                                          <Text style={styles.role}>{item.attributes.role_id ? roleName(item.attributes.role_id) : 'Undefined'}</Text>
                                                                                    </View>
                                                                              </View>
                                                                              {/* <View style={{ marginVertical: 'auto' }}>
                                                                                    <img src={require("../assets/radiobtn.png")} style={{ height: 20, width: 20, color: '#4e3e71' }} />
                                                                              </View> */}
                                                                        </View>
                                                                  </TouchableOpacity>

                                                            )}
                                                      />
                                                         <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around'}}>
                                                      {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71'}}>{"< Prev"}</Text></TouchableOpacity>}
                                                      {data.length < 12 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71'}}>{"Next >"}</Text></TouchableOpacity>}
                                                </View>
                                                </View>
                                             
                                                            <TouchableOpacity onPress={() => navigateTo()} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                                  <View style={styles.button}>
                                                                        <Text style={styles.buttonText}>Continue</Text>
                                                                  </View>
                                                            </TouchableOpacity>
                                                           

                                                </View>
                                          }
                                    </View>


                              </View>
                        </View>
                        </View>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ width: 550, height: 500 }}>
                              <InviteUser handleClose={handleClose} navigation={navigation} />
                        </View>

                  </Dialog>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 20,
            backgroundColor: '#4e3e71',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      topButton: { backgroundColor: '#4e3e71', fontSize: 16, color: '#ffff', paddingVertical: 5, paddingHorizontal: 10, borderRadius: 4, height: 35, width: 120 },
      button0: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 10,
            backgroundColor: '#aa9cc9',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText0: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      listItem: {
            width: 300,
            maxWidth: 300,
            height: 88,
            marginHorizontal: 26,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      listItem_active: {
            height: 88,
            width: 360,
            marginHorizontal: 16,
            borderColor: '#4e3e71',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 20,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      role: {
            color: '#b2bac6',
            paddingHorizontal: 11
      },
      names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11

      },

      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      filterIcon: {
            height: 48,
            width: 48,
            borderRadius: 24,
            padding: 8,
            borderWidth: 1,
            borderColor: '#4e3e71',
            marginVertical: 'auto',
            marginHorizontal: 'auto'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      picker: {
            height: 50,
            width: 160,
            color: 'white',
            paddingHorizontal: 15,
            backgroundColor: '#4e3e71',
            borderRadius: 50,
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            // width: 1100
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 10,
            margin: 10,
            flexDirection: 'column'

      }

})