import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getErrorMessage, putAxiosAPI } from "../../../framework/src/Blocks/AxiosAPIBlock";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  handleClose2: any;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDisplayModal: boolean;
  userData: any;
  loader: boolean;
  modalOpen: boolean;
  modalOpenMsg: any;
  loaderFor: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriesController extends BlockComponent<Props, S, SS> {
  apiContactPriorityId: any;
  apiSetPriorityId: any;
  apigetUserDetailId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      isDisplayModal: false,
      userData: null,
      loader: false,
      modalOpen: false,
      modalOpenMsg: "",
      loaderFor: {
        a: false,
        b: false,
      },
    };

    // Customizable Area Startg
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiContactPriorityId !== null &&
      this.apiContactPriorityId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiContactPriorityId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        if(this.state.loaderFor.a){
          this.setPriority()
        }
      } else {
        //Check Error Response
        let msg = getErrorMessage(responseJson.errors[0]);
        this.setState({
          modalOpen: true,
          modalOpenMsg: msg,
        });
        console.log(
          getErrorMessage(responseJson.errors[0]) + "errorr ----- 76"
        );
        this.setState({ loader: false });
        // this.handleClose2(false)
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apigetUserDetailId !== null &&
      this.apigetUserDetailId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apigetUserDetailId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getUserDetailSuccess(responseJson.data.attributes);
      } else {
        this.getUserDetailFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  handleClose2(arg0: boolean) {
    throw new Error("Method not implemented.");
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getUserDetail();
  }
  getUserDetail = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetUserDetailId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/account_detail"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getUserDetailSuccess = (data: any) => {
    this.setState({ userData: data });
  };

  getUserDetailFail = () => {};

  _handleConntactPriority = async (name: string) => {
    this.setState({
      loader: true,
      loaderFor:
        name === "primary" ? { a: true, b: false } : { a: false, b: true },
    });
    const header = {
      Accept: "application/form-data",
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const body =
      name === "primary"
        ? {
            hospital_id: localStorage.getItem('current_hospital_id'),
          }
        : {
            hospital_id: localStorage.getItem('current_hospital_id'),
            is_primary_contact: false,
          };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiContactPriorityId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/choose_contact_priority_for_hospital"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  setPriority = async () => {
    console.log("set primary ====> ")
    let url = "/account_block/accounts/update_account";
    let status:any = true
    let imageData = new FormData()
    imageData.append('is_primary_contact', status);
    await putAxiosAPI(url, imageData).then((res: any) => {
      if(res.error){
        console.log(res.data);
      } else {
        console.log(res.data);
        this.postContactPrioritySuccess();
      }
    });
  };

  postContactPrioritySuccess = () => {
    const role = localStorage.getItem("role");
    if (role === "4") {
      this.props.navigation.navigate("SalesSelectManufacture");
    } else {
      this.props.navigation.navigate("Dashboard");
    }
  };

  postContactPriorityFail = () => {};
  ClosePopUp = () => {
    this.setState({ modalOpen: false, loader:false, loaderFor: {a:false, b:false} });
  };
  // Customizable Area End
}
