import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    FlatList,
    TextInput
} from "react-native";

import RequestedMaterialController, {
    Props,
} from "./RequestedMaterialController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../assets'
import DesktopDesign from "./DesktopReqMaterial";

//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";
import { fontSize } from "@mui/system";
import { ListEmptyView } from "../../../../../framework/src/CustomPopUp";

class RequestedMaterial extends RequestedMaterialController {

    renderHaderContainer = () => {
        return (
            <View style={styles.renderHaderContainer}>
                <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
                <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                </TouchableOpacity>
                
                <Text style={styles.renderHaderTextContainer}>Requested Material</Text>
            </View>
        )
    }

    renderCellListContainer = (item: any) => {
        return (
            <View style={styles.renderCellListContainer}>
                {item.image === 'null' ?
                    <Image source={IMG_CONST.requestImg} style={styles.bitMapImg} /> :
                    <Image source={{ uri: item.image }} />
                }
                <Image source={item.image} style={styles.bitMapImg} />
                <Text style={styles.renderCellListTextContainer}>Material: {item.name}</Text>
                <TouchableOpacity onPress={() => this.props.navigation.navigate('VerifyMaterial', { materialId: item.id })} style={styles.paddingButton}>
                    <Text style={styles.paddingButtonText}>{item.status}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    renderFlatListConatiner = () => {
        return (
            <View style={styles.renderFlatListConatiner}>
                <FlatList
                    data={this.state.requestList}
                    ListEmptyComponent={ListEmptyView()}
                    renderItem={({ item }) => this.renderCellListContainer(item)}
                />
            </View>
        )
    }

    render() {
        return (
    
            <View style={styles.container}>
                {this.renderHaderContainer()}
                <Text style={styles.hadlineText}>Requested Material List</Text>
                {this.renderFlatListConatiner()}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },

    renderHaderContainer: {
        height: scale(72),
        backgroundColor: '#4e3e71',
        flexDirection: 'row',
        alignItems: 'center'
    },

    leftArrowImg: {
        marginLeft: scale(24),
        width: scale(24),
        height: scale(24),
    },

    renderHaderTextContainer: {
        color: "#e8fafe",
        marginLeft: scale(64),
        fontSize: scale(20),
    },

    hadlineText: {
        fontSize: scale(18),
        color: '#4e3e71',
        fontWeight: 'bold',
        lineHeight: scale(18),
        marginTop: scale(30),
        marginLeft: scale(24)
    },

    renderFlatListConatiner: {
        paddingLeft: scale(18),
        marginTop: verticalScale(33)
    },

    renderCellListContainer: {
        width: scale(340.1),
        borderRadius: scale(12),
        borderWidth: scale(1),
        borderColor: "#d0d0d0",
        paddingLeft: scale(18),
        paddingBottom: scale(12),
        paddingRight: scale(17.1),
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: verticalScale(13),
        marginTop: verticalScale(15)
    },

    bitMapImg: {
        width: scale(72),
        height: scale(72),
        borderRadius: scale(12),
        resizeMode: "contain",
        // backgroundColor: 'red'
    },

    renderCellListTextContainer: {
        color: '#4e3e71',
        fontSize: scale(17),
        lineHeight: scale(22)
    },

    paddingButton: {
        width: scale(72),
        height: scale(30),
        backgroundColor: '#4e3e71',
        justifyContent: 'center',
        alignItems: "center"
    },

    paddingButtonText: {
        color: '#ffffff',
        lineHeight: scale(22),
        fontSize: scale(17)
    }
})

export default RequestedMaterial;