import React,{ useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import AddIcon from '@material-ui/icons/Add';
import ListAltIcon from '@material-ui/icons/ListAlt';

export default function Footer({navigation}: {navigation: any}) {
      return (
            <View style={styles.container}>
            <TouchableOpacity onPress={() => navigation.navigate('ExBooking')}>
                  <View style={styles.list}>
                       <HomeOutlinedIcon 
                       style={{
                        color: '#fff',
                        fontSize: 30,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'
                        }}/>
                        <Text style={styles.text}>Dashboard</Text>
                  </View>
                  </TouchableOpacity>
                  <View style={styles.list}>
                  <ListOutlinedIcon 
                       style={{
                        color: '#fff',
                        fontSize: 30,
                        backgroundColor: '#486484', 
                        borderRadius: "50%", 
                        padding: "5px"
                        }}/>
                        <Text style={{color: '#486484'}}>All Bookings</Text>
                  </View>
                  <View style={styles.list}>
                  <AddIcon 
                       style={{
                        color: '#fff',
                        fontSize: 30,
                        backgroundColor: '#486484', 
                        borderRadius: "50%", 
                        padding: "5px"
                        }}/>
                        <Text style={{color: '#486484'}}>Add Material</Text>
                  </View>
                  <View style={styles.list}>
                  <ListAltIcon 
                       style={{
                        color: '#fff',
                        fontSize: 30,
                        backgroundColor: '#486484', 
                        borderRadius: "50%", 
                        padding: "5px"
                        }}/>
                        <Text style={{color: '#486484'}}>Catalouge</Text>
                  </View>
            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            padding: 10,
            shadowColor: "#000",
            shadowOffset: {
            width: 0,
            height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8

      },
      list:{
         flexDirection: 'column',
         alignItems: 'center'
      },
      text: {
            color: '#007af2',
            justifyContent: 'center'
      }
}
)