// Customizable Area Start
import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ageWithMonth, hideName } from "../../../../../framework/src/Utilities";
import scale from "../../../../../components/src/Scale";
import GlobalCss from "../../../../../framework/src/GlobalCss";

export default function SurgeryDetails({ navigation }: { navigation: any }) {
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const user = JSON.parse(localStorage.getItem("previous_booking") || "{}");
  const date = user.attributes.booking_datetime;
  var d = new Date(date);

  const chats = (id: any) => {
    const chatApiParams = {
      material_id: id,
      order_id: user.id
    };
    localStorage.setItem("chatApiParams", JSON.stringify(chatApiParams));
    navigation.navigate("SharedNotes");
  };
  const role_id: any = JSON.parse(localStorage.getItem("role") || "{}");
  console.log("====", user);

  const ShowBtn = () => {
    if (role_id === 2) {
      if (user.attributes.confirmed_by_material_manager) {
        return (
          <View style={styles.button1}>
            <Text style={styles.buttonText1}> Surgery Confirmed </Text>
          </View>
        )
      } else {
        return (
          <TouchableOpacity
            onPress={() => navigation.navigate("PatientMaterialList")}
            style={styles.marginTop}
          >
            <View style={styles.button1}>
              <Text style={styles.buttonText1}>Checkoff Material</Text>
            </View>
          </TouchableOpacity>
        )
      }
    } else if (role_id === 4) {
      if (user.attributes.confirmed_by_sales_rep) {
        return (
          <View style={styles.button1}>
            <Text style={styles.buttonText1}> Surgery Confirmed </Text>
          </View>
        )
      } else {
        return (
          <TouchableOpacity
            onPress={() => navigation.navigate("PatientMaterialList")}
            style={styles.marginTop}
          >
            <View style={styles.button1}>
              <Text style={styles.buttonText1}>Checkoff Material</Text>
            </View>
          </TouchableOpacity>
        )
      }
    } else {
      return null
    }
   
  }

   const BookingStatus = (item: any) => {
            if (item.attributes.confirmed_by_bc === true &&
            item.attributes.confirmed_by_material_manager === true &&
            item.attributes.confirmed_by_sales_rep === true) {
                  return "Confirmed"
            } else {
                  return "Pending"
            }
            }
  return (
    <View style={styles.container}>
      <View style={styles.headerWrapper}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.leftArrowIconWrapper}
        >
          <Image
            source={require("../../../assets/leftArrow.png")}
            style={styles.leftArrowIcon}
          />
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Surgery Details</Text>
      </View>
      <View style={GlobalCss.mobileSuregryCard}>
        <View style={styles.horizontalBorder}>
          <Text style={styles.hospitalnName}>Hospital Name:{" "}</Text>
          <Text style={styles.color}>
            {user &&
              user.attributes &&
              user.attributes.hospital &&
              user.attributes.hospital.data &&
              user.attributes.hospital.data.attributes &&
              user.attributes.hospital.data.attributes.name}
          </Text>
          <Text style={styles.newBtn}>{user.attributes.patient_type}</Text>
        </View>
        <View style={styles.profileDetails}>
          <Image
            source={user.attributes.patient_image ? user.attributes.patient_image : user.attributes.patient_gender === 'Male' ?  require("../../../assets/patient_male.png") :require("../../../assets/patient_female.png") } 
            style={styles.imgWrapper}
          />
          <View style={styles.userDetailWrapper}>
            <Text style={styles.patitentName}>
              Patient Name:{" "}
              <Text numberOfLines={2} style={styles.name}>
              {role_id == '4' ? hideName(user.attributes?.first_name, user.attributes?.last_name) : user.attributes?.first_name+" "+user.attributes?.last_name} {" "}
              </Text>{" "}
            </Text>
            <Text style={styles.ageTitle}>
              Age:{" "}
              <Text style={styles.age}>{ageWithMonth(user.attributes.patient_age)} </Text>{" "}
            </Text>
          </View>
        </View>
        <View style={styles.buttonsRow}>
          <TouchableOpacity>
            <View style={styles.button}>
              <Text style={styles.buttonText}>{d.toLocaleDateString()}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity>
            <View style={styles.button}>
              <Text style={styles.buttonText}>
                {d.toLocaleTimeString([], { timeStyle: "short" })}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity>
            <View style={styles.button}>
              <Text style={styles.buttonText}>
                {BookingStatus(user)}{" "}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View>
          <View style={styles.details}>
            <Text style={styles.doctorDetailText}>
              {role_id === 2 ? "Doctor Details" : "Manager Details"}
            </Text>
          </View>
          <View style={styles.detailsList1}>
            <Text style={styles.phoneNumberText}>
              1) Phone Number:
              <Text style={styles.emailText}>
                {role_id === 2
                  ? user &&
                    user.attributes &&
                    user.attributes.doctor &&
                    user.attributes.doctor.data &&
                    user.attributes.doctor.data.attributes &&
                    user.attributes.doctor.data.attributes.full_phone_number
                  : user &&
                    user.attributes &&
                    user.attributes.material_manager &&
                    user.attributes.material_manager.data &&
                    user.attributes.material_manager.data.attributes &&
                    user.attributes.material_manager.data.attributes
                      .full_phone_number}
              </Text>
            </Text>
            <Text style={styles.phoneNumberText}>
              2) Email:{" "}
              <Text style={styles.emailText}>
                {role_id === 2
                  ? user &&
                    user.attributes &&
                    user.attributes.doctor &&
                    user.attributes.doctor.data &&
                    user.attributes.doctor.data.attributes &&
                    user.attributes.doctor.data.attributes.email
                  : user &&
                    user.attributes &&
                    user.attributes.material_manager &&
                    user.attributes.material_manager.data &&
                    user.attributes.material_manager.data.attributes &&
                    user.attributes.material_manager.data.attributes.email}
              </Text>
            </Text>
          </View>
          <View style={styles.detailsList1}>
            {role_id === 2 && (
              <>
                <TouchableOpacity
                  onPress={() =>
                    checked1 ? setChecked1(false) : setChecked1(true)
                  }
                >
                  <View style={styles.cardWrapper}>
                    <Text style={styles.cardTitle}>
                      Surgery equipment(Facility)
                    </Text>
                    {checked1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.reassignMaterialWrapper}
                  onPress={() => navigation.navigate("ReassignMaterial")}
                >
                  <Text style={styles.reAssignMaterial}>Reassign Material</Text>
                </TouchableOpacity>
                <Collapse in={checked1}>
                  {user &&
                    user.attributes &&
                    user.attributes.ordered_materials &&
                    user.attributes.ordered_materials.data
                      .filter(
                        (x: any) =>
                          x.attributes.assign_to_type ===
                          "BxBlockHospital::Hospital"
                      )
                      .map((x: any, y: any) => {
                        return (
                          <View style={styles.drawerItem}>
                            <Text style={styles.detailIndex}>
                              {y + 1}){" "}
                              {x.attributes.materials.data.attributes.name}
                            </Text>
                            <TouchableOpacity
                              onPress={() => chats(x.attributes.material_id)}
                            >
                              <Image
                                source={require("../../../assets/messageIcon.png")}
                                style={styles.messageIcon}
                              />
                            </TouchableOpacity>
                            <Text style={styles.quntityText}>
                              {x.attributes.quantity}
                            </Text>
                          </View>
                        );
                      })}
                </Collapse>
              </>
            )}
            <TouchableOpacity
              onPress={() =>
                checked2 ? setChecked2(false) : setChecked2(true)
              }
            >
              <View style={styles.cardWrapper}>
                <Text style={styles.cardTitle}>
                  Surgery equipment(Salesrep)
                </Text>
                {checked2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </View>
            </TouchableOpacity>
            {role_id === 4 && (
              <TouchableOpacity
                style={styles.reassignMaterialWrapper}
                onPress={() => navigation.navigate("ReassignMaterial")}
              >
                <Text style={styles.reAssignMaterial}>Reassign Material</Text>
              </TouchableOpacity>
            )}
            <Collapse in={checked2}>
              {user &&
                user.attributes &&
                user.attributes.ordered_materials &&
                user.attributes.ordered_materials.data
                  .filter(
                    (x: any) =>
                      x.attributes.assign_to_type === "AccountBlock::Account"
                  )
                  .map((x: any, y: any) => {
                    return (
                      <View style={styles.drawerItem}>
                        <Text style={styles.detailIndex}>
                          {y + 1}) {x.attributes.materials.data.attributes.name}
                        </Text>
                        <TouchableOpacity
                          onPress={() => chats(x.attributes.material_id)}
                        >
                          <Image
                            source={require("../../../assets/messageIcon.png")}
                            style={styles.messageIcon}
                          />
                        </TouchableOpacity>
                        <Text style={styles.quntityText}>
                          {x.attributes.quantity}
                        </Text>
                      </View>
                    );
                  })}
            </Collapse>
           {new Date().getTime().toString() > user.attributes.booking_datetime ? null : 
             <View>{ShowBtn()}</View> } 
          </View>

      
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 15,
    backgroundColor: "#4e3e71",
    marginBottom: 20
  },
  newBtn: {
    backgroundColor: "#4e3e71",
    paddingHorizontal: 10,
    borderRadius: 12,
    color: "#fff",
    fontSize: 12,
    paddingTop: 5,
    marginLeft: 10,
    height: 30
  },
  button1: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
    marginTop: 10
  },
  buttonText1: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center"
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto"
  },
  topHeading: {
    paddingTop: 40,
    fontSize: 18,
    color: "#0778df",
    paddingVertical: 5
  },
  info: {
    color: "#7f7f7f",
    paddingVertical: 5,
    fontSize: 17
  },
  color: {
    color: "#4e3e71",
    fontSize: 17
  },
  drawers: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  drawerItem: {
    paddingHorizontal: 5,
    flexDirection: "row",
    marginVertical: 10,
    justifyContent: "space-between"
  },
  drawerItem2: {
    paddingVertical: 5,
    marginHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 2,
    borderBottomColor: "#d8d8d8"
  },
  textB: {
    fontSize: 20,
    fontWeight: "bold"
  },
  BottomInfo: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  material: {
    padding: 5,
    justifyContent: "space-between",
    flexDirection: "row",
    color: "#0778df"
  },
  detailsList1: {
    padding: 5,
    borderBottomColor: "#939ca3",
    borderBottomWidth: 1
  },
  detailsList2: {
    margin: 20,
    fontWeight: "bold",
    color: "#0778df",
    justifyContent: "space-between",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#939ca3"
  },
  detailsList3: {
    margin: 20,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  details: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 20,
    marginHorizontal: 10
  },
  //     color: {
  //       color: '#0778df',
  //     },
  buttonsRow: {
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20
  },
  button: {
    borderRadius: 18,
    paddingVertical: 5,
    paddingHorizontal: 15,
    marginHorizontal: 10,
    marginVertical: 5,
    backgroundColor: "#4e3e71"
  },
  buttonText: {
    color: "#cce9e5",
    fontSize: 15,
    textAlign: "center"
  },
  horizontalBorder: {
    borderBottomColor: "#c1c1c1",
    borderBottomWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 5,
    flexDirection: "row"
    // justifyContent: 'center',
  },
  profileDetails: {
    flexDirection: "row",
    margin: 16
  },
  card: {
    backgroundColor: "#f0f9f8",
    margin: 10
  },
  doctorDetailText: { fontWeight: "bold", color: "#4e3e71", fontSize: 20 },
  phoneNumberText: { padding: 5, fontSize: 16, color: "#7f7f7f" },
  emailText: { color: "#4e3e71" },
  cardWrapper: { flexDirection: "row", justifyContent: "space-between" },
  cardTitle: { fontWeight: "bold", padding: 5, color: "#4e3e71", fontSize: 20 },
  detailIndex: { color: "#7f7f7f", flex: 0.5, fontSize: 17 },
  quntityText: { color: "#7f7f7f", flex: 0.2, fontSize: 17, paddingLeft: 20 },
  reassignMaterialWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: "#979797",
    width: 140,
    marginVertical: 5,
    marginLeft: 5
  },
  reAssignMaterial: { color: "#4e3e71", fontSize: 16, fontWeight: "bold" },
  hospitalnName: { color: "#7f7f7f", fontSize: 17 },
  imgWrapper: { height: 92, width: 92 },
  userDetailWrapper: {
    marginHorizontal: 16,
    flexDirection: "column",
    marginVertical: "auto"
  },
  patitentName: { fontSize: 16, color: "#7f7f7f" },
  name: { fontSize: 20, color: "#4e3e71" , width: scale(280)},
  ageTitle: { fontSize: 16, color: "#7f7f7f" },
  age: { fontSize: 20, color: "#4e3e71" },
  messageIcon: { height: 15, width: 15 },
  marginTop: { marginVertical: 20 }
});
// Customizable Area End
