import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      TextInput,
      View,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "../../DesktopHeader";
import DesktopMenue from "../../DesktopMenue";
import scale, { verticalScale } from "../../../../../framework/src/Scale";
import { getAxiosAPI, getErrorMessage, postAxiosAPI } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../../../framework/src/Utilities";
import CustomPopUp from "../../../../../framework/src/CustomPopUp";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {

      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);
      const [loader, setLoader] = useState(false);
    
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)


      const handleClose = () => {
            // setOpen(false)
            setPopup(false)
            setMessage("")
      }


      const getSurgeons = async () => {
            setLoader(true)
            let url = `/bx_block_material_manager/material_manager/pending_surgeons`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors[0])
                    console.log(msg);
                    setPopup(true)
                    setMessage(msg)
                    setLoader(false)
                  } else {
                    setLoader(false)
                    setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes?.first_name?.toLowerCase(), b.attributes?.first_name?.toLowerCase())))
                  }
                })
      }

      useEffect(() => {
            getSurgeons();
      }, []);
      console.log(data)

      const acceptManager = async (id: any) => {
            console.log(id)
            let url = `/bx_block_material_manager/material_manager/accept_surgeon?id=${id}`;
            await postAxiosAPI(url, null).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(res.data);
                setPopup(true)
                setMessage(msg)
                setLoader(false)
              } else {
                setLoader(false)
                setPopup(true)
                setMessage("Surgeon Accepted Successfully")
                getSurgeons()
              }
            }); 
      }


      const handleSearch = (text: any) => {
            setQuery(text)
            if (text !== "") {
                  const newData = data.filter((name: any) => {
                        return Object.values(name.attributes)
                              .join(" ")
                              .toLowerCase()
                              .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
                  //console.log(newData);

            } else {
                  setsearchData(data);
            }
      };

      return (
            <View style={styles.desktopContainer}>
                  {popup && <CustomPopUp message={message} btnText="Back" ClosePopUp={handleClose}/>}
                  <DesktopHeader heading={'Doctor List'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Doctor List </Text>
                              <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Search "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../../../assets/search.png") : require("../../../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>

                                    </View>
                              </View>
                              {loader ? <ActivityIndicator
                                    style={{ margin: 'auto', height: 100, width: 100, }}
                                    size="large" color="#4e3e71" />
                                    :
                                          <View style={styles.card}>
                                                <View style={{ flex: 1, }}>
                                                      {
                                                            (query.length > 0 && searchData.length > 0) ?
                                                                  <View style={styles.add}>
                                                                        <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                                  </View>
                                                                  :
                                                                  (query.length > 0 && searchData.length === 0) ?
                                                                        <View style={styles.add}>
                                                                              <Text style={styles.resultText}>Searched Doctor not found</Text>
                                                                        </View> : null
                                                      }

                                                      <FlatList
                                                            style={{ height: 562 }}
                                                            data={query.length < 1 ? data : searchData}
                                                            keyExtractor={(item: any) => item.id}
                                                            horizontal={false}
                                                            numColumns={3}
                                                            renderItem={({ item }) => (
                                                                  <>
                                                                        <View style={styles.listItem}>
                                                                              <View style={{ flexDirection: 'row', paddingHorizontal: 12 }}>
                                                                                    <img src={require("../../../assets/hospital.png")} style={{ height: 56, width: 56 }} />
                                                                                    <Text style={styles.names}>{item.attributes.first_name} {item.attributes.last_name}</Text>
                                                                                    {/* <Text style={styles.topButton}>{item.attributes.facilities?.data[0].attributes?.hospital?.data?.attributes.name}</Text> */}
                                                                                    <Text style={styles.topButton}>Hospital name</Text>
                                                                              </View>

                                                                              <View style={{ borderTopColor: '#e5e5e5', marginTop: 10, padding: 12, borderTopWidth: 1 }}>
                                                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 12 }}>
                                                                                          <Text style={{ fontSize: 18, color: '#7f7f7f' }}>Phone Number</Text>
                                                                                          <Text style={{ fontSize: 18, color: '#4e3e71' }}>{item.attributes.full_phone_number}</Text>
                                                                                    </View>
                                                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 12 }}>
                                                                                          <Text style={{ fontSize: 18, color: '#7f7f7f' }}>Email ID:-</Text>
                                                                                          <Text style={{ fontSize: 18, color: '#4e3e71' }}>{item.attributes.email}</Text>
                                                                                    </View>
                                                                                    <View>

                                                                                          <TouchableOpacity style={styles.button0} onPress={() => acceptManager(item.id)}>
                                                                                                <Text style={styles.buttonText0}>Accept Doctor</Text>
                                                                                          </TouchableOpacity>
                                                                                          <TouchableOpacity style={styles.button}>
                                                                                                <Text style={styles.buttonText}>Validate with Facility Management</Text>
                                                                                          </TouchableOpacity>
                                                                                    </View>
                                                                              </View>
                                                                        </View>


                                                                  </>

                                                            )}
                                                      />
                                                </View>

                                          </View>
                              }
              
                        </View>
                        </View>
                  </View>
      
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      names: {
            color: '#4e3e71',
            fontSize: 18,
            fontWeight: 'bold',
            paddingHorizontal: 11,
            width: scale(70),

      },
      topButton: { backgroundColor: '#4e3e71', fontSize: 16, color: '#ffff', paddingVertical: 5, paddingHorizontal: 10, borderRadius: 4, height: 35, width: 120 },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column'

      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 10,
            backgroundColor: '#ffff',
            borderColor: '#4e3e71',
            borderWidth: 1,
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            width: '100%'
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      listItem: {
            // height: 88,
            maxWidth: 400,
            marginHorizontal: 20,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 4,
            marginVertical: 20,
            paddingVertical: 12,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between'
      },
      button0: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 10,
            backgroundColor: '#4e3e71',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText0: {
            color: '#fff',
            fontSize: 17,
            textAlign: 'center',
      },

})