import React, { useState } from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Drawer from '@material-ui/core/Drawer';
import Menu from "./Menu2";


export default function NewHeader({ navigation }: { navigation: any }) {
  const [state, setState] = useState<any>({
    left: false,
  });

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    // console.log("clicked");
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <View style={styles.header}>
      {['left'].map((anchor: any) => (
        <React.Fragment key={anchor}>
          <MenuIcon onClick={toggleDrawer(anchor, true)} />
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <Menu navigation={navigation} state={setState} />
          </Drawer>
        </React.Fragment>
      ))}
      <Text style={styles.headerText}>Dashboard </Text>
      <NotificationsIcon color="disabled" style={{ fontSize: 30 }} />
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingBottom: 20
  },
  headerText: {
    color: '#0579ed',
    fontSize: 20
  },
})