import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  TextInput,
  Button,
  Image,
  ScrollView,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Platform
} from "react-native";

//@ts-ignore
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from 'axios';
import { getUserIpAddress } from "../../../framework/src/Utilities";

const validation_Schema = yup.object({
  //fname: yup.string().required("Name is required").matches(/^[a-zA-Z ]+$/, "Only characters are allowed").min(3, "must be atleast 3 characters"),
  email: yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required")

})

export default class EmailAccountLoginBlock extends EmailAccountLoginController {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken')
    const activated = localStorage.getItem('activated')
    let IpAddrress = await getUserIpAddress()
    this.setState({userIP: IpAddrress})
    if (token && activated === 'true') {
      console.log("User Authenticated -----------------")
      this.props.navigation.navigate("Dashboard")
    } else {
      console.log("User Not Authenticated -----------------")
      this.role_name();
    }
  
  }

  

  render() {
    const { navigation } = this.props;

    return (
      // Required for all blocks
          window.screen.width < 950 ? 
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        {/* Required for all blocks */}
        <TouchableWithoutFeedback
          testID={"Background"}
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View style={styles.container}>

            <View style={styles.card}>
              <img src={require("../assets/illustration-register-copy@3x.png")} 
              style={{
                width: 216,
                height: 234,
                position: 'absolute',
                top: -165,
                left: 180,
                zIndex: 99
              }} />
    
                <View style={styles.upperText}>
                  <Text style={styles.labelTitle}>{this.labelTitle}</Text>
                  <Text style={styles.bottomText}>Insert your Email ID to Login</Text>
                </View>
         

              {/* <Text style={styles.titleWhySignUp}>{this.state.labelHeader}</Text> */}
              <Formik
                initialValues={{ email: '', password: '', }}
                validationSchema={validation_Schema}
                onSubmit={(values, actions) => {
                  this.setState({ email: values.email })
                  this.setState({ password: values.password })
                  this.doEmailLogIn()
                  console.log(values);
                }}
              >
                {
                  ({ errors, touched, handleChange, handleBlur, values, handleSubmit }) => (

                    <View>
                      <TextInput
                        testID="txtInputEmail" //Merge Engine::From BDS
                        style={styles.bgMobileInput} //UI Engine::From Sketch
                        placeholderTextColor="#b2bac6"
                        placeholder="Email" //UI Engine::From Sketch
                        onBlur={handleBlur('email')}
                        onChangeText={handleChange('email')}
                        value={values.email} //Merge Engine::From BDS - {...this.testIDProps}
                      />
                      {touched.email && errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                      <TextInput
                        testID="txtInputPassword" //Merge Engine::From BDS
                        style={styles.bgMobileInput} //UI Engine::From Sketch
                        placeholderTextColor="#b2bac6"
                        secureTextEntry={true}
                        onBlur={handleBlur('password')}
                        onChangeText={handleChange('password')}
                        value={values.password}
                        placeholder={this.state.placeHolderPassword} //UI Engine::From Sketch
                      //Merge Engine::From BDS - {...this.testIDProps}
                      />

                      {touched.password && errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}
                      <View style={styles.forgotpassText}>
                        <Text
                          testID={"btnForgotPassword"} //Merge Engine::From BDS
                          style={styles.forgotPassword} //UI Engine::From Sketch
                          onPress={() => navigation.navigate('ForgotPassword')}//Merge Engine::From BDS - {...this.testIDProps}
                        >
                          Forgot Password?
                        </Text>
                      </View>
                      <TouchableOpacity disabled={this.state.loader} onPress={() => handleSubmit()}>
                        <View style={this.state.loader ? styles._unactivebtn : styles.button}>
                          <Text style={styles.buttonText}>{this.state.btnTxtLogin}</Text>
                          {this.state.loader ? <ActivityIndicator style={styles.loaderMobile} size="large" color="#2e2442" /> : null }
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
              </Formik>

              <View style={styles.SignUp}>
                <Text style={styles.bottomText}>Don't have an account ? {'\n'}</Text>
                <Text style={styles.lowerTextcolor}
                  onPress={() => navigation.navigate('RolesPermissions2')}
                >Sign Up</Text>
              </View>

            </View>
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
        </ScrollView>
        :
          <View style={styles.desktopContainer}>
           <ImageBackground source={require('../assets/group.png')} resizeMode="cover" style={styles.image}>
            <View style={styles.upmedlogo}>

              <img src={require("../assets/upmed.png")} style={{ height: 56, width: 140, paddingBottom: 20 }} />
           
            <View style={styles.desktopCard}>
                <View style={styles.card2}>
              <View style={styles.upperText1}>
                  <Text style={styles.deskheading}>{this.labelTitle}</Text>
                  <Text style={styles.deskpara}>Insert your Email ID to Login</Text>
                </View>
         

              {/* <Text style={styles.titleWhySignUp}>{this.state.labelHeader}</Text> */}
              <Formik
                initialValues={{ email: '', password: '', }}
                validationSchema={validation_Schema}
                onSubmit={(values, actions) => {
                  this.setState({ email: values.email })
                  this.setState({ password: values.password })
                  this.doEmailLogIn()
                  console.log(values);
                }}
              >
                {
                  ({ errors, touched, handleChange, handleBlur, values, handleSubmit }) => (

                    <View>
                      <TextInput
                        testID="txtInputEmail" //Merge Engine::From BDS
                        style={styles.bgMobileInput} //UI Engine::From Sketch
                        placeholderTextColor="#b2bac6"
                        placeholder="Email" //UI Engine::From Sketch
                        onBlur={handleBlur('email')}
                        onChangeText={handleChange('email')}
                        value={values.email} //Merge Engine::From BDS - {...this.testIDProps}
                      />
                      {touched.email && errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                      <TextInput
                        testID="txtInputPassword" //Merge Engine::From BDS
                        style={styles.bgMobileInput} //UI Engine::From Sketch
                        placeholderTextColor="#b2bac6"
                        secureTextEntry={true}
                        onBlur={handleBlur('password')}
                        onChangeText={handleChange('password')}
                        value={values.password}
                        placeholder={this.state.placeHolderPassword} //UI Engine::From Sketch
                      //Merge Engine::From BDS - {...this.testIDProps}
                      />

                      {touched.password && errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}
                      <View style={styles.forgotpassText}>
                        <Text
                          testID={"btnForgotPassword"} //Merge Engine::From BDS
                          style={styles.forgotPassword} //UI Engine::From Sketch
                          onPress={() => navigation.navigate('ForgotPassword')}//Merge Engine::From BDS - {...this.testIDProps}
                        >
                          Forgot Password?
                        </Text>
                      </View>
                      <TouchableOpacity disabled={this.state.loader} onPress={() => handleSubmit()}>
                        <View style={this.state.loader ? styles._unactivebtn : styles.button}>
                          <Text style={styles.buttonText}>{this.state.btnTxtLogin}</Text>
                          {this.state.loader ? <ActivityIndicator style={styles.loader} size="large" color="#2e2442" /> : null }
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
              </Formik>

              <View style={styles.SignUp}>
                <Text style={styles.bottomText}>Don't have an account ? {'\n'}</Text>
                <Text style={styles.lowerTextcolor}
                    onPress={() => navigation.navigate('RolesPermissions2')}
                >Sign Up</Text>
              </View>

            </View>
            </View>
            </View>
            </ImageBackground>
        </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column-reverse',
    backgroundColor: "#4e3e71",
  },
  loader: {position: 'absolute', left: 175, bottom:6},
  loaderMobile: {position: 'absolute', left: 155, bottom:6},
  errorText:{ color: 'red' },
  upmedlogo: {
    margin: 'auto',
    alignItems: 'center'
  },
   image: {
    flex: 1,
    justifyContent: "center"
  },
  desktopContainer: {
    flex: 1,
  },
  desktopCard: {
    backgroundColor: '#e8fafe',
    margin: 'auto',
    borderRadius: 40,
    width: 450
  },
    deskheading: {
    color: '#4e3e71',
    fontSize: 32,
    paddingTop: 50,
    paddingHorizontal: 5,
  },
  deskpara: {
    color: '#4e3e71',
    fontSize: 15,
    marginVertical: 20,
  },
  card: {
    position: 'relative',
    borderTopRightRadius: 24,
    backgroundColor: '#e8fafe',
    paddingHorizontal: 24
  },
  card2: {
    backgroundColor: '#e8fafe',
    borderRadius: 40,
    marginHorizontal: 30
  },

  SignUp: {
    flexDirection: 'column',
    marginHorizontal: 'auto',
    paddingBottom: 50,
    paddingTop: 30
  },
  color: {
    fontWeight: 'bold',

  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  bgOtherLoginButton: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "center",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    color: "#6200EE",
    fontWeight: "bold"
  },

  bgMobileInput: {
    flex: 1,
    outlineStyle: 'none',
    marginVertical: 10,
    paddingVertical:15,
    paddingLeft: 10,
    fontSize: 18,
    color: '#112950',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5'
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    // includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },

  bgPasswordContainer: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 5,
    marginVertical: 5,
    padding: 10,
    // includeFontPadding: true,
    backgroundColor: 'rgb(239, 247, 255)',
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  },

  labelTitle: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 66,
    paddingBottom: 20
  },
  upperText: {
    paddingVertical: 20,
  },
  upperText1: {
    paddingVertical: 5,
  },
  bottomText: {
    color: '#281c41',
    justifyContent: 'center',
    fontSize: 15,

  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30, paddingHorizontal: 10 } : {},

  forgotPassword: {
    color: "#4e3e71",
    fontSize: 14,
    marginVertical: 5
  },
  forgotpassText: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  checkBoxContainerView: {
    flexDirection: "row",
    marginBottom: 10,
    marginLeft: -7,
    zIndex: -1
  },
  rememberMe: {
    color: "#6200EE",
    fontWeight: "bold",
    alignSelf: "center",
    zIndex: -1
  },
  orLabel: {
    color: "#00000000",
    fontWeight: "bold",
    alignSelf: "center",
    margin: 20
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: '#4e3e71',
    marginVertical: 11,
  },
  _unactivebtn: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: '#8e7bb7',
    marginVertical: 11,
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  lowerTextcolor: {
    fontWeight: 'bold',
    marginHorizontal: 'auto',
    color: 'rgb(75, 103, 134)',
  }
});
// Customizable Area End