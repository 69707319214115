// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      View,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import SharedNotes from "./SharedNotes";
import Dialog from '@material-ui/core/Dialog';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
import { getAxiosAPI,putAxiosAPI,  getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";



export default function DesktopSurgeryDetails({ navigation}: { navigation: any}) {
    
      const user = JSON.parse(localStorage.getItem('previous_booking') || '{}')
      const [materials, setMaterials] = useState([])

      const role = localStorage.getItem('role')


      const fetchOrders = async () => {
            let url = `/bx_block_order/orders/${user.id}`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors[0])
                    console.log(msg)
                  } else {
                        localStorage.setItem('previous_booking', JSON.stringify(res.data.data)); 
                        setMaterials(role === '4' ? res.data.data.attributes.ordered_materials.data.filter((x: any) => x.attributes.assign_to_type === "AccountBlock::Account").map((x: any, y: any) => { return x }) : res.data.data.attributes.ordered_materials.data.filter((x: any) => x.attributes.assign_to_type === "BxBlockHospital::Hospital").map((x: any, y: any) => { return x }))}
                })
      }
      
      useEffect(() => {
            fetchOrders();
      }, [])

  

      const date = user.attributes.booking_datetime
      var d = new Date(date);


      const [open, setOpen] = React.useState(false);
      const handleClose = () => {
            setOpen(false);
      };
    



      const chats = (id: any) => {
            console.log(id)
            const chatApiParams = {
                  material_id: id,
                  order_id: user.id
            }
            localStorage.setItem('chatApiParams', JSON.stringify(chatApiParams))
            // navigation.navigate('SharedNotes')
            setOpen(true);
      }

      const [open2, setOpen2] = useState(false)

      const openMaterialDetails = (item: any) => {

            setOpen2(true)
            localStorage.setItem('MaterialDialogDetails', JSON.stringify(item))
            console.log(item)
      }
      const MaterialDialogDetails: any = JSON.parse(localStorage.getItem("MaterialDialogDetails") || '{}')

      const handleDrawer = (item:any) => {
            let newdata: any = materials.map((x: any) => {

                  if (x.id === item.id) {
                        x.ischecked = !x.ischecked
                  } 
                  return x
            })
            setMaterials(newdata)
      }
      console.log(materials);
      

      const handleCheckBox = (e: any, item: any) => {
            let newdata: any = materials.map((x: any) => {

                  if (x.id === item.id) {
                        x.attributes.status = e.target.value
                        x.ischecked = true
                  } 
                  return x
            })
            setMaterials(newdata)
      }

      const callApi = async () => {
            const ordered_materials = materials.map((x: any) => {
                  return {
                        id: x.id,
                        availability_status: x.attributes.status
                  }
            })
            const postData = {
                  id: user.id,
                  ordered_materials: ordered_materials
            }
            console.log(postData)
            let url = "/bx_block_order/orders/check_off_materials_of_order";
            await putAxiosAPI(url, postData).then((res: any) => {
              if(res.error){
                console.log(res.data);
              } else {
                  console.log(res.data)
                  navigation.goBack()
              }
            });
      }


      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'Patient Material List'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold', paddingHorizontal: 15, paddingTop:10 }}>Patient Material List </Text>
                              <View style={styles.card}>
                                    <View style={{ flex: 0.2, borderRadius: 12, margin: 15, padding: 15, borderWidth: 1, borderColor: '#7f7f7f' }}>
                                          <img 
                                          src={user.attributes.patient_image ? user.attributes.patient_image : user.attributes.patient_gender === 'Male' ?  require("../assets/patient_male.png") :require("../assets/patient_female.png") }
                                          style={{ borderRadius: 12 }} />
                                          <View style={styles.infoRow}>
                                                <Text style={{ color: '#7f7f7f' }}>Hospital name:</Text>
                                                <Text style={{ color: '#4e3e71' }}>{user.attributes.hospital.data?.attributes.name}</Text>
                                          </View>
                                          <View style={styles.infoRow}>
                                                <Text style={{ color: '#7f7f7f' }}>Doctor name:</Text>
                                                <Text style={{ color: '#4e3e71' }}>{user.attributes.doctor.data.attributes.first_name + " " + user.attributes.doctor.data.attributes.last_name}</Text>
                                          </View>
                                          <View style={styles.infoRow}>
                                                <Text style={{ color: '#7f7f7f' }}>Patient name:</Text>
                                                <Text style={{ color: '#4e3e71' }}>{user.attributes.first_name+" "+user.attributes.last_name}</Text>
                                          </View>
                                         
                                          
                                    </View>
                                   
                                    <View style={{ flex: 0.8, borderRadius: 12, margin: 15, padding: 25, backgroundColor: '#e8fafe', flexDirection: 'row' }}>
                                          <View style={{ flex: 0.4, margin: 10, height: '100%' }}>

                                                <Text style={{ color: '#4e3e71', fontWeight: 'bold', fontSize: 16 }}>Surgery equipment({role === '4' ? 'Sales Rep' : 'Facility' }): </Text>

                                                {
                                                      materials.map((x: any, y: any) => {
                                                            return (
                                                                  <View style={styles.drawerItem}>
                                                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                                                                        <TouchableOpacity onPress={() => openMaterialDetails(x)}>
                                                                              <Text style={{ color: '#7f7f7f',  fontSize: 17 }}>{y + 1}) Material {y + 1}</Text>
                                                                        </TouchableOpacity>
                                                                              <View style={{ backgroundColor: x.attributes.status === 'A' ? '#288600' : x.attributes.status === 'TBH' ? 'blue' : 'red', height: 12, width: 12, borderRadius: 6, marginVertical: 'auto', marginHorizontal: 10 }}></View>
                                                                        <TouchableOpacity  onPress={() => chats(x.attributes.material_id)}>
                                                                              <img src={x.attributes.flagged ? require("../assets/chatIconRed.png") : require("../assets/chatIcon.png")} style={{ height: 15, width: 15, paddingTop: 5 }} />
                                                                              </TouchableOpacity>
                                                                        <TouchableOpacity  onPress={() => handleDrawer(x)}>
                                                                                    {x.ischecked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }    
                                                                        </TouchableOpacity>
                                                                        </View>
                                                                        <Collapse in={x.ischecked}>
                                                                              <View style={styles.renderbuttomContainer}>
                                                                                    <RadioGroup
                                                                                          defaultValue=""
                                                                                          aria-label="gender"
                                                                                          name="customized-radios"
                                                                                          row
                                                                                    >
                                                                                          <FormControlLabel
                                                                                                value="A"
                                                                                                control={
                                                                                                      <Checkbox
                                                                                                            style={{ color: "#000" }}
                                                                                                            checked={x.attributes.status === "A" ? true : false}
                                                                                                            onChange={event => handleCheckBox(event, x)}
                                                                                                      />
                                                                                                }
                                                                                                label="A"
                                                                                                style={{ fontSize: 16, color: "#7f7f7f" }}
                                                                                          />
                                                                                          <FormControlLabel
                                                                                                value="TBH"
                                                                                                control={
                                                                                                      <Checkbox
                                                                                                            style={{ color: "#000" }}
                                                                                                            checked={x.attributes.status === "TBH" ? true : false}
                                                                                                            onChange={event => handleCheckBox(event, x)}
                                                                                                      />
                                                                                                }
                                                                                                label="TBH"
                                                                                                style={{ fontSize: 16, color: "#7f7f7f" }}
                                                                                          />
                                                                                          <FormControlLabel
                                                                                                value="NA"
                                                                                                control={
                                                                                                      <Checkbox
                                                                                                            style={{ color: "#000" }}
                                                                                                            checked={x.attributes.status === "NA" || x.attributes.status === null ? true : false}
                                                                                                            onChange={event => handleCheckBox(event, x)}
                                                                                                      />
                                                                                                }
                                                                                                label="NA"
                                                                                                style={{ fontSize: 16, color: "#7f7f7f" }}
                                                                                          />
                                                                                    </RadioGroup>
                                                                              </View>
                                                                        </Collapse>
             

                                                                  </View>
                                                            )
                                                      })
                                                }

                                          </View>


                                    </View>


                              </View>

                              <View style={{ margin: 15, flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <TouchableOpacity onPress={() => navigation.goBack()}>
                                          <View style={styles.button2}>
                                                <Text style={styles.buttonText2}>Back</Text>
                                          </View>
                                    </TouchableOpacity>      
                                    <TouchableOpacity onPress={() => callApi()}>
                                                <View style={styles.button1}>
                                                      <Text style={styles.buttonText1}>Confirm Material</Text>
                                                </View>
                                    </TouchableOpacity>
                                    
                              </View>
                        </View>
                        </View>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ width: 500, height: 800 }}>
                              <SharedNotes navigation={navigation} />
                        </View>

                  </Dialog>
                 
                  <Dialog
                        open={open2}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View style={{ marginBottom: 10 }}>
                              <View style={{ margin: 20, height: 200, width: 350 }}>
                                    <Text style={{ fontWeight: 'bold', paddingVertical: 15, paddingHorizontal: 'auto', color: '#4e3e71', fontSize: 20, borderBottomColor: 'grey', borderBottomWidth: 1 }}>{MaterialDialogDetails?.attributes?.materials?.data?.attributes?.name} </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                          <Text style={{ color: "#7f7f7f", fontSize: 17 }} >Status:</Text>
                                          <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.attributes?.status === "A" ? "Available" : "Not Available"}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                          <Text style={{ color: "#7f7f7f", fontSize: 17 }}>Size:</Text>
                                          <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.attributes?.size}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                          <Text style={{ color: "#7f7f7f", fontSize: 17 }}>Quantity: </Text>
                                          <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.attributes?.quantity} Pieces</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 }}>
                                          <Text style={{ color: "#7f7f7f", fontSize: 17 }}>Assigned To:</Text>
                                          <Text style={{ color: "#4e3e71", fontSize: 17 }}>{MaterialDialogDetails?.attributes?.assign_to_type === "BxBlockHospital::Hospital" ? "Facility" : "Sales Rep"}</Text>
                                    </View>


                              </View>
                              <TouchableOpacity onPress={() => chats(MaterialDialogDetails?.attributes?.material_id)}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Chat</Text>
                                    </View>
                              </TouchableOpacity>
                              <TouchableOpacity onPress={() => setOpen2(false)}>
                                    <View style={styles.button1}>
                                          <Text style={styles.buttonText1}>Close</Text>
                                    </View>
                              </TouchableOpacity>
                        </View>

                  </Dialog>
            </View>
      )
}


const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      desktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      drawerItem: {
            paddingHorizontal: 5,
            flexDirection: 'column',
            marginVertical: 10,
            justifyContent: 'space-between'

      },
      renderbuttomContainer: {
            flexDirection: "row",
            marginHorizontal: 30,
            marginVertical: 5
            // marginTop: verticalScale(19)
      },
      detailsList3: {
            padding: 20,
            flexDirection: 'row',
            marginRight: 50,
            justifyContent: 'space-between'

      },
      button1: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            backgroundColor: '#4e3e71',
            marginHorizontal: 20,
            marginVertical: 5
      },
      buttonText1: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      button2: {
            borderRadius: 12,
            paddingVertical: 12,
            paddingHorizontal: 60,
            borderColor: '#4e3e71',
            borderWidth: 1,
            backgroundColor: '#fff',
      },
      buttonText2: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'

      },
      infoRow: { flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10 },
      button: {
            borderRadius: 18,
            paddingVertical: 5,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: '#4e3e71',
      },
      buttonText: {
            color: '#cce9e5',
            fontSize: 15,
            textAlign: 'center',
      },
      card: {
            flex: 1,
            flexDirection: 'row',
      },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            // padding: 15,
            margin: 15,
            flexDirection: 'column'

      }
})
// Customizable Area End
