import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, StyleSheet, View } from "react-native";
import { asyncLocalStorage } from "../../../framework/src/Utilities"
import axios from "axios"
let config = require('../../../framework/src/config')

export default function OtpTimer() {
      const [counter, setCounter] = useState<any>(localStorage.getItem("timer") ? localStorage.getItem("timer") : 60);

      const isTimer = localStorage.getItem('isTimer')
      //console.log(isTimer)
   

      setTimeout(function(){
            if( !isTimer && counter >= 0) {
                  setCounter(counter-1)
                  asyncLocalStorage.setItem("timer", counter)
            } else{
                  let time: any = true
                  localStorage.removeItem('timer')
                  localStorage.setItem("isTimer", time)
            }
          
      },1000)

      const resendOtpAPI = () => {
            localStorage.removeItem("isTimer")
            setCounter(60)
            const mail = localStorage.getItem('signup_mail')
            //axios.defaults.headers.token = await asyncLocalStorage.getItem('authToken');
            axios.post(`${config.baseURL}/account_block/otp_resends?email=${mail}`)  
              .then((response) => {
                console.log(response.data);
                localStorage.setItem('authToken',response.data.email_otp.meta.token)
            
              }, (error) => {
                console.log(error)
                 
              })
              .catch(function (error) {
                console.log(error)
                 ;
              })

      }

    
 
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
      <View>
<Text style={{color: 'rgb(72, 100, 132)', paddingTop: 20, fontSize: 18 }}>Did not receive the code?</Text>
    { counter > 0 && !isTimer? 
      <View>
    <Text style={styles.resendCode_unActive}>Resend Code</Text>   
    </View> :
    <TouchableOpacity onPress={() => resendOtpAPI()} disabled={false}>
    <Text style={styles.resendCode}>Resend Code</Text>   
    </TouchableOpacity>
    }
    </View>
    <View>
    { counter > 0 && !isTimer ?
          <Text style={{ fontSize: 16, color: '#112950', paddingTop: 22}}>{counter < 10 ? "00:0"+counter : "00:"+counter}</Text>
           : null
           }
    </View>
          
         
    </View>
  )
}


const styles = StyleSheet.create({
      resendCode:{
            color: "#4e3e71",
            paddingVertical:5,
            fontWeight: 'bold',
          },
          resendCode_unActive:{
            color: "#767676",
            paddingVertical:5,
            fontWeight: 'bold',
          }
})