import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    TextInput
} from "react-native";

import OutOdOfficeController, {
    Props,
} from "./OutOdOfficeController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../assets';
import DesktopDesign from "./DesktopOutOfOffice";
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardTimePicker,
  TimePicker,
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import { Formik} from "formik";
import * as yup from "yup";
import GlobalCss from "../../../../../framework/src/GlobalCss";

export const validation_Schema = yup.object({
    email: yup.string()
    .email("Please enter a valid Email")
    .required("Email is required"),
})

class OutOfOffice extends OutOdOfficeController {

    renderHaderContainer = () => {
        return (
            <View style={styles.renderHaderContainer}>
                <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
                    <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                </TouchableOpacity>
                <Text style={styles.renderHaderTextContainer}>Out of Office</Text>
            </View>
        )
    }

    renderEnderDetailsContainer = () => {
        const { startDate, startTime, endDate, endTime } = this.state

        console.log(Date.parse(startDate.toLocaleDateString() + " " + startTime.toLocaleTimeString()));
        
        return (
            <View style={styles.renderEnderDetailsContainer}>
                <Text style={styles.startText}>Start Date & Time</Text>
                <View style={styles.textInputContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                // disableFuture={true}
                                disablePast={true}
                                style={{marginLeft: 10}}
                                openTo="date"
                                format="MM/dd/yyyy"
                                // label="Date of Birth"
                                views={["year", "month", "date"]}
                                value={startDate}
                                onChange={(date: any) => this.setState({startDate: date})}
                              />
                              </MuiPickersUtilsProvider>

                    <View style={styles.timeTextContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker 
                            autoOk 
                            value={startTime}
                            onChange={(time: any) => this.setState({ startTime: time})} />
                        </MuiPickersUtilsProvider>

    
                    </View>
                </View>
                <Text style={styles.endText}>End Date & Time</Text>
                <View style={styles.textInputContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                // disableFuture={true}
                                disablePast={true}
                                style={{marginLeft: 10}}
                                openTo="date"
                                format="MM/dd/yyyy"
                                // label="Date of Birth"
                                views={["year", "month", "date"]}
                                value={endDate}
                                onChange={(date: any) => this.setState({endDate: date})}
                              />
                              </MuiPickersUtilsProvider>

                    <View style={styles.timeTextContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker 
                            autoOk 
                            value={endTime}
                            onChange={(time: any) => this.setState({ endTime: time})} />
                        </MuiPickersUtilsProvider>
    
                    </View>
                </View>
                <Text style={styles.enterText}>Enter Substitute Sales Rep</Text>
                <Formik
              initialValues={{email: ''}}
              validationSchema={validation_Schema}
                onSubmit={(values, actions) => {
                    console.log(values)
                    let d1 = Date.parse(startDate.toLocaleDateString() + " " + startTime.toLocaleTimeString())
                    let d2 = Date.parse(endDate.toLocaleDateString() + " " + endTime.toLocaleTimeString())
                    this.submitDates(d1, d2, values.email)
                }}
                >
                  {
                    ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                    <View>
                <View style={styles.textInputContainer}>
                    <TextInput placeholder="Enter Email Address" style={styles.textInput}
                        onBlur={handleBlur('email')}
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('email')}
                        value={values.email}
                    />
                </View>
                {touched.email && errors.email ?   <Text style={styles.errorMsg}>{errors.email}</Text> : null}

                <TouchableOpacity style={GlobalCss.buttonView} onPress={() => handleSubmit()}>
                <Text style={GlobalCss.buttonText}>
                    Assign to sales rep
                </Text>
                </TouchableOpacity>
                </View>  
        )}</Formik>
            </View>
        )
    }



    render() {
        return (
            window.screen.width < 950 ? 
            <View style={styles.container}>
                {this.renderHaderContainer()}
                {this.renderEnderDetailsContainer()}
   
                </View> :
                <View>
                    <DesktopDesign navigation={this.props.navigation}/>
                </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,

    },
    errorMsg:{ paddingVertical: 5,paddingHorizontal: 10,  color: 'red'},
    renderHaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: "#4e3e71",
        height: scale(72),
        paddingLeft: scale(24)
    },

    leftArrowImg: {
        width: scale(24),
        height: scale(24),
        resizeMode: 'contain'
    },

    renderHaderTextContainer: {
        color: "#e8fafe",
        fontSize: scale(20),
        marginLeft: scale(95)
    },

    renderEnderDetailsContainer: {
        marginTop: verticalScale(43),
        // paddingLeft: scale(30)
        marginHorizontal: 20
    },

    startText: {
        color: '#4e3e71',
        fontSize: scale(20),
        lineHeight: scale(22),
        fontWeight: 'bold'
    },

    textInput: {
        color: '#4e3e71',
        fontSize: scale(17),
        lineHeight: scale(22),
        height: 50,
        paddingLeft: 10,
        borderBottomColor: '#b2bac6',
        borderBottomWidth: 1,
    },

    endText: {
        color: '#4e3e71',
        fontSize: scale(20),
        lineHeight: scale(22),
        fontWeight: 'bold',
        marginTop: verticalScale(31)
    },

    enterText: {
        color: "#4e3e71",
        fontSize: scale(20),
        lineHeight: scale(22),
        fontWeight: 'bold',
        marginTop: verticalScale(31)
    },

    lineView: {
        width: scale(345),
        height: scale(1),
        backgroundColor: '#e5e5e5',
        marginTop: verticalScale(15)
    },

    textInputContainer: {
        marginTop: verticalScale(20),
        // marginHorizontal: 24,

    },

    timeTextContainer: {
        marginTop: verticalScale(20)
    },

    renderButtonContainer: {
        width: scale(360),
        height: scale(54),
        backgroundColor: '#4e3e71',
        borderRadius: scale(12),
        justifyContent: 'center',
        alignItems: 'center',
        bottom: scale(0),
        position: 'absolute',
        alignSelf: 'center',
        marginBottom: verticalScale(24)
    },

    renderButtonTextContainer: {
        color: '#e8fafe',
        fontSize: scale(17),
        lineHeight: scale(22)
    }


})

export default OutOfOffice;