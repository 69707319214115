import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../../framework/src/IBlock";
import { getErrorMessage } from "../../../../../framework/src/Blocks/AxiosAPIBlock";


export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  // Customizable Area Start
  // Customizable Area End
  data: any;
  show: any;
  isFetching: any;
  startDate: any;
  startTime: any;
  email: any;
  endDate: any;
  endTime: any;
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SocialMediaAccountMobileController
  extends BlockComponent<Props, S, SS>{
  createAccountAPICallId: any;
  googleUser: any;
  getGlobalCallID: any;
  submitDatesApiID: any;
  constructor(props: Props) {
    super(props);
    // this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
      // Customizable Area End
    ]);

    // Customizable Area Start
    this.state = {
      loading: false,
      isRegistration: true,
      data: [
        {
          id: 1,
          //  image:require('../assets/hospitalImg.png'),
          text: "Heart Materials",
          text1: '1) Operating Scissors',
          text2: '2) Operating Scissors'
        },
        {
          id: 2,
          //  image:require('../assets/hospitalImg.png'),
          text: "Heart Materials",
          text1: '1) Operating Scissors',
          text2: '2) Operating Scissors'
        }
      ],
      show: false,
      isFetching: false,
      startDate: new Date(),
      startTime: new Date(),
      email: '',
      endDate: new Date(),
      endTime: new Date(),
    };
    // Customizable Area End
  }

  async componentDidMount() {


  }


  async receive(from: string, message: Message) {
    console.log("@@@ API MESSAGE Badge   =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        this.showAlert('Error', 'Internal Server Error')
      }

      if (responseJson ) {
        if (apiRequestCallId === this.submitDatesApiID) {
          if(responseJson.errors){
            // console.log(responseJson)
            let msg = getErrorMessage(responseJson.errors[0])
            this.showAlert("Error",msg)
          }else {
            this.showAlert("success", `Assigned to ${this.state.email} successfully`)
            // console.log(responseJson)
          }
        }
      } 
    }
  }

  getBrandListApiSuccesCallBack = (responseJson: any) => {
    console.log('@@@ Get Brand List==============', responseJson);

  }

  getBrandListApiFailureCallBack = (responseJson: any) => {
    console.log('@@@ apiu Response==============', responseJson)

  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    ) 
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  submitDates = async (start:any, end:any, email: any) => {
    let data = {
      out_office:{
        start_date: start,
        end_date: end
      }
    }
    console.log(data)

    const header = {
      "Content-Type": "application/json",
      'token' : localStorage.getItem('authToken')
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitDatesApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_materials/out_office?email=${email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}
