import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
// @ts-ignore
import bg from "../assets/group.png";
import TermsConditionController, { Props } from "./Terms&ConditionsController";

export default class TermsCondition extends TermsConditionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getTerms();
  }

  render() {
    return window.screen.width < 950 ? (
      <View style={styles.container}>
        <View style={styles.headingView}>
          <TouchableOpacity
            style={styles.leftArrowIconWrapper}
            onPress={() => this.props.navigation.goBack()}
          >
            <img
              src={require("../assets/leftArrow1.png")}
              style={{
                height: 24,
                width: 24,
              }}
            />
          </TouchableOpacity>
          <Text style={styles.heading}>Terms And Conditions</Text>
        </View>
        {this.state.loader ? (
          <ActivityIndicator
            style={{ margin: "auto", height: 350, width: 100 }}
            size="large"
            color="#4e3e71"
          />
        ) : (
          <ScrollView>
            <Text
              style={{
                color: "#4e3e71",
                fontSize: 18,
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}
            >
              {this.state.terms}
            </Text>
          </ScrollView>
        )}

        <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
          <View style={styles.button}>
            <Text style={styles.buttonText}>Back</Text>
          </View>
        </TouchableOpacity>
      </View>
    ) : (
      <View style={styles.desktopContainer}>
        <View style={styles.cardWeb}>
          <Text style={styles.headingweb}>Terms & Conditions</Text>
          {this.state.loader ? (
            <ActivityIndicator
              style={{ margin: "auto", height: 350, width: 100 }}
              size="large"
              color="#4e3e71"
            />
          ) : (
            <>
              <ScrollView style={{ height: "400px", paddingVertical: 20 }}>
                <Text style={styles.content}>{this.state.terms}</Text>
              </ScrollView>
              <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
                <View style={styles.button}>
                  <Text style={styles.buttonText}>Back</Text>
                </View>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardWeb: {
    margin: "auto",
    backgroundColor: "#e8fafe",
    borderRadius: 40,
    padding: 30,
    width: 450,
  },
  desktopContainer: {
    flex: 1,
    height: "100%",
    backgroundImage: `url(${bg})`,
  },
  container: {
    flex: 1,
    backgroundColor: "#ffff",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 20,
    marginHorizontal: "auto",
  },
  headingweb: {
    color: "#4e3e71",
    fontSize: 20,
    marginHorizontal: "auto",
    fontWeight: "600",
    paddingBottom: 10,
  },
  content: {
    color: "#4e3e71",
    fontSize: 18,
    marginHorizontal: "auto",
  },
  text: {
    flexDirection: "row",
    justifyContent: "center",
    padding: 50,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
});
