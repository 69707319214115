import React, {useEffect, useState} from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  ActivityIndicator
} from "react-native";
import { Formik, Form, Field} from "formik";
import * as yup from "yup";
import GlobalCss from "../../../../../framework/src/GlobalCss";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Collapse from '@material-ui/core/Collapse';
import { getErrorMessage, postAxiosAPI } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import CustomPopUp from "../../../../../framework/src/CustomPopUp";


const validation_Schema = yup.object({ 
    name:yup.string().required("Name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
    address:yup.string().required("Address is required"),
    zip:yup.string().required("Zip is required").matches(/[0-9]/, "Only digits are allowed"),
})

export default function DesktopSurgeryDetails({ navigation, handleClose }: { navigation: any, handleClose: any}) {

    const [patientImage, setPatientImage] = useState<any>(null);
    const [imageDemo , setImageDemo] = useState<any>(null);
    const [Diagnosisfields, setDiagnosisFields] = useState([{ id: new Date().getTime().toString(), name: '',isOpen: true }]);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [popup, setPopup] = useState(false)


    const onImageChange = (event :any) => {
        setPatientImage({image: event.target.files[0], name: event.target.files[0].name, type: event.target.files[0].type})
        setImageDemo(URL.createObjectURL(event.target.files[0]))
        }

    function handleAdd() {
        const values = [...Diagnosisfields];
        values.push({id: new Date().getTime().toString(), name: '',isOpen: false });
        setDiagnosisFields(values);
        }

        const handleCollapsed = (field :any) => { 
            let newdata: any = Diagnosisfields.map((x: any) => {
            if(x.id === field.id ) {
              x.isOpen = !x.isOpen
            } else {
              x.isOpen = false
            }
            return x
          })
          setDiagnosisFields(newdata)
          }

          function handleChangeDiagnosis(i:any, event:any) {
            const values = [...Diagnosisfields];
            values[i].name = event.target.value;
            setDiagnosisFields(values);
            // console.log(event.target.value);
            // console.log(i);
          }
        
          // console.log(Diagnosisfields)

      const createBrand = async (values :any) => {
        setSubmitLoader(true)
        console.log(patientImage)
        let url = "/bx_block_catalogue/brands";
        var data = new FormData();
        if(patientImage){
          data.append("brand[name]", values.name);
          data.append("brand[address]", values.address);
          data.append("brand[zip]", values.zip);
          data.append("brand[logo]",  patientImage ? patientImage.image : null, patientImage.name ? patientImage.name : null);
          Diagnosisfields.map((x:any) => {
            data.append("brand[categories_attributes][][name]", x.name)
          })
        } else {
          data.append("brand[name]", values.name);
          data.append("brand[address]", values.address);
          data.append("brand[zip]", values.zip);
          Diagnosisfields.map((x:any) => {
            data.append("brand[categories_attributes][][name]", x.name)
          })
        }
        await postAxiosAPI(url, data).then((res:any) => {
          if(res.error){
            console.log(res.data)
            setSubmitLoader(false)
            setDiagnosisFields([{ id: new Date().getTime().toString(), name: '',isOpen: true }])
            setPatientImage(null)
            setImageDemo(null)
            let msg = getErrorMessage(res.data.errors[0], true)
            setMessage(msg)
            setPopup(true)
          } else {
            console.log(res.data)
            setSubmitLoader(false)
            setDiagnosisFields([{ id: new Date().getTime().toString(), name: '',isOpen: true }])
            setPatientImage(null)
            setImageDemo(null)
            setMessage("Manufacturer created successfully")
            setPopup(true)
            if(window.screen.width < 950) {
              setTimeout(() => {navigation.goBack()},2000)
            }
          }
        })
      }

      const ClosePopUp = () => {
        if(window.screen.width < 950) {
          setPopup(false)
        } else {
          handleClose()
        }

      }

    return (
        <View>
            { window.screen.width < 950 ? <View style={styles.headingView}>
          <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
            <img src={require("../../../assets/leftArrow1.png")} style={{
              height: 24, width: 24,
            }} />
          </TouchableOpacity>
    <Text style={styles.heading}>Create Manufacturer</Text>
      </View> : null }
            <View style={styles.card}>
         {popup && <CustomPopUp message={message} btnText="back" ClosePopUp={() => ClosePopUp()}/>}
                <Text style={styles.headingH1}>Manufacturer Details</Text>
                <Formik
                initialValues={{ 
                    name:'',
                    address:'',
                    zip:'',
                }}
                    validationSchema={validation_Schema}
                    onSubmit={(values :any, actions : any) => {  
                        console.log(values)
                        createBrand(values)
                    }}
                    >
                  {
                  ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                <View style={styles.formikData}>
                    <TextInput 
                         placeholder="Manufacturer Name"
                         placeholderTextColor="#b2bac6"
                         style={styles.input}
                         onBlur={handleBlur('name')}
                         onChangeText={handleChange('name')}
                         value={values.name}
                    />
                    {touched.name && errors.name ?   <Text style={GlobalCss.errorMsg}>{errors.name}</Text> : null}
                    <TextInput 
                         placeholder="Address"
                         placeholderTextColor="#b2bac6"
                         style={styles.input}
                         onBlur={handleBlur('address')}
                         onChangeText={handleChange('address')}
                         value={values.address}
                    />
                    {touched.address && errors.address ?   <Text style={GlobalCss.errorMsg}>{errors.address}</Text> : null}
                    <TextInput 
                         placeholder="ZIP"
                         placeholderTextColor="#b2bac6"
                         style={styles.input}
                         onBlur={handleBlur('zip')}
                         onChangeText={handleChange('zip')}
                         value={values.zip}
                    />
                    {touched.zip && errors.zip ?   <Text style={GlobalCss.errorMsg}>{errors.zip}</Text> : null}
                        <View style={styles.patientImage}>
                                {
                                        imageDemo ? 
                                        <img src={imageDemo} alt="preview image" style={{width: 80, height: 80, paddingTop: 10, borderRadius: '16px', }}/> :
                                        <img src={require("../../../assets/manifac_img.png")} style={{height: 80, width: 80, paddingTop: 10, borderRadius: '16px' }} />
                                }
                         <label htmlFor="file">
                            <View style={styles.editIcon}>
                                    <TouchableOpacity style={styles.buttonNew}>
                                    <Text style={styles.buttonTextNew}>Upload Logo</Text>
                                    </TouchableOpacity>
                            </View>
                            </label>
                        <input 
                        type='file' 
                        id="file" 
                        style={{display:'none'}} 
                        name="patient_image"   
                        accept="image/*"
                        onChange={onImageChange}
                        />
                         </View>
                         {
                                   Diagnosisfields.map((field, idx) => {
                                        return (
                                              <View>
                                          <TouchableOpacity onPress={() => handleCollapsed(field)}>
                                          <View style={styles.drawers}>
                                                <Text style={styles.headingH2}>Add Category {idx+1}</Text>
                                                {field.isOpen ? <ArrowDropUpIcon style={{color : '#4e3e71', paddingTop: 5 }}/> : <ArrowDropDownIcon style={{color : '#4e3e71', paddingTop: 5 }}/> }
                                          </View>
                                          </TouchableOpacity>
                                             
                                          <Collapse in={field.isOpen}>
                                              <View key={`${field}-${idx}`} style={{marginHorizontal: 10, }}>
                              <TextInput 
                              placeholder="Category Name"
                              placeholderTextColor="#b2bac6"
                              style={styles.input}
                              value={field.name}
                              onChange={e => handleChangeDiagnosis(idx, e)}
                              />
                            </View>
                            </Collapse>
                            </View>
                            )})}
                              <TouchableOpacity style={{width :240}} onPress={() => handleAdd()}>        
                              <View style={styles.buttonNew2}>
                              <Text style={styles.buttonTextNew}> Add More Categories</Text>
                              </View>
                        </TouchableOpacity>
                        <View style={{ marginTop:   window.screen.width < 950 ? 200 : 0}}>
                    <TouchableOpacity disabled={submitLoader} onPress={() => handleSubmit()} style={submitLoader ? GlobalCss.buttonViewDisable : GlobalCss.buttonView}>
                    <Text style={GlobalCss.buttonText}>Create</Text>
                    {submitLoader && <ActivityIndicator style={{position: 'absolute', bottom: 8,left: window.screen.width < 950 ? 140 : 189}} size="large" color="#2e2442" />}
                </TouchableOpacity>
                </View>
                    </View>
                    )} 
              </Formik>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    card: {
        margin: 24
    },
    leftArrowIconWrapper: {
        width: 24,
        height: 24,
        position: "absolute",
        left: 24
      },
    headingView: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 27,
        backgroundColor: '#4e3e71',
        marginBottom: 20,
    
      },
      heading:{
        color: '#e8fafe',
        fontSize: 20,
        marginHorizontal: 'auto'       
      },
    drawers: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        paddingVertical: 10
      }, 
      headingH2: {
        color: '#4e3e71',
        fontSize: 16,
        fontWeight: "700",
        // marginVertical: 5
      },
      headingH1: {
        color: '#4e3e71',
        fontSize: 18,
        fontWeight: 'bold',
        // marginVertical: 5
      },
    buttonNew: {
        borderRadius: 8,
        paddingVertical: 10,
        paddingHorizontal: 30,
        backgroundColor: '#4e3e71',
        marginVertical: 30,
        marginHorizontal: 20
      },
    buttonNew2: {
        borderRadius: 8,
        paddingVertical: 10,
        paddingHorizontal: 30,
        backgroundColor: '#4e3e71',
        marginVertical: 5,
        marginHorizontal: 20
      },
      buttonTextNew: {
        color: 'white',
        fontSize: 14,
        textAlign: 'center',
      },
    formikData:{

    },
    editIcon:{
        flex: 1,
        flexDirection: 'column'
  },
    patientImage:{
        flexDirection: 'row',
        marginHorizontal: 10
    //     justifyContent: 'space-between',
    },
    input: {
        outlineStyle: 'none',
        flex: 1,
        marginVertical: 10,
        paddingVertical:5,
        paddingLeft: 5,
        fontSize: 18,
        color: '#112950',
        borderBottomWidth: 1,
        borderBottomColor: '#e5e5e5'
    },
})
