import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    FlatList,
    TextInput
} from "react-native";

import VerifyMaterialController, {
    Props,
} from "./VerifyMaterialController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../assets'

//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";
import { fontSize } from "@mui/system";

class VerifyMaterial extends VerifyMaterialController {

    renderHaderContainer = () => {
        return (
            <View style={styles.renderHaderContainer}>
                <TouchableOpacity onPress={()=> this.props.navigation.goBack()}>
                <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
                </TouchableOpacity>
                <Text style={styles.renderHaderTextContainer}>Verify Material</Text>
            </View>
        )
    }


    renderImgContainer = () => {
        return (
            <View style={styles.renderImgContainer}>
                <Image source={IMG_CONST.bitmap} style={styles.bitMapImg} />
            </View>
        )
    }

    renderInformastionContainer = () => {
        return (
            <View style={styles.renderInformastionContainer}>
                <View>
                    <Text style={styles.renderInformastionTextContainer}>Enter Manufacturer Name</Text>
                    <TextInput placeholder="Name" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>

                <View style={styles.renderInformastionSubContainer}>
                    <Text style={styles.renderInformastionTextContainer}>Material Category</Text>
                    <TextInput placeholder="Material Category" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>

                <View style={styles.renderInformastionSubContainer}>
                    <Text style={styles.renderInformastionTextContainer}>Material Name</Text>
                    <TextInput placeholder="Material Name" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>

                <View style={styles.renderInformastionSubContainer}>
                    <Text style={styles.renderInformastionTextContainer}>Division</Text>
                    <TextInput placeholder="Division" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>

                <View style={styles.renderInformastionSubContainer}>
                    <Text style={styles.renderInformastionTextContainer}>Catlog No</Text>
                    <TextInput placeholder="Catlog No" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>

                <View style={styles.renderInformastionSubContainer}>
                    <Text style={styles.renderInformastionTextContainer}>Barcode</Text>
                    <TextInput placeholder="Barcode" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>

                <View style={styles.renderInformastionSubContainer}>
                    <Text style={styles.renderInformastionTextContainer}>Gtin No</Text>
                    <TextInput placeholder="Gtin No" style={styles.placeHolderStyle} />
                    <View style={styles.lineView} />
                </View>
            </View>
        )
    }


    renderButtonContainer = () => {
        return (
            <TouchableOpacity style={styles.renderButtonContainer}>
                <Text style={styles.renderButtonTextContainer}>Add Material</Text>
            </TouchableOpacity>
        )
    }


    renderDetailsButton = () => {
        return(
            <TouchableOpacity style={styles.renderDetailsButton}>
                <Text style={styles.renderDetailsTextButton}>Details Wrong</Text>
            </TouchableOpacity>
        )
    }


    render() {
        return (
            <View style={styles.container}>
                {this.renderHaderContainer()}
                {this.renderImgContainer()}
                {this.renderInformastionContainer()}
                {this.renderButtonContainer()}
                {this.renderDetailsButton()}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },

    renderHaderContainer: {
        height: scale(72),
        backgroundColor: '#4e3e71',
        flexDirection: 'row',
        alignItems: 'center'
    },

    leftArrowImg: {
        marginLeft: scale(24),
        width: scale(24),
        height: scale(24),
    },

    renderHaderTextContainer: {
        color: "#e8fafe",
        marginLeft: scale(95),
        fontSize: scale(20),
    },

    bitMapImg: {
        width: scale(109),
        height: scale(109),
        borderRadius: scale(12),
        resizeMode: "contain"
    },

    renderImgContainer: {
        marginTop: verticalScale(34),
        alignSelf: 'center'
    },

    renderInformastionContainer: {
        marginTop: verticalScale(41),
        paddingLeft: scale(24),
        paddingRight: scale(24)
    },

    renderInformastionTextContainer: {
        color: "#4e3e71",
        lineHeight: scale(18),
        fontSize: scale(12),
        fontWeight: 'bold'
    },

    placeHolderStyle: {
        marginTop: verticalScale(5),
        color: "#dddddd",
        fontWeight: 'bold',
        fontSize: scale(15),
        lineHeight: scale(22)
    },

    lineView: {
        width: scale(327),
        height: scale(1),
        backgroundColor: '#e5e5e5',
        marginTop: verticalScale(19)
    },

    renderInformastionSubContainer: {
        marginTop: verticalScale(16)
    },

    renderButtonContainer: {
        width: scale(360),
        height: scale(54),
        borderRadius: scale(12),
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4e3e71',
        alignSelf: 'center',
        marginTop: verticalScale(54)
    },

    renderButtonTextContainer: {
         color:'#e8fafe',
         fontSize:scale(17),
         lineHeight:scale(22)
    },

    renderDetailsButton: {
        width: scale(360),
        height: scale(54),
        borderRadius: scale(12),
        justifyContent: 'center',
        alignItems: 'center',
        borderColor:'#e3e3e3',
        alignSelf: 'center',
        marginTop: verticalScale(20),
        borderWidth:scale(1),
        marginBottom:scale(45)
    },

    renderDetailsTextButton: {
        color:'#4e3e71',
        lineHeight:scale(22),
        fontSize:scale(17)
    }
})

export default VerifyMaterial;