import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  FlatList,
  ScrollView,
  ActivityIndicator
} from 'react-native';
import DesktopDesign from "./DesktopValidateBC";
import { getAxiosAPI, getErrorMessage } from '../../../../framework/src/Blocks/AxiosAPIBlock';
import { SortData } from '../../../../framework/src/Utilities';

export default function ValidateBc({ navigation }: { navigation: any }) {

      const [query, setQuery] = useState("");
      const [data, setData] = useState<any>([])
      const [searchData, setsearchData] = useState([]);
      const [loader, setLoader] = useState(true);
      
  const getHospitals = async () => {
    setLoader(true)
    let url = `/bx_block_material_manager/material_manager/pending_booking_coordinators`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            console.log(msg);
            setLoader(false)
          } else {
            setLoader(false)
            setData(res.data.data.sort((a:any, b:any) => SortData(a.attributes.first_name.toLowerCase(), b.attributes.first_name.toLowerCase())))
          }
        })
  };  
  useEffect(() => { 
    const userStatus = localStorage.getItem('userStatus')
    if (userStatus === 'pending') {
      console.log(userStatus)
      navigation.goBack()
    } else {
      getHospitals()
      console.log(userStatus)
    }
    }, []);
      // console.log(data)
      
      
      const handleSearch = (text : any) => {
    setQuery(text)

    if(text !== "") {
          const newData = data.filter((name: any) => {
          return Object.values(name.attributes)
                .join(" ")
                .toLowerCase()
                .includes(text.toLowerCase());
          });
          setsearchData(newData);
          //console.log(newData);
          
    } else {
      setsearchData(data);
    }
      };

        const ListEmptyView = () => {
      return (
        <View>
          <Text style={styles.headingEmpty}>No Record Found </Text>
          
          </View>

      )
      }
      

      const navigateTo = (item : any) => {
            localStorage.setItem('validateBCDetails', JSON.stringify(item))
            navigation.navigate('ValidateBc2')
      }
      
  return (
    window.screen.width < 950 ? 
            <View style={styles.container}>
                        <View style={styles.headingView}>
                        <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                          <img src={require("../../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24,
                                          }} />
                                    </TouchableOpacity>
                                    <Text style={styles.heading}>Booking Co-ordinator</Text>
                  </View>
                  <Text style={styles.heading2}>BC List</Text>

                    <View style={styles.searchBox}>
                        <TextInput
                              style={styles.input}
                              placeholder="Search "
                              autoCapitalize="none"
                              autoCorrect={false}
                              clearButtonMode="always"
                              placeholderTextColor="#939ca3"
                              value={query}
                              onChangeText={queryText => handleSearch(queryText)}
                        />
                        <TouchableOpacity onPress={() => setQuery('')}>
                  <img src={query === '' ? require("../../assets/search.png" ) : require("../../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
                  </TouchableOpacity> 
                  </View>
                  {loader ? <ActivityIndicator
          style={{ margin: 'auto', height: 100, width: 100, }}
          size="large" color="#4e3e71" />
          :   
                   <View style={styles.card}>
             <ScrollView style={{flex: 1}}>
    {
                      (query.length > 0 && searchData.length > 0) ? 
                      <View style={styles.add}>
                      <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                      </View> 
                           : 
                           (query.length > 0 && searchData.length === 0) ? 
                           <View style={styles.add}>
                           <Text style={styles.resultText}>Searched member not found</Text>
                           </View> : null
                     }
                      
                      <FlatList
                                    data={query.length < 1 ? data : searchData}
                                        ListEmptyComponent={ListEmptyView()}
                              keyExtractor={(item: any) => item.id}
                              renderItem={({ item }) => (
                              <TouchableOpacity onPress={() => navigateTo(item)}>
                                     <View style={styles.listItem}>
                                        <View style={{flexDirection: 'row'}}>
                                          <img src={require("../../assets/personImg.png")} style={{ height: 70, width: 70}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto'}}>
                                                            <Text style={styles.names}>{item.attributes.first_name ? item.attributes.first_name + " " + item.attributes.last_name : 'Undefined'}</Text>
                                                            <Text style={styles.names2}>{ item.attributes.facilities.data[0].attributes.hospital.data.attributes.name}</Text>
                                                </View>
                                        </View>
                                    </View>
                              </TouchableOpacity>
                             
                              )}
                              />
                      </ScrollView>
                      </View> }
      </View>
      :
      <View>
        <DesktopDesign navigation={navigation}/>
      </View>
        )
}

const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: "#fff"
      },
      leftArrowIconWrapper: {
        width: 24,
        height: 24,
        position: "absolute",
        left: 24
  },
      names2: {
            color: '#8e9aab',
            paddingHorizontal: 11,
      fontSize: 14
      },
        listItem: {
//    height: 88,
   marginHorizontal:26,
   borderColor: '#e5e5e5',
   borderWidth: 1,
   borderRadius: 12,
   marginVertical: 5,
   padding: 20,
   flexDirection: 'row',
   justifyContent: 'space-between'
      },
      headingEmpty: {
      marginTop: 100,
    marginHorizontal: 'auto',
    color: '#b2bac6',
    fontSize: 24,
    fontWeight: 'bold',
    flexDirection : 'row',
    justifyContent: 'center'    
        },
    names: {
    color: '#4e3e71',
    fontSize: 17,
      paddingHorizontal: 11,
    paddingVertical: 5
    
  },
        card: {
    flex: 1,
    marginTop:20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: "#fff",
    marginVertical: 6,
      },
         add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
      headingView: {
            flexDirection: 'row',
            alignItems: 'center',
               height: 72,
            paddingVertical: 15,
            backgroundColor: '#4e3e71',
            marginBottom: 20,

      },
        heading: {
            color: '#fff',
            fontSize: 20,
            marginHorizontal: 'auto'
      },
      heading2: {
            color: '#4e3e71',
            fontSize: 18,
            marginHorizontal: 24,
            fontWeight: 'bold'
        },
        searchBox: {
  flexDirection: 'row',
  marginHorizontal:26,
  marginVertical: 20,
  borderWidth: 1,
  borderColor: '#8a8a8a',
  borderRadius: 50
  
},
input: {
outlineStyle: 'none',
  flex: 1,
  paddingLeft: 10,
  height: 48,
  color: '#4e3e71',
  fontSize: 16,
  paddingStart: 20
},

})