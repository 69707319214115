import axios from "axios";

export var axiosInstance: any;

export function getAxiosInstance() {
  // const router = useRouter() 
  const token: any =  localStorage.getItem('authToken');
  let config = require('../config');
  let instance = axios.create({
    baseURL: config.baseURL,
    headers: {
      'Content-Type': 'application/json',
      'token': token
    },
  });
  instance.interceptors.response.use((response) => {
    return response;
  }, 
  (err) => {
    const error = err.response;
    // if error is 401 

     if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
      sessionStorage.setItem('sessionExpired', '1');
    } 
    return Promise.reject(error);
  });

  return instance;
}

export async function getAxiosAPI(url : any) {
      try {
            const response = await getAxiosInstance().get(url);
            return ({error : false ,data: response.data});
      } catch (error) {
          let err  = error as any
           console.log(err.data)
            return ({error: true, data: err.data});
      }
}

export async function deleteAxiosAPI(url : any) {
      try {
            const response = await getAxiosInstance().delete(url);
            return ({error : false ,data: response.data});
      } catch (error) {
          let err  = error as any
           console.log(err.data)
            return ({error: true, data: err.data});
      }
}
export async function postAxiosAPI(url : any, data: any) {
      try {
            const response = await getAxiosInstance().post(url, data);
            return ({error : false ,data: response.data});
      } catch (error) {
        let err  = error as any
        console.log(err.data)
         return ({error: true, data: err.data});
      }
}
export async function patchAxiosAPI(url : any, data: any = null) {
      try {
            const response = await getAxiosInstance().patch(url, data);
            return ({error : false ,data: response.data});
      } catch (error) {
        let err  = error as any
        console.log(err.data)
         return ({error: true, data: err.data});
      }
}
export async function putAxiosAPI(url : any, data: any) {
      try {
            const response = await getAxiosInstance().put(url, data);
            return ({error : false ,data: response.data});
      } catch (error) {
        let err  = error as any
        console.log(err.data)
         return ({error: true, data: err.data});
      }
}

export function capitalizeFirstLetter(str : any) {
      return str[0].toUpperCase() + str.slice(1);
}

export function getErrorMessage(obj: any, isKeyNeed : boolean = false){
  let err = obj
  let str = '';
  if(!isKeyNeed){
      for (let k in err) {
    if (err.hasOwnProperty(k)) {
      str += err[k];
    }
  }
  console.log(str);
  return str
  } else {
      for (const [p, val] of Object.entries(obj)) {
      str += `${capitalizeFirstLetter(p)} ${val}\n`;
      }
      return str;
  }


} 


