import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
      StyleSheet,
      Text,
      TouchableOpacity,
      TextInput,
      View,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      Platform,
      FlatList,
      ActivityIndicator
} from "react-native";
import DesktopHeader from "../DesktopHeader";
import DesktopMenue from "../DesktopMenue";
import scale, { verticalScale } from "../../../../framework/src/Scale";
import Dialog from '@material-ui/core/Dialog';
import { getAxiosAPI, getErrorMessage, postAxiosAPI } from "../../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../../framework/src/Utilities";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {

      const [data, setData] = useState([]);
      const [query, setQuery] = useState("");
      const [searchData, setsearchData] = useState([]);

      const [open, setOpen] = useState(false)

      const handleClose = () => {
            setOpen(false)
      }

      useEffect(() => {
            const getHospitals = async () => {
                  let url = `/bx_block_material_manager/material_manager/pending_booking_coordinators`
                  await getAxiosAPI(url).then((res: any) => {
                        if(res.error){
                          let msg = getErrorMessage(res.data.errors[0])
                          console.log(msg)
                        } else {
                            console.log(res.data.data)
                            setData(res.data.data.sort((a:any, b:any) => SortData(a.attributes?.first_name?.toLowerCase() , b.attributes?.first_name?.toLowerCase())))
                        }
                      })
            };
            getHospitals();
      }, []);
      console.log(data)

      const ListEmptyView = () => {
            return (
                  <View style={{ marginBottom: 80 }}>
                        <Text style={styles.heading}>No Record Found</Text>
                  </View>

            )
      }

      const acceptManager = async (id: any) => {
            console.log(id)
            let url = `/bx_block_material_manager/material_manager/accept_booking_coordinator?id=${id}`;
            await postAxiosAPI(url, null).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    console.log(msg);
              } else {
                  console.log(res.data);
                  setOpen(true)
              }
            });
      }


      const handleSearch = (text: any) => {
            setQuery(text)
            if (text !== "") {
                  const newData = data.filter((name: any) => {
                        return Object.values(name.attributes)
                              .join(" ")
                              .toLowerCase()
                              .includes(text.toLowerCase());
                  });
                  setsearchData(newData);
                  //console.log(newData);

            } else {
                  setsearchData(data);
            }
      };

      return (
            <View style={styles.desktopContainer}>
                  <DesktopHeader heading={'Booking Co-ordinator'} navigation={navigation} />
                  <View style={styles.bottomDesktopCard}>
                        <DesktopMenue navigation={navigation} />
                        <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>
                              <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Booking Co-ordinator </Text>
                              <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Search "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../../assets/search.png") : require("../../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>

                                    </View>
                              </View>
                              <View>
                                    <View>
                                          <View style={styles.card}>
                                                <View style={{ flex: 1, }}>
                                                      {
                                                            (query.length > 0 && searchData.length > 0) ?
                                                                  <View style={styles.add}>
                                                                        <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                                  </View>
                                                                  :
                                                                  (query.length > 0 && searchData.length === 0) ?
                                                                        <View style={styles.add}>
                                                                              <Text style={styles.resultText}>Searched Co-ordinator not found</Text>
                                                                        </View> : null
                                                      }

                                                      <FlatList
                                                            style={{ height: 562 }}
                                                            ListEmptyComponent={ListEmptyView()}
                                                            data={query.length < 1 ? data : searchData}
                                                            keyExtractor={(item: any) => item.id}
                                                            horizontal={false}
                                                            numColumns={3}
                                                            renderItem={({ item }) => (
                                                                  <>
                                                                        <View style={styles.listItem}>
                                                                              <View style={{ flexDirection: 'row', paddingHorizontal: 12 }}>
                                                                                    <img src={require("../../assets/hospital.png")} style={{ height: 56, width: 56 }} />
                                                                                    <View style={{ flex: 1, flexDirection: 'column'}}>
                                                                                          <Text style={styles.names}>{item.attributes.first_name} {item.attributes.last_name}</Text>
                                                                                          <Text style={{ fontSize: 18, color: '#7f7f7f', paddingHorizontal: 11}}>{item.attributes.hospitals[0].name}</Text>
                                                                                    </View> 
                                                                              </View>

                                                                              <View style={{ borderTopColor: '#e5e5e5', marginTop: 10, padding: 12, borderTopWidth: 1 }}>
                                                                                    <View style={{ flexDirection: 'row',paddingVertical: 12 }}>
                                                                                          <Text style={{ fontSize: 18, color: '#7f7f7f' }}>1) Phone Number: </Text>
                                                                                          <Text style={{ fontSize: 18, color: '#4e3e71' }}>{item.attributes.full_phone_number}</Text>
                                                                                    </View>
                                                                                    <View style={{ flexDirection: 'row',paddingVertical: 12 }}>
                                                                                          <Text style={{ fontSize: 18, color: '#7f7f7f' }}>2) Email ID: </Text>
                                                                                          <Text style={{ fontSize: 18, color: '#4e3e71' }}>{item.attributes.email}</Text>
                                                                                    </View>
                                                                                    <View>
                                                                                          <TouchableOpacity style={styles.button0} onPress={() => acceptManager(item.id)}>
                                                                                                <Text style={styles.buttonText0}>Accept BC</Text>
                                                                                          </TouchableOpacity>
                                                                                          <TouchableOpacity style={styles.button}>
                                                                                                <Text style={styles.buttonText}>Reject BC</Text>
                                                                                          </TouchableOpacity>
                                                                                    </View>
                                                                              </View>
                                                                        </View>


                                                                  </>

                                                            )}
                                                      />
                                                </View>

                                          </View>
                                    </View>


                              </View>
                        </View>
                        </View>
                  </View>
                  <Dialog
                        open={open}
                        onClose={handleClose}
                  >
                        <View style={{ height: 500, width: 500 }}>
                              <Text style={{ margin: 'auto', fontSize: 25, color: '#4e3e71' }}>BC Added successfully </Text>
                        </View>
                  </Dialog>
            </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      heading: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      heading2: {
            marginHorizontal: 'auto',
            color: '#4e3e71',
            fontSize: 20,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
      names: {
            color: '#4e3e71',
            fontSize: 18,
            fontWeight: 'bold',
            paddingHorizontal: 11,
            width: scale(70),

      },
      topButton: { backgroundColor: '#4e3e71', fontSize: 16, color: '#ffff', paddingVertical: 5, paddingHorizontal: 10, borderRadius: 4, height: 35, width: 120 },
      outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column'

      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginVertical: 10,
            backgroundColor: '#ffff',
            borderColor: '#4e3e71',
            borderWidth: 1,
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
      },
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            width: '100%'
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      listItem: {
            // height: 88,
            maxWidth: 400,
            marginHorizontal: 20,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 4,
            marginVertical: 20,
            paddingVertical: 12,
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between'
      },
      button0: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 10,
            backgroundColor: '#4e3e71',
            // width: '20%',
            marginHorizontal: 10
      },
      buttonText0: {
            color: '#fff',
            fontSize: 17,
            textAlign: 'center',
      },

})