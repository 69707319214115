import React, {useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity } from 'react-native';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "../CalendarCSS.css"
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { getErrorMessage, postAxiosAPI } from '../../../../framework/src/Blocks/AxiosAPIBlock';
import CustomPopUp from '../../../../framework/src/CustomPopUp';




export default function ProposedDateTime({navigation, handleClose} : {navigation: any, handleClose: any }) {
      const [value, onChange] = useState(new Date());
      const [selectedDate, setSelectedDate] = useState(new Date());
      const [datevalid, setDateValid] = useState(false)
      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)

      const [isvalid, setvalid] = useState(false);

      const handleDateChange = (date : any) => {
            setSelectedDate(date);
          };

      const ClosePopUp = () => {
            if(window.screen.width < 950) {
                  navigation.goBack()
            }else {
            setPopup(false)
            handleClose()
            }
            
      }

     
          
      const navigateTo = async () => {
            const user = JSON.parse(localStorage.getItem('proposeApiData') || '{}')    
          const postData = {
                order_id: user.order_id,
                surgeon_id: user.surgeon_id,
                proposed_date: Date.parse(value.toLocaleDateString() + " " + selectedDate.toLocaleTimeString())
            }
            
            console.log(postData)
            let url = "/bx_block_order/orders/order_propose_date_request"
            await postAxiosAPI(url, postData).then((res: any) => {
                  if(res.error){
                        let msg = getErrorMessage(res.data.errors[0])
                        console.log(msg);
                        setPopup(true)
                        setMessage(msg)
                  } else {
                        console.log(res.data)
                        setPopup(true)
                        setMessage("Request sent successfully")
                       
                  }
                }); 
          }

          
      return (
            <View style={styles.container}>
                  
              <View>
              {window.screen.width < 950 && <View style={styles.headingView}>
              <Text style={styles.heading}>Propose Date & Time </Text>
              </View>}
             {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
              <Text style={{color: '#4e3e71', fontSize: 24 ,padding: 24}}>Select Date & Time</Text>
                  <View style={{marginHorizontal: 'auto'}}>
                  <Calendar
                    onChange={onChange}
                    value={value}
                    className="calenderNew"
                    defaultActiveStartDate={value}
                  />
                  </View>

                  {isvalid ? <Text style={{color: 'red'}}>Please Select future Dates</Text> : null}
                  </View>
                  <View>
                        <Text style={{ color: '#4e3e71', padding: 24, fontSize: 24}}>Select Time</Text>
                        <View style={styles.timePicker}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                    'aria-label': 'change time',
                              }}
                              />
                        </MuiPickersUtilsProvider>
                        </View>
                        {datevalid ? <Text style={{color: 'red', paddingLeft: 50}}>Please Select future Time</Text> : null}
                  </View>
                 
                  <TouchableOpacity onPress={() => navigateTo()}>
                  <View style={styles.button}>
                  <Text style={styles.buttonText}>Continue</Text>
                  </View>
            </TouchableOpacity>
            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff",
            flexDirection: 'column',
            justifyContent: 'space-between'
          },
          headingView: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 27,
            height: 72,
            backgroundColor: '#4e3e71',
            marginBottom: 20,
        
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          timePicker: {
            //     margin: 20,
                paddingHorizontal: 24,
                backgroundColor: "#fff",
                borderRadius: 10,
          },
          button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 24,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
         
      
})