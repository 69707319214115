import React, { useEffect, useState } from 'react';
import {
  getAxiosAPI,
  deleteAxiosAPI
} from '../../framework/src/Blocks/AxiosAPIBlock';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress'
];

const AppLogout = ({ children }) => {
  let timer;

  const [sessionTime, setSessionTime] = useState(null);

  const getSessionTime = async () => {
    let url = `/bx_block_login/session_expiration_time`;
    await getAxiosAPI(url).then(res => {
      if (res.error) {
        console.log(res.data);
      } else {
        let time = +res.data.sessions_expiration_time.split(' ')[0];
        setSessionTime(time * 1000 * 60);
      }
    });
  };

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach(item => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, sessionTime); // You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    if (sessionTime) {
      Object.values(events).forEach(item => {
        window.addEventListener(item, () => {
          resetTimer();
          handleLogoutTimer();
        });
      });
    } else {
      getSessionTime();
    }
  }, [sessionTime]);
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = async () => {
    const currentRoute = window.location.pathname;
    const publicRoutes = [
      '/ForgotPassword',
      '/AuthenticateAccount',
      '/EmailAccountRegistration',
      '/RolesPermissions2',
      '/EmailAccountLoginBlock',
      '/TermsCondition',
      '*/AlertWeb',
      '/',
      '/OTPInputAuth',
      '/NewPassword'
    ];

    if (!publicRoutes.includes(currentRoute)) {
      let url = `/account_block/accounts/logout`;
      await deleteAxiosAPI(url).then(res => {
        if (!res.error) {
          console.log(res.data.expired_token);
          localStorage.setItem('authToken', res.data.expired_token);
          localStorage.setItem('sessionExpired', 'true');
        }
      });
    }
  };
  return children;
};

export default AppLogout;
