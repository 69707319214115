import { StylesProvider, withTheme } from '@material-ui/core';
import React from 'react';
import {
      StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Button,
      Image,
      ScrollView,
      TouchableWithoutFeedback,
      Platform
} from 'react-native';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export default function Profile3() {
      return (
            <View>
                  <View style={styles.container}>
                        <View style={styles.profileImage} >
                              <AccountCircleIcon style={{ fontSize: 120, color: '#007af2' }} />
                        </View>
                        <View style={styles.editIcon}>
                              <EditIcon style={{ fontSize: 25 }} />
                        </View>
                  </View>
                  <View style={styles.bottomContainer}>
                        <View style={styles.list}>
                              <View style={styles.text}>
                                    <Text style={{ color: '#007af2', fontSize: 20 }}>User Name : <Text style={{ color: '#8b8b8b', fontSize: 20 }}> Steve john </Text> </Text>
                                    <EditIcon style={{ color: 'black' }} />
                              </View>
                              <View style={styles.text}>
                                    <Text style={{ color: '#007af2', fontSize: 20 }}>Phone Number :  <Text style={{ color: '#8b8b8b', fontSize: 20 }}> 7010551048 </Text> </Text>
                                    <EditIcon style={{ color: 'black' }} />
                              </View>
                              <View style={styles.text}>
                                    <Text style={{ color: '#007af2', fontSize: 20 }}>Email Address : <Text style={{ color: '#8b8b8b', fontSize: 20 }}> john@gmail.com </Text> </Text>
                                    <EditIcon style={{ color: 'black' }} />
                              </View>
                              <View style={styles.text}>
                                    <Text style={{ color: '#007af2', fontSize: 20 }}>Contact Priority : <Text style={{ color: '#8b8b8b', fontSize: 20 }}> Primary </Text> </Text>
                                    <EditIcon style={{ color: 'black' }} />
                              </View>
                              <View style={styles.text}>
                                    <Text style={{ color: '#007af2', fontSize: 20 }}>My Facility : <Text style={{ color: '#8b8b8b', fontSize: 20 }}> ARM Hospital</Text> </Text>
                                    <EditIcon style={{ color: 'black' }} />
                              </View>


                        </View>
                        <TouchableOpacity >
                              <View style={styles.button}>
                                    <Text style={styles.buttonText}>Edit Profile </Text>
                              </View>
                        </TouchableOpacity>
                  </View>
            </View>
      )

}
const styles = StyleSheet.create({
      container: {
            flex: 1,
            padding: 1,
            backgroundColor: "#eff8ff"
      },
      profileImage: {
            flexDirection: 'row',
            margin: 20,
            marginBottom: 0,
            position: 'relative',
            justifyContent: 'center'
      },
      editIcon: {
            justifyContent: 'center',
            position: 'absolute',
            top: 105,
            right: 170,
            elevation: 10,
            backgroundColor: '#e9e9e9'
      },
      bottomContainer: {
            flex: 1,
      },
      list: {
            flexDirection: 'column',
            marginHorizontal: 20,
            marginVertical: 20,
            justifyContent: 'center'
      },
      text: {
            padding: 18,
            backgroundColor: '#f1f1f1',
            borderRadius: 5,
            margin: 8,
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      icons: {
            justifyContent: 'flex-end',
      },
      button: {
            borderRadius: 8,
            paddingVertical: 10,
            paddingHorizontal: 30,
            marginHorizontal: 30,
            marginVertical: 10,
            backgroundColor: '#0579ed',
      },
      buttonText: {
            color: 'white',
            fontSize: 20,
            textAlign: 'center',
      },
})