import React from "react";

//Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  Text,
  TextInput,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  TouchableOpacity
} from "react-native";


import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import OtpTimer from "../../otp-input-confirmation/src/OtpTimer"

export default class ResendOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  

  render() {
      const { navigation } = this.props;
    
  
      return (
            <View>
            <View>
    <Text style={{color: 'rgb(72, 100, 132)', paddingTop: 20, fontSize: 18}}>Did not received the code?</Text>
<Text style={styles.resendCode}
    onPress={() => this.displayData()}
    >Resend Code</Text>   
  </View>
            </View>
      )
  }

}

const styles = StyleSheet.create({
       resendCode:{
            color: "#4e3e71",
            paddingVertical:5,
            fontWeight: 'bold',
          }
})