import React,{ useState}  from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CallIcon from '@material-ui/icons/Call';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';


export default function History2({navigation}: {navigation: any}) {
      const [checked4, setChecked4]  = useState(false);
      return (
            <View style={styles.container}>
                   <View style={styles.card}>
            <Text style={styles.horizontalBorder}>Hospital Name: <Text style={styles.color}>ARM Hospital</Text></Text>
                  <View style={styles.profileDetails}>
                  <AccountCircleIcon  style={{color:"#007af2" , fontSize: '120px'}} />
                  <Text style={styles.topHeading}>Patient Name {'\n'} Age: 26</Text>
                  </View>
                  <View style={styles.buttonsRow}>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>12/04/2021</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>12:30 PM</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Confirmed</Text>
                        </View>
                  </TouchableOpacity>
                  </View>
                  <TouchableOpacity onPress={() => checked4 ? setChecked4(false) : setChecked4(true)}>
                       <View style={styles.drawers}>
                             <Text style={{fontWeight: 'bold'}}>Contact Details: </Text>
                             {checked4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                       </View>
                       </TouchableOpacity>
                       <Collapse in={checked4}>
                       <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <Text>1)</Text>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#939ca3'}}>john</Text>
                                  <Text style={{color: '#0778df'}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <CallIcon style={{ 
                        color: '#fff',
                        fontSize: 20,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </Text>
                        </View>
                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <Text>1)</Text>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#939ca3'}}>john</Text>
                                  <Text style={{color: '#0778df'}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <CallIcon style={{ 
                        color: '#fff',
                        fontSize: 20,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </Text>
                        </View>
                        <View style={styles.detailsList3}>
                        <View style={{flexDirection: 'row'}}>
                            <View>
                                  <Text>1)</Text>
                            </View>
                            <View style={{flexDirection: 'column', paddingHorizontal: 5}}>
                                  <Text style={{color: '#939ca3'}}>john</Text>
                                  <Text style={{color: '#0778df'}}>Booking Co-ordinator</Text>
                            </View>
                           
                        </View>
                        <Text >
                        <CallIcon style={{ 
                        color: '#fff',
                        fontSize: 20,
                        backgroundColor: '#007af2', 
                        borderRadius: "50%", 
                        padding: "5px",
                        justifyContent: 'center'}} />
                        </Text>
                        </View>
                        </Collapse>
            </View>
            </View>
      )
}

const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#eff8ff",
            flexDirection: 'column'
          },
          detailsList3: {
            margin: 10,
            fontWeight: 'bold',
            color: '#0778df',
            flexDirection: 'row',
            justifyContent: 'space-between'

          },
          drawers: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingVertical: 10
          }, 
          material: {
            marginHorizontal: 20,
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 10     
          },
          topHeading: {
            paddingTop: 40,
            color: '#0778df',
            paddingVertical: 5
          },
          color: {
            color: '#0778df',
            fontSize: 20,
            fontWeight: 'bold'
          },
          button: {
            borderRadius: 2,
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginHorizontal: 10,
            marginVertical: 5,
            backgroundColor: '#0778df',
          },
          buttonText: {
            color: 'white',
            fontSize: 15,
            textAlign: 'center',
          },
          buttonsRow: {
            flexDirection: 'row',
            justifyContent: 'center',
            paddingLeft: 20,
            paddingRight: 20
          },
          profileDetails:{
            flexDirection: 'row',   
          },
          card : {
            marginTop:20,
            borderRadius: 10,
            elevation: 3,
            backgroundColor: '#cce4fe',
            marginHorizontal: 20,
            marginVertical: 6,
          },
          horizontalBorder: {
            borderBottomColor: '#939ca3',
            borderBottomWidth: 1,
            padding: 15,
            borderColor: 'grey',
            flexDirection: 'row',
            justifyContent: 'center',
          },
})