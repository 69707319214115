import React,{useState, useEffect} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
FlatList
 } from 'react-native';
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';

 export default function CMFPA({navigation}: {navigation: any}) { 
      const order = JSON.parse(localStorage.getItem('order') || '{}')


          let codes = order?.cpt_codes?.map((x:any, y:any) => (
                {
                  ischecked: false,
                      code: x.code,
                      material_available: x.material_available
                }
          ))
          const [cptCodes, setCptCodes] = useState(codes)
          const handlePress = (y: any) => {
            let newCode = cptCodes.map((x:any) => {
                      if(x.code === y.code) {
                            x.ischecked = !x.ischecked
                      }else {
                            x.ischecked = false
                      }
                      return x
                })
                setCptCodes(newCode)
        }

      const ListEmptyView = () => {
            return (
              <View>
                <Text style={styles.heading2}>No Record Found</Text>
                {/* <ActivityIndicator size="large" color="#4e3e71" /> */}
                </View>
                )}

      const navigateTo = async (code: any) => {
            let url = `/bx_block_order/orders/fetch_cpt_code_materials?cpt_code=${code}`
            await getAxiosAPI(url).then((response: any) => {
                  if(response.error){
                    let msg = getErrorMessage(response.data.errors[0])
                    console.log(response.error)
                  } else {
                        console.log(response.data.data);
                         let arr2 = response.data.data.attributes.material_data
                         let arr1 = response.data.data.attributes.cpt_materials.data.map((x:any) => (
                               {
                                     material_name: x.attributes.name,
                                     material_id: parseInt(x.id),
                                     selectedCptId: response.data.data.id,
                               }
                         ))
                         let merged = [];
                         for(let i=0; i<arr1.length; i++) {
                         merged.push({
                         ...arr1[i], 
                         ...(arr2.find((itmInner :any ) => itmInner.material_id === arr1[i].material_id))}
                         );
                         }
                         //console.log(merged);
                         localStorage.setItem('selectedCodeList', JSON.stringify(merged))
                         localStorage.setItem('selectedCptCode', code)
                         navigation.navigate('CMFPB')
                        //  setData(merged);
                    
              }
            })
      }


       return (
             <View style={styles.container}>
                   {
                         window.screen.width < 950 ?
                               <View>
                                     <View style={styles.headingView}>
                                           <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                                                 <img src={require("../assets/leftArrow1.png")} style={{
                                                       height: 24, width: 24,
                                                 }} />
                                           </TouchableOpacity>
                      <Text style={styles.heading}> Choose Material  </Text>
                                     </View>
                                     <FlatList
                                     data={cptCodes.filter((x: any) => x.material_available)}
                                    //  horizontal={false}
                                    //  numColumns={3}
                                     ListEmptyComponent={ListEmptyView()}
                                     keyExtractor={(item: any) => item.id}
                                     renderItem={({ item }) => (
                                                       <TouchableOpacity onPress={() => navigateTo(item.code)}>
                                                             <View style={styles.card}>
                                                                   <View style={{ flexDirection: 'row' }}>
                                                                         <img src={require("../assets/procedure.png")} style={{ height: 55, width: 55 }} />
                                                                         <View style={{ flexDirection: 'column', marginVertical: 'auto', paddingHorizontal: 24 }}>
                                                                               <Text style={styles.info}>Procedure Code </Text>
                                                                               <Text style={styles.info}>({item.code})</Text>
                                                                         </View>
                                                                   </View>
                                                                   <View style={{ marginVertical: 'auto' }}>
                                                                         <img src={require("../assets/arrow.png")} style={{ height: 16, width: 16 }} />
                                                                   </View>
                                                             </View>
                                                       </TouchableOpacity>
                                                   )}
                                                   />
                                    
                                     </View>
                               : <FlatList
                                     data={cptCodes.filter((x: any) => x.material_available)}
                                     horizontal={false}
                                     numColumns={3}
                                     ListEmptyComponent={ListEmptyView()}
                                     keyExtractor={(item: any) => item.id}
                                     renderItem={({ item }) => (
                                           <TouchableOpacity onPress={() => navigateTo(item.code)}>
                                                 <View style={styles.card}>
                                                       <View style={{ flexDirection: 'row' }}>
                                                             <img src={require("../assets/procedure.png")} style={{ height: 55, width: 55 }} />
                                                             <View style={{ flexDirection: 'column', marginVertical: 'auto', paddingHorizontal: 24 }}>
                                                                   <Text style={styles.info}>Procedure Code </Text>
                                                                   <Text style={styles.info}>({item.code})</Text>
                                                             </View>
                                                       </View>
                                                       <View style={{ marginVertical: 'auto' }}>
                                                             <img src={require("../assets/arrow.png")} style={{ height: 16, width: 16 }} />
                                                       </View>

                                                 </View>

                                           </TouchableOpacity>
                                     )}
                               />
                   }    
                   </View>
       )}

const styles = StyleSheet.create({
            container: {
                  flex: 1,
                  backgroundColor: "#ffff",
                  flexDirection: 'column',
              
                },
                heading2: {
                  marginVertical: 100,
                  marginHorizontal: 'auto',
                  color: '#b2bac6',
                  fontSize: 24,
                  fontWeight: 'bold',
                  flexDirection : 'row',
                  justifyContent: 'center'
                },
                headingView: {
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 15,
                  backgroundColor: '#4e3e71',
                  marginBottom: 20,
           
                },
                heading:{
                  color: '#e8fafe',
                  fontSize: 20,
                  marginHorizontal: 'auto'       
      },
      leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
      },
                dropdown: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingVertical: 5,
                  paddingHorizontal: 20,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  marginTop: 10,
                  backgroundColor: '#e2f0fe'

                },
                drawerItem: {
                  paddingHorizontal: 10,
                  borderBottomRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  backgroundColor: '#e2f0fe'

                },
                text: {
                  color: '#4e3e71',
                  fontSize: 25,
                  marginHorizontal: 24,
          
                },
      card: {
                      width: 360,
                      marginHorizontal: 24,
                      marginVertical: 10,
                      borderColor: '#f2f4f5',
                      borderWidth: 1,
                      borderRadius: 12,
                      padding: 16,
                      flexDirection: 'row',
                      justifyContent: 'space-between'

                },
               
                info: {
                  fontSize: 17,
                  color: '#4e3e71'

                },
})