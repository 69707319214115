import React, { useState, useEffect } from 'react';
import {
      StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TextInput,
      Button,
      Image,
      ScrollView,
      FlatList,
} from 'react-native';
import scale, { verticalScale } from "../../../components/src/Scale";




export default function WelcomePage({ navigation }: { navigation: any }) {

      return (
            <View style={styles.container}>
            <View style={styles.imgView}>
                  <img src={require("../assets/welcomePage.png")} style={{height: 450, width: 400}}/>
            </View>
            <View style={{ marginHorizontal: 'auto'}}>
            <Text style={styles.upperText}>UP MED</Text>
            <Text style={styles.lowerText}>Find best materials and equipments for your next </Text>
            <Text style={styles.lowerText}>surgery</Text>
            </View>

            <TouchableOpacity onPress={() => navigation.navigate('EmailAccountLoginBlock')} style={styles.button}>
                  <Text style={styles.buttonText}>Get Started</Text>
            </TouchableOpacity>
                              
            </View>
      )
}



const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#4e3e71",
            paddingVertical: 20,
            flexDirection: 'column',
            justifyContent: 'space-between'
      },
      button:{
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#fff',
            marginBottom: 10,
            marginTop: 10
      },
      buttonText:{
            color: '#4e3e71',
            fontSize: 17,
            textAlign: 'center',
      },
      upperText:{
      fontSize:32,
      color: '#cce9e5',
      marginHorizontal: 'auto',
      marginVertical: 20
      },
      lowerText:{
      fontSize: 16,
      color: '#f2f6fe',
      marginHorizontal: 'auto',
      },
      imgView:{
            // paddingLeft: 6,
            marginTop:50 
      },

     

})

