// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  Button,
  ScrollView,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
// Customizable Area End

import OTPInputAuthController, {
  Props,
} from "../../otp-input-confirmation/src/OTPInputAuthController";
import React from "react";

// import OTPInputView from '@twotalltotems/react-native-otp-input'
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import ResendOTP from "./ResendOTP";
import { Formik } from "formik";
import * as yup from "yup";
import TIMER from "./OtpTimer";
// import "./react-native-otp-textinput/index.css"

const validation_Schema = yup.object({
  otp: yup
    .string()
    .required("Opt is required")
    .matches(/^[0-9]+$/, "Only numbers are allowed")
    .min(4, "must be atleast 4 digits"),
});

export default class ForgotPasswordOTP extends OTPInputAuthController {
  constructor(props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    

    const renderer = ({ minutes, seconds, completed }) => {
      if (completed) {
        this.setState({ showTimer: true });
        // Render a completed state
        return null;
      } else {
        // Render a countdown
        return (
          <span style={{ color: "#4e3e71", fontWeight: "bold" }}>
            {minutes < 10 ? "0" + minutes : minutes}:
            {seconds < 10 ? "0" + seconds : seconds}
          </span>
        );
      }
    };

    return (
      //Merge Engine DefaultContainer
      window.screen.width < 950 ? (
        <Formik
          initialValues={{ otp: "" }}
          validationSchema={validation_Schema}
          onSubmit={(values, actions) => {
            console.log(values);
            this.setState({ otp: values.otp });
            this.submitOtp(true);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <View style={styles.container}>
              <View style={styles.card}>
                <img
                  src={require("../assets/illustration-register-copy@3x.png")}
                  style={{
                    width: 216,
                    height: 234,
                    position: "absolute",
                    top: -165,
                    left: 180,
                    zIndex: 99,
                  }}
                />
                <Text style={styles.upperText}>Verify Code </Text>
                <Text
                  style={styles.titleWhySignUp} //UI Engine::From Sketch
                >
                  Please enter otp received on{" "}
                  {window.localStorage.getItem("value")}.{" "}
                  {/*UI Engine::From Sketch*/}
                </Text>
                <View style={styles.OtpInputView}>
                  <OtpInput
                    numInputs={4}
                    //value={this.state.otp}
                    inputStyle={{
                      margin: 10,
                      height: 50,
                      width: 50,
                      borderRadius: 5,
                      fontSize: 20,
                      backgroundColor: "#e8fafe",
                    }}
                    containerStyle="otpConntainer"
                    //onChange={(otp: string) => this.setState({otp: otp})}
                    //onBlur={handleBlur('otp')}
                    onChange={handleChange("otp")}
                    isInputNum={true}
                    value={values.otp}
                  />
                </View>
                {touched.otp && errors.otp ? (
                  <Text style={{ color: "red", paddingTop: 20 }}>
                    {errors.otp}
                  </Text>
                ) : null}

                {this.state.showError ? (
                  <Text style={{ color: "red", paddingTop: 20 }}>
                    {" "}
                    Please Enter Vaild OTP{" "}
                  </Text>
                ) : null}
                <TIMER />
                {/* // {...this.btnSubmitOTPProps} */}
                <TouchableOpacity
                  disabled={this.state.loader}
                  onPress={() => handleSubmit()}
                >
                  <View
                    style={
                      this.state.loader ? styles.unactive_button : styles.button
                    }
                  >
                    <Text style={styles.buttonText}>Continue</Text>
                    {this.state.loader ? (
                      <ActivityIndicator
                        style={{ position: "absolute", right: 160 }}
                        size="large"
                        color="#2e2442"
                      />
                    ) : null}
                  </View>
                </TouchableOpacity>
              </View>

              {/* Customizable Area End */}
              {/* Merge Engine UI Engine Code */}
            </View>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{ otp: "" }}
          validationSchema={validation_Schema}
          onSubmit={(values, actions) => {
            console.log(values);
            this.setState({ otp: values.otp });
            this.submitOtp(true);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit,
          }) => (
            <View style={styles.desktopContainer}>
              <ImageBackground
                source={require("../assets/group.png")}
                resizeMode="cover"
                style={styles.image}
              >
                <View style={styles.desktopCard}>
                  <Text style={styles.upperText}>Verify Code</Text>
                  <Text
                    style={styles.titleWhySignUp} //UI Engine::From Sketch
                  >
                    Please enter otp received on{" "}
                    {window.localStorage.getItem("value")}.{" "}
                    {/*UI Engine::From Sketch*/}
                  </Text>

                  <View style={styles.OtpInputView}>
                    <OtpInput
                      numInputs={4}
                      inputStyle={{
                        margin: 10,
                        height: 50,
                        width: 50,
                        borderRadius: 5,
                        fontSize: 20,
                        backgroundColor: "#e8fafe",
                      }}
                      containerStyle="otpConntainer"
                      onChange={handleChange("otp")}
                      value={values.otp}
                      isInputNum={true}
                    />
                  </View>
                  {touched.otp && errors.otp ? (
                    <Text style={{ color: "red", paddingTop: 20 }}>
                      {errors.otp}
                    </Text>
                  ) : null}

                  {this.state.showError ? (
                    <Text style={{ color: "red", paddingTop: 20 }}>
                      {" "}
                      Please Enter Vaild OTP{" "}
                    </Text>
                  ) : null}
                  <TIMER />
                  {/* // {...this.btnSubmitOTPProps} */}
                  <TouchableOpacity
                    disabled={this.state.loader}
                    onPress={() => handleSubmit()}
                  >
                    <View
                      style={
                        this.state.loader
                          ? styles.unactive_button
                          : styles.button
                      }
                    >
                      <Text style={styles.buttonText}>Continue</Text>
                      {this.state.loader ? (
                        <ActivityIndicator
                          style={{ position: "absolute", right: 188, top:10 }}
                          size="large"
                          color="#2e2442"
                        />
                      ) : null}
                    </View>
                  </TouchableOpacity>
                </View>
                {/* Customizable Area End */}
                {/* Merge Engine UI Engine Code */}
              </ImageBackground>
            </View>
          )}
        </Formik>
      )
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column-reverse",
    backgroundColor: "#4e3e71",
  },
  desktopContainer: {
    flex: 1,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  desktopCard: {
    backgroundColor: "#e8fafe",
    margin: "auto",
    borderRadius: 40,
    padding: 26,
  },
  card: {
    position: "relative",
    borderTopRightRadius: 24,
    backgroundColor: "#e8fafe",
    paddingHorizontal: 24,
  },
  OtpInputView: {
    marginHorizontal: 10,
    marginVertical: 10,
  },
  BottomText: {
    marginVertical: 20,
  },
  OtpInput: {
    margin: 10,
  },
  upperText: {
    fontSize: 32,
    textAlign: "left",
    color: "#4e3e71",
    paddingTop: 96,
    paddingBottom: 20,
  },
  resendCode: {
    color: "#4e3e71",
    paddingVertical: 5,
    fontWeight: "bold",
  },
  titleWhySignUp: {
    color: "#4e3e71",
    justifyContent: "center",
    fontSize: 18,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },

  phoneInputMobile: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginBottom: 64,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1,
  },
  unactive_button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#8e7bb7",
    marginBottom: 38,
    marginTop: 150,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 150,
  },
  desktopbutton: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    backgroundColor: "#4e3e71",
    marginBottom: 38,
    marginTop: 20,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10,
  },
});
// Customizable Area End
