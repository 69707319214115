import React from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    SafeAreaView,
    FlatList
} from "react-native";

import DashbordController, {
    Props,
} from "./DashbordController";
import scale, { verticalScale } from "../../../../../components/src/Scale";
import * as IMG_CONST from '../../assets'
//@ts-ignore
import ModalActivityIndicator from "react-native-modal-activityindicator";
// import { Calendar, CalendarList, Agenda } from 'react-native-calendars';

class Dashboard extends DashbordController {
    renderHaderContainer = () => {
        return (
            <View>
                <View style={styles.renderHaderMainContainer}>
                    <View style={styles.renderHaderContainer}>
                        <Image source={IMG_CONST.menu} style={styles.menuImg} />
                        <Text style={styles.renderHaderTextContainer}>Dashboard</Text>
                        <Image source={IMG_CONST.bell} style={styles.menuImg} />
                    </View>
                    {this.renderImgContainer()}
                    <View>
                        {/* <Calendar
                            markingType={'period'}
                            markedDates={{
                                '2012-05-15': { marked: true, dotColor: '#50cebb' },
                                '2012-05-16': { marked: true, dotColor: '#50cebb' },
                                '2012-05-21': { startingDay: true, color: '#50cebb', textColor: 'white' },
                                '2012-05-22': { color: '#70d7c7', textColor: 'white' },
                                '2012-05-23': { color: '#70d7c7', textColor: 'white', marked: true, dotColor: 'white' },
                                '2012-05-24': { color: '#70d7c7', textColor: 'white' },
                                '2012-05-25': { endingDay: true, color: '#50cebb', textColor: 'white' }
                            }}
                        /> */}
                    </View>

                </View>
                <Text style={styles.renderSelectedTextContainer}>Surgeries on selected data</Text>
            </View>
        )
    }

    renderImgContainer = () => {
        return (
            <View style={styles.renderImgContainer}>
                <View style={styles.renderImgSubContainer}>
                    <Image source={IMG_CONST.person} style={styles.personSecondImg} />
                </View>
                <View style={styles.renderImgTextsubContainer}>
                    <Text style={styles.renderImgTextContainer}>Hi Kaixa,</Text>
                    <Text style={styles.renderImgTextsecondContainer}>Welcome back</Text>
                </View>
            </View>
        )
    }

    renderListContainer = (item: any) => {
        return (
            <View style={styles.renderSelectedContainer}>
                <View style={styles.renderSelectedSubContainer}>
                    <View style={styles.renderPersonImgcontainer}>
                        <Image source={IMG_CONST.person} style={styles.personImg} />
                        <View style={styles.renderListTextContainer}>
                            <Text style={styles.renderListText}>{item.text}</Text>
                            <Text style={styles.renderListSecondText}>{item.text1}</Text>
                        </View>
                    </View>
                    <View style={styles.renderPersonImgSecondSubcontainer}>
                        <Text style={styles.renderPersonImgSecondTextSubcontainer}>{item.text2}</Text>
                        <Text style={styles.renderPersonImgThreeTextSubcontainer}>{item.date}</Text>
                    </View>
                    <View style={styles.lineView}></View>
                    <View style={styles.renderPersonImgSecondSubcontainer}>
                        <TouchableOpacity style={styles.comfirmButton}>
                            <Text style={styles.comfirmButtonText}>Confirmed</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.rightArrowContainer}>
                            <Text style={styles.renderListLastTextContainer}>View Details</Text>
                            <Image source={IMG_CONST.rightArrow} style={styles.rightArrowImg} />
                        </TouchableOpacity>
                    </View>
                </View>

            </View>
        )
    }

    render() {
        return (
            <View style={styles.container}>
                {this.renderHaderContainer()}
                <FlatList
                    data={this.state.data}
                    renderItem={({ item }) => this.renderListContainer(item)}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    renderHaderMainContainer: {
        height: scale(250),
        backgroundColor: '#4e3e71',
        borderBottomEndRadius: scale(30),
        borderBottomLeftRadius: scale(30)
    },

    renderHaderContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: verticalScale(30),
        paddingLeft: scale(20),
        paddingRight: scale(20),
    },

    menuImg: {
        width: scale(24),
        height: scale(18),
        resizeMode: 'contain'
    },

    renderHaderTextContainer: {
        color: '#e8fafe'
    },

    renderImgContainer: {
        marginTop: verticalScale(35),
        marginLeft: scale(20),
        flexDirection: 'row'
    },

    renderImgSubContainer: {
        width: scale(50),
        height: scale(50),
        borderRadius: scale(25),
        backgroundColor: '#37e6e0',
        justifyContent: 'center',
        alignItems: 'center'
    },

    personImg: {
        width: scale(71),
        height: scale(71),
        borderRadius: scale(5),
    },

    personSecondImg: {
        width: scale(35),
        height: scale(35),
    },

    renderImgTextsubContainer: {
        marginLeft: scale(12),
    },

    renderImgTextContainer: {
        color: '#ffffff'
    },

    renderImgTextsecondContainer: {
        color: '#9ebaed',
        marginTop: verticalScale(5)
    },

    renderSelectedTextContainer: {
        color: '#4e3e71',
        marginTop: verticalScale(20),
        marginLeft: scale(24),
        fontSize: scale(24)
    },

    renderSelectedContainer: {
        marginLeft: scale(24),
        marginTop: verticalScale(30),
    },

    renderSelectedSubContainer: {
        width: scale(340),
        borderRadius: scale(15),
        borderColor: "#ddd",
        borderWidth: scale(1),
        paddingBottom: scale(20)
    },

    renderPersonImgcontainer: {
        flexDirection: 'row',
        paddingLeft: scale(17),
        marginTop: verticalScale(16)
    },

    renderPersonImgSubcontainer: {

    },

    renderPersonImgSecondSubcontainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: scale(16),
        paddingRight: scale(15),
        marginTop: verticalScale(15)
    },

    lineView: {
        height: scale(1),
        backgroundColor: '#ddd',
        marginTop: verticalScale(10)
    },

    renderListTextContainer: {
        marginLeft: scale(15),
        marginTop: verticalScale(7)
    },

    renderListText: {
        fontSize: scale(17),
        color: '#4e3e71'
    },

    renderListSecondText: {
        fontSize: scale(14),
        color: '#8e9aab',
        marginTop: verticalScale(4)
    },

    renderPersonImgSecondTextSubcontainer: {
        fontSize: scale(12),
        color: '#b2bac6'
    },

    renderPersonImgThreeTextSubcontainer: {
        fontSize: scale(12),
        color: "#5e6f88"
    },

    comfirmButton: {
        width: scale(70),
        height: scale(18),
        borderRadius: scale(12),
        backgroundColor: '#4e3e71',
        justifyContent: 'center',
        alignItems: 'center'
    },

    comfirmButtonText: {
        color: "#ffffff",
        fontSize: scale(12),
        lineHeight: scale(18),
    },

    renderListLastTextContainer: {
        fontSize: scale(13),
        color: "#4e3e71",
        lineHeight: scale(18),
        marginRight: scale(9)
    },

    rightArrowImg: {
        width: scale(12),
        height: scale(12),
        marginTop: verticalScale(5)
    },

    rightArrowContainer: {
        flexDirection: 'row',
        marginLeft: scale(10)
    }




})

export default Dashboard;