// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Picker,
  View,
  Image,
  TextInput,
  ScrollView,
  FlatList,
} from "react-native";
import Checkbox from '@material-ui/core/Checkbox';
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./CalendarCSS.css"
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import scale, { verticalScale } from "../../../framework/src/Scale";
import { validation_Schema } from "./BookingInfo";
import { Formik, Form, Field } from "formik";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ProgressBar from "./StepperComponent";
import { getAxiosAPI , getErrorMessage} from '../../../framework/src/Blocks/AxiosAPIBlock';
import { SortData } from '../../../framework/src/Utilities';
import GlobalCss from '../../../framework/src/GlobalCss';
import {  showErrorMsg } from "../../../framework/src/CustomPopUp";





export default function DesktopCreateBooking({ navigation }: { navigation: any }) {
  const order = JSON.parse(localStorage.getItem('order') || '{}')


  const [checked, setChecked] = useState(true);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [query1, setQuery1] = useState("");
  const [searchData, setsearchData] = useState([]);
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateP, setSelectedDateP] = useState(order?.patient_age ? new Date(order.patient_age * 1000   ) : new Date());
  const [saveHospital, setSaveHospital] = useState<any>(order.savedHospital ? order.savedHospital : {});
  const [hospital_id, setHospital_id] = useState(order.hospital_id ? order.hospital_id : '')
  const [hospital_name, setHospital_name] = useState('');
  const [patientImage, setPatientImage] = useState(order.patient_image ? order.patient_image : '' );
  const [imageDemo , setImageDemo] = useState(order.patient_imageDemo ? order.patient_imageDemo : '');

  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);
 
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const handleDateChangeP = (date: any) => {
    setSelectedDateP(date);
  };

  const selectedFacility = JSON.parse(localStorage.getItem('selectedFacility') || '[]')
  const getBase64 = (file :any) => {
    return new Promise((resolve,reject) => {
       const reader = new FileReader();
       reader.onload = () => resolve(reader.result);
       reader.onerror = error => reject(error);
       reader.readAsDataURL(file);
    });
  }

const onImageChange = (event :any) => {
    // console.log(event.target.files[0], event.target.files[0].name)
    setPatientImage({image: event.target.files[0], name: event.target.files[0].name, type: event.target.files[0].type})
    const file = event.target.files[0];
    getBase64(file).then((base64: any) => {
          // localStorage["fileBase64"] = base64;
          setImageDemo(base64);
          // console.debug("file stored",base64);
    });

    }

console.log(patientImage)


const getHospitals = async () => {
  setLoader(true) 
    let url = `/bx_block_hospitals/facilities`
    await getAxiosAPI(url).then((res: any) => {
          if(res.error){
            let msg = getErrorMessage(res.data.errors[0])
            console.log(res.data);
            setPopup(true)
            setMessage(msg)
            setLoader(false)
          } else {
            setLoader(false)
            setData(res.data.data.sort((a: any, b: any) => SortData(a.attributes.name.toLowerCase() ,b.attributes.name.toLowerCase())))
          }
        })
};

  useEffect(() => {
    if (selectedFacility.length > 0) {
      setData(selectedFacility)
      console.log(selectedFacility)
    } else {
      getHospitals();
    }

  }, []);
  console.log(data)

  const handleChange = (item: any) => {
    let newdata: any = data.map((x: any) => {

      if (x.id === item.id) {
        x.ischecked = true
      } else {
        x.ischecked = false
        setHospital_id('')
      }
      return x
    })
    setData(newdata)
    setHospital_id(item.id)
    setHospital_name(item.attributes.name)
    setSaveHospital(item)

  };

  const handleSearch = (text: any) => {
    setQuery(text)
    if (text !== "") {
      const newData = data.filter((name: any) => {
        return Object.values(name.attributes)
          .join(" ")
          .toLowerCase()
          .includes(text.toLowerCase());
      });
      setsearchData(newData);
      //console.log(newData);

    } else {
      setsearchData(data);
    }
  };

  const [state1, setState1] = React.useState({
    Xray: order.imagings ? order.imagings.indexOf("X-ray") !== -1 ? true : false : false,
    Carm: order.imagings ? order.imagings.indexOf("Carm") !== -1 ? true : false : false,
    Microscope: order.imagings ? order.imagings.indexOf("Microscope") !== -1 ? true : false : false,
  });
  const [query2, setQuery2] = useState("");
  const [searchData2, setsearchData2] = useState([]);
  const [state2, setState2] = React.useState({
    Draps: order.disposables ? order.disposables.indexOf("draps") !== -1 ? true : false : false,
    Sutures: order.disposables ? order.disposables.indexOf("sutures") !== -1 ? true : false : false,
  });
  const [items, setItems] = useState('');

  const handleChange1 = (event: any) => {
    setState1({ ...state1, [event.target.name]: event.target.checked });
    console.log(state1);
  };




  const [cptCodes, setCptCodes] = useState([]);
      const [dreapsList, setDrapsList] = useState([])
      const [suturesList, setSuturesList] = useState([])
      const [sutureSizeList, setSutureSizeList] = useState([])
      const [DiagnosisCodes, setDiagnosisCodes] = useState<any>([]);

      const getCptCodeList = async () => {
        let url = `/bx_block_code/order_code/cpt_codes_list`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(res.data)
              } else {
                setCptCodes(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
              }
            })
    }
const getDiagnosisList =  async () => {
        let url = `/bx_block_code/order_code/procedure_codes_list`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(msg)
              } else {
                setDiagnosisCodes(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
              }
            })
    }
const getDrapsList = async () => {
        let url = `/bx_block_code/order_code/draps_list`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(msg)
              } else {
                setDrapsList(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
              }
            })
}
const getSuturesList =  async () => {
        let url = `/bx_block_code/order_code/suture_list`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(msg)
              } else {
                setSuturesList(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
              }
            })
}
const getSuturesSizeList =  async () => {
        let url = `/bx_block_code/order_code/suture_size_list`
        await getAxiosAPI(url).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                console.log(msg)
              } else {
                setSutureSizeList(res.data.data.sort((a: any, b: any) => SortData(a.attributes.code ,b.attributes.code)))
              }
            })
    }


      useEffect(() => {
            getCptCodeList();
            getDrapsList()
            getSuturesList()
            getDiagnosisList()
            getSuturesSizeList()
          }, []);

  const [isString, setisString] = useState(false);
  const [isempty, setIsempty] = useState(false);
  const [isempty1, setIsempty1] = useState(false);
  
  const handleSearch2 = (text: any) => {
    setQuery2(text)
    if (text !== '' && !isNaN(+text)) {
      console.log("number")
      setIsempty(false)
      if (text !== "") {
        const newData = cptCodes.filter((name: any) => {
          return Object.values(name.attributes)
            .join(" ")
            .toLowerCase()
            .includes(text.toLowerCase());
        });
        setsearchData2(newData);
        //console.log(newData);

      } else {
        setsearchData2(cptCodes);
      }

    } else if (text !== '' && isNaN(+text)) {
      console.log("string")
      setisString(true)
    } else if (scptCodes.lenght > 1) {
      setIsempty(false)
    } else {
      setisString(false)
      setIsempty(false)
    }
    // setChecked(true)

  };

  const selectDiagnosisCode = (idx: any ,code: any) => {
    const values = [...Diagnosisfields];
    values[idx].code = code;
    setDiagnosisFields(values);
    setQuery1('')
    
  }

  const [colorCode, setColorCode] = useState<any>([])
  const [scptCodes, setsCptCodes] = useState<any>([]);
  const selectCptCode = (code: any, status: any) => {
    setQuery2('')
    let data = {
      code: code,
      material_available: status
    }
  
    // setColorCode(data)
    setsCptCodes((codes: any) => [...codes, data])
    setColorCode((preVal: any) => [...preVal, code])
    // setsCptCodes([...new Set(scptCodes)])
  }

  const [addDiagnosis, setAddDiagnosis] = useState([0]);
  const [Diagnosisfields, setDiagnosisFields] = useState([{ id: new Date().getTime().toString(), code: '', laterality: 'left', isOpen: true }]);
  
  
  function handleChangeDiagnosis(i: any, event: any) {
    const values = [...Diagnosisfields];
    values[i].code = event.target.value;
    setDiagnosisFields(values);
    setQuery1(event.target.value)

    
    if(event.target.value !== "") {
      const newData = DiagnosisCodes.filter((name: any) => {
      return Object.values(name.attributes)
            .join(" ")
            .toLowerCase()
            .includes(event.target.value.toLowerCase());
      });
      setsearchData(newData);
      //console.log(newData);
      
} else {
  setsearchData(DiagnosisCodes);
}

  }
  function handleChangeLitrality(i: any, event: any) {
    const values = [...Diagnosisfields];
    values[i].laterality = event.target.value;
    setDiagnosisFields(values);
  }
  console.log(Diagnosisfields)
  function handleAdd() {
    const values = [...Diagnosisfields];
    values.push({ id: new Date().getTime().toString(), code: '', laterality: 'left', isOpen: false });
    setDiagnosisFields(values);
  }

  function handleRemove(i: any) {
    const values = [...Diagnosisfields];
    values.splice(i, 1);
    setDiagnosisFields(values);
  }

  const handleCollapsed = (field: any) => {

    let newdata: any = Diagnosisfields.map((x: any) => {

      if (x.id === field.id) {
        x.isOpen = !x.isOpen
      } else {
        x.isOpen = false
      }
      return x
    })
    setDiagnosisFields(newdata)

  }
  const addMoreDiagnosiscodes = () => {
    setAddDiagnosis([...addDiagnosis, addDiagnosis.length])
  }

  useEffect(() => {
    if (order.cpt_codes) {
      setsCptCodes(order.cpt_codes)
    }
    if (order.diagnosis_codes) {
      setDiagnosisFields(order.diagnosis_codes)
    }
    if (order.cptColorCodes) {
      setColorCode(order.cptColorCodes)
    }
  }, [])

  let freshCptCode = [...new Set(scptCodes)]

  const ListEmptyView = () => {
    return (
      <View>
        <Text style={styles.heading2}>No Record Found</Text>
        </View>
        )}





  const removeCptCode = (code: any) => {
    console.log(code)
    setsCptCodes(scptCodes.filter((item: any) => item.code !== code.code))
    setColorCode(colorCode.filter((item: any) => +item !== +code.code))
  }

  const saveData = (x: any) => {
    const order = JSON.parse(localStorage.getItem('order') || '{}')
    order.surgery_detail = x.surgery_detail
    order.latex_allergies = x.latex_allergies
    order.or_table = x.or_table
    order.patient_age = Date.parse(selectedDateP.toLocaleDateString()) / 1000
    order.first_name = x.first_name
    order.last_name = x.last_name
    order.pr_op_antibiotics = x.pr_op_antibiotics
    order.procedure_duration = x.procedure_duration
    order.cpt_codes = freshCptCode
    order.patient_type = x.patient_type
    order.imagings = [state1.Xray ? 'X-ray' : null, state1.Carm ? 'C-Arm' : null, state1.Microscope ? 'Microscope' : null]
    order.disposables = [state2.Draps ? 'draps' : null, state2.Sutures ? 'sutures' : null]
    order.diagnosis_codes = Diagnosisfields,
      order.insurance_carrier = x.insurance_carrier,
      order.policy_member_id = x.policy_member_id,
      order.authorization = x.authorization
    order.hospital_id = hospital_id ? hospital_id : order.hospital_id
    order.hospital_name = hospital_name ? hospital_name : order.hospital_name
    order.savedHospital = saveHospital ? saveHospital : order.savedHospital
    order.booking_datetime = Date.parse(value.toLocaleDateString() + " " + selectedDate.toLocaleTimeString())
    order.draps = x.draps
    order.sutures = x.Sutures
    order.sutures_size = x.Sutures_size
    order.patient_gender = x.patient_gender
    order.patient_image = patientImage 
    order.patient_imageDemo = imageDemo
    order.patient_email = x.patient_email
    order.patient_phone = x.patient_phone
    order.cptColorCodes = colorCode
    order.physiotherapy = x.physiotherapy
    order.homecare = x.homecare
    order.benefits = x.benefits

    console.log(order);
    localStorage.setItem('order', JSON.stringify(order))
    navigation.navigate('SelectManufacture')
    // setOpen(true);



  }
  const [open, setOpen] = React.useState(false);

  const handleClose = (id: any) => {
    if (id === '1') {
      navigation.navigate('SelectManufacture')
    } else if (id === '2') {
      navigation.navigate('CMFPA')
    } else {
      setOpen(false);
    }



  };

  const addFacility = () => {
    localStorage.setItem('comingFromBookingFlow', 'yes')
    navigation.navigate('AddFacility')
  }


  return (
                <View style={styles.desktopContainer}>
          <DesktopHeader heading={'Create New Booking'} navigation={navigation} />
          <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={{ flex: 0.85}}>
              <Text style={GlobalCss.watermarkTextCreateB}>CONFIDENTIAL</Text>
              <View>
              <ProgressBar activeStepCount={hospital_id ? 1 : 0}/>
              </View>
        
                        <ScrollView style={styles.outerCard}>
                              <View>
                                      <TouchableOpacity onPress={() => checked ? setChecked(false) : setChecked(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontSize: 20, color: '#4e3e71', fontWeight: 'bold'}}>Booking Date and Time</Text>
                        {checked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                        </TouchableOpacity> 
                        <Collapse in={checked}>
                        <View>
                              <Text style={{ fontSize: 16, color: '#4e3e71', paddingVertical: 20 }}>Select Date</Text>
                        <View>
                        <Calendar
                        minDate={new Date()}
                        onChange={onChange}
                        value={value}
                        className="calenderNew"
                        defaultActiveStartDate={value}
                        />
                              </View>
                        <Text style={{ fontSize: 16, color: '#4e3e71', paddingVertical: 20 }}>Select Time</Text>
                              <View>
                        <View style={styles.timePicker}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                    'aria-label': 'change time',
                              }}
                              />
                        </MuiPickersUtilsProvider>
                        </View>
                        {/* {datevalid ? <Text style={{color: 'red', paddingLeft: 50}}>Please Select future Time</Text> : null} */}
                  </View>
                        </View>
                                    </Collapse>
                                    {/* <Text style={GlobalCss.watermarkText}>CONFIDENTIAL</Text> */}
                                    <TouchableOpacity onPress={() => checked1 ? setChecked1(false) : setChecked1(true)}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{fontSize: 20, color: '#4e3e71', fontWeight: 'bold', paddingVertical: 20}}>Select Your Facility</Text>
                        {checked1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                        </View>
                                    </TouchableOpacity>
                                    <Collapse in={checked1}>
                                          <View style={{flexDirection: 'row'}}>
                                    <View style={styles.searchBox}>
                                    <TextInput
                                                style={styles.input}
                                                placeholder="Search "
                                                autoCapitalize="none"
                                                autoCorrect={false}
                                                clearButtonMode="always"
                                                placeholderTextColor="#939ca3"
                                                value={query}
                                                onChangeText={queryText => handleSearch(queryText)}
                                          />
                                    <TouchableOpacity onPress={() => setQuery('')}>
                                    <img src={query === '' ? require("../assets/search.png" ) : require("../assets/cross.png" )} style={{height: 30, width: 30, paddingRight: 15, paddingTop: 10}}/>
                                    </TouchableOpacity> 
                                    </View> 
                                      <TouchableOpacity onPress={() => addFacility()}>
                                          <View style={styles.button0}>
                                          <Text style={styles.buttonText0}>Add Facility</Text>
                                          </View>
                                    </TouchableOpacity>
                                          </View>
                                          <View style={styles.card}>
                        <ScrollView style={{flex: 1}} >
                        {
                              (query.length > 0 && searchData.length > 0) ? 
                              <View style={styles.add}>
                              <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                              </View> 
                                   : 
                                   (query.length > 0 && searchData.length === 0) ? 
                                   <View style={styles.add}>
                                   <Text style={styles.resultText}>Searched facility not found, {'\n'} 
                                   <Text style={{color: '#1083f3', fontWeight: 'bold', paddingHorizontal: 50}} onPress={() => navigation.navigate('AddNewFacility')}>Add Facility </Text></Text>
                                   </View> : null
                             }
                              
                           <FlatList                             
                              data={query.length <1 ? data: searchData}
                              ListEmptyComponent={ListEmptyView()}
                              keyExtractor={(item: any) => item.id}
                              renderItem={({ item }) => (
                              <TouchableOpacity disabled={item.attributes.booking_coordinator && item.attributes.material_manager ? false: true} onPress={() => handleChange(item)} >
                        <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                        <View style={{flexDirection: 'row' , flex: 0.9}}>
                                          <img src={item.attributes.logo ? item.attributes.logo : require("../assets/hospital copy.png")} style={{ height: 56, width: 56}}/> 
                                              <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                               <Text style={styles.names}>{item.attributes.name}</Text>    
                                                  <Text style={styles.role} numberOfLines={2}>{item.attributes.address}</Text>
                                                  <Text style={styles.disableMsg} numberOfLines={2}>{showErrorMsg(item)}</Text>
                                                </View>
                                        </View>
                                        {item.attributes.booking_coordinator && item.attributes.material_manager &&
                                          <View   style={{marginVertical: 'auto', flex: 0.1}}>
                                       <img src={item.ischecked ? require("../assets/radio_active.png") : require("../assets/radio_unactive.png")}  style={{height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }}/>      
                                       </View>}
                                    </View>
                              </TouchableOpacity>
                             
                              )}
                              />
                              </ScrollView>
                              </View> 
                                    </Collapse>

                                    <View>
                                   
                                    <TouchableOpacity onPress={() => checked2 ? setChecked2(false) : setChecked2(true)}>
                                    {/* <Text style={{fontSize: 20, color: '#4e3e71', fontWeight: 'bold', paddingVertical: 25}}>Surgery Details</Text>                                       */}
                                          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                          <Text style={{fontSize: 20, color: '#4e3e71', fontWeight: 'bold',paddingBottom: 0, padding: 20}}>Booking Information</Text>
                                          {checked2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                                          </View>
                                          </TouchableOpacity>
                                          <Collapse in={checked2}>
                      
                                                <View>
                                                      <Formik
                                          initialValues={{ 
                                                surgery_detail: order.surgery_detail ? order.surgery_detail : '',
                                                procedure_duration: order.procedure_duration ? order.procedure_duration : '',
                                                or_table: order.or_table ? order.or_table : '',
                                                first_name: order.first_name ? order.first_name : '',
                                                last_name: order.last_name ? order.last_name : '',
                                                patient_age: order.patient_age ? order.patient_age : '',
                                                diagnosis_code: '',
                                                patient_type: order.patient_type ? order.patient_type : 'Ambulatory',
                                                latex_allergies: order.latex_allergies ? order.latex_allergies : 'Yes',
                                                pr_op_antibiotics: order.pr_op_antibiotics ? order.pr_op_antibiotics : 'Yes',
                                                insurance_carrier: order.insurance_carrier ? order.insurance_carrier : '',
                                                policy_member_id: order.policy_member_id ? order.policy_member_id : '',
                                                authorization: order.authorization ? order.authorization : '',
                                                draps: order.draps ? order.draps : '',
                                                Sutures: order.sutures ? order.sutures : '',
                                                Sutures_size: order.sutures_size ? order.sutures_size : '',
                                                patient_gender: order.patient_gender ? order.patient_gender : '',
                                                patient_email: order.patient_email ? order.patient_email : '',
                                                patient_phone: order.patient_phone ? order.patient_phone : '',
                                                physiotherapy: order.physiotherapy ? order.physiotherapy : 'Yes',
                                                homecare: order.homecare ? order.homecare : 'Yes',
                                                benefits: order.benefits ? order.benefits : ''
                  
              

            }}
            validationSchema={validation_Schema}
              onSubmit={(values, actions) => {
                  if(scptCodes.length < 1 ){
                        setIsempty(true)
                        console.log(isempty);
                      } else if(Diagnosisfields[0].code === '') {
                        setIsempty1(true)
                      } else {
                    console.log(values);
                        saveData(values)
                      }
              }}
            >
                  {
                  ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                 
            <View style={{flexDirection: 'row'}}>
        
            <View style={styles.info}>
                        
                        <RadioGroup aria-label="quiz" name="quiz" value={values.patient_type} onBlur={handleBlur('patient_type')} onChange={handleChange('patient_type')}>
                        <View style={{flexDirection: 'row' }}>
                        <FormControlLabel style={{color: '#4e3e71'}}  value="Ambulatory"  control={<Radio style={{color: '#4e3e71'}} />} label="Ambulatory" />
                        <FormControlLabel style={{color: '#4e3e71'}}  value="Admitted" control={<Radio style={{color: '#4e3e71'}} />} label="Admitted" />
                        </View>
                        </RadioGroup>
                        {touched.patient_type && errors.patient_type ?   <Text style={{color: 'red'}}>{errors.patient_type}</Text> : null}
                       
                       
                        <Text style={styles.headingH2}>Latex Allergies</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('latex_allergies')} value={values.latex_allergies} onChange={handleChange('latex_allergies')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{color: '#4e3e71'}}  value="Yes" control={<Radio style={{color: '#4e3e71'}} />} label="Yes" />
                        <FormControlLabel style={{color: '#4e3e71'}}  value="No" control={<Radio style={{color: '#4e3e71'}} />} label="No" />
                        <FormControlLabel style={{color: '#4e3e71'}}  value="Unknown" control={<Radio style={{color: '#4e3e71'}} />} label="Unknown" />
                        </View>
                        </RadioGroup>
                        {touched.latex_allergies && errors.latex_allergies ?   <Text style={{color: 'red'}}>{errors.latex_allergies}</Text> : null}

                        <View>
                              <TextInput 
                              placeholder="Surgery Details"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('surgery_detail')}
                              onChangeText={handleChange('surgery_detail')}
                              value={values.surgery_detail}
                              />
                              {touched.surgery_detail && errors.surgery_detail ?   <Text style={{color: 'red'}}>{errors.surgery_detail}</Text> : null}

                               <TextInput 
                              placeholder="Length of Procedure"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('procedure_duration')}
                              onChangeText={handleChange('procedure_duration')}
                              value={values.procedure_duration}
                              />
                              {touched.procedure_duration && errors.procedure_duration ?   <Text style={{color: 'red'}}>{errors.procedure_duration}</Text> : null}

                               <TextInput 
                              placeholder="O.R Table"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('or_table')}
                              onChangeText={handleChange('or_table')}
                              value={values.or_table}
                              />
                              {touched.or_table && errors.or_table ?   <Text style={{color: 'red'}}>{errors.or_table}</Text> : null}
                        </View>

                        <Text style={styles.headingH2}>Choose Items</Text>
                        <View style={{flexDirection: 'row'}}>
                        
                        <FormControlLabel
                              control={
                              <Checkbox
                                    checked={state1.Xray}
                                    onChange={handleChange1}
                                    name="Xray"
                                    style={{color: '#4e3e71'}} 
                              />
                              }
                              label="X-Ray"
                              style={{color: '#4e3e71'}} 
                              />
                               <FormControlLabel
                              control={
                              <Checkbox
                                    checked={state1.Carm}
                                    onChange={handleChange1}
                                    name="Carm"
                                    style={{color: '#4e3e71'}} 
                              />
                              }
                              label="C-Arm"
                              style={{color: '#4e3e71'}} 
                              />
                               <FormControlLabel
                              control={
                              <Checkbox
                                    checked={state1.Microscope}
                                    onChange={handleChange1}
                                    name="Microscope"
                                    style={{color: '#4e3e71'}} 
                              />
                              }
                              label="Microscope"
                              style={{color: '#4e3e71'}} 
                              />
                              </View>

                        <View>
                          <Text style={styles.headingH2}>Disposables</Text>
                          <Text style={styles.inputlabel}>Drapes</Text>
                          <View style={styles.pickerContainer}>
                            <Picker
                              selectedValue={values.draps}
                              style={styles.picker}
                              onValueChange={handleChange('draps')}
                            >
                              <Picker.Item value={null} label='Select Drapes :-' />
                              {
                                    dreapsList.map((item: any) => (
                                          <Picker.Item value={item.attributes.name} label={item.attributes.name} />
                                    ))
                              }
                            </Picker>
                          </View>
                          <Text style={styles.inputlabel}>Sutures</Text>
                          <View style={styles.pickerContainer}>
                            <Picker
                              selectedValue={values.Sutures_size}
                              style={styles.picker}
                              onValueChange={handleChange('Sutures_size')}
                            >
                              <Picker.Item value={null} label='Size :-' />
                              {
                                    sutureSizeList.map((item: any) => (
                                          <Picker.Item label={item.attributes.size} value={item.attributes.size} />
                                    ))
                              }
                            </Picker>
                          </View>
                          <View style={styles.pickerContainer}>
                            <Picker
                              selectedValue={values.Sutures}
                              style={styles.picker}
                              onValueChange={handleChange('Sutures')}
                            >
                              <Picker.Item value={null} label='Select Sutures :-' />
                              {
                                    suturesList.map((item: any) => (
                                          <Picker.Item value={item.attributes.name} label={item.attributes.name} />
                                    ))
                              }
                            </Picker>
                          </View>


                        </View>
                        <View>
                        <Text style={styles.headingH2}>Pre-Op Antibiotics</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('pr_op_antibiotics')} value={values.pr_op_antibiotics} onChange={handleChange('pr_op_antibiotics')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{color: '#4e3e71'}} value="Yes" control={<Radio style={{color: '#4e3e71'}} />} label="Yes" />
                        <FormControlLabel style={{color: '#4e3e71'}} value="No" control={<Radio style={{color: '#4e3e71'}} />} label="No" />
                        </View>
                        </RadioGroup>
                        {touched.pr_op_antibiotics && errors.pr_op_antibiotics ?   <Text style={{color: 'red'}}>{errors.pr_op_antibiotics}</Text> : null}
                        </View>
                        <View>
                        <Text style={styles.headingH2}>Postop</Text>
                        <Text style={styles.inputlabel}>Physiotherapy</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('physiotherapy')} value={values.physiotherapy} onChange={handleChange('physiotherapy')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{color: '#4e3e71'}} value="Yes" control={<Radio style={{color: '#4e3e71'}} />} label="Yes" />
                        <FormControlLabel style={{color: '#4e3e71'}} value="No" control={<Radio style={{color: '#4e3e71'}} />} label="No" />
                        </View>
                        </RadioGroup>
                        {touched.physiotherapy && errors.physiotherapy ?   <Text style={{color: 'red'}}>{errors.physiotherapy}</Text> : null}
                        </View>
                        <View>
                        <Text style={styles.inputlabel}>Homecare</Text>
                        <RadioGroup aria-label="quiz" name="quiz" onBlur={handleBlur('homecare')} value={values.homecare} onChange={handleChange('homecare')}>
                        <View style={{flexDirection: 'row'}}>
                        <FormControlLabel style={{color: '#4e3e71'}} value="Yes" control={<Radio style={{color: '#4e3e71'}} />} label="Yes" />
                        <FormControlLabel style={{color: '#4e3e71'}} value="No" control={<Radio style={{color: '#4e3e71'}} />} label="No" />
                        </View>
                        </RadioGroup>
                        {touched.homecare && errors.homecare ?   <Text style={{color: 'red'}}>{errors.homecare}</Text> : null}
                        </View>

                        <Text style={styles.headingH2}>Insurance Information</Text>
                              <TextInput 
                              placeholder="Insurance carrier"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('insurance_carrier')}
                              onChangeText={handleChange('insurance_carrier')}
                              value={values.insurance_carrier}
                              />
                                  <TextInput 
                              placeholder="Policy/member id"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('policy_member_id')}
                              onChangeText={handleChange('policy_member_id')}
                              value={values.policy_member_id}
                              />
                                  <TextInput 
                              placeholder="Authorization"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('authorization')}
                              onChangeText={handleChange('authorization')}
                              value={values.authorization}
                              />
                                  <TextInput 
                              placeholder="Benefits"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('benefits')}
                              onChangeText={handleChange('benefits')}
                              value={values.benefits}
                              />
                                        
                        
            </View>
            <View style={{width: '50%'}}>
                 <View>
                 <Text style={styles.headingH3}>Patient Information</Text>
                                     <TextInput 
                              placeholder="First Name"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('first_name')}
                              onChangeText={handleChange('first_name')}
                              value={values.first_name}
                              />
                              {touched.first_name && errors.first_name ?   <Text style={{color: 'red'}}>{errors.first_name}</Text> : null}
                                    
                                     <TextInput 
                              placeholder="Last Name"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('last_name')}
                              onChangeText={handleChange('last_name')}
                              value={values.last_name}
                              />
                              {touched.last_name && errors.last_name ?   <Text style={{color: 'red'}}>{errors.last_name}</Text> : null}


                               {/* <TextInput 
                              placeholder="patient age"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              keyboardType='numeric'
                              maxLength={2}
                              onBlur={handleBlur('patient_age')}
                              onChangeText={(e) => handleDateChange(e)}
                              value={values.patient_age}
                              /> */}
    
                              
                              <View style={{ paddingVertical: 20}}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                disableFuture={true}
                                style={{marginLeft: 10}}
                                openTo="date"
                                format="MM/dd/yyyy"
                                label="Date of Birth"
                                views={["year", "month", "date"]}
                                value={selectedDateP}
                                onChange={handleDateChangeP}
                              />
                              </MuiPickersUtilsProvider>
                              {touched.patient_age && errors.patient_age ?   <Text style={{color: 'red'}}>{errors.patient_age}</Text> : null}
                              </View>
                                
                              <TextInput 
                              placeholder="Patient Email"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('patient_email')}
                              onChangeText={handleChange('patient_email')}
                              value={values.patient_email}
                              />
                              {touched.patient_email && errors.patient_email ?   <Text style={{color: 'red'}}>{errors.patient_email}</Text> : null}
                                <TextInput 
                              placeholder="Patient Phone Number"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              onBlur={handleBlur('patient_phone')}
                              onChangeText={handleChange('patient_phone')}
                              value={values.patient_phone}
                              />
                              {touched.patient_phone && errors.patient_phone ?   <Text style={{color: 'red'}}>{errors.patient_phone}</Text> : null}
                 <View style={styles.pickerContainer}>
                              <Picker
                                    selectedValue={values.patient_gender}
                                    style={styles.picker}
                                    onValueChange={handleChange('patient_gender')}
                              > 
                                    <Picker.Item value={null} label='Select gender' />
                                    <Picker.Item label="Male" value="Male" />
                                    <Picker.Item label="Female " value="Female" />
                                    <Picker.Item label="Other" value="Other" />
                                    
                              </Picker>
                              {touched.patient_gender && errors.patient_gender ?   <Text style={{color: 'red'}}>{errors.patient_gender}</Text> : null}    
                         </View>
                         <View style={styles.patientImage}>
                               {
                                     imageDemo ? 
                                     <img src={imageDemo} alt="preview image" style={{width: 60, height: 60, paddingTop: 10, borderRadius: '50%', }}/> :
                                     <img src={order?.patient_image ? order.patient_image : require("../assets/avatar-chat1.png")} style={{height: 60, width: 60, paddingTop: 10, borderRadius: '50%' }} />
                               }
                         <label htmlFor="file">
                 
                                    <View style={styles.editIcon}>
                                          <TouchableOpacity style={styles.buttonImg}>
                                          <Text style={styles.buttonTextImg}>Select Patient Image</Text>
                                          </TouchableOpacity>
                                    </View>
                                    
                                    </label>

                               <input 
                               type='file' 
                               id="file" 
                               style={{display:'none'}} 
                               name="patient_image"   
                               accept="image/*"
                               onChange={onImageChange}
                               />
                         </View>
                        </View>
                                    <View>
                              <Text style={styles.headingH2}>Select Codes</Text>            
                                    <TextInput 
                            placeholder={freshCptCode.length > 0 ? "Select more codes or proceed" : "Select (CPT) codes"}
                              keyboardType='numeric'
                              placeholderTextColor={ freshCptCode.length > 0 ? '#00663d' : "#b2bac6"}
                              style={[styles.input1, { borderColor: freshCptCode.length > 0 ? '#00663d' : 'red' }]}
                              value={query2}
                              onChangeText={queryText => handleSearch2(queryText)}
                              />
                              {isString ?   <Text style={{color: 'red'}}>Numbers are allowed</Text> : null}
                              {isempty ?   <Text style={{color: 'red'}}>Cpt codes are required</Text> : null}
                              {
                                    (query2 !== "" && searchData2.length > 0 && !isString) ? 
                              <FlatList 
                                style={{ height: 300, backgroundColor: 'white' }}
                                data={query2.length <1 ? cptCodes: searchData2}
                                keyExtractor={(item: any, index:any) => index}
                                renderItem={({ item }) => (
                                      <View style={styles.cptcodesContainer}>
                                    <TouchableOpacity disabled={colorCode.includes(item.attributes.code) ? true : false} onPress={() => selectCptCode(item.attributes.code, item.attributes.material_available)}>
                                          <Text style={colorCode.includes(item.attributes.code) ? {backgroundColor: '#4e3e71', color: "#fff", fontSize: 18, paddingHorizontal: 10, borderRadius: 5} : {
                                                backgroundColor: '#e2e5e9', color: "#4e3e71", fontSize: 18, paddingHorizontal: 10, borderRadius: 5
                                      }}>{item.attributes.code}
                                        {"   "}
                                        <Text style={{ color: '#626f84', fontSize: 14 }}>{item.attributes.description ? (item.attributes.description).substring(0, 160) + "..." : "No description available"} </Text>
                                      </Text>
                                          </TouchableOpacity>
                                         
                                      </View>
                                )}
                              /> : null

                              }
                               <FlatList 
                                data={freshCptCode}
                                horizontal={false}
                                numColumns={5}
                              //   keyExtractor={(item: any) => item.id}
                                renderItem={({ item }: {item :any}) => (
                                          <TouchableOpacity >
                                          <View style={styles.cptcodes}>
                                          <Text style={styles.cptText}>{item.code}</Text>
                                          <CancelIcon onClick={() => removeCptCode(item)} style={{ color: '#4e3e71', paddingLeft: "5px"}}/>
                                    </View>
                                          </TouchableOpacity>
                                )}
                              />

                              
                              <Text style={styles.headingH2}>Diagnosis Code</Text>
                               
                              {
                                   Diagnosisfields.map((field, idx) => {
                                        return (
                                              <View>
                                          <TouchableOpacity onPress={() => handleCollapsed(field)}>
                                          <View style={styles.drawers}>
                                                <Text style={styles.headingH2}>Diagnosis Code {idx+1}</Text>
                                                {field.isOpen ? <ArrowDropUpIcon style={{color : '#4e3e71', paddingTop: 5 }}/> : <ArrowDropDownIcon style={{color : '#4e3e71', paddingTop: 5 }}/> }
                                          </View>
                                          </TouchableOpacity>
                                             
                                          <Collapse in={field.isOpen}>
                                              <View key={`${field}-${idx}`} style={{marginHorizontal: 10, }}>
                                                    <TextInput 
                              placeholder="Diagnosis Code"
                              placeholderTextColor="#b2bac6"
                              style={styles.input1}
                              value={field.code}
                              onChange={e => handleChangeDiagnosis(idx, e)}
                              />
                                {isempty1 ?   <Text style={{color: 'red'}}>Diagnosis Code are required</Text> : null}
                               {
                                    (query1 !== "" && searchData.length) ? 
                              <FlatList 
                              style={{height: 300,  backgroundColor: 'white' }}
                                data={query1.length <1 ? DiagnosisCodes : searchData}
                                ListEmptyComponent={ListEmptyView()}
                                keyExtractor={(item: any, index:any) => index}
                                renderItem={({ item }) => (
                                      <View style={styles.cptcodesContainer}>
                                            <TouchableOpacity onPress={() => selectDiagnosisCode(idx, item.attributes.code)}>
                                          <Text style={DiagnosisCodes.includes(item.attributes.code) ? {backgroundColor: '#4e3e71', color: "#fff", fontSize: 18, paddingHorizontal: 10, borderRadius: 5} : {
                                                backgroundColor: '#e2e5e9', color: "#4e3e71", fontSize: 18, paddingHorizontal: 10, borderRadius: 5
                                                  }}>{item.attributes.code} <Text style={{ color: '#626f84', fontSize: 14 }}>{item.attributes.description ? (item.attributes.description).substring(0, 35) + "..." : "No description available"} </Text></Text>
                                          </TouchableOpacity>
                                         
                                      </View>
                                )}
                              /> : null

                              }
                              <Text style={styles.headingH2}>Laterality</Text>
                              <RadioGroup aria-label="quiz" name="quiz" value={field.laterality} onChange={e => handleChangeLitrality(idx, e)}>
                              <View style={{flexDirection: 'row'}}>
                              <FormControlLabel style={{color: '#4e3e71'}}  value="left" control={<Radio style={{color: '#4e3e71'}} />} label="Left" />
                              <FormControlLabel style={{color: '#4e3e71'}}  value="right" control={<Radio style={{color: '#4e3e71'}} />} label="Right" />
                              <FormControlLabel style={{color: '#4e3e71'}}  value="both" control={<Radio style={{color: '#4e3e71'}} />} label="Both" />
                              </View>
                              
                              </RadioGroup>
                            
                                              </View>
                                              </Collapse>
                                              </View>
                                        )
                                  })  
                              }
                              
                                    

                              <TouchableOpacity onPress={() => handleAdd()}>
                                    
                              <View style={styles.buttonNew}>
                              <Text style={styles.buttonTextNew}> Add More Diagnosis Codes</Text>
                              </View>
                        </TouchableOpacity>

                              
                              
                          <View>
                        <TouchableOpacity onPress={() => handleSubmit()} >
                              <View style={styles.button}>
                              <Text style={styles.buttonText}>Continue</Text>
                              </View>
                        </TouchableOpacity>
                        </View>
                       

                            </View>
                </View>
                          
                </View>    
                    )}
                                    </Formik> 
                              </View>
                              <View>
                                    
                              </View>
                          </Collapse>
                                    </View>
                              </View>
                        </ScrollView>
              
            </View>          
                   
                          </View>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
          >
            <View style={{width: 500}}>
                          <View style={styles.headingView}>
                            <Text style={styles.heading}>Material Choose</Text>
                          </View>
                            <DialogContent>
                            <View>
                            <TouchableOpacity onPress={() => handleClose('1')}>
                                <View style={styles.Dialogbutton}>
                                <Text style={styles.DialogbuttonText}>Choose Manufaturer</Text>
                                </View>
                              </TouchableOpacity>
                              <View style={styles.text}>
                                <Text style={{ fontSize: 24, color: '#4e3e71' }}>Or</Text>
                              </View>
                              <View>
                              <TouchableOpacity onPress={() => handleClose('2')}>
                              <View style={styles.Dialogbutton}>
                                <Text style={styles.DialogbuttonText}>Choose from procedure list</Text>
                                  </View>
                                </TouchableOpacity>
                              </View>
                            </View>
                            </DialogContent>
            </View>
                           
                          </Dialog>
                  </View >
      )
}

const styles = StyleSheet.create({
  desktopContainer: {
    flex: 1,
    flexDirection: 'column'
  },
  heading2: {
    marginVertical: 20,
    marginHorizontal: 'auto',
    color: '#b2bac6',
    fontSize: 24,
    fontWeight: 'bold',
    flexDirection : 'row',
    justifyContent: 'center'
  },
  pickerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5',
    paddingVertical: 10
  },
  inputlabel: {
    color: '#4e3e71',
    fontSize: 18,
    paddingVertical: 5,
  },
  picker: {
    cursor: 'pointer',
    width: '100%',
    height: 50,
    color: '#4e3e71',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'transparent',
    backgroundColor: 'transparent'
  },
  menuWrapper: {
    // paddingTop: 
  },
  headingView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 20,

  },
  heading: {
    color: '#e8fafe',
    fontSize: 20,
    marginHorizontal: 'auto'
  },
  text: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
  },
  menuName: {
    color: 'white',
    fontSize: 15,
    paddingHorizontal: 5,
    marginVertical: 'auto'
  },
  desktopMenu: {
    flex: 0.15,
    flexDirection: 'column',
    backgroundColor: '#4e3e71',
    paddingTop: 20,
    height: 2050

  },
  drawers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  cptText: {
    color: '#4e3e71',
    paddingHorizontal: 2
  },
  cptcodes: {
    flexDirection: 'row',
    backgroundColor: '#e2e5e9',
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 10,
    marginHorizontal: 5,
    marginVertical: 5
  },
  cptcodesContainer: {
    flex: 1,
    backgroundColor: '#eff8ff',
    marginHorizontal: 10,
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderBottomColor: '#8f8f8f',
    borderBottomWidth: 1,
  },
  headingH1: {
    color: '#4e3e71',
    fontSize: 25,
    //     marginVe 20
  },
  headingH2: {
    color: '#4e3e71',
    fontSize: 20,
    fontWeight: 'bold',
    marginVertical: 15
  },
  headingH3: {
    color: '#4e3e71',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15
  },
  info: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 10,
    width: '50%'
  },
  listItem: {
    height: 88,
    marginHorizontal: 26,
    borderColor: '#e5e5e5',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listItem_active: {
    height: 88,
    marginHorizontal: 26,
    borderColor: '#4e3e71',
    borderWidth: 1,
    borderRadius: 12,
    marginVertical: 5,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  role: {
    color: '#b2bac6',
    paddingHorizontal: 11,
    width: scale(100),

  },
  disableMsg: {
    color: 'red',
    paddingHorizontal: 11,
    width: scale(100),

  },
  names: {
    color: '#4e3e71',
    fontSize: 15,
    paddingHorizontal: 11

  },
  patientImage:{
    flexDirection: 'row',
    marginHorizontal: 20,
    // justifyContent: 'space-between',
},
editIcon:{
      // flex: 1,
      // flexDirection: 'column',
      // marginVertical: 30,
      // marginHorizontal: 50
      marginHorizontal: 50
},

  resultText: {
    paddingVertical: 10,
    fontSize: 18

  },
  add: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  card: {
    flex: 1,
    height: 350,
    width: '70%',
    marginTop: 20,
    borderRadius: 6,
    elevation: 3,
    backgroundColor: 'transparent',
  },
  bottomDesktopCard: {
    flex: 1,
    flexDirection: 'row'
  },
  timePicker: {
    width: '50%',
    borderRadius: 10,
  },
  searchBox: {
    flexDirection: 'row',
    flex: 1,
    marginVertical: 20,
    borderWidth: 1,
    borderColor: '#8a8a8a',
    borderRadius: 50
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginLeft: 300,
    marginVertical: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    width: '50%'
    // marginTop: 10
  },
  buttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  buttonImg: {
    borderRadius: 12,
    paddingVertical: 5,
    paddingHorizontal: 10,
    // marginLeft: 300,
    marginVertical: 20,
    backgroundColor: '#4e3e71',
    // marginBottom: 10,
    // width: '50%'
    // marginTop: 10
  },
  buttonTextImg: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  Dialogbutton: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: '#4e3e71',
  },
  DialogbuttonText: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  button0: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 20,
    marginVertical: 20,
    backgroundColor: '#4e3e71',
    marginBottom: 10,
    // width: '50%'
    marginHorizontal: 10
  },
  buttonText0: {
    color: '#e8fafe',
    fontSize: 17,
    textAlign: 'center',
  },
  input: {
outlineStyle: 'none',
    flex: 1,
    paddingLeft: 10,
    height: 48,
    color: '#4e3e71',
    fontSize: 16,
    paddingStart: 20,
    // width: 950
  },
  input1: {
    outlineStyle: 'none',
    flex: 1,
    marginTop: 20,
    marginBottom: 10,
    paddingVertical: 10,
    paddingLeft: 10,
    fontSize: 18,
    color: '#112950',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5'
  },
  outerCard: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: '#7f7f7f',
    Height: 500,
    margin: 15,
    padding: 15,
    flexDirection: 'column'

  },
  buttonNew: {
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 5,
    backgroundColor: '#4e3e71',
    marginVertical: 20,
    marginHorizontal: 50
  },
  buttonTextNew: {
    color: 'white',
    fontSize: 20,
    textAlign: 'center',
  },


})

// Customizable Area End