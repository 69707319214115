export const leftArrow = require('../assets/leftArrow.png');
export const hospital = require('../assets/hospital.png')
export const selectedBox = require('../assets/activeImage.png')
export const unSelectedBox = require('../assets/inActive.png');
export const homeImg = require("../assets/home.png");
export const menu = require('../assets/menuImg.png');
export const bell = require('../assets/bell.png');
export const person = require('../assets/person.jpg');
export const rightArrow = require('../assets/rightArrow.png');
export const searchIcon = require('../assets/searchIcon.png');
export const filterIcon = require('../assets/filtericon.png');
export const downArrow = require("../assets/DownArrow1.png");
export const chat = require("../assets/message.png");
export const newDownArrow = require("../assets/downArrowImg.png");
export const upDownArrow = require("../assets/upDownImg.png");
export const boxImg = require("../assets/checkbox.png");
export const BarCodeImg = require("../assets/BarCodeImg.png");
export const setting = require("../assets/Settings.png");
export const personImg = require("../assets/personImg.png");
export const greenImg = require("../assets/greenImg.png");
export const redImg = require("../assets/redImg.png");
export const writeCheckBox = require("../assets/writeCheckBox.png");
export const editIcon =  require("../assets/editIcon.png");
export const personImg1 = require("../assets/PersonImg1.png");
export const bitmap = require("../assets/bitmap.png");
export const backIcon = require("../assets/backIcon.png");
export const  requestImg = require("../assets/bitmap.png");


