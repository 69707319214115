import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../../../framework/src/StorageProvider";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  route:any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  managerDetail:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ValidateManagerDetailController extends BlockComponent<Props, S, SS> {
  apiAcceptManagerId:any;
  apiRejectManagerId:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      managerDetail: JSON.parse(localStorage.getItem('managerData')|| '{}')
    };

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiAcceptManagerId !== null &&
      this.apiAcceptManagerId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiAcceptManagerId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.acceptManagerSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.acceptManagerFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiRejectManagerId !== null &&
      this.apiRejectManagerId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiRejectManagerId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson ) {
        this.rejectManagerSuccess();
      } else {
        //Check Error Response
        this.rejectManagerFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }


  _handleAcceptManager = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAcceptManagerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_material_manager/material_manager/accept_manager?id=${this.state.managerDetail.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  acceptManagerSuccess = (data: any) => {
    this.props.navigation.navigate("MaterialConfirmed")
  };

  acceptManagerFail = () => {};

  _handleRejectManager = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRejectManagerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_material_manager/material_manager/reject_manager?id=${this.state.managerDetail.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  rejectManagerSuccess = () => {
    this.showAlert('Sucess','Invitation Rejected')
  };

  rejectManagerFail = () => {};
  // Customizable Area End
}
