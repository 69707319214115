import React,{ useState} from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TextInput,
Button,
Image,
ScrollView,
TouchableWithoutFeedback,
Platform } from 'react-native';

export default function CMFPconfirm({navigation}: {navigation: any}) {
      return (
            <View style={styles.container}>
                   <View style={{flexDirection: 'column', flex: 0.8}}>
                        <Text style={styles.text}>Your list is added sucessfully</Text>
                  </View>
                  <View style={{flex: 0.2}}>
                  <TouchableOpacity onPress={() => navigation.navigate('CMFPB')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Choose Another Material</Text>
                        </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => navigation.navigate('CartList')}>
                        <View style={styles.button}>
                        <Text style={styles.buttonText}>Confirm Material</Text>
                        </View>
                  </TouchableOpacity>
                  </View>
            </View>
      )
};

const styles = StyleSheet.create({
      container: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-around',
            backgroundColor: "#ffff"

          },
          text: {
            // paddingHorizontal: 50,
            marginVertical: 'auto',
            marginHorizontal: 24,
            alignItems: 'center',
            // paddingVertical: 10,
            fontSize: 24,
            color: '#4e3e71'
          },
          button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginHorizontal: 20,
            backgroundColor: '#4e3e71',
            marginBottom: 10,
            marginTop: 10
          },
          buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
          },
})