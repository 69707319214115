import React , { useState , useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  ActivityIndicator
} from 'react-native';
import * as IMG_CONST from './assets'
import { getAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import NootificationDetails from "./NotificationDetails";
import { Dialog } from '@material-ui/core';
import { SessionOutModal } from '../../../framework/src/CustomPopUp';

export default function NotiFication({ navigation }: { navigation: any }) {

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [openDetails, setOpenDetails] = useState(false);
  const [sessionPopup, setSessionPopup] = useState(false)

  const handleClose = () => {
    setOpenDetails(false);
  }


  useEffect(() => {

    const getNotifications = async () => {
      let url = "/bx_block_notifications/notifications?limit=50";
      await getAxiosAPI(url).then((res: any) => {
        if(res.error){
          console.log(res.error);
          setLoader(false)
          let msg = getErrorMessage(res.data.errors[0])
          if(msg === 'Your session has been expired. Click here to login'){
            setSessionPopup(true)
          }
        } else {
          setData(res.data.data)
          setLoader(false)
        }
      })
    }
    getNotifications()

  }, []);

    const TodaysDate = new Date().toLocaleDateString()
  const yesterdayDate = new Date(Date.now() - 864e5).toLocaleDateString()
  let datesArray: any = []

  const getDate = (time: any) => {
    var d = new Date(time)
    var newdate = d.toLocaleDateString()
    if (datesArray.includes(newdate)) {
      return null
    } else {

      datesArray.push(newdate)
      // console.log(datesArray)
      return newdate
    }

    // return d.toLocaleDateString()
  }
  
  function formatTime(timeCreated: any) {
    var d = new Date(timeCreated)
    // return d.toLocaleTimeString([], { timeStyle: 'short' })
    const string : any =  d.getTime().toString()

    var diff = Math.floor((Date.now() - string) / 1000);
    var interval = Math.floor(diff / 31536000);

    if (interval >= 1) {
      return interval + "y";
    }
    interval = Math.floor(diff / 2592000);
    if (interval >= 1) {
      return interval + "m";
    }
    interval = Math.floor(diff / 604800);
    if (interval >= 1) {
      return interval + "w";
    }
    interval = Math.floor(diff / 86400);
    if (interval >= 1) {
      return interval + "d";
    }
    interval = Math.floor(diff / 3600);
    if (interval >= 1) {
      return interval + " hours ago";
    }
    interval = Math.floor(diff / 60);
    if (interval >= 1) {
      return interval + " m";
    }
    return "1 minute ago";
  }


  const getTime = (time: any) => {
    var d = new Date(time)
    return d.toLocaleTimeString([], { timeStyle: 'short' })
  
  }

  const showDetails = (id: any) => {
    if (id) {
      localStorage.setItem("notificationId", id.order_propose_date_request_id)
      if(window.screen.width < 950 ){
        navigation.navigate("NotificationDetails")
      }else {
        setOpenDetails(true)
      }
      
    } else {
      console.log("no id found")
    }
  }

    return (
      <View style={styles.container}>
        {sessionPopup ? <SessionOutModal /> : null}
        {window.screen.width < 950 ?  <View style={styles.renderHaderContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image source={IMG_CONST.leftArrow} style={styles.leftArrowImg} />
          </TouchableOpacity>
          <Text style={styles.renderHaderTextContainer}>Notification </Text>
          <Image source={IMG_CONST.setting} style={styles.settingImg} />
        </View> :
        <View style={styles.renderHaderContainer1}>
          <Text style={styles.renderHaderTextContainer1}>Notification </Text>
          </View>}
        
        { loader ? <ActivityIndicator
          style={{ margin: 'auto', height: 100, width: 100, }}
          size="large" color="#4e3e71" />
          :
          <ScrollView style={{ flex: 1 }}>
          <View>
            {data.map((x: any) => {
              let renderDate = getDate(x.attributes.created_at)
              return (
                <View key={x.id} style={styles.renderProfileContainer}>
                  {renderDate ?
                    <View style={styles.button}>
                      <Text style={styles.buttonText}> {renderDate === TodaysDate ? "Today" : renderDate === yesterdayDate ? "Yesterday" : renderDate} </Text>
                    </View>
                    : null}
                  <View style={styles.renderProfileSubProfileContainer}>
                    <Image source={IMG_CONST.personImg1} style={styles.personImg} />

                    <TouchableOpacity onPress={() => showDetails(x.attributes.external_data)} style={styles.textContainer}>
                      {/* <Text style={styles.heading}>{item.attributes.headings}</Text> */}
                      <Text numberOfLines={5} style={styles.addedText}>{x.attributes.contents}</Text>
                      <Text style={styles.agoText}>{renderDate === TodaysDate ? formatTime(x.attributes.created_at) : getTime(x.attributes.created_at)}</Text>
                    </TouchableOpacity>


                  </View>
                  <View style={styles.lineView} />
                </View>
              )
            })
            }
          </View>
        </ScrollView>}

            <Dialog
            open={openDetails}
            onClose={handleClose}
            >
              <View>
                <NootificationDetails handleParent={handleClose} navigation={navigation} />
              </View>
            </Dialog>
      </View>
    )
  }

  

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#ffff",
      flexDirection: 'column'
    },

    renderHaderContainer: {
      height: 72,
      backgroundColor: "#4e3e71",
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: "center",
      paddingLeft: 24,
      paddingRight: 24
    },
    renderHaderContainer1: {
      height: 72,
      backgroundColor: "#fffff",
      flexDirection: 'row',
      // justifyContent: 'space-between',
      alignItems: "center",
      paddingLeft: 24,
      paddingRight: 24
    },
    button: {
      borderRadius: 4,
      paddingVertical: 5,
      paddingHorizontal: 10,
      maxWidth: 120,
      backgroundColor: '#4e3e71',
  
    },
    buttonText: {
      color: '#e8fafe',
      fontSize: 17,
      textAlign: 'center',
    },

    datebox: {
      color: 'white',
      backgroundColor: '#4e3e71',
      paddingHorizontal: 10,
      paddingVertical: 5,
      maxWidth: 100,
      justifyContent: 'center'
    },

    leftArrowImg: {
      width: 24,
      height: 24,
      resizeMode: 'contain'
    },

    renderHaderTextContainer: {
      color: "#e8fafe",
      fontSize: 20,
    },
    renderHaderTextContainer1: {
      color: "#4e3e71",
      fontSize: 20,
    },

    settingImg: {
      width: 24,
      height: 24
    },

    renderProfileContainer: {
      paddingLeft: 24,
      marginTop: 10,
    },

    renderProfileSubContainer: {
      width: 62,
      height: 24,
      backgroundColor: "#4e3e71",
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 3
    },

    renderProfileSubTextContainer: {
      color: "#ffffff",
      fontSize: 142
    },

    renderProfileSubProfileContainer: {
      flexDirection: 'row',
      marginTop: 27
    },

    personImg: {
      width: 56,
      height: 56,
      resizeMode: "contain"
    },

    agoText: {
      color: '#112950',
      fontSize: 12,
      lineHeight: 18,
      marginTop: 4
    },

    lineView: {
      width: 32,
      height: 1,
      backgroundColor: "#f2f4f5",
      marginTop: 21,
      alignSelf: 'center',
      marginRight: 20
    },
    heading: {
      fontSize: 18,
      color: "#4e3e71",
      fontWeight: 'bold'
    },
    addedText: {
      color: "#8e9aab",
      lineHeight: 24,
      fontSize: 18,
      width: 300,
      marginRight: 10
    },

    textContainer: {
      marginTop: 15,
      marginLeft: 12
    }

  })

