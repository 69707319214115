import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../../../framework/src/StorageProvider";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  manufacturerName: string;
  materialCategory: string;
  materialName: string;
  division: string;
  catlogNo: string;
  barcode: string;
  gtinNo: string;
  imagePath: string;
  errors: any;
  manufacturerList: any;
  materailCategorieList: any;
  editable: any;
  image:any;
  materialId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VerifyMaterialDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddMaterialId: any;
  apigetManufacturerNameId: any;
  apigetMaterailCategorieId: any;
  apigetRequestedMaterialId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      manufacturerName: "",
      materialCategory: "",
      materialName: "hello",
      division: "",
      catlogNo: "",
      barcode: "",
      gtinNo: "",
      imagePath: "",
      image:[],
      errors: [],
      manufacturerList: [],
      materailCategorieList: [],
      editable: [false, false, false, false, false],
      materialId: JSON.parse(localStorage.getItem("requestedMaterialId")|| '{}')
    };

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiAddMaterialId !== null &&
      this.apiAddMaterialId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiAddMaterialId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.materialAddedSuccessfully(responseJson.data);
      } else {
        //Check Error Response
        this.materialAddedFailed();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apigetManufacturerNameId !== null &&
      this.apigetManufacturerNameId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apigetManufacturerNameId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getManufacturerNameSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.getManufacturerNameFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apigetMaterailCategorieId !== null &&
      this.apigetMaterailCategorieId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apigetMaterailCategorieId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getMaterailCategoriesSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.getMaterailCategoriesNameFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apigetRequestedMaterialId !== null &&
      this.apigetRequestedMaterialId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apigetRequestedMaterialId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getRequestedMaterialSuccess(responseJson.data);
      } else {
        //Check Error Response
        this.getRequestedMaterialFail();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getManufacturerName();
    this.getRequestedMaterial();
  }
  getManufacturerName = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetManufacturerNameId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_catalogue/brands"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getManufacturerNameSuccess = (data: any) => {
    this.setState({ manufacturerList: data });
  };

  getManufacturerNameFail = () => {};
  getMaterailCategories = async (brandId: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetMaterailCategorieId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories?brand_ids=${brandId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getMaterailCategoriesSuccess = (data: any) => {
    this.setState({ materailCategorieList: data });
  };

  getMaterailCategoriesNameFail = () => {};
  getRequestedMaterial = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetRequestedMaterialId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_materials/material_request/${this.state.materialId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getRequestedMaterialSuccess = (data: any) => {
    const detail = data.attributes;
    console.log('=======', detail)
    this.getMaterailCategories(detail?.brand?.data?.attributes?.id||'')
    this.setState({
      materialName: detail.name ? detail.name : "",
      division: detail.divison ? detail.divison : "",
      catlogNo: detail.catalog_no ? detail.catalog_no : "",
      barcode: detail.barcode ? detail.barcode : "",
      gtinNo: detail.gtin_no ? detail.gtin_no : "",
      materialCategory:detail?.category?.data?.attributes?.id||'',
      manufacturerName:detail?.brand?.data?.attributes?.id||'',
    });
  };

  getRequestedMaterialFail = () => {};
  _handleAddMaterial = async () => {
    const header = {
      // Accept: "application/form-data",
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const body = {
      material_request: {
        brand_id: this.state.manufacturerName,
        category_id: this.state.materialCategory,
        name: this.state.materialName,
        divison: this.state.division,
        catalog_no: this.state.catlogNo,
        image: [this.state.image],
        barcode: this.state.barcode,
        gtin_no: this.state.gtinNo
      }
    };
    if(this.state.image) {
      var postData = new FormData();
      postData.append("material[name]", this.state.materialName);
      postData.append("material[division]", this.state.division);
      postData.append("material[catalog_no]", this.state.catlogNo);
      postData.append("material[barcode]", this.state.barcode);
      postData.append("material[gtin_no]",  this.state.gtinNo);
      postData.append("category_id", this.state.materialCategory);
      postData.append("material[image]", this.state.image ? this.state.image : null  , this.state.image.name ? this.state.image.name : null );
      postData.append("brand_id", this.state.manufacturerName);
//       postData.append("material[description]", values.description);
//       postData.append("material[price]", values.price);
//       postData.append("material[discount_price]", values.discount_price);
// } else {
      var postData = new FormData();
      postData.append("material[name]", this.state.materialName);
      postData.append("material[division]", this.state.division);
      postData.append("material[catalog_no]", this.state.catlogNo);
      postData.append("material[barcode]", this.state.barcode);
      postData.append("material[gtin_no]",  this.state.gtinNo);
      postData.append("category_id", this.state.materialCategory);
      // postData.append("material[image]", imageData ? imageData : null  , imageData.name ? imageData.name : null );
      postData.append("brand_id", this.state.manufacturerName);
      // postData.append("material[description]", values.description);
      // postData.append("material[price]", values.price);
      // postData.append("material[discount_price]", values.discount_price);
}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddMaterialId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_materials/materials`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  materialAddedSuccessfully = (data: any) => {
    this.showAlert("Success", "Material created successfully");
  };

  materialAddedFailed = () => {};
  // Customizable Area End
}
