import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";

// Customizable Area Start
import * as Yup from "yup";
import { getRegex } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  phoneField: Boolean;
  userInput: any;
  countryCode: any;
  showResend: Boolean;
  valueForResnedOTP: any;
  counter: any;
  loader: boolean;
  validationSchema: any

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean;


  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  passwordVisibleImage: any = passwordVisibleImage;
  passwordInvisibleImage: any = passwordInvisibleImage;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required("Please enter a password")
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, "Minimum eight characters, at least one letter and one number")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: Yup.string()
        .required("Please confirm your password")
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Passwords must match"
          )
        })
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      phoneField: false,
      userInput: '',
      countryCode: '',
      showResend: false,
      valueForResnedOTP: '',
      loader: false,
      counter: 60,
      validationSchema: {}
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ token: otpAuthTkn });
        // localStorage.setItem('authToken', otpAuthTkn )
        if (this.isChangePassword) {
          this.setState({ accountStatus: "ChangePassword" });
        }
        this.otpToken = this.state.token;
        // runEngine.debugLog("otpAuthTkn", this.state.token);
      } else {
        const accountType = message.getData(
          getName(MessageEnum.NavigationForgotPasswordPageInfo)
        );
        if (accountType) {
          this.startForgotPassword(accountType);
        }
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validationAPICallId &&
      this.validationAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson === undefined) {
        return;
      }

      if (responseJson && responseJson?.data) {

        this.setState({ validationSchema: responseJson.data })
        // const passRegex = RegExp(
        //   responseJson.data[0].password_validation_regexp
        // );
        // const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
        // const passwordRulesFromValidation =
        //   responseJson.data[0].password_validation_rules;

        // this.setState({
        //   //email schema
        //   emailSchema: {
        //     email: Yup.string()
        //       .email(configJSON.pleaseEnterAValidEmail)
        //       .required(configJSON.emailIsRequired)
        //       .matches(emailRegex, configJSON.invalidEmailAddress)
        //   },
        //   //password schema
        //   passwordSchema: {
        //     password: Yup.string()
        //       .required(configJSON.pleaseEnterAPassword)
        //       .matches(passRegex, configJSON.invalidPassword),

        //     confirmPassword: Yup.string()
        //       .required(configJSON.pleaseConfirmYourPassword)
        //       .when("password", {
        //         is: val => (val && val.length > 0 ? true : false),
        //         then: Yup.string().oneOf(
        //           [Yup.ref("password")],
        //           configJSON.passwordsMustMatch
        //         )
        //       })
        //   },
        //   passwordRules: passwordRulesFromValidation
        // });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered email!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;
        console.log(responseJson.data.attributes.pin + "------------")
        this.setState({ token: this.otpToken });
        localStorage.setItem('authToken', this.otpToken)
        localStorage.setItem('resetPassOtp', responseJson.data.attributes.pin)
        // data.attributes.pin

        //navigate to OTP page
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );

        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenEmailMessage),
          this.state.emailValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling

      else if (responseJson && responseJson.errors) {
        // this.parseApiErrorResponse(responseJson);
        this.setState({ loader: false })
        let msg = getErrorMessage(responseJson.errors[0])
        this.showAlert("Error", msg);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({ loader: false })
        let msg = getErrorMessage(responseJson.errors[0])
        this.showAlert("Error", msg);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered phone!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;
        console.log("tokens data ", responseJson.meta.token)
        this.setState({ token: this.otpToken });
        localStorage.setItem('authToken', this.otpToken)
        localStorage.setItem('resetPassOtp', responseJson.data.attributes.pin)


        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );
        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenPhoneNumberMessage),
          this.state.phoneValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.setState({ loader: false })
        console.log(responseJson)
        let msg = getErrorMessage(responseJson.errors[0])
        this.showAlert("Error", msg);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({ loader: false })
        console.log(responseJson)
        let msg = getErrorMessage(responseJson.errors[0])
        this.showAlert("Error", msg);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered 3");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          accountStatus: "Confirmation"
        });
        setTimeout(() => {
          this.props.navigation.navigate("Dashboard")
        }, 2000)
        localStorage.removeItem("isTimer")
      } else if (responseJson !== undefined && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
  }

  validationSchemas = () => {
    console.log(this.state.validationSchema)
    const { validationSchema } = this.state
    let validation_Schema;
    if (validationSchema) {
      const newRegx = getRegex(validationSchema)      
      validation_Schema = Yup.object({
        password: Yup
          .string()
          .required("Please enter a password")
          .matches(
            newRegx.regEx,
            newRegx.message
            )
          .min(validationSchema.min_length, `Password must be at least ${validationSchema.min_length} characters`)
          .max(validationSchema.max_length, `Maximum ${validationSchema.max_length} characters allowed`),
        rePassword: Yup
          .string()
          .required("Please confirm your password")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
          }),
      })
    } else {
      validation_Schema = Yup.object({
        password: Yup.string()
          .required("Please enter a password")
          .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#@*^$%&? "])[a-zA-Z0-9!#@*^$%&?]{8,}$/, "Requires at least 1 uppercase letter, 1 lowercase letter, 1 number, and one special character, with a length of at least 8 characters")
          .min(8, "Password must be at least 8 characters").max(16, "Maximum 16 characters allowed "),
        confirmPassword: Yup.string()
          .required("Please confirm your password")
          .when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Passwords must match"
            )
          })
      })
    }

    return validation_Schema

  }



  resendOTPcall() {
    this.displayData()
    // this.state.phoneField  ? this.goToOtpAfterPhoneValidation() : this.goToOtpAfterEmailValidation()
    // this.state.phoneField ? console.log(this.state.countryCode + this.state.userInput) : console.log(this.state.phoneValue)
    // console.log(this.state.valueForResnedOTP);   
  }

  saveData = (value: any) => {
    localStorage.setItem('value', "+" + value);
  }

  displayData = async () => {
    this.setState({ showResend: false })
    try {
      let user: any = await AsyncStorage.getItem('value');
      if (user !== '' && !isNaN(+user)) {
        console.log("value is number");
        this.goToOtpAfterPhoneValidation(user)
      } else {
        console.log("value is string");
        this.goToOtpAfterEmailValidation(user)
      }
      // this.state.phoneField  ? this.goToOtpAfterPhoneValidation(user) : this.goToOtpAfterEmailValidation()
    }
    catch (error) {
      alert(error)
    }
  }


  setPhoneValue = (value: any) => {
    if (value === "") {
      this.setState({ phoneField: false })
    } else {
      this.setState({ phoneValue: value })
    }
  }

  handleText(text: string) {
    console.log(text);
    this.setState({ userInput: text })
    if (text !== '' && !isNaN(+text)) {
      this.setState({ phoneField: true })

    } else if (this.state.userInput === '' && this.state.phoneField === true) {
      this.setState({ phoneField: false })

    } else {
      console.log('string');
      this.setState({ emailValue: text })
    }
  }

  hanlePhoneNumberChange(isValid: any, phone: any, country: any) {
    if (phone === '') {
      this.setState({ phoneField: false })
    } else if (this.state.userInput === '' && this.state.phoneField === true) {
      this.setState({ phoneField: false })
    } else {
      this.setState({ userInput: phone, countryCode: country.dialCode })
      //this.setState({phoneValue: this.state.userInput + this.state.phoneValue})
      console.log(isValid, phone, country.dialCode);
    }
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail"
    });
  }

  goToOtpAfterEmailValidation(value: any) {
    this.setState({ loader: true })
    localStorage.setItem('value', value);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // this.setState({
    //   emailValue: values.email ? values.email : ""
    // });

    const data = {
      type: "email_account",
      attributes: {
        email: this.state.emailValue ? this.state.emailValue : value
      }
    };

    const httpBody = {
      data: data
    };

    console.log(httpBody);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(value: any) {
    this.setState({ loader: true })
    // console.log("entered phone validation code");
    // if (
    //   !this.state.countryCode ||
    //   this.state.countryCode.length === 0
    // ) {
    //   this.showAlert(
    //     configJSON.goToOtpAfterPhoneValidationErrorTitle,
    //     configJSON.goToOtpAfterPhoneValidationErrorBody
    //   );
    //   return;
    // }
    // console.log(this.state.countryCode);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    console.log(this.state.userInput);

    this.setState({
      phoneValue: this.state.countryCode + this.state.userInput
    });

    console.log(this.state.phoneValue);
    console.log(value);



    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: value
      }
    };

    this.saveData(value)


    const httpBody = {
      data: data
    };
    console.log(httpBody);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    this.setState({ loader: true })
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : ""
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    rePassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: localStorage.getItem('reset_password_token'),
      new_password: values.password,
      otp_code: localStorage.getItem('resetPassOtp')
    };

    const httpBody = {
      data: data
    };
    console.log(httpBody);


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
}
