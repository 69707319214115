import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import InviteUser from "./InviteUser";

import CfPwaAdjustment1Controller, {
  Props,
  configJSON
} from "./CfPwaAdjustment1Controller";
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';

export default class CfPwaAdjustment1 extends CfPwaAdjustment1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <View>
            <Text style={styles.topText}>Find your team members. you can also invite them to join the app.</Text>
            <View style={styles.searchBox}>
              <View style={styles.searchIcon}>
              <SearchIcon color="disabled" style={{ fontSize: 30 }}/>
              </View>
            <TextInput
              style={styles.input}
              placeholder="Search Members"
              keyboardType="numeric"
              selectionColor  ="rgb(239, 247, 255)"
         
            />
            </View> 
            <View style={styles.outerCard}>
                <View style={styles.imageView}>
                  <Text>Image</Text>
                </View>
                <View style={styles.textSide}>
                  <Text style={styles.headingText}>John McHall</Text>
                  <Text style={styles.bottomText}>Procreument staff member</Text>
                  <View style={styles.BottomIcons}>
                    <Text style={{fontSize: 20}} onPress={this.handleClickOpen}><SendIcon /> invite</Text>
                    <Text><SettingsIcon color='primary' /> <CancelIcon /></Text>
                  </View>
                </View>
              </View>  

               <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
              >
              <InviteUser />
              </Dialog>      
          </View>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "100%" : "100%",
    backgroundColor: "rgb(239, 247, 255)",
  },
  topText: {
    paddingHorizontal: 20,
    fontSize: 20,
    paddingVertical: 50,
    color: '#97a7b9',
  },
  bottomText: {
    color: '#97a7b9',
    fontSize: 20
  },
  headingText: {
    color: 'rgb(0, 122, 242)',
    fontSize: 20
  },
  textSide: {
    paddingLeft: 10
  },
  BottomIcons:{
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchIcon: {
    paddingLeft:30,
    paddingVertical:10
  },
  imageView: {
    padding: 20,
    marginVertical: 5,
    marginHorizontal: 10,
    backgroundColor: "rgb(239, 247, 255)",
  },
  outerCard: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 10,
    backgroundColor: '#fff',
    marginHorizontal: 20,
    marginVertical: 20
  },
searchBox: {
  flexDirection: 'row',
  marginHorizontal:20,
  marginVertical: 20,
  borderWidth: 1,
  borderColor: 'grey',
  borderRadius: 50
  
},
  input: {
outlineStyle: 'none',
    borderRadius: 20,
    borderColor: 'black',
    paddingHorizontal:20,
    paddingVertical: 10,
    fontSize: 20
    
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
