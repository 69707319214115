import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../../framework/src/IBlock";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  materialList: any;
  salesEmail: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ReassignMaterialController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountAPICallId: any;
  googleUser: any;
  apiAddMaterialId: any;

  constructor(props: Props) {
    super(props);
    // this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ]);

    // Customizable Area Start
    this.state = {
      data: JSON.parse(localStorage.getItem("previous_booking") || "{}"),
      materialList: [],
      salesEmail: ""
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiAddMaterialId !== null &&
      this.apiAddMaterialId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiAddMaterialId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.materialAssignedSuccessfully(responseJson.data);
      } else {
        //Check Error Response
        this.materialAssignedFailed();
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();

    const user = JSON.parse(localStorage.getItem("previous_booking") || "{}");
    const role_id: any = JSON.parse(localStorage.getItem("role") || "{}");
    const materails: any = [];

    user &&
      user.attributes &&
      user.attributes.ordered_materials &&
      user.attributes.ordered_materials.data
        .filter(
          (x: any) =>
            x.attributes.assign_to_type ===
            (role_id === 2
              ? "BxBlockHospital::Hospital"
              : "AccountBlock::Account")
        )
        .map((x: any) => {
          x.isReassign = false;
          materails.push(x);
        });
    this.setState({ materialList: materails });
  }

  _handleCheckBox = (event: any, index: any) => {
    this.state.materialList[index].isReassign = event.target.checked;
    this.setState({ materialList: this.state.materialList });
  };
  _handleReAssignedMaterial = async () => {
    const role_id: any = JSON.parse(localStorage.getItem("role") || "{}");

    const header = {
      // Accept: "application/form-data",
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    let ordered_materials: any = [];
    this.state.materialList.map((a: any) => {
      if (a.isReassign) {
        ordered_materials.push(parseInt(a.id));
      }
    });
    const materialMnagerbody = {
      ordered_material_ids: ordered_materials
    };
    const salesRapBody = {
      ordered_material_ids: ordered_materials,
      email: this.state.salesEmail
    };
    const apiUrl =
      role_id === 2
        ? "bx_block_materials/reassign_material"
        : "bx_block_materials/reassign_material_through_email";

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddMaterialId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(role_id === 2 ? materialMnagerbody : salesRapBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  materialAssignedSuccessfully = (data: any) => {
    this.showAlert("Success", "Material assign successfully");
    localStorage.setItem("previous_booking", JSON.stringify(data));
    setTimeout(() => {
      this.props.navigation.navigate("SurgeryDetails");
    }, 2000);
  };

  materialAssignedFailed = () => {};
}
