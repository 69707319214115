import React from 'react';
import {StyleSheet,
Text,
View,
TouchableOpacity,
TouchableHighlight ,
TextInput,
FlatList
 } from 'react-native';
import { Formik, Form, Field} from "formik";
import * as yup from "yup";

const validation_Schema = yup.object({
  name: yup.string().required("Name is required"),
  category: yup.string().required("Category is required"),
  description: yup.string().required("Description are required"),
})

export default function RequestMaterial({ navigation, handleClose }: { navigation: any, handleClose : any}) {


  const NavigateTo = (values: any) => {
    const requestedMaterials = JSON.parse(localStorage.getItem('requestedMaterials') || '[]')
    requestedMaterials.push({
      name: values.name,
      material_id: new Date().getTime().toString(),
      category: values.category,
      description: values.description,
      quantity: 1
    })
    localStorage.setItem('requestedMaterials', JSON.stringify(requestedMaterials))
    navigation.navigate('CartList')
  }
       return (

        <Formik
        initialValues={{ 
          name: '',
          category: '',
          description: ''
        }}
        validationSchema={validation_Schema}
          onSubmit={(values, actions) => {
            console.log(values);
            NavigateTo(values)
          }}
        >
              {
              ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
             <View style={styles.container}>
                  {window.screen.width < 950 ? <View style={styles.headingView}>
                    <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
                      <img src={require("../assets/leftArrow1.png")} style={{
                        height: 24, width: 24,
                      }} />
                    </TouchableOpacity>
                    <Text style={styles.heading}>Request Material</Text>
                  </View> : null }
                  <View style={window.screen.width < 950 ? { justifyContent: 'space-between', flex: 1 } : { flex: 1}}>
                   <View style={styles.card}>
                         <Text style={styles.text}>Material Details</Text>
                         
                         <View>
                         <TextInput 
                       style={styles.input}
                        placeholder="Material Name"
                        placeholderTextColor="#b2bac6"
                        onBlur={handleBlur('name')}
                        onChangeText={handleChange('name')}
                        value={values.name}
                 />
                 {touched.name && errors.name ?   <Text style={{color: 'red', paddingLeft: 10}}>{errors.name}</Text> : null}
                  <TextInput 
                       style={styles.input}
                        placeholder="Category"
                        placeholderTextColor="#b2bac6"
                        onBlur={handleBlur('category')}
                        onChangeText={handleChange('category')}
                        value={values.category}
                 />
                 {touched.category && errors.category ?   <Text style={{color: 'red', paddingLeft: 10}}>{errors.category}</Text> : null}
                  <TextInput 
                       style={styles.input}
                        placeholder="Description"
                        placeholderTextColor="#b2bac6"
                        multiline={true}
                        // numberOfLines={3}
                        onBlur={handleBlur('description')}
                        onChangeText={handleChange('description')}
                        value={values.description}
                 />
                 {touched.description && errors.description ?   <Text style={{color: 'red', paddingLeft: 10}}>{errors.description}</Text> : null}
                         </View>
                    </View>
                    {
                      window.screen.width < 950 ?
                        <TouchableOpacity onPress={() => handleSubmit()}>
                          <View style={styles.button}>
                            <Text style={styles.buttonText}>Request Material</Text>
                          </View>
                        </TouchableOpacity>
                        :
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                          <TouchableOpacity onPress={() => handleClose()}>
                            <View style={styles.button0}>
                              <Text style={styles.buttonText0}>Cancel</Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => handleSubmit()}>
                            <View style={styles.button}>
                              <Text style={styles.buttonText}>Request Material</Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                    }
                 
                    </View>
             </View>
               )}
               </Formik> 
             
       )}

const styles = StyleSheet.create({
            container: {
                  flex: 1,
                  backgroundColor: "#fff",
                  flexDirection: 'column',
            },
            headingView: {
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 27,
              backgroundColor: '#4e3e71',
              marginBottom: 20,

  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
            heading: {
              color: '#e8fafe',
              fontSize: 20,
              marginHorizontal: 'auto'
            },
                card: {
                      paddingHorizontal: 20,
                      paddingVertical: 20,

                },
                text: {
                  color: '#4e3e71',
                  // fontWeight: 'bold',
                  fontSize: 25
                },      
              input: {
outlineStyle: 'none',
                flex: 1,
                marginVertical: 20,
                paddingVertical: 10,
                paddingLeft: 10,
                fontSize: 18,
                color: '#112950',
                borderBottomWidth: 1,
                borderBottomColor: '#e5e5e5'
              },
                inputMultiline: {
                  height: 120,
                  margin: 6,
                  borderRadius: 5,
                  BorderColor: '#f1f7fe',
                  backgroundColor: '#fff',
                  paddingLeft: 20,
                  paddingTop: 20
                },
                button: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 30,
                  backgroundColor: '#4e3e71',
                  marginVertical: 20,
                  marginHorizontal: 20
                },
                buttonText: {
                  color: 'white',
                  fontSize: 20,
                  textAlign: 'center',
                },
                button0: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 60,
                  borderColor: '#4e3e71',
                  borderWidth: 1,
                  backgroundColor: '#fff',
                  marginVertical: 20,
                  marginHorizontal: 20
                },
                buttonText0: {
                  color: '#4e3e71',
                  fontSize: 20,
                  textAlign: 'center',
                },
})