import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../../framework/src/IBlock";



export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  // Customizable Area Start
  // Customizable Area End
  data: any;
  show: any;
  isFetching: any;
  requestList: any;
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SocialMediaAccountMobileController
  extends BlockComponent<Props, S, SS>
  {
  createAccountAPICallId: any;
  googleUser: any;
  getGlobalCallID: any;
  constructor(props: Props) {
    super(props);
    // this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
      // Customizable Area End
    ]);

    // Customizable Area Start
    this.state = {
      loading: false,
      isRegistration: true,
      data: [
        {
          id: 1,
          image: require('../../../assets/bitmap.png'),
          text: "Scissors",
        },
      ],
      show: false,
      isFetching: true,
      requestList: ''
    };
    // Customizable Area End
  }

  async componentDidMount() {
    this.getGlobalBrandList()

  }

  async receive(from: string, message: Message) {
    console.log("@@@ API MESSAGE Badge   =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        this.showAlert('Error', 'Internal Server Error')
      }

      if (responseJson) {
        if (apiRequestCallId === this.getGlobalCallID) {
          this.getBrandListApiSuccesCallBack(responseJson);
        }
      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.getGlobalCallID) {
          this.getBrandListApiFailureCallBack(responseJson);
        }
      }
    }
  }

  getBrandListApiSuccesCallBack = (responseJson: any) => {
    let attibuteData: any = [];
    console.log('@@@ Get Brand List==============', responseJson);
    let dataList = responseJson.data;
    console.log("@@@@@============dataList", dataList)
    dataList.map((val: any) => {
      attibuteData.push(val.attributes)
      console.log("@@@@@======attibuteData", attibuteData)
    })
    this.setState({ requestList: attibuteData })
    console.log("@@@@@======attibuteData", this.state.requestList)

  }

  getBrandListApiFailureCallBack = (responseJson: any) => {
    console.log('@@@ Get Brand List==============', responseJson)
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };



  getGlobalBrandList = async () => {
    console.log('@@@ Props Data==========', this.props.navigation.getParam())
    // let brandID = this.props.navigation.state.params.brandId;
    this.setState({ isFetching: true });
    this.getGlobalCallID = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: 'bx_block_materials/material_request',
    });
  }


}
