import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  FlatList
} from "react-native";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MenuController from "./Menu2Controller";
const routeList = [
  {
    name: "Home",
    routename: "Dashboard2",
    // image: require("../../assets/homeIcon.png")
  },
  {
    name: "Profile",
    routename: "Profile",
    // image: require("../../assets/userIcon.png")
  },
  {
    name: "validate New Manager",
    routename: "ValidateManager",
    // image: require("../../assets/validateNewManager.png")
  },
  {
    name: "Validate New Surgeon",
    routename: "ValidateNewSurgeonBlock",
    image: require("../../assets/userIcon.png")
  },
  {
    name: "New Material",
    // routename: "ValidateMaterial",
    image: require("../../assets/plus.png"),
    routename: "ValidateNewSurgonBlock",
    // image: require("../../assets/userIcon.png")
  },
  {
    name: "New Material",
    routename: "Dashboard",
    // image: require("../../assets/plus.png")
  },
  {
    name: "History",
    routename: "Dashboard",
    // image: require("../../assets/historyIcon.png")
  },
  {
    name: "Notofication",
    routename: "Notification",
    // image: require("../../assets/bell.png")
  },
  {
    name: "Logout",
    routename: "Dashboard",
    // image: require("../../assets/logout.png")
  }
];
export default class Menu extends MenuController {
  changeRoute = (route: any) => {
    // state({ left: false });
    this.props.navigation.navigate(route);
  };

  logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.profile}>
          <Image
            source={{
              uri: this.state.userData && this.state.userData.profile_image
            }}
            style={styles.profileImage}
          />

          <Text style={styles.profileText}>
            {this.state.userData && this.state.userData.first_name}{" "}
            {this.state.userData && this.state.userData.last_name}
          </Text>
          <Text style={styles.profileText2}>Role: Material Manager</Text>
        </View>

        <View style={styles.subWrapper}>
          <FlatList
            data={routeList}
            renderItem={({ item }) => {
              return (
                <TouchableOpacity
                  style={styles.menuWrapper}
                  onPress={() => item.name==='Logout'?this.logOut():this.changeRoute(item.routename)}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Image source={item.image} style={styles.menuImage} />

                    <Text style={styles.menuName}>{item.name}</Text>
                  </View>

                  <ChevronRight color={"disabled"} />
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#4E3E71"
  },
  profileImage: {
    width: 100,
    height: 100,
    backgroundColor: "#b2bac6",
    borderRadius: 99
  },
  profileText: {
    color: "#fff",
    fontSize: 24
  },
  profileText2: {
    color: "#fff",
    fontSize: 14,
    paddingTop: 5
  },
  profile: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4E3E71",
    paddingHorizontal: 50,
    flex: 0.3
  },
  menuImage: { height: 24, width: 24, marginRight: 8 },
  menuName: { fontSize: 14, color: "#4e3e71" },
  menuWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 24,
    width: "100%",
    paddingHorizontal: 10,
    alignItems: "center"
  },
  subWrapper: {
    borderTopEndRadius: 24,
    zIndex: 999,
    backgroundColor: "#E8FAFE",
    flex: 0.7
  }
});
