import React,{ useState, useEffect} from 'react';
import {StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TouchableHighlight ,
      TextInput,
      FlatList,
TouchableWithoutFeedback,
Platform } from 'react-native';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, Field} from "formik";
import * as yup from "yup";
import { postAxiosAPI,putAxiosAPI, getErrorMessage } from '../../../framework/src/Blocks/AxiosAPIBlock';
import CustomPopUp from "../../../framework/src/CustomPopUp";





const validation_schema = yup.object({
      name: yup.string().required("Name is required").matches(/[a-zA-Z]/, "Only alphabets are allowed with minimum length 1"),
      or_name: yup.string().matches(/[a-zA-Z]/, "Only alphabets are allowed").min(3, "must be atleast 3 characters"),
      address: yup.string().required("Address is required").min(3, "must be atleast 3 characters"),
      email: yup.string()
      .required("Email is required")
      .email("Please enter a valid Email")
      ,
      phone_number: yup.string().required("Phone Number is required").matches(/^[0-9]+$/, "Only numbers are allowed").min(10, "must be atleast 10 digits"), 
      zip: yup.string().required("Zip code is required").matches(/^[0-9]+$/, "Only numbers are allowed").min(4, "must be atleast 4 digits"),

})

export default function AddNewFacilities({ navigation, handleClose, editStateFacility, isForEditProfile }: { navigation: any, handleClose: any, editStateFacility: any, isForEditProfile: boolean}) {
      const [hospitals, setHospitals] = useState<any>('Yes');
      const [showInputOR, setShowInputOR] = useState(false);
      const [showAmbulatoryInput, setShowAmbulatoryInput] = useState(false);
      const [showAmbulatory, setShowAmbulatory] = useState(false);
      const [ambulatory , setAmbulatory] = useState('Yes');
      const [OR , setOR]  = useState('Yes');
      const [showOr, setShowOr] = useState(false)

      

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      const [loading, setLoading] = useState(true);
      const [isForEdit, setIsForEdit] = useState<any>(null);
      const [editState, setEditState] = useState<any>(null);

      const [patientImage, setPatientImage] = useState<any>('');
      const [imageDemo , setImageDemo] = useState('');

      const getBase64 = (file :any) => {
            return new Promise((resolve,reject) => {
               const reader = new FileReader();
               reader.onload = () => resolve(reader.result);
               reader.onerror = error => reject(error);
               reader.readAsDataURL(file);
            });
          }

      const onImageChange = (event :any) => {
            // console.log(event.target.files[0], event.target.files[0].name)
            setPatientImage({image: event.target.files[0], name: event.target.files[0].name, type: event.target.files[0].type})
            const file = event.target.files[0];
            getBase64(file).then((base64: any) => {
                  // localStorage["fileBase64"] = base64;
                  setImageDemo(base64);
                  // console.debug("file stored",base64);
            });

            }

      const ClosePopUp = () => {
            setPopup(false)
            window.location.reload();
      }

      useEffect(() => {
            const facility = JSON.parse(localStorage.getItem('selectedFacility') || '{}')
            if(Object.keys(facility).length && !isForEditProfile) {
                  setIsForEdit(true)
                  setEditState(facility) 
                  console.log("here ===> mobile", facility)
            } else if (isForEditProfile || editStateFacility) {
                  setIsForEdit(isForEditProfile)
                  setEditState(editStateFacility) 
                  console.log("here ===> web", isForEditProfile, editStateFacility)
            } else {
                  setIsForEdit(false)
                  setEditState({}) 
            }

      return () => {
            localStorage.removeItem('selectedFacility')
            setIsForEdit(null)
            setEditState(null) 
      }
      },[editStateFacility, navigation])

      console.log(isForEdit, editState)
   
    




      const handelhospital = (e: any) => {
            setHospitals(e.target.value)
            // e.preventDefault()
            }

      const handelOR = (e: any) => {
            setOR(e.target.value)
            }

      const handelambulatory = (e: any) => {
            setAmbulatory(e.target.value)
      }

      const makeAPICall = async (values :any, actions: any) => {

            if(patientImage) {
                  var newData = new FormData()
                  newData.append('[hospital][logo]', patientImage ? patientImage.image : null, patientImage.name ? patientImage.name : null);
                  newData.append('[hospital][name]', values.name.trim());
                  newData.append('[hospital][address]', values.address);
                  newData.append('[hospital][zip]', values.zip);
                  newData.append('[hospital][email]', values.email);
                  newData.append('[hospital][phone_number]', values.phone_number);
                  newData.append('[hospital][is_hospital]', hospitals === 'Yes' ? 'true': 'false');
                  newData.append('[hospital][is_or]', hospitals === "Yes" ? 'true' : 'false');
                  newData.append('[hospital][is_ambulatory]', hospitals === "No" ? 'true' : 'false' );
                  newData.append('[hospital][or_name]',  values.or_name);
                  newData.append('[hospital][ambulatory_name]', values.ambulatory_name);
            } else {
                  var newData = new FormData()
                  // newData.append('[hospital][logo]', patientImage ? patientImage.image : null, patientImage.name ? patientImage.name : null);
                  newData.append('[hospital][name]', values.name.trim());
                  newData.append('[hospital][address]', values.address);
                  newData.append('[hospital][zip]', values.zip);
                  newData.append('[hospital][email]', values.email);
                  newData.append('[hospital][phone_number]', values.phone_number);
                  newData.append('[hospital][is_hospital]', hospitals === 'Yes' ? 'true': 'false');
                  newData.append('[hospital][is_or]', hospitals === "Yes" ? 'true' : 'false');
                  newData.append('[hospital][is_ambulatory]', hospitals === "No" ? 'true' : 'false' );
                  newData.append('[hospital][or_name]',  values.or_name);
                  newData.append('[hospital][ambulatory_name]', values.ambulatory_name);
            }
     
            if(isForEdit && editState.id) {
                  let url = `/bx_block_hospitals/hospitals/${editState?.id}`
                  await putAxiosAPI(url, newData).then((res: any) => {
                        if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                              console.log(res.data);
                              setPopup(true)
                              setMessage(msg)
                              setLoading(false)
                        } else {
                          setLoading(false)
                          setPopup(true)
                          actions.resetForm()
                          console.log(res.data);
                          localStorage.setItem('selectedFacility', JSON.stringify(res.data.data))
                          setMessage("Updated Successfully")
                        }
                      });
            } else {
                  let url = "/bx_block_hospitals/hospitals"
                  await postAxiosAPI(url, newData).then((res: any) => {
                        if(res.error){
                              let msg = getErrorMessage(res.data.errors[0])
                              console.log(res.data);
                              setPopup(true)
                              setMessage(msg)
                              setLoading(false)
                        } else {
                        actions.resetForm()
                          setLoading(false)
                          setPopup(true)
                          actions.resetForm()
                          setMessage("Added Successfully")
                        }
                      });
            }
           
      }

      useEffect(() => {
            if(hospitals === 'Yes'){
                  setShowOr(true)
            } else {
                  setShowOr(false)
            }
            if(hospitals === "No") {
                  setShowAmbulatory(true)
            } else {
                  setShowAmbulatory(false)
            }

            if(OR === 'No') {
                  setShowInputOR(true)
            } else if (ambulatory === 'No') {
                  setShowAmbulatoryInput(true)
            } else {
                  setShowInputOR(false)
                  setShowAmbulatoryInput(false)
            }
         
            // return () => {
            //       localStorage.removeItem('selectedFacility')
            //       setIsForEdit(null)
            //       setEditState(null) 
            // }
      }, [hospitals,OR,ambulatory])

      return (
            <View style={styles.container}>
                  {
                        editState ? 
                        <Formik
              initialValues={isForEdit ? {  
              name: editState.attributes?.name ? editState.attributes?.name : "",
              address: editState.attributes?.address ? editState.attributes?.address : "",
              zip: editState.attributes?.zip ? editState.attributes?.zip : "",
              phone_number: editState.attributes?.phone_number ? editState.attributes?.phone_number : '',
              email: editState.attributes?.email ? editState.attributes?.email : "",
              or_name: '',
              ambulatory_name: ''
            } : {
                  name: "",
                  address: "",
                  zip: "",
                  phone_number: '',
                  email:   "",
                  or_name: '',
                  ambulatory_name: ''
            }
      }
              validationSchema={validation_schema}
                onSubmit={(values, actions) => {
                  console.log(values);
                  //actions.resetForm(); 
                  makeAPICall(values, actions)
                  
                }}
              >
                {
                  ({ errors, touched ,handleChange, handleBlur, values, handleSubmit }) => (
                        <View>
                                 {popup && <CustomPopUp ClosePopUp={ClosePopUp} btnText={"Back"} message={message}/>}
                              {window.screen.width < 950 ?  <View style={styles.headingView}>
                                    <TouchableOpacity onPress={() => navigation.goBack()}>
                                          <img src={require("../assets/leftArrow1.png")} style={{
                                                height: 24, width: 24, position: "absolute",
                                                left: 24}} />
                                    </TouchableOpacity>
                                    <Text style={styles.heading}>Enter Details</Text>
                              </View> : <Text style={{ fontSize: 20, color: '#4e3e71', padding: 20, paddingBottom: 0}}>Facility Details</Text> }
                              <View style={styles.card}>
                             
            <View>

           
            <TextInput 
                       style={styles.input}
                        placeholder="Enter Name"
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('name')}
                        onBlur={handleBlur('name')}
                        value={values.name}
                        
                 />
                 {touched.name && errors.name ?   <Text style={{color: 'red'}}>{errors.name}</Text> : null}
                   <TextInput 
                       style={styles.input}
                        placeholder="Enter Address"
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('address')}
                        onBlur={handleBlur('address')}
                        value={values.address}
                 />
                 {touched.address && errors.address ?   <Text style={{color: 'red'}}>{errors.address}</Text> : null}
                   <TextInput 
                       style={styles.input}
                        placeholder="Enter Zip Code"
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('zip')}
                        onBlur={handleBlur('zip')}
                        value={values.zip}
                 />
                 {touched.zip && errors.zip ?   <Text style={{color: 'red'}}>{errors.zip}</Text> : null}
                   <TextInput 
                       style={styles.input}
                        placeholder="Contact Number"
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('phone_number')}
                        onBlur={handleBlur('phone_number')}
                        value={values.phone_number}
                 />
                 {touched.phone_number && errors.phone_number ?   <Text style={{color: 'red'}}>{errors.phone_number}</Text> : null}
                   <TextInput 
                       style={styles.input}
                        placeholder="Email ID"
                        placeholderTextColor="#b2bac6"
                        onChangeText={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                 />
                 {touched.email && errors.email ?   <Text style={{color: 'red'}}>{errors.email}</Text> : null}
                 <View style={styles.patientImage}>
                               {
                                     imageDemo ? 
                                     <img src={imageDemo} alt="preview image" style={{width: 60, height: 60, paddingTop: 10, borderRadius: 6, }}/> :
                                     <img src={editState?.attributes?.logo ? editState?.attributes?.logo : require("../assets/hospital.png")} style={{height: 60, width: 60, paddingTop: 10, borderRadius: 6 }} />
                               }
                         <label htmlFor="file">
                 
                                    <View style={styles.editIcon}>
                                          <TouchableOpacity style={styles.buttonNew}>
                                          <Text style={styles.buttonTextNew}>Select Facility Image</Text>
                                          </TouchableOpacity>
                                    </View>
                                    
                                    </label>

                               <input 
                               type='file' 
                               id="file" 
                               style={{display:'none'}} 
                               name="patient_image"   
                               accept="image/*"
                               onChange={onImageChange}
                               />
                         </View>
               
                  <View style={{flexDirection: window.screen.width > 950 ? 'row' : 'column'}}>
                        
                  <View style={{paddingRight: 50}}>
                  <Text style={styles.headingH2}>Is this the Hospital</Text>
                  <RadioGroup aria-label="quiz" name="quiz" value={hospitals} onChange={handelhospital}>
                  <View style={{flexDirection: 'row'}}>
                  <FormControlLabel style={{color: '#4e3e71'}}  value='Yes' control={<Radio style={{color: '#4e3e71'}}  />} label="Yes" />
                  <FormControlLabel style={{color: '#4e3e71'}}  value="No" control={<Radio style={{color: '#4e3e71'}}  />} label="No" />
                  </View>
                  
                  </RadioGroup>
                        </View>
                        {
                              showAmbulatory ? 
                              <View>
                              <Text style={styles.headingH2}>Ambulatory Service Centre ?</Text>
                  <RadioGroup aria-label="quiz" name="quiz" value={ambulatory} onChange={handelambulatory}>
                  <View style={{flexDirection: 'row'}}>
                  <FormControlLabel style={{color: '#4e3e71'}}  value="Yes" control={<Radio style={{color: '#4e3e71'}}  />} label="Yes" />
                  <FormControlLabel style={{color: '#4e3e71'}}  value="No" control={<Radio style={{color: '#4e3e71'}}  />} label="No" />
                  </View>
                  </RadioGroup>
               
                  </View> :
                  <View>{
                        showOr ? 
                        <View>
                              <Text style={styles.headingH2}>Is this the main O.R</Text>
                  <RadioGroup aria-label="quiz" name="quiz" value={OR} onChange={handelOR}>
                  <View style={{flexDirection: 'row'}}>
                  <FormControlLabel style={{color: '#4e3e71'}}  value="Yes" control={<Radio style={{color: '#4e3e71'}}  />} label="Yes" />
                  <FormControlLabel style={{color: '#4e3e71'}}  value="No" control={<Radio style={{color: '#4e3e71'}}  />} label="No" />
                  </View>
                  </RadioGroup>
                        </View> : null
                        }

                 
                  </View> 

                  }
                                          </View>
                  {/*  */}
                  { showAmbulatoryInput ?  
                        <View>
                              <TextInput
                                    style={styles.input}
                                    placeholder="Enter in Name"
                                    placeholderTextColor="#b2bac6"
                                    onChangeText={handleChange('ambulatory_name')}
                                    onBlur={handleBlur('ambulatory_name')}
                                    value={values.ambulatory_name}

                              />
                        </View> : null}
                  { showInputOR ?
                        <View>
                              <TextInput
                                    style={styles.input}
                                    placeholder="Enter in Name"
                                    placeholderTextColor="#b2bac6"
                                    onChangeText={handleChange('or_name')}
                                    onBlur={handleBlur('or_name')}
                              />
                        </View> : null}
                        <View>
                        
                        </View>
                        
            </View>
                              </View>
                              {
                                    window.screen.width > 950 ? 
                                          <View style={{flexDirection: 'row',justifyContent: 'space-between', width: '100%'}}>
                                                <TouchableOpacity onPress={() => {handleClose(); window.location.reload()}}>
                                                      <View style={styles.button2}>
                                                            <Text style={styles.buttonText2}>Cancel</Text>
                                                      </View>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => handleSubmit()}>
                                                      <View style={styles.button1}>
                                                            <Text style={styles.buttonText1}>Confirm</Text>
                                                      </View>
                                                </TouchableOpacity>
                                          </View> : 
                                          <TouchableOpacity onPress={() => handleSubmit()}>
                                                <View style={styles.button}>
                                                      <Text style={styles.buttonText}>Confirm</Text>
                                                </View>
                                          </TouchableOpacity>
                              }
           
            </View>
           
                       
                  )}
                  </Formik> : null
                  }
                   
                
            </View>
            )
      
      }
const styles = StyleSheet.create({
            container: {
                        flex: 1,
                        backgroundColor: "#ffff",
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    
                      },
                      patientImage:{
                        flexDirection: 'row',
                        marginHorizontal: 10
                    //     justifyContent: 'space-between',
                    },
                      editIcon:{
                        flex: 1,
                        flexDirection: 'column',
                        // marginVertical: 30,
                        // marginHorizontal: 50
                  },
                      buttonNew: {
                        borderRadius: 8,
                        paddingVertical: 10,
                        paddingHorizontal: 20,
                        backgroundColor: '#4e3e71',
                        marginVertical: 20,
                        marginHorizontal: 30
                      },
                      buttonTextNew: {
                        color: 'white',
                        fontSize: 15,
                        textAlign: 'center',
                      },
                      headingView: {
                        flexDirection: 'row',
                        // alignItems: 'center',
                        paddingVertical: 27,
                        backgroundColor: '#4e3e71',
                        marginBottom: 20,
                    
                      },
                      heading:{
                        color: '#e8fafe',
                        fontSize: 20,
                        marginHorizontal: 'auto'       
                      },
                headingH2: {
                  fontSize: 16,
                  paddingVertical: 10,
                  fontWeight: 'bold',
                  color: '#4e3e71'
                },
                card: {
                  paddingTop: 20,
                  marginHorizontal: 24,
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'space-between'

                },
                input: {
outlineStyle: 'none',
                  flex: 1,
                  marginTop: 20,
                  padding:10,
                  paddingLeft: 10,
                  fontSize: 18,
                  color: '#112950',
                  borderBottomWidth: 1,
                  borderBottomColor: '#e5e5e5'
                },
                button: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 30,
                  backgroundColor: '#4e3e71',
                  marginVertical: 20,
                  marginHorizontal: 30
                },
                buttonText: {
                  color: 'white',
                  fontSize: 20,
                  textAlign: 'center',
                },
                button1: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 80,
                  backgroundColor: '#4e3e71',
                  marginVertical: 20,
                  marginHorizontal: 30
                },
                buttonText1: {
                  color: 'white',
                  fontSize: 20,
                  textAlign: 'center',
                },
                button2: {
                  borderRadius: 8,
                  paddingVertical: 10,
                  paddingHorizontal: 70,
                  backgroundColor: '#fff',
                  marginVertical: 20,
                  marginHorizontal: 30,
                  borderColor: '#4e3e71',
                  borderWidth: 1
                },
                buttonText2: {
                  color: '#4e3e71',
                  fontSize: 20,
                  textAlign: 'center',
                },
})