import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  terms: any;
  loader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  termsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      terms: "",
      loader: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if(apiRequestCallId === this.termsApiCallId) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({terms: responseJson.data.description, loader: false})
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getTerms() {
    const headers = {
      "Content-Type": 'application/json',
      'token' : localStorage.getItem('authToken')
    };
  
    const getHospitalsList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsApiCallId = getHospitalsList.messageId;
  
    getHospitalsList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/accounts/terms_conditions'
    );
  
    getHospitalsList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getHospitalsList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(getHospitalsList.id, getHospitalsList);
    
  }

  // Customizable Area End
}
