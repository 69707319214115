// Customizable Area Start
import React, { useEffect } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import GlobalCss from "../../../framework/src/GlobalCss";
import DesktopDesign from "./DesktopMaterialDetails";

export default function MaterialDetails({ navigation }: { navigation: any }) {
  const dummyDescription = "No description available";
  const material = JSON.parse(localStorage.getItem("selectedMaterial") || "{}");
  const role_id = localStorage.getItem("role") || "";

  const navigateToEdit = () => {
    localStorage.setItem("editMaterialData", JSON.stringify(material));
    navigation.navigate("NewMaterial");
  };

  useEffect(() => {
    localStorage.removeItem("editMaterialData");
  }, []);

  return window.screen.width < 950 ? (
    <View style={styles.container}>
      <View style={styles.headingView}>
        <TouchableOpacity
          style={styles.leftArrowIconWrapper}
          onPress={() => navigation.goBack()}
        >
          <img
            src={require("../assets/leftArrow1.png")}
            style={{
              height: 24,
              width: 24,
            }}
          />
        </TouchableOpacity>
        <Text numberOfLines={2} style={styles.heading}>
          {material.attributes.name}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <View>
          <View style={{ marginHorizontal: "auto" }}>
            <img
              src={
                material.attributes.image
                  ? material.attributes.image
                  : require("../assets/dummyMaterial.png")
              }
              style={{
                height: 274,
                width: 274,
                borderRadius: 12,
                paddingTop: 10,
              }}
            />
            <View style={styles.line} />
          </View>
          <View
            style={{
              marginHorizontal: 24,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 24, color: "#4e3e71" }}>
              {material.attributes.name}{" "}
            </Text>
            <Text style={{ fontSize: 24, color: "#4e3e71" }}>
              (Manufacturor test :{" "}
              {material.attributes.brand.data.attributes.name})
            </Text>
            <Text
              style={{ fontSize: 16, paddingVertical: 10, color: "#4e3e71" }}
            >
              {material.attributes.description
                ? material.attributes.description
                : dummyDescription}
            </Text>
          </View>
        </View>
        {role_id === "4" && (
          <View>
            <TouchableOpacity
              onPress={() => navigateToEdit()}
              style={GlobalCss.buttonView}
            >
              <Text style={GlobalCss.buttonText}>Edit Details</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  ) : (
    <DesktopDesign navigation={navigation} />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
  },
  editBTn: {
    fontSize: 18,
    fontWeight: "700",
    color: "#4e3e71",
    textDecorationLine: "underline",
  },
  line: {
    width: 48,
    height: 5,
    bordeRadius: 100,
    backgroundColor: "#e5e5e5",
    marginHorizontal: "auto",
    marginVertical: 50,
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24,
  },
  quantity: {
    flexDirection: "row",
    // width: 108,
    // height: 32,
    borderRadius: 5,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    marginHorizontal: 10,
    justifyContent: "space-between",
  },
  btn: {
    fontSize: 30,
    color: "#4e3e71",
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  btn2: {
    fontSize: 20,
    color: "#4e3e71",
    padding: 2,
    backgroundColor: "#cce9e5",
    borderRadius: 4,
    margin: 5,
    paddingHorizontal: 10,
  },
  likeicon: {
    height: 32,
    width: 32,
    borderRadius: 16,
    padding: 7,
    borderWidth: 1,
    borderColor: "#e5e5e5",
    // marginVertical: 10,
    marginHorizontal: 11,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 27,
    backgroundColor: "#4e3e71",
    marginBottom: 20,
  },
  heading: {
    color: "#e8fafe",
    fontSize: 18,
    marginHorizontal: 40,
    paddingHorizontal: 30,
  },
  pickerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5",
    paddingVertical: 10,
    marginHorizontal: 24,
  },
  picker: {
    width: "100%",
    height: 50,
    color: "#4e3e71",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "white",
  },
  color: {
    color: "#0579ed",
    fontSize: 20,
  },
  button: {
    borderRadius: 12,
    paddingVertical: 15,
    paddingHorizontal: 50,
    marginHorizontal: 20,
    backgroundColor: "#4e3e71",
    marginBottom: 10,
    marginTop: 10,
  },
  buttonText: {
    color: "#e8fafe",
    fontSize: 17,
    textAlign: "center",
  },
  dropdown: {
    marginVertical: 20,
    marginHorizontal: 20,
    paddingVertical: 20,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 6,
  },
  listItem: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginVertical: 20,
  },
  itemImage: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 10,
    margin: 10,
  },
});
// Customizable Area End
