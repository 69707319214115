// Customizable Area Start
import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
} from "react-native";
import Drawer from "@material-ui/core/Drawer";
import NotiFication from "./Notification";
import Profile from "../../CustomisableUserProfiles/src/CustomisableUserProfiles";

export default function DesktopHeader({
  navigation,
  heading,
}: {
  navigation: any;
  heading: any;
}) {
  const role_id = localStorage.getItem("role");
  const profile_image = JSON.parse(
    localStorage.getItem("loggedinUserInfo") || "{}"
  );

  const [showPrfole, setShowProfile] = useState({
    notification: false,
    profile: false,
  });

  const [state, setState] = React.useState({
    right: false,
  });
  const openDrawer = (type: any) => {
    if (type === "profile") {
      setShowProfile({ notification: false, profile: true });
    } else {
      setShowProfile({ profile: false, notification: true });
    }
    setState({
      right: true,
    });
  };
  type Anchor = "right";

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const { width } = Dimensions.get("window");

  return (
    <View style={styles.desktopHeader}>
      <View style={styles.leftHeader}>
        <img
          src={require("../assets/upmed.png")}
          style={{ height: 50, width: width * 0.1 }}
        />
      </View>
      <View style={styles.rightHeader}>
        <Text
          style={{
            color: "#e8fafe",
            fontSize: 20,
            marginVertical: "auto",
            paddingHorizontal: 26,
          }}
        >
          {heading}
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: "auto",
            marginHorizontal: 26,
          }}
        >
          {role_id === "1" ? (
            <TouchableOpacity
              onPress={() => navigation.navigate("CreateBooking")}
            >
              <View style={styles.buttonH}>
                <Text style={styles.buttonTextH}>Start Booking </Text>
              </View>
            </TouchableOpacity>
          ) : null}
          <TouchableOpacity onPress={() => openDrawer("profile")}>
            <img
              src={
                profile_image.profile_image
                  ? profile_image.profile_image
                  : require("../assets/avatar-chat1.png")
              }
              style={{
                height: 45,
                width: 45,
                marginRight: 15,
                borderRadius: "50%",
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openDrawer("notification")}>
            <img
              src={require("../assets/bellDesktop.png")}
              style={{
                height: 30,
                width: 30,
                borderRadius: 15,
                marginLeft: 20,
                margin: "auto",
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <View style={{ width: 400 }}>
              {showPrfole.profile ? (
                <Profile
                  navigation={navigation}
                  id="CustomisableUserProfiles"
                />
              ) : (
                <NotiFication navigation={navigation} />
              )}
            </View>
          </Drawer>
        </React.Fragment>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  leftHeader: {
    flex: 0.15,
    backgroundColor: "#e8fafe",
    alignItems: "center",
    justifyContent: "center",
  },
  rightHeader: {
    flex: 0.85,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#4e3e71",
  },
  desktopHeader: {
    flexDirection: "row",
    height: "10vh",
  },
  buttonH: {
    borderRadius: 12,
    paddingVertical: 12,
    paddingHorizontal: 45,
    marginHorizontal: 24,
    backgroundColor: "#ffff",
  },
  buttonTextH: {
    color: "#4e3e71",
    fontSize: 17,
    textAlign: "center",
  },
});
// Customizable Area End
