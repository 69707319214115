import React, {useEffect, useState} from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator
} from "react-native";
import DesktopHeader from "../../DesktopHeader";
import DesktopMenue from "../../DesktopMenue";
import scale from "../../../../../framework/src/Scale";
import { getAxiosAPI, getErrorMessage, postAxiosAPI } from "../../../../../framework/src/Blocks/AxiosAPIBlock";
import GlobalCss from "../../../../../framework/src/GlobalCss";
import Dialog from '@material-ui/core/Dialog';
import CreateManufac from "./CreateManufac";


export default function DesktopSurgeryDetails({ navigation }: { navigation: any}) {

      const [ data, setData] = useState([])
      const [query, setQuery] = useState("");
      const [loader, setLoader] = useState({
            list: false,
            submit: false
      });
      const [brand_id, setbrand_id ] = useState('');
      const [offset, setOffset] = useState(0);

      const [message, setMessage] = useState("");
      const [popup, setPopup] = useState(false)
      const [open, setOpen] = useState(false)

      const handleClose = () => {
            setOpen(false)
      }

      useEffect(() => {
            getManufaclist()
      },[])

      const getManufaclist =  async (search: any = '') => {
            setLoader((pre:any) => ({...pre, list: true}))
            let url = `/bx_block_catalogue/brands?search=${search}&limit=9&offset=${offset}`
            await getAxiosAPI(url).then((res: any) => {
                  if(res.error){
                    let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
                    setLoader((pre:any) => ({...pre, list: false}))
                  } else {
                    setLoader((pre:any) => ({...pre, list: false}))
                    setData(res.data.data)
                  }
                })
              }
      
      const handleSearch = (text: any) => {
            setQuery(text)
            getManufaclist(text)
      };

      

      const ListEmptyView = () => {
            return (
                  <View>
                        <Text style={styles.heading}>No Manufacturer Found</Text>
                  </View>

            )
      }

      const handleChange = (item:any) => {
            let newdata: any = data.map((x: any) => {
              
              if(x.id === item.id ) {
                x.ischecked = !x.ischecked
              } else {
                  x.ischecked = false
              }
              return x
            })
            setData(newdata)
            if(item.ischecked){
                  setbrand_id(item.id)
                }
            // setbrand_id(item.id)
            // setHospital_name(item.attributes.name)
            
          };

          const navigateTo = async() => {
            setLoader((pre:any) => ({...pre, list: true}))
            let url = "/account_block/accounts/select_brands_for_sales_rep";
            var data = new FormData();
            data.append("brand_ids", brand_id);
            
            await postAxiosAPI(url, data).then((res: any) => {
              if(res.error){
                let msg = getErrorMessage(res.data.errors[0])
                    console.log(res.data);
                    setLoader((pre:any) => ({...pre, list: false}))
              } else {
                  setLoader((pre:any) => ({...pre, list: false}))
                  localStorage.setItem("brandID", brand_id);
                  navigation.navigate('SelectMaterial')
              }
            });
           
          }


      return (
                <View style={styles.desktopContainer}>
          <DesktopHeader  heading={"Select Manufacturer"} navigation={navigation} />
          <View style={styles.bottomDesktopCard}>
            <DesktopMenue navigation={navigation} />
            <View style={{ flex: 0.85}}>
                        <View style={styles.outerCard}>   
                        <Text style={{ fontSize: 20, color: '#4e3e71', fontWeight: 'bold' }}>Search Manufacturer </Text>
                              <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <View style={styles.searchBox}>
                                                <TextInput
                                                      style={styles.input}
                                                      placeholder="Enter Manufacturer Name "
                                                      autoCapitalize="none"
                                                      autoCorrect={false}
                                                      clearButtonMode="always"
                                                      placeholderTextColor="#939ca3"
                                                      value={query}
                                                      onChangeText={queryText => handleSearch(queryText)}
                                                />
                                                <TouchableOpacity onPress={() => setQuery('')}>
                                                      <img src={query === '' ? require("../../../assets/search.png") : require("../../../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                                </TouchableOpacity>
                                          </View>
                                          <TouchableOpacity onPress={() => setOpen(true)} style={styles.buttonTop}>
                                          <Text style={GlobalCss.buttonText}>Create Manufacturer</Text>
                                          </TouchableOpacity>       
                                     </View>
                        </View>

                                          {loader.list ? <ActivityIndicator
                                    style={{ margin: 'auto', height: 100, width: 100, }}
                                    size="large" color="#4e3e71" />
                                    : <View style={styles.card}>
                                    <View style={{ flex: 1, }}>
                                          {
                                                (query.length > 0 && data.length > 0) ?
                                                      <View style={styles.add}>
                                                            <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                                      </View>
                                                      :
                                                      (query.length > 0 && data.length === 0) ?
                                                            <View style={styles.add}>
                                                                  <Text style={styles.resultText}>Searched Manufacturer not found</Text>
                                                            </View> : null
                                          }

                                          <FlatList
                                                style={{ height: 500 }}
                                                ListEmptyComponent={ListEmptyView()}
                                                data={data}
                                                keyExtractor={(item: any) => item.id}
                                                horizontal={false}
                                                numColumns={3}
                                                renderItem={({ item }) => (
                                                            <TouchableOpacity onPress={() => handleChange(item)}>
                                                                        <View style={item.ischecked ? styles.listItem_active : styles.listItem}>
                                                                        <View style={{flexDirection: 'row' , flex: 0.9}}>
                                                                              <img src={require("../../../assets/manifac_img.png")} style={{ height: 56, width: 56}}/> 
                                                                              <View style={{flexDirection: 'column', marginVertical: 'auto',}}>
                                                                              <Text style={styles.names} numberOfLines={1}>{item.attributes.name} </Text>    
                                                                                    <Text style={styles.role} numberOfLines={4}>{item.attributes.address}</Text>
                                                                              </View>
                                                                        </View>
                                                                        <View style={{marginVertical: 'auto', flex: 0.1}}>
                                                                        <img src={item.ischecked ? require("../../../assets/radio_active.png") : require("../../../assets/radio_unactive.png")}  style={{height: 26, width: 26, backgroundColor: '#4e3e71', borderRadius: '50%' }}/>      
                                                                        </View>
                                                                        </View>
                                                      </TouchableOpacity>

                                                )}
                                          />
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                          <TouchableOpacity disabled={brand_id  ? false : true} onPress={() => navigateTo()} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <View style={brand_id ? styles.button : styles.button0}>
                                                      <Text style={brand_id ? styles.buttonText : styles.buttonText0}>Continue</Text>
                                                     {loader.submit && brand_id ? <ActivityIndicator
                                                            style={{ margin: 'auto',position: 'absolute', right: 65, top: 8}}
                                                            size="large" color="#4e3e71" /> : null}
                                                </View>
                                          </TouchableOpacity>                        
                                    </View>
                              </View> }

                                    </View>
                              </View>
                        
                      
                  </View>
                  <Dialog
                        open={open}
                        onClose={() => handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                  >
                        <View 
                        style={{ width: 500, height: 600 }}
                        >
                              <CreateManufac handleClose={handleClose} navigation={navigation} />
                        </View>

                  </Dialog>
                  </View>
      )
}

const styles = StyleSheet.create({
      desktopContainer: {
            flex: 1,
            flexDirection: 'column'
      },
      button0: {
            position: 'relative',
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 10,
            backgroundColor: '#aa9cc9',
            marginHorizontal: 10
      },
      buttonText0: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      button: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 10,
            backgroundColor: '#4e3e71',
            marginHorizontal: 10
      },
      buttonTop: {
            borderRadius: 12,
            paddingVertical: 15,
            paddingHorizontal: 50,
            marginVertical: 20,
            backgroundColor: '#4e3e71',
            marginHorizontal: 10
      },
      buttonText: {
            color: '#e8fafe',
            fontSize: 17,
            textAlign: 'center',
      },
      add: {
            flexDirection: 'row',
            justifyContent: "center"
      },
      resultText: {
            paddingVertical: 10,
            fontSize: 18

      },
      card: {
            flex: 1,
            // height: 300,
            marginTop: 10,
            borderRadius: 6,
            elevation: 3,
            backgroundColor: '#fff',
            marginVertical: 6,
      },
      heading: {
            marginTop: 100,
            marginHorizontal: 'auto',
            color: '#b2bac6',
            fontSize: 24,
            fontWeight: 'bold',
            flexDirection: 'row',
            justifyContent: 'center'
      },
      listItem: {
            height: 300,
            width: 360,
            marginHorizontal:12,
            borderColor: '#e5e5e5',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 10,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },
          listItem_active: {
            height: 300,
            width: 360,
            marginHorizontal:12,
            borderColor: '#4e3e71',
            borderWidth: 1,
            borderRadius: 12,
            marginVertical: 5,
            padding: 10,
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between'
          },
          role: {
            color: '#b2bac6',
            paddingHorizontal: 11,
            width: scale(60),
            
          },
          names: {
            color: '#4e3e71',
            fontSize: 15,
            paddingHorizontal: 11,
            width: scale(220),
            
          },
          
      searchBox: {
            flex: 1,
            flexDirection: 'row',
            marginVertical: 20,
            borderWidth: 1,
            borderColor: '#8a8a8a',
            borderRadius: 50
      },
      input: {
outlineStyle: 'none',
            flex: 1,
            paddingLeft: 10,
            height: 48,
            color: '#4e3e71',
            fontSize: 16,
            paddingStart: 20,
            // width: '100%'
      },
       bottomDesktopCard: {
            flex: 1,
            flexDirection: 'row'
      },
        outerCard: {
            flex: 1,
            backgroundColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            borderColor: '#7f7f7f',
            padding: 15,
            margin: 15,
            flexDirection: 'column'
            
      }
      
})