import React,{ useState} from 'react';
import {StyleSheet,
      Text,
      View,
      TouchableOpacity,
      TouchableHighlight ,
      Modal,
      TextInput,
      FlatList,
TouchableWithoutFeedback,
Platform } from 'react-native';
import EmailAccountRegistrationController, {
      Props
} from "./EmailAccountRegistrationController";
import scale, { verticalScale } from "../../../framework/src/Scale";
import GlobalCss from '../../../framework/src/GlobalCss';


export default function FailityName({navigation} : {navigation : any})  {
    
      //     console.log(navigation.params)
          const facility = JSON.parse(localStorage.getItem('selectedFacility') || '{}')
          const role_id = localStorage.getItem('role')
          const isPrimary = localStorage.getItem('isPrimary')

          const renderEditButton = (item: any) => {
                console.log(isPrimary,role_id)
                if(role_id === '1') {
                      return (
                        <TouchableOpacity onPress={() => navigation.navigate("AddNewFacility")} style={GlobalCss.buttonView}>
                <Text style={GlobalCss.buttonText}>Edit</Text>
              </TouchableOpacity>
                      )
                } 
                if((role_id === '2'&& isPrimary === "true") || (role_id === '3' && isPrimary === "true" )) {
                  return (
                    <TouchableOpacity onPress={() => navigation.navigate("AddNewFacility")} style={GlobalCss.buttonView}>
                    <Text style={GlobalCss.buttonText}>Edit</Text>
                  </TouchableOpacity>
                  )
                  }
                
                else {
                      return null
                }
          }

      return (
        
            <View style={styles.container}>
            <View style={styles.headingView}>
        <TouchableOpacity style={styles.leftArrowIconWrapper} onPress={() => navigation.goBack()}>
          <img src={require("../assets/leftArrow1.png")} style={{
            height: 24, width: 24,
          }} />
        </TouchableOpacity>
    <Text style={styles.heading}>{facility.attributes.name}</Text>
          
      </View>
      <View style={styles.card}>
              <Text style={styles.color}>{facility.attributes.name}</Text>
              <View style={styles.profileDetails}>
                <img src={facility.attributes.logo ? facility.attributes.logo : require("../assets/hospital.png")} style={{ height: 66, width: 66 }} />
                <View style={styles.names}>                                         
                  <Text numberOfLines={2} style={styles.info}><Text style={{ color: '#7f7f7f'}}>Material Manager: </Text>
                  {facility.attributes.material_manager ? facility.attributes?.material_manager?.first_name+" "+facility.attributes.material_manager?.last_name : "Not Present"}
                  </Text>
                  <Text numberOfLines={2} style={styles.info}><Text style={{ color: '#7f7f7f'}}>Booking co-ordinatior: </Text>
                  {facility.attributes.booking_coordinator ? facility.attributes.booking_coordinator?.first_name+" "+facility.attributes.booking_coordinator?.last_name : 'Not Present'}
                  </Text>
                </View>
              </View>
            {/* {renderEditButton(facility)} */}
            </View>
            
            

</View> 
      )
      }


const styles = StyleSheet.create({
      container: {
            flex: 1,
            backgroundColor: "#fff",
            flexDirection: 'column'
          },
          headingView: {
            backgroundColor: "#4e3e71",
            height: 72,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        
          },
          heading:{
            color: '#e8fafe',
            fontSize: 20,
            marginHorizontal: 'auto'       
          },
          leftArrowIconWrapper: {
            width: 24,
            height: 24,
            position: "absolute",
            left: 24
          },
          color: {
            color: '#4e3e71',
            fontSize: 24,
          },
          card: {
            flexDirection: 'column',
            marginTop: 40,
            paddingHorizontal: 10,
            // paddingVertical: 10,
            borderRadius: 11,
            marginHorizontal: 24,
            // marginVertical: 6,
            borderWidth: 1,
            borderColor: '#4e3e71'
          },
          horizontalBorder: {
            borderBottomColor: '#939ca3',
            borderBottomWidth: 1,
            padding: 15,
            borderColor: 'grey',
            flexDirection: 'row',
            justifyContent: 'center',
          },
          profileDetails: {
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingVertical: 20,
            marginBottom: 20
          },
          names: {
            paddingHorizontal: 16
          },
          image: {
        
          },
          info: {
            color: '#4e3e71',
            width: scale(250),
            fontSize: 16,
            padding: 2,
          },
   
          topHeading: {
                borderBottomWidth: 1,
                borderColor: 'grey',
                paddingHorizontal: 20,
                marginHorizontal: 20,
                marginVertical: 5,
                paddingVertical: 5,
                flexDirection: 'row'
          },
          details: {
            paddingHorizontal: 20,
            marginVertical: 20,
            paddingVertical: 5,
          },
          button: {
            borderRadius: 8,
            paddingVertical: 10,
            paddingHorizontal: 30,
            backgroundColor: '#0579ed',
            marginTop: 50,
            marginHorizontal: 30
          },
          buttonText: {
            color: 'white',
            fontSize: 20,
            textAlign: 'center',
          },
          text: {
                fontSize: 20,
                paddingVertical: 2
          }
      
})