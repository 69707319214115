import React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  TextInput,
  ScrollView,
  Picker
} from "react-native";
import VerifyMaterialDetailController from "./VerifyMaterialDetailController";
import Dropzone from "react-dropzone";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
// import { th } from "date-fns/locale";

const validation_Schema = yup.object({
  manufacturerName: yup.string().required("Manufacturer name is required"),
  materialCategory: yup.string().required("Material category is required"),
  materialName: yup.string().required("Material name is required"),
  division: yup.string().required("Division is required"),
  catlogNo: yup.string().required("Catlog no is required"),
  barcode: yup.string().required("Barcode is required"),
  gtinNo: yup
    .string()
    .length(14, "Gtin no should be 14 characters")
    .required("Gtin no is required")
});
export default class VerifyMaterialDetail extends VerifyMaterialDetailController {
  _onHandleDrop = (acceptedFiles: any, rejectedFiles: any, event: any) => {
    console.log("acceptedFiles", acceptedFiles);
    this.setState({ image: acceptedFiles });
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr: any = reader.result;
      this.setState({ imagePath: binaryStr });
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  render() {
    return (
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.headerWrapper}>
            <TouchableOpacity
              onPress={() => this.props.navigation.goBack()}
              style={styles.leftArrowIconWrapper}
            >
              <Image
                source={require("../../../assets/leftArrow.png")}
                style={styles.leftArrowIcon}
              />
            </TouchableOpacity>
            <Text style={styles.headerTitle}>Add Material</Text>
          </View>
          <View style={styles.subWrapper}>
            <Text
              style={{ color: "#4e3e71", fontSize: 24, marginVertical: 30 }}
            >
              Material Details:
            </Text>
            <Dropzone onDrop={this._onHandleDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <View style={styles.imageContainer}>
                  {this.state.imagePath === "" ? (
                    <div {...getRootProps()}>
                      <View style={styles.uploadIconWrapper}>
                        <Image
                          source={require("../../../assets/upload.png")}
                          style={styles.uploadIcon}
                        />
                      </View>
                      <Text style={styles.dragAndDropText}>
                        Drag and Drop or Browse to upload
                      </Text>
                      <input {...getInputProps()} />{" "}
                    </div>
                  ) : (
                    <Image
                      source={{ uri: this.state.imagePath }}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "center"
                      }}
                    />
                  )}
                </View>
              )}
            </Dropzone>
            <Formik
              enableReinitialize
              initialValues={{
                manufacturerName: this.state.manufacturerName,
                materialCategory: this.state.materialCategory,
                materialName: this.state.materialName,
                division: this.state.division,
                catlogNo: this.state.catlogNo,
                barcode: this.state.barcode,
                gtinNo: this.state.gtinNo,
                price: '',
                discount_price: '',
                description:''
              }}
              validationSchema={validation_Schema}
              onSubmit={(values, actions) => {
                this.setState({ manufacturerName: values.manufacturerName });
                this.setState({ materialCategory: values.materialCategory });
                this.setState({ materialName: values.materialName });
                this.setState({ division: values.division });
                this.setState({ gtinNo: values.gtinNo });
                this.setState({ barcode: values.barcode });
                this.setState({ catlogNo: values.catlogNo });
                this._handleAddMaterial();
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit,
                setFieldValue
              }) => {
                console.log(
                  "=====",
                  this.state.materialCategory,
                  this.state.materailCategorieList
                );
                return (
                  <>
                    <Text style={styles.inputlabel}>Manufacturer Name</Text>
                    <View style={styles.textInputStyle}>
                      <Picker
                        selectedValue={values.manufacturerName}
                        style={[
                          values.manufacturerName === ""
                            ? styles.disablepicker
                            : styles.enablePicker
                        ]}
                        onValueChange={e => {
                          setFieldValue("manufacturerName", e);
                          this.getMaterailCategories(e);
                        }}
                      >
                        <Picker.Item
                          value=""
                          label="Select Manufacturer Name"
                        />
                        {this.state.manufacturerList &&
                          this.state.manufacturerList.length > 0 &&
                          this.state.manufacturerList.map((item: any) => {
                            return (
                              <Picker.Item
                                label={item.attributes.name}
                                value={item.id}
                                key={item.id}
                              />
                            );
                          })}
                      </Picker>
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.manufacturerName && errors.manufacturerName
                        ? errors.manufacturerName
                        : ""}
                    </Text>
                    <Text style={styles.inputlabel}>Material category</Text>

                    <View style={styles.textInputStyle}>
                      <Picker
                        selectedValue={values.materialCategory}
                        style={[
                          values.materialCategory === ""
                            ? styles.disablepicker
                            : styles.enablePicker
                        ]}
                        onValueChange={handleChange("materialCategory")}
                      >
                        <Picker.Item
                          value=""
                          label="Select material category"
                        />
                        {this.state.materailCategorieList &&
                          this.state.materailCategorieList.length > 0 &&
                          this.state.materailCategorieList.map((item: any) => {
                            return (
                              <Picker.Item
                                label={item.attributes.name}
                                value={item.id}
                                key={item.id}
                              />
                            );
                          })}
                      </Picker>
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.materialCategory && errors.materialCategory
                        ? errors.materialCategory
                        : ""}
                    </Text>
                    <Text style={styles.inputlabel}>Material Name</Text>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        style={styles.textInputStyle}
                        // placeholder="Material Name"
                        editable={this.state.editable[0]}
                        placeholderTextColor="#b2bac6"
                        value={values.materialName}
                        onBlur={handleBlur("materialName")}
                        onChangeText={handleChange("materialName")}
                      />
                      {!this.state.editable[0] && (
                        <TouchableOpacity
                          onPress={() => {
                            this.state.editable[0] = true;
                            this.setState({ editable: this.state.editable });
                          }}
                        >
                          <Image
                            source={require("../../../assets/editIcon.png")}
                            style={styles.editIcon}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.materialName && errors.materialName
                        ? errors.materialName
                        : ""}
                    </Text>
                    <Text style={styles.inputlabel}>Division</Text>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        style={styles.textInputStyle}
                        // placeholder="Division"
                        editable={this.state.editable[1]}
                        placeholderTextColor="#b2bac6"
                        value={values.division}
                        onBlur={handleBlur("division")}
                        onChangeText={handleChange("division")}
                      />
                      {!this.state.editable[1] && (
                        <TouchableOpacity
                          onPress={() => {
                            this.state.editable[1] = true;
                            this.setState({ editable: this.state.editable });
                          }}
                        >
                          <Image
                            source={require("../../../assets/editIcon.png")}
                            style={styles.editIcon}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.division && errors.division
                        ? errors.division
                        : ""}
                    </Text>
                    <Text style={styles.inputlabel}>Catlog No</Text>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        style={styles.textInputStyle}
                        // placeholder="Catlog No"
                        placeholderTextColor="#b2bac6"
                        value={values.catlogNo}
                        onBlur={handleBlur("catlogNo")}
                        onChangeText={handleChange("catlogNo")}
                        editable={this.state.editable[2]}
                      />
                      {!this.state.editable[2] && (
                        <TouchableOpacity
                          onPress={() => {
                            this.state.editable[2] = true;
                            this.setState({ editable: this.state.editable });
                          }}
                        >
                          <Image
                            source={require("../../../assets/editIcon.png")}
                            style={styles.editIcon}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.catlogNo && errors.catlogNo
                        ? errors.catlogNo
                        : ""}
                    </Text>
                    <Text style={styles.inputlabel}>Barcode</Text>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        style={styles.textInputStyle}
                        // placeholder="Barcode"
                        placeholderTextColor="#b2bac6"
                        value={values.barcode}
                        onBlur={handleBlur("barcode")}
                        onChangeText={handleChange("barcode")}
                        editable={this.state.editable[3]}
                      />
                      {!this.state.editable[3] && (
                        <TouchableOpacity
                          onPress={() => {
                            this.state.editable[3] = true;
                            this.setState({ editable: this.state.editable });
                          }}
                        >
                          <Image
                            source={require("../../../assets/editIcon.png")}
                            style={styles.editIcon}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.barcode && errors.barcode ? errors.barcode : ""}
                    </Text>
                    <Text style={styles.inputlabel}>Gtin No</Text>
                    <View style={{ flexDirection: "row" }}>
                      <TextInput
                        style={styles.textInputStyle}
                        // placeholder="Gtin No"
                        placeholderTextColor="#b2bac6"
                        value={values.gtinNo}
                        onBlur={handleBlur("gtinNo")}
                        onChangeText={handleChange("gtinNo")}
                        editable={this.state.editable[4]}
                      />
                      {!this.state.editable[4] && (
                        <TouchableOpacity
                          onPress={() => {
                            this.state.editable[4] = true;
                            this.setState({ editable: this.state.editable });
                          }}
                        >
                          <Image
                            source={require("../../../assets/editIcon.png")}
                            style={styles.editIcon}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                    <Text style={{ color: "red" }}>
                      {touched.gtinNo && errors.gtinNo ? errors.gtinNo : ""}
                    </Text>
                    <TouchableOpacity
                      style={styles.acceptManagerButton}
                      onPress={() => handleSubmit()}
                    >
                      <Text style={styles.acceptManagerText}>
                        Confirm Details
                      </Text>
                    </TouchableOpacity>
                  </>
                );
              }}
            </Formik>
          </View>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  headerWrapper: {
    backgroundColor: "#4e3e71",
    height: 72,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  leftArrowIconWrapper: {
    width: 24,
    height: 24,
    position: "absolute",
    left: 24
  },
  leftArrowIcon: {
    width: 24,
    height: 24
  },
  headerTitle: {
    color: "#e8fafe",
    fontSize: 20
  },
  subWrapper: {
    width: "100%",
    paddingHorizontal: 24
  },
  inputlabel: {
    color: "#4e3e71",
    fontSize: 18,
    marginTop: 25,
    fontWeight: "bold"
  },
  textInputStyle: {
    paddingVertical: 5,
    fontSize: 15,
    flex: 1,
    color: "#112950",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e5e5"
  },
  editIcon: {
    height: 18,
    width: 18,
    resizeMode: "center"
  },
  acceptManagerButton: {
    marginHorizontal: 24,
    height: 54,
    backgroundColor: "#4e3e71",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10
  },
  disablepicker: {
    width: "100%",
    color: "#b2bac6",
    borderWidth: 0
  },
  enablePicker: {
    width: "100%",
    color: "#000",
    borderWidth: 0
  },
  acceptManagerText: { fontSize: 17, color: "#e8fafe" },
  imageContainer: {
    height: 126,
    borderWidth: 2,
    borderColor: "rgba(143, 146, 161, 0.2)",
    width: "100%",
    borderRadius: 16,
    marginBottom: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(143, 146, 161, 0.05)",
    borderStyle: "dashed"
  },
  uploadIconWrapper: {
    borderRadius: 16,
    width: 48,
    height: 48,
    backgroundColor: "rgba(143,146,161,0.5)",
    opacity: 0.5,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    marginHorizontal: "auto"
  },
  uploadIcon: { height: 24, width: 24 },
  dragAndDropText: { fontSize: 14, color: "#4e3e71" }
});
